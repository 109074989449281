import * as React from 'react';
import { Grid, Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
// import atmCardImage from 'assets/images/ksg-logo.png'; // Adjust the path accordingly
import ksgBackgroundImage from '../assets/login.png'; // Import the background image
import { motion } from 'framer-motion';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [resetToken, setResetToken] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [otpSent, setOtpSent] = React.useState(false);
  const [otpValid, setOtpValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // Loading state for spinner
  const [errors, setErrors] = React.useState({
    email: '',
    password: '',
    username: '',
    resetToken: '',
    newPassword: '',
    confirmPassword: '',
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const formStyle = {
    width: '100%',
    maxWidth: 350,
    bgcolor: 'transparent',
    p: 2,
    mx: 'auto',
    borderRadius: 2,
    boxShadow: 3,
    zIndex: 2, // Ensure the form appears above the background
    backdropFilter: 'blur(6px)', // Optional: Adds blur effect to the background
    border: '1px solid rgba(255, 255, 255, 0.3)', // Optional: Border for the form for contrast
  };

  const buttonStyle = {
    borderRadius: 25,
    padding: '10px 20px',
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    let hasError = false;
    const newErrors = { email: '', password: '' };

    if (!email) {
      newErrors.email = 'Email is required';
      hasError = true;
    }
    if (!password) {
      newErrors.password = 'Password is required';
      hasError = true;
    }
    setErrors(newErrors);

    if (hasError) return;

    setLoading(true);

    try {
      const response = await fetch(`${BACKEND_URL}/v1/authorize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username: email, password }),
      });
      const data = await response.json();
      console.log('data', data);

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      const { role, userName, emailId, clientID } = data;
      localStorage.setItem('role', role);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('userName', userName);
      localStorage.setItem('email', emailId);
      localStorage.setItem('clientId', clientID);

      if (role === 'Super Admin') {
        navigate(`/manage-dashboard`);
      } else if (role === 'Admin') {
        navigate(`/fuel-card`);
      } else if (role === 'Broker') {
        navigate(`/manage-brokers`);
      } else {
        navigate('/unknown');
      }
    } catch (error) {
      setErrors({ ...errors, password: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    let hasError = false;
    const newErrors = { username: '', email: '' };

    if (!username) {
      newErrors.username = 'Username is required';
      hasError = true;
    }
    if (!email) {
      newErrors.email = 'Email is required';
      hasError = true;
    }
    setErrors(newErrors);

    if (hasError) return;

    setLoading(true);

    try {
      const response = await fetch(
        `${BACKEND_URL}/v1/forgot-password?username=${username}&emailId=${email}`,
        {
          method: 'POST',
        },
      );

      if (!response.ok) {
        throw new Error('Failed to send reset email. Update a valid Email for the username');
      }

      setMessage('OTP sent valid for 5 mins. Please check your email.');
      setOtpSent(true);
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleValidateOtp = async () => {
    if (!resetToken) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        resetToken: 'OTP is required',
      }));
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${BACKEND_URL}/v1/validate-otp?username=${username}&otp=${resetToken}`,
        {
          method: 'POST',
        },
      );

      if (!response.ok) {
        throw new Error('Invalid OTP');
      }

      setOtpValid(true); // Mark OTP as validated
      setMessage('OTP validated successfully. You can now set a new password.');
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        resetToken: error.message,
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: 'Passwords do not match' }));
      return;
    }

    if (!newPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'New password is required',
      }));
      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: '',
      confirmPassword: '',
    }));

    setLoading(true);

    try {
      if (!otpValid) {
        setMessage('Please validate OTP first.');
        return;
      }

      const resetResponse = await fetch(
        `${BACKEND_URL}/v1/reset-password?username=${username}&token=${resetToken}&newPassword=${newPassword}`,
        {
          method: 'POST',
        },
      );

      if (!resetResponse.ok) {
        throw new Error('Failed to update password');
      }

      setMessage('Password updated successfully');
      setTimeout(() => {
        navigate('/');
        setShowForgotPassword(false); // Ensure to reset state to show login form
      }, 2000); // Redirect to login after 2 seconds
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setResetToken(value);
    }
  };

  return (
    <Grid
      container
      sx={{
        height: '100vh', // Use 100vh to cover full viewport height
        width: '100vw', // Use 100vw to cover full viewport width
        backgroundImage: `url(${ksgBackgroundImage})`,
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center',

        position: 'fixed',
        zIndex: -1,

        top: 0,
        left: 0,
        // marginLeft: 6,
        padding: 0, // remove margin and padding to avoid gaps
        // display: 'flex',
        // flexDirection: 'row',
        // justifyContent: 'center', // Ensures alignment to the left side
        overflowY: 'hidden', // Prevents any unwanted overflow
      }}
    >
      {/* Right side with image and text */}
      <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center"></Grid>

      {/* Left side with the login form */}
      <Grid
        item
        xs={12}
        md={2.9}
        justifyContent="center"
        alignItems="center"
        sx={{ ml: -63, mt: 6 }}
      >
        {/* Container Box for text and form */}
        <Box
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', maxWidth: 300, textAlign: 'center', marginLeft: '50px' }}
        >
          <Box
            // component={motion.img}
            // src={atmCardImage}
            // alt="Fuel Card Design"
            sx={{ maxWidth: '70%', height: 'auto', mt: 13 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        </Box>
        {/* Login Form Box */}
        <Box
          component={motion.div}
          sx={formStyle}
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {!showForgotPassword ? (
            <>
              <Typography
                align="center"
                sx={{
                  mb: 2,
                  fontFamily: 'sans-serif',
                  color: '#FFFFFF',
                  fontSize: '24px',
                }}
              >
                Login
              </Typography>
              <form onSubmit={handleLogin}>
                <TextField
                  id="login-email"
                  placeholder="Username"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  fullWidth
                  sx={{
                    width: '300px', // Adjust width as needed
                    mb: 2,
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#999',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                    '& .MuiInputBase-input': {
                      py: '8px', // Reduce vertical padding to decrease height
                      fontSize: '0.875rem', // Optional: Adjust input text size
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem', // Smaller placeholder font size
                      opacity: 1,
                    },
                  }}
                  autoComplete="username"
                  error={!!errors.email}
                  helperText={errors.email}
                  size="small"
                />
                <TextField
                  id="login-password"
                  placeholder="Password"
                  type="password"
                  variant="outlined"
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  fullWidth
                  sx={{
                    width: '300px', // Adjust width as needed
                    mb: 2,
                    backgroundColor: 'white',
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: '#ccc',
                      },
                      '&:hover fieldset': {
                        borderColor: '#999',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#1976d2',
                      },
                    },
                    '& .MuiInputBase-input': {
                      py: '8px', // Reduce vertical padding to decrease height
                      fontSize: '0.875rem', // Optional: Adjust input text size
                    },
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.75rem', // Smaller placeholder font size
                      opacity: 1,
                    },
                  }}
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password}
                  size="small"
                />
                <Button
                  color="primary"
                  type="submit"
                  onClick={handleLogin}
                  variant="contained"
                  fullWidth
                  sx={{
                    width: '300px', // Custom width
                    height: '36px', // Compact height
                    borderRadius: '8px', // Adjust radius (e.g., '4px', '12px', '24px' for pill shape)
                    fontSize: '0.875rem', // Smaller text
                    textTransform: 'none', // Disable uppercase
                    py: 1, // Tighter vertical padding
                    px: 2, // Tighter horizontal padding
                    '&:hover': {
                      backgroundColor: 'primary.dark', // Optional: Darken hover effect
                    },
                  }}
                  // disabled={loading}
                  endIcon={loading ? <CircularProgress size={24} /> : <CheckCircleFilled />}
                >
                  Submit
                </Button>
              </form>
              <Button
                color="secondary"
                onClick={() => setShowForgotPassword(true)}
                sx={{ mt: 2, borderRadius: 25 }}
                fullWidth
              >
                Forgot Password?
              </Button>

              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
            </>
          ) : otpSent ? (
            <>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: 4, fontFamily: 'Roboto, sans-serif', color: '#FFFFFF' }}
              >
                Reset Password
              </Typography>
              <TextField
                id="otp"
                label="OTP"
                variant="outlined"
                value={resetToken}
                onChange={handleOtpChange}
                fullWidth
                sx={{
                  mb: 2,
                  backgroundColor: 'white', // Set background color to white
                }}
                autoComplete="off"
                inputProps={{
                  maxLength: 6,
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                }}
                error={!!errors.resetToken}
                helperText={errors.resetToken}
              />
              <Button
                color="primary"
                onClick={handleValidateOtp}
                variant="contained"
                fullWidth
                sx={buttonStyle}
                style={{
                  backgroundColor: otpValid ? '#4CAF50' : '#f0f0f0', // Green if OTP is valid, light gray if not
                  color: otpValid ? 'white' : '#333', // White text if OTP is valid, dark text if not
                  border: 'none',
                  padding: '10px 20px',
                  cursor: loading ? 'not-allowed' : 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                disabled={!resetToken}
              >
                Validate OTP
              </Button>
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
              {otpValid && (
                <>
                  <TextField
                    id="new-password"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    sx={{
                      mb: 2,
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#ccc',
                        },
                        '&:hover fieldset': {
                          borderColor: '#999',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '0.875rem', // Adjust this value to your preference (default is 1rem)
                        opacity: 1, // Ensure the placeholder is fully visible
                      },
                    }}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                    autoComplete="off" // Disable autofill
                  />
                  <TextField
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    sx={{
                      mb: 2,
                      backgroundColor: 'white',
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#ccc',
                        },
                        '&:hover fieldset': {
                          borderColor: '#999',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#1976d2',
                        },
                      },
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '0.875rem', // Adjust this value to your preference (default is 1rem)
                        opacity: 1, // Ensure the placeholder is fully visible
                      },
                    }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    autoComplete="off"
                  />
                  <Button
                    color="primary"
                    onClick={handleResetPassword}
                    variant="contained"
                    fullWidth
                    size="small"
                    sx={{
                      width: '300px', // Reduced width (from fullWidth)
                      height: '32px', // Reduced height
                      fontSize: '0.8125rem', // 13px - smaller text
                      borderRadius: '4px', // Subtle rounded corners
                      textTransform: 'none', // Prevent uppercase transformation
                      padding: '6px 12px', // Compact padding
                      '&:hover': {
                        backgroundColor: 'primary.dark',
                      },
                    }}
                    disabled={!newPassword || !confirmPassword}
                  >
                    Reset Password
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: 4, fontFamily: 'Roboto, sans-serif', color: '#FFFFFF' }}
              >
                Forgot Password
              </Typography>
              <TextField
                id="forgot-username"
                placeholder="Username"
                variant="outlined"
                onChange={(event) => setUsername(event.target.value)}
                value={username}
                fullWidth
                sx={{
                  width: '300px', // Adjust width as needed
                  mb: 2,
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#999',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                  '& .MuiInputBase-input': {
                    py: '8px', // Reduce vertical padding to decrease height
                    fontSize: '0.875rem', // Optional: Adjust input text size
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '0.75rem', // Smaller placeholder font size
                    opacity: 1,
                  },
                }}
                autoComplete="off"
                error={!!errors.username}
                helperText={errors.username}
              />
              <TextField
                id="forgot-email"
                placeholder="Email"
                variant="outlined"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                fullWidth
                sx={{
                  width: '300px', // Adjust width as needed
                  mb: 2,
                  backgroundColor: 'white',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#ccc',
                    },
                    '&:hover fieldset': {
                      borderColor: '#999',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2',
                    },
                  },
                  '& .MuiInputBase-input': {
                    py: '8px', // Reduce vertical padding to decrease height
                    fontSize: '0.875rem', // Optional: Adjust input text size
                  },
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '0.75rem', // Smaller placeholder font size
                    opacity: 1,
                  },
                }}
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email}
              />
              <Button
                color="primary"
                onClick={handleForgotPassword}
                variant="contained"
                fullWidth
                // sx={buttonStyle}
                size="small"
                sx={{
                  width: '300px', // Reduced width (from fullWidth)
                  height: '32px', // Reduced height
                  fontSize: '0.8125rem', // 13px - smaller text
                  borderRadius: '4px', // Subtle rounded corners
                  textTransform: 'none', // Prevent uppercase transformation
                  padding: '6px 12px', // Compact padding
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
                disabled={!username || !email}
              >
                Send OTP
              </Button>
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
