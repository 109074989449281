import { useEffect, useState } from 'react';

const InactivityTimer = ({ onLogout, timeout = 300000 }) => {
  const [lastActivity, setLastActivity] = useState(Date.now());

  useEffect(() => {
    const handleActivity = () => {
      setLastActivity(Date.now());
    };

    const handleTimeout = () => {
      if (Date.now() - lastActivity > timeout) {
        onLogout();
      }
    };

    const timer = setInterval(handleTimeout, 1000);

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      clearInterval(timer);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [lastActivity, timeout, onLogout]);

  return null;
};

export default InactivityTimer;
