import * as React from 'react';
import { Grid, Box, Button, TextField, Typography, CircularProgress } from '@mui/material';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import atmCardImage from 'assets/images/FuelCardDesign.png'; // Adjust the path accordingly
import { motion } from 'framer-motion';

const Login = () => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showForgotPassword, setShowForgotPassword] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [resetToken, setResetToken] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [otpSent, setOtpSent] = React.useState(false);
  const [otpValid, setOtpValid] = React.useState(false);
  const [loading, setLoading] = React.useState(false); // Loading state for spinner
  const [errors, setErrors] = React.useState({
    email: '',
    password: '',
    username: '',
    resetToken: '',
    newPassword: '',
    confirmPassword: ''
  });

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();

  const formStyle = {
    width: '100%',
    maxWidth: 400,
    bgcolor: '#EEEEEE',
    p: 4,
    mx: 'auto',
    borderRadius: 2,
    boxShadow: 3
  };

  const buttonStyle = {
    borderRadius: 25,
    padding: '10px 20px'
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    let hasError = false;
    const newErrors = { email: '', password: '' };

    if (!email) {
      newErrors.email = 'Email is required';
      hasError = true;
    }
    if (!password) {
      newErrors.password = 'Password is required';
      hasError = true;
    }
    setErrors(newErrors);

    if (hasError) return;

    setLoading(true);

    try {
      const response = await fetch(`${BACKEND_URL}/v1/authorize`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: email, password })
      });
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      const { role, userName, emailId, clientID } = data;
      localStorage.setItem('role', role);
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('userName', userName);
      localStorage.setItem('email', emailId);
      localStorage.setItem('clientId', clientID);

      if (role === 'Super Admin') {
        navigate(`/manage-dashboard`);
      } else if (role === 'Admin') {
        navigate(`/fuel-card`);
      } else if (role === 'Broker') {
        navigate(`/manage-brokers`);
      } else {
        navigate('/unknown');
      }
    } catch (error) {
      setErrors({ ...errors, password: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    let hasError = false;
    const newErrors = { username: '', email: '' };

    if (!username) {
      newErrors.username = 'Username is required';
      hasError = true;
    }
    if (!email) {
      newErrors.email = 'Email is required';
      hasError = true;
    }
    setErrors(newErrors);

    if (hasError) return;

    setLoading(true);

    try {
      const response = await fetch(`${BACKEND_URL}/v1/forgot-password?username=${username}&emailId=${email}`, {
        method: 'POST'
      });

      if (!response.ok) {
        throw new Error('Failed to send reset email. Check Username and Email');
      }

      setMessage('OTP sent valid for 5 mins. Please check your email.');
      setOtpSent(true);
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleValidateOtp = async () => {
    if (!resetToken) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        resetToken: 'OTP is required'
      }));
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${BACKEND_URL}/v1/validate-otp?username=${username}&otp=${resetToken}`, {
        method: 'POST'
      });

      if (!response.ok) {
        throw new Error('Invalid OTP');
      }

      setOtpValid(true);
      setMessage('OTP validated successfully. You can now set a new password.');
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        resetToken: error.message
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: 'Passwords do not match' }));
      return;
    }

    if (!newPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'New password is required'
      }));
      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      newPassword: '',
      confirmPassword: ''
    }));

    setLoading(true);

    try {
      if (!otpValid) {
        setMessage('Please validate OTP first.');
        return;
      }

      const resetResponse = await fetch(
        `${BACKEND_URL}/v1/reset-password?username=${username}&token=${resetToken}&newPassword=${newPassword}`,
        {
          method: 'POST'
        }
      );

      if (!resetResponse.ok) {
        throw new Error('Failed to update password');
      }

      setMessage('Password updated successfully');
      setTimeout(() => {
        navigate('/');
        setShowForgotPassword(false); // Ensure to reset state to show login form
      }, 2000); // Redirect to login after 2 seconds
    } catch (error) {
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (event) => {
    const value = event.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setResetToken(value);
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      {/* Right side with image and text */}
      <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#EEEEEE' }}>
        <Box textAlign="center" sx={{ color: 'black' }}>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              mb: 1,
              fontSize: '5rem',
              fontFamily: 'Roboto, sans-serif',
              background: 'linear-gradient(45deg, #FF6F61, #FFB74D)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              display: 'inline'
            }}
          >
            KSG Fuel
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mb: 4,
              fontFamily: 'Roboto, sans-serif',
              color: '#333'
            }}
          >
            Powering success with every Drop
          </Typography>
          <Box
            component={motion.img}
            src={atmCardImage}
            alt="Fuel Card Design"
            sx={{ maxWidth: '80%', height: 'auto' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          />
        </Box>
      </Grid>
      {/* Left side with the login form */}
      <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" sx={{ bgcolor: '#EEEEEE' }}>
        <Box component={motion.div} sx={formStyle} initial={{ scale: 0.9 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
          {!showForgotPassword ? (
            <>
              <Typography variant="h3" align="center">
                Login
              </Typography>
              <TextField
                id="login-email"
                placeholder="Username"
                variant="outlined"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                fullWidth
                sx={{ mb: 2 }}
                autoComplete="username"
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                id="login-password"
                placeholder="Password"
                type="password"
                variant="outlined"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                fullWidth
                sx={{ mb: 2 }}
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password}
              />
              <Button
                color="primary"
                type="submit"
                onClick={handleLogin}
                variant="contained"
                fullWidth
                sx={buttonStyle}
                disabled={!email || !password}
                endIcon={loading ? <CircularProgress size={24} /> : <CheckCircleFilled />}
              >
                Submit
              </Button>

              <Button color="secondary" onClick={() => setShowForgotPassword(true)} sx={{ mt: 2, borderRadius: 25 }} fullWidth>
                Forgot Password?
              </Button>

              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
            </>
          ) : otpSent ? (
            <>
              <Typography variant="h6" align="center">
                Reset Password
              </Typography>
              <TextField
                id="otp"
                label="OTP"
                variant="outlined"
                value={resetToken}
                onChange={handleOtpChange}
                fullWidth
                sx={{ mb: 2 }}
                autoComplete="off"
                inputProps={{
                  maxLength: 6,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
                error={!!errors.resetToken}
                helperText={errors.resetToken}
              />
              <Button color="primary" onClick={handleValidateOtp} variant="contained" fullWidth sx={buttonStyle} disabled={!resetToken}>
                Validate OTP
              </Button>
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
              {otpValid && (
                <>
                  <TextField
                    id="new-password"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                  />
                  <TextField
                    id="confirm-password"
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    sx={{ mb: 2 }}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                  <Button
                    color="primary"
                    onClick={handleResetPassword}
                    variant="contained"
                    fullWidth
                    sx={buttonStyle}
                    disabled={!newPassword || !confirmPassword}
                  >
                    Reset Password
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Typography variant="h6" align="center">
                Forgot Password
              </Typography>
              <TextField
                id="forgot-username"
                label="Username"
                variant="outlined"
                onChange={(event) => setUsername(event.target.value)}
                value={username}
                fullWidth
                sx={{ mb: 2 }}
                autoComplete="off"
                error={!!errors.username}
                helperText={errors.username}
              />
              <TextField
                id="forgot-email"
                label="Email"
                variant="outlined"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                fullWidth
                sx={{ mb: 2 }}
                autoComplete="off"
                error={!!errors.email}
                helperText={errors.email}
              />
              <Button
                color="primary"
                onClick={handleForgotPassword}
                variant="contained"
                fullWidth
                sx={buttonStyle}
                disabled={!username || !email}
              >
                Send OTP
              </Button>
              <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                {message}
              </Typography>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
