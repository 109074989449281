import * as React from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Popover,
  TextField,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';

import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import { darken, lighten, styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import FuelCard from './detailed-view';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { textTransform } from '../../../node_modules/@mui/system/index';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const FuelCardListView = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const rawStatusFilter = queryParams.get('status'); // Keep raw value
  const statusFilter = rawStatusFilter?.toUpperCase(); // Uppercase for comparison
  const [clients, setClients] = React.useState([]);
  const [, setClientId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const loggedInUserClientId = localStorage.getItem('clientId');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const [cardsListView, setCardsListView] = React.useState([]);
  const [isDetailedViewOpen, setIsDetailedViewOpen] = React.useState(false);
  const [currentRowDetails, setCurrentRowDetails] = React.useState({});
  const [isAllCardsChosen, setIsAllCardsChosen] = React.useState(false);

  const [selectedClient, setSelectedClient] = useState('');
  const [payrUse] = useState('');
  const [type] = useState('');
  const [searchParam] = useState('');

  const cardStatuses = [
    { id: 1, value: 'Active', shortForm: 'ACTIVE' },
    { id: 2, value: 'Blocked', shortForm: 'INACTIVE' },
  ];

  const superAdminCardsListViewColumns = [
    {
      field: 'cardNumber',
      headerName: 'CARD NUMBER',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'clientId',
      headerName: 'ACCOUNT NUMBER',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'customerId',
      headerName: 'CUSTOMER ID',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: 'CUSTOMER NAME',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardStatus',
      headerName: 'STATUS',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
  ];

  const superAdminNoRecordsFoundRow = [
    {
      id: '',
      cardNumber: '',
      clientId: 'No records found',
      customerId: '',
      customerName: '',
      cardStatus: '',
    },
  ];

  const clientCardsListViewColumns = [
    {
      field: 'cardNumber',
      headerName: 'CARD NUMBER',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'unitNumber',
      headerName: 'UNIT NO',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'driver',
      headerName: 'DRIVER',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardStatus',
      headerName: 'STATUS',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'savingsWeekly',
      headerName: 'SAVINGS WEEKLY',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'savingsMonthly',
      headerName: 'SAVINGS MONTHLY',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'savingsYearly',
      headerName: 'SAVINGS YEARLY',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
  ];

  const clientNoRecordsFoundRow = [
    {
      id: '',
      unitNumber: '',
      employeeNumber: '',
      driver: 'No records found',
      cardStatus: '',
      savingsWeekly: '',
      savingsMonthly: '',
      savingsYearly: '',
    },
  ];

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'sans-serif',
  };
  const fontStylesChartTypography = {
    fontSize: '14px',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    position: 'absolute',
    left: '240px',
    marginBottom: '15px',
    marginLeft: 'clamp(-3vw, -50px)',
    transition: 'margin-left 0.3s ease',
  };
  const inputLabelStyles = {
    fontSize: '16px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600,
    marginBottom: '10px',
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '10px' };
  const buttonsFontSize = '16px';
  const buttonBackground = '#011f4b';
  const buttonChosenBackground = '#005b96';

  const fetchCardDetails = async (client) => {
    if (!client) return;

    const customerId = client.clientID;
    setLoading(true);
    let getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/card?clientID=${customerId}`;

    const statusFilter = new URLSearchParams(window.location.search).get('status');
    if (statusFilter) {
      const apiStatus =
        statusFilter.toUpperCase() === 'BLOCKED' ? 'INACTIVE' : statusFilter.toUpperCase();
      getCardDetailsForCustomerUrl += `&status=${apiStatus}`;
    }

    try {
      const response = await fetch(getCardDetailsForCustomerUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      generateListView(data);
    } catch (error) {
      console.error('Error fetching card details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCustomerChange = (event) => {
    const inputClient = event.target.value;
    const chosenClient = clients.find((client) => client.clientID === inputClient);
    if (chosenClient) {
      setSelectedClient(inputClient);
      fetchCardDetails(chosenClient);
    }
  };

  useEffect(() => {
    getClients();
  }, [doNotPerformCustomerFiltration]);

  const getClients = () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }
    setLoading(true);
    fetch(getClientsUrl)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        data.sort((a, b) => a.clientID - b.clientID);
        setClients(data);
        setLoading(false);

        if (!doNotPerformCustomerFiltration && data.length > 0) {
          const firstClientID = data[0].clientID;
          setSelectedClient(firstClientID);
          if (!rawStatusFilter) {
            // Only fetch if no status filter from dashboard
            fetchCardDetails(data[0]);
          }
        } else if (doNotPerformCustomerFiltration && data.length > 0) {
          setSelectedClient('');
          if (!rawStatusFilter) {
            // Only fetch if no status filter from dashboard
            getAllAvailableCards();
          }
        }
      });
  };

  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchCardSummaries();
  }, [type, searchParam, payrUse]);

  const fetchCardSummaries = () => {
    let url = `${BACKEND_URL}/v1/card-summaries`;

    const queryParams = new URLSearchParams();
    if (type) queryParams.append('type', type);
    if (searchParam) queryParams.append('searchParam', searchParam);
    if (payrUse) queryParams.append('payrUse', payrUse);

    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`;
    }

    setLoading(true);
    fetch(url)
      .then((response) => {
        if (response.status === 204) {
          throw new Error('No cards found for the given date range.');
        }
        return response.text();
      })
      .then((data) => {
        setMessage(data);
      })
      .catch((error) => {
        console.error('Error fetching card summaries:', error);
        setMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCardsByStatus = async () => {
    setLoading(true);
    try {
      let url = `${BACKEND_URL}/v1/by-status`;
      if (statusFilter) {
        // Map "BLOCKED" to "INACTIVE" for the API call
        const apiStatus = statusFilter === 'BLOCKED' ? 'INACTIVE' : statusFilter;
        url += `?status=${apiStatus}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Fetched cards from /by-status:', data); // Debug log
      if (doNotPerformCustomerFiltration) {
        await generateAvailableCardsListView(data);
      } else {
        generateListView(data);
      }
    } catch (error) {
      console.error('Error fetching cards:', error);
      setCardsListView([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data when status filter changes
  useEffect(() => {
    if (rawStatusFilter && (doNotPerformCustomerFiltration || clients.length > 0)) {
      fetchCardsByStatus();
    }
  }, [rawStatusFilter, doNotPerformCustomerFiltration, clients.length]);

  const generateListView = (cards) => {
    let cardsList = [];
    let cardSerialNo = 1;

    cards.forEach((card) => {
      const displayStatus =
        card?.status === 'INACTIVE'
          ? 'Blocked'
          : card?.status === 'ACTIVE'
          ? 'Active'
          : card?.status || 'Unknown';

      const clientDetails = getClientDetailsByCustomerId(card?.companyXRef?.trim() || '');
      const isUnassigned = card?.companyXRef?.trim() === 'DF82B';

      const cardObject = {
        id: cardSerialNo++,
        cardNumber: card.cardNumber || '',
        clientId: isUnassigned ? 'N/A' : card?.companyXRef || '',
        customerId: isUnassigned ? 'KSG' : clientDetails?.customerID || '',
        customerName: isUnassigned ? 'KSG' : clientDetails?.clientName || '',
        cardStatus: isUnassigned ? 'Unassigned' : displayStatus,
      };

      if (!doNotPerformCustomerFiltration) {
        Object.assign(cardObject, {
          unitNumber: card?.unitNumber?.trim() || '',
          employeeNumber: card?.driverId || '',
          driver: card?.driverName?.trim() === 'undefined' ? '' : card?.driverName?.trim() || '',
          savingsWeekly: 0,
          savingsMonthly: 0,
          savingsYearly: 0,
        });
      }

      cardsList.push(cardObject);
    });

    console.log('Generated cardsList for regular user:', cardsList);
    setCardsListView(cardsList);
  };

  const generateAvailableCardsListView = async (cards) => {
    let cardsList = [];
    let cardSerialNo = 1;

    for (const card of cards) {
      const cardStatus = cardStatuses.find((status) => status.shortForm === card?.status);
      const clientDetails = getClientDetailsByCustomerId(card?.companyXRef?.trim() || '');
      const isUnassigned = card?.companyXRef?.trim() === 'DF82B';

      const cardObject = {
        id: cardSerialNo++,
        cardNumber: card?.cardNumber || '',
        clientId: isUnassigned ? 'N/A' : card?.companyXRef || '',
        customerId: isUnassigned ? 'KSG' : clientDetails?.customerID || '',
        customerName: isUnassigned ? 'KSG' : clientDetails?.clientName || '',
        cardStatus: isUnassigned ? 'Unassigned' : cardStatus?.value || 'Unknown',
      };
      cardsList.push(cardObject);
    }
    console.log('Generated cardsList for Super Admin:', cardsList);
    setCardsListView(cardsList);
  };

  const getClientDetailsByCustomerId = (customerId) => {
    let client = clients.find((client) => client.clientID === customerId);
    return client || {};
  };

  const getAllAvailableCards = async () => {
    let url = `${BACKEND_URL}/v1/card/all`;
    if (statusFilter) {
      const apiStatus = statusFilter === 'BLOCKED' ? 'INACTIVE' : statusFilter;
      url += `?status=${apiStatus}`;
    }
    setLoading(true);
    try {
      const response = await fetch(url);
      const data = await response.json();
      await generateAvailableCardsListView(data);
    } catch (error) {
      console.error('Error fetching card details:', error);
    } finally {
      setLoading(false);
    }
  };

  const gridRowClicked = (event) => {
    let rowDetails = event?.row;
    setCurrentRowDetails(rowDetails);
    setIsDetailedViewOpen(!isDetailedViewOpen);
  };

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Active': {
      backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
      },
    },
    '& .super-app-theme--Unassigned': {
      backgroundColor: 'rgb(255, 241, 172)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(255, 241, 172)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Blocked': {
      backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
      },
    },
    '& .MuiDataGrid-row': {
      height: '36px !important',
      minHeight: '36px !important',
    },
    '& .MuiDataGrid-cell': {
      lineHeight: '36px',
      padding: '0 !important',
    },
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  const filteredClients = doNotPerformCustomerFiltration
    ? clients
    : clients.filter((client) => loggedInUserClientId.includes(client.clientID));

  const displayedClients = doNotPerformCustomerFiltration
    ? [...filteredClients]
    : filteredClients.slice(0, 1);

  const firstClient = displayedClients[0] || {};

  const searchFilteredClients = displayedClients.filter(
    (client) =>
      client.clientID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.customerID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      client.clientName.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelect = (clientID) => {
    handleCustomerChange({ target: { value: clientID } });
    setOpen(false);
  };

  return (
    <div className="container" style={{ marginLeft: '10px' }}>
      <Typography
        variant="h1"
        sx={{
          fontStylesRootTypography,
          textAlign: 'left',
          marginLeft: '1px',
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
        }}
        gutterBottom
      >
        CARD MANAGEMENT
      </Typography>
      <form className="container">
        <Grid container spacing={2} style={gridRowStyles}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Box>
              {displayedClients.length > 0 && (
                <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                  <Tooltip title="Get cards assigned by customer">
                    <FormControl fullWidth>
                      {doNotPerformCustomerFiltration || filteredClients.length > 1 ? (
                        <>
                          <Box
                            component="div"
                            onClick={handleClick}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              padding: '4px',
                              cursor: 'pointer',
                              justifyContent: 'space-between',
                              minHeight: '15px',
                              fontSize: '10px',
                            }}
                            style={inputLabelStyles}
                          >
                            <Typography variant="body1" sx={{ fontSize: '12px' }}>
                              {selectedClient
                                ? `${
                                    clients.find((client) => client.clientID === selectedClient)
                                      ?.clientID || ''
                                  } - ${
                                    clients.find((client) => client.clientID === selectedClient)
                                      ?.customerID || ''
                                  } - ${
                                    clients.find((client) => client.clientID === selectedClient)
                                      ?.clientName || ''
                                  }`
                                : 'Customer'}
                            </Typography>
                          </Box>
                          <Popover
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 250,
                                width: 300,
                              },
                            }}
                          >
                            <div style={{ width: '95%' }}>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="search"
                                label="Search"
                                type="search"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{
                                  margin: 1,
                                  '& .MuiInputBase-root': {
                                    height: '25px',
                                    fontSize: '10px',
                                    padding: '2px 6px',
                                  },
                                  '& .MuiInputLabel-root': {
                                    fontSize: '10px',
                                  },
                                }}
                              />
                              {searchFilteredClients.length > 0 ? (
                                searchFilteredClients.map((client) => (
                                  <MenuItem
                                    key={client.clientID}
                                    selected={client.clientID === selectedClient}
                                    onClick={() => handleSelect(client.clientID)}
                                    sx={{
                                      minHeight: '30px',
                                      fontSize: '12px',
                                      padding: '4px 8px',
                                    }}
                                  >
                                    {client.clientID
                                      ? `${client.clientID} - ${client.customerID} - ${client.clientName}`
                                      : ''}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>No results found</MenuItem>
                              )}
                            </div>
                          </Popover>
                        </>
                      ) : (
                        <div style={{ marginTop: '20px', marginLeft: '12px' }}>
                          <Typography variant="body1">
                            {firstClient.clientID
                              ? `${firstClient.clientID} - ${firstClient.customerID} - ${firstClient.clientName}`
                              : 'No customer selected'}
                          </Typography>
                        </div>
                      )}
                    </FormControl>
                  </Tooltip>
                </FormControl>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>

      {loading ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                <CardContent>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <CircularProgress color="success" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : cardsListView.length === 0 ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                <CardContent>
                  <div
                    style={{
                      height: 700,
                      maxWidth: '95%',
                      marginTop: '20px',
                      align: 'center',
                    }}
                  >
                    <StyledDataGrid
                      onRowClick={gridRowClicked}
                      rows={
                        doNotPerformCustomerFiltration
                          ? superAdminNoRecordsFoundRow
                          : clientNoRecordsFoundRow
                      }
                      columns={
                        doNotPerformCustomerFiltration
                          ? superAdminCardsListViewColumns
                          : clientCardsListViewColumns
                      }
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      rowHeight={25}
                      slots={{ toolbar: CustomToolbar }}
                      columnHeaderHeight={31}
                      sx={{
                        headerAlign: 'left',
                        marginLeft: '-10px',
                        '& .MuiDataGrid-columnHeaders': {
                          fontSize: '10px',
                          backgroundColor: '#31478F',
                          color: 'white',
                        },
                        '& .MuiDataGrid-cell': {
                          fontSize: '10px',
                          padding: '0px !important',
                          lineHeight: '1 !important',
                        },
                        '& .MuiDataGrid-row': {
                          minHeight: '25px !important',
                          maxHeight: '25px !important',
                          height: '25px !important',
                          padding: '0px !important',
                          margin: '0px !important',
                          borderBottom: 'none !important',
                        },
                        '& .MuiDataGrid-row:nth-of-type(odd) .MuiDataGrid-cell': {
                          backgroundColor: '#E6F0FF',
                        },
                        '& .MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell': {
                          backgroundColor: '#F8F8F8',
                        },
                        '& .MuiDataGrid-virtualScroller': {
                          padding: '0px !important',
                          margin: '0px !important',
                        },
                        '& .MuiDataGrid-virtualScrollerContent': {
                          gap: '0px !important',
                        },
                        '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
                          marginBottom: '0px !important',
                        },
                        '& .MuiDataGrid-main': {
                          overflow: 'hidden !important',
                        },
                      }}
                      // slots={{
                      //   toolbar: (props) => (
                      //     <GridToolbar
                      //       {...props}
                      //       sx={{ '& .MuiTypography-root': { fontSize: '8px' } }}
                      //     />
                      //   ),
                      // }}
                    />
                  </div>
                </CardContent>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        cardsListView.length > 0 && (
          <div className="container" style={{ display: 'flex' }}>
            <div className="card" style={{ flex: '1', margin: '2px' }}>
              <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                  <CardContent>
                    <div
                      style={{
                        height: 700,
                        maxWidth: '95%',
                        marginTop: '10px',
                        align: 'center',
                      }}
                    >
                      <StyledDataGrid
                        rows={cardsListView}
                        columns={
                          doNotPerformCustomerFiltration
                            ? superAdminCardsListViewColumns
                            : clientCardsListViewColumns
                        }
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        rowHeight={25}
                        columnHeaderHeight={31}
                        onRowClick={gridRowClicked}
                        sx={{
                          headerAlign: 'left',
                          marginLeft: '-10px',
                          '& .MuiDataGrid-columnHeaders': {
                            fontSize: '10px',
                            backgroundColor: '#31478F',
                            color: 'white',
                          },
                          '& .MuiDataGrid-cell': {
                            fontSize: '10px',
                            padding: '0px !important',
                            lineHeight: '1 !important',
                          },
                          '& .MuiDataGrid-row': {
                            minHeight: '25px !important',
                            maxHeight: '25px !important',
                            height: '25px !important',
                            padding: '0px !important',
                            margin: '0px !important',
                            borderBottom: 'none !important',
                          },
                          '& .MuiDataGrid-row:nth-of-type(odd) .MuiDataGrid-cell': {
                            backgroundColor: '#E6F0FF',
                          },
                          '& .MuiDataGrid-row:nth-of-type(even) .MuiDataGrid-cell': {
                            backgroundColor: '#F8F8F8',
                          },
                          '& .MuiDataGrid-virtualScroller': {
                            padding: '0px !important',
                            margin: '0px !important',
                          },
                          '& .MuiDataGrid-columnHeader[data-field="clientId"]': {
                            marginLeft: '-10px',
                          },
                          '& .MuiDataGrid-virtualScrollerContent': {
                            gap: '0px !important',
                          },
                          '& .MuiDataGrid-row.MuiDataGrid-row--lastVisible': {
                            marginBottom: '0px !important',
                          },
                          '& .MuiDataGrid-main': {
                            overflow: 'hidden !important',
                          },
                          '& .MuiDataGrid-toolbar': {
                            fontSize: '1px',
                          },
                        }}
                        slots={{ toolbar: CustomToolbar }}
                      />
                    </div>
                  </CardContent>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      )}

      <div>
        <Dialog fullScreen open={isDetailedViewOpen} onClose={() => setIsDetailedViewOpen(false)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar sx={{ minHeight: 35, padding: '2px 6px' }}>
              <Typography
                sx={{ ml: 1, flex: 1, fontSize: '3px' }}
                variant="h6"
                component="div"
              ></Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setIsDetailedViewOpen(false)}
                aria-label="close"
                sx={{ fontSize: '4px', padding: '2px' }}
              >
                <CloseIcon sx={{ fontSize: '4px' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            {currentRowDetails && (
              <FuelCard
                inputCardNumber={currentRowDetails.cardNumber}
                inputClientId={currentRowDetails.clientId}
                inputCustomerId={currentRowDetails.customerId}
                inputCustomerName={currentRowDetails.customerName}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default FuelCardListView;
