import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  TextField
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import Loader from 'components/Loader';

const ManageDiscount = ({ isOnboard = false, onSuccess, client }) => {
  const [loading, setLoading] = React.useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonsFontSize = '16px';
  const [chosenAccount, setChosenAccount] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [discountPercentage, setDiscountPercentage] = React.useState('');
  const [discountHistoryListView, setDiscountHistoryListView] = React.useState([]);
  const [acknowledgementMessage, setAcknowledgementMessage] = React.useState('');
  const [isDiscountAcknowledged, setIsDiscountAcknowledged] = React.useState(false);
  const [percentageError, setPercentageError] = React.useState('');
  const buttonBackground = '#011f4b';
  const [accountDetails, setAccountDetails] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleAccountChange = (event) => {
    const selectedAccount = accountDetails.find((account) => account.clientID === event.target.value);
    if (selectedAccount) {
      const { clientID, customerID } = selectedAccount;
      setChosenAccount(clientID);
      setCustomerId(customerID);
      getDiscountByAccountId(clientID);
    }
  };

  const handleDiscountChange = (event) => {
    event.preventDefault();
    if (isOnboard) {
      const createDiscountObject = {
        discount: discountPercentage
      };
      onSuccess(createDiscountObject);
    } else {
      const sendApprovalEmailUrl = `${BACKEND_URL}/v1/send-approval-email?clientID=${chosenAccount}&customerID=${customerId}&discount=${discountPercentage}`;
      setLoading(true);
      fetch(sendApprovalEmailUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          alert('Approval email sent successfully');
          setChosenAccount('');
          setDiscountPercentage('');
          setAcknowledgementMessage('');
          setIsDiscountAcknowledged(false);
          getDiscountByAccountId(chosenAccount);
        })
        .catch((error) => {
          console.error('Error sending approval email:', error);
        })
        .finally(() => setLoading(false));
    }
  };

  const getDiscountByAccountId = (accountId) => {
    const getDiscountUrl = `${BACKEND_URL}/v1/discounts?clientID=${accountId}`;
    setLoading(true);
    fetch(getDiscountUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setDiscountPercentage(data.discount);
        convertDiscountHistory(data.discountHistory);
      })
      .catch((error) => {
        console.error('Error fetching discount details:', error);
        setDiscountPercentage(0);
        convertDiscountHistory({});
      })
      .finally(() => setLoading(false));
  };

  const discountHistoryListViewColumns = [
    {
      field: 'id',
      headerName: 'Serial No.',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'date',
      headerName: 'Discount Date',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'discount',
      headerName: 'Discount Applied',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    }
  ];

  const convertDiscountHistory = (history) => {
    const historyList = [];
    let historyId = 1;
    for (const [key, value] of Object.entries(history)) {
      const date = new Date(key);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
      };
      const readableDate = date.toLocaleString('en-US', options);
      historyList.push({
        id: historyId++,
        date: readableDate,
        discount: value
      });
    }

    historyList.sort((a, b) => new Date(a.date) - new Date(b.date));
    setDiscountHistoryListView(historyList);
  };

  const acknowledgeDiscount = () => {
    setIsDiscountAcknowledged(!isDiscountAcknowledged);
  };

  const handlePercentageChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value >= 0 && value <= 100) {
      setDiscountPercentage(value);
      setPercentageError('');
      setTimeout(() => {
        setAcknowledgementMessage(
          `Are you sure about applying a discount of ${value}% on invoices generated for client ${isOnboard ? client : chosenAccount}?`
        );
      }, 500);
    } else {
      setPercentageError('Percentage can be only between 0 - 100');
    }
  };

  useEffect(() => {
    if (isOnboard) {
      // Implement onboard-specific logic if needed
    } else {
      const getClientsUrl = `${BACKEND_URL}/v1/client`;

      fetch(getClientsUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          data.sort((a, b) => a.clientID - b.clientID);
          setAccountDetails(data);
        })
        .catch((error) => {
          console.error('Error fetching client details:', error);
        });
    }
  }, [isOnboard]);

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode)
      }
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode)
      }
    }
  }));

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif'
  };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif'
  };
  const cardHeight = 300;
  const inputLabelStyles = {
    fontSize: '12px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '12px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600
    }
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  const filteredDiscountHistoryListView = discountHistoryListView
    .filter((entry) => entry.date.toLowerCase().includes(searchTerm.toLowerCase()) || entry.discount.toString().includes(searchTerm))
    .sort((a, b) => {
      if (a.date.toLowerCase().includes(searchTerm.toLowerCase())) return -1;
      if (b.date.toLowerCase().includes(searchTerm.toLowerCase())) return 1;
      return 0;
    });

  return (
    <div>
      <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
        Manage Discount
      </Typography>
      {loading && <Loader />}
      <div className="container" style={{ display: 'flex' }}>
        <div className="card" style={{ flex: '1' }}>
          <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
            <CardContent>
              <form id="check-available-inspectors" onSubmit={handleDiscountChange}>
                <div>
                  {isOnboard && (
                    <Typography variant="h5" sx={{ m: 1 }}>
                      Account: {client}
                    </Typography>
                  )}
                </div>
                {!isOnboard && (
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={5} md={5} lg={5} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '90%', minHeight: 2 }} size="small">
                          <InputLabel id="chosenAccount" style={inputLabelStyles}>
                            Account
                          </InputLabel>
                          <Select
                            labelId="chosenAccount"
                            id="chosenAccount"
                            value={chosenAccount}
                            label="Account"
                            onChange={handleAccountChange}
                          >
                            {accountDetails.map((account) => (
                              <MenuItem key={account.clientID} value={account.clientID}>
                                {account.clientID} - {account.customerID} - {account.clientName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={3} sm={3} md={3} lg={3}>
                    <Box>
                      <FormControl sx={{ minWidth: '90%' }} size="small" error={Boolean(percentageError)}>
                        <TextField
                          style={inputLabelStyles}
                          id="discountPercentage"
                          label={discountPercentage ? 'Discount Percentage' : 'Discount percentage (0-100)'}
                          value={discountPercentage}
                          onChange={handlePercentageChange}
                          InputLabelProps={textFieldLabelStyles}
                          required
                        />
                        {percentageError && <FormHelperText id="input-error-text">{percentageError}</FormHelperText>}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={6} sm={2} md={2} lg={2}>
                    <Box>
                      <TextField
                        label="Search Discount History"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputLabelProps={textFieldLabelStyles}
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                    {acknowledgementMessage && (
                      <Box>
                        <FormControlLabel
                          control={<Checkbox onChange={acknowledgeDiscount} />}
                          label={acknowledgementMessage}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontFamily: 'DM Sans, sans-serif'
                            }
                          }}
                        />
                      </Box>
                    )}
                    <Button
                      style={{
                        marginTop: '2px',
                        fontFamily: 'DM Sans',
                        fontSize: buttonsFontSize,
                        backgroundColor: buttonBackground
                      }}
                      type="submit"
                      variant="contained"
                      disabled={!isDiscountAcknowledged}
                      sx={{ mt: 0, mb: 0, borderRadius: 0 }}
                    >
                      {isOnboard ? 'Set Discount' : 'Apply Discount'}
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                </Grid>
              </form>

              {filteredDiscountHistoryListView.length > 0 && (
                <div className="container" style={{ display: 'flex' }}>
                  <div className="card" style={{ flex: '1' }}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                        <Card>
                          <CardContent>
                            <Grid container alignItems="center" justifyContent="center">
                              <Grid item>
                                <Typography variant="h5" style={fontStylesChartTypography}>
                                  Discount History
                                </Typography>
                              </Grid>
                            </Grid>
                            <StyledDataGrid
                              sx={{
                                '& .super-app-theme--header': {
                                  backgroundColor: 'rgba(0, 9, 81)',
                                  color: 'white'
                                }
                              }}
                              rows={filteredDiscountHistoryListView}
                              columns={discountHistoryListViewColumns}
                              rowHeight={36}
                              getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ManageDiscount;
