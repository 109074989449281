import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Fade,
  Grow,
  TextField,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';
import {
  Person as PersonIcon,
  Email as EmailIcon,
  Work as WorkIcon,
  LocationOn as LocationIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Profile = () => {
  const userName = localStorage.getItem('userName');
  const email = localStorage.getItem('email');
  const role = localStorage.getItem('role');
  const clientID = localStorage.getItem('clientId');

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const [clientDetails, setClientDetails] = useState({
    customerID: '',
    clientName: '',
    primaryContactNumber: '',
    secondaryContactNumber: '',
    pointOfContact: '',
    secondaryPointOfContact: '',
    primaryEmail: '',
    secondaryEmail: '',
    address: '',
  });

  const [editMode, setEditMode] = useState(false);
  const [editedDetails, setEditedDetails] = useState({ ...clientDetails });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    if (clientID) {
      fetchClientDetails(clientID);
    }
  }, [clientID]);

  const fetchClientDetails = async (clientID) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/client`, {
        params: { clientID },
      });

      if (response.data && response.data.length > 0) {
        const client = response.data[0];
        setClientDetails({
          customerID: client.customerID,
          clientName: client.clientName || client.name || 'N/A',
          primaryContactNumber: client.primaryContactNumber || 'N/A',
          secondaryContactNumber: client.secondaryContactNumber || 'N/A',
          pointOfContact: client.pointOfContact || 'N/A',
          secondaryPointOfContact: client.secondaryPointOfContact || 'N/A',
          primaryEmail: client.primaryEmail || 'N/A',
          secondaryEmail: client.secondaryEmail || 'N/A',
          address: client.address || 'N/A',
        });
        setEditedDetails({
          customerID: client.customerID,
          clientName: client.clientName || client.name || 'N/A',
          primaryContactNumber: client.primaryContactNumber || 'N/A',
          secondaryContactNumber: client.secondaryContactNumber || 'N/A',
          pointOfContact: client.pointOfContact || 'N/A',
          secondaryPointOfContact: client.secondaryPointOfContact || 'N/A',
          primaryEmail: client.primaryEmail || 'N/A',
          secondaryEmail: client.secondaryEmail || 'N/A',
          address: client.address || 'N/A',
        });
      } else {
        console.error('No client details found for the given clientID');
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
    const parsedAddress = parseAddress(clientDetails.address);
    console.log('Parsed Address:', parsedAddress); // Debugging
    // setEditedDetails((prevDetails) => ({
    //   ...prevDetails,
    //   ...parsedAddress,
    // }));
    setEditedDetails({
      ...clientDetails, // Keep other details
      ...parsedAddress, // Spread parsed address fields
    });
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setEditedDetails({ ...clientDetails });
  };

  const handleSaveClick = async () => {
    try {
      console.log('Edited Details:', editedDetails);

      // Construct the address string from the individual fields
      const updatedAddress = [
        editedDetails.unitNumber,
        editedDetails.streetNumber,
        editedDetails.streetName,
        editedDetails.city,
        editedDetails.province,
        editedDetails.country,
        editedDetails.postalCode,
      ]
        .filter(Boolean)
        .join(',');
      console.log('Updated Address: ', updatedAddress);

      const updatedClientData = {
        clientID: clientID,
        customerID: clientDetails.customerID,
        clientName: clientDetails.clientName,
        address: updatedAddress,
        primaryContactNumber: editedDetails.primaryContactNumber,
        secondaryContactNumber: editedDetails.secondaryContactNumber,
        pointOfContact: editedDetails.pointOfContact,
        primaryEmail: editedDetails.primaryEmail,
        secondaryEmail: editedDetails.secondaryEmail,
        secondaryPointOfContact: editedDetails.secondaryPointOfContact,
      };

      console.log('Updated Client data: ', updatedClientData);

      const response = await fetch(`${BACKEND_URL}/v1/client`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedClientData),
      });

      if (response.ok) {
        // Update the local state with the new details
        setClientDetails(updatedClientData);
        setEditMode(false); // Exit edit mode

        // Show success message
        setSnackbarMessage('Client details updated successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        // Handle non-OK responses (e.g., 4xx or 5xx errors)
        throw new Error('Failed to update client details.');
      }
    } catch (error) {
      console.error('Error updating client details:', error);

      // Show error message
      setSnackbarMessage('Failed to update client details. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // const parseAddress = (address) => {
  //   if (!address)
  //     return {
  //       unitNumber: "",
  //       streetNumber: "",
  //       streetName: "",
  //       city: "",
  //       province: "",
  //       country: "",
  //       postalCode: "",
  //     };

  //   const addressParts = address.split(",");
  //   return {
  //     unitNumber: addressParts[0] || "",
  //     streetNumber: addressParts[1] || "",
  //     streetName: addressParts[2] || "",
  //     city: addressParts[3] || "",
  //     province: addressParts[4] || "",
  //     country: addressParts[5] || "",
  //     postalCode: addressParts[6] || "",
  //   };
  // };

  const parseAddress = (addressString) => {
    if (!addressString) return {}; // Handle empty case

    const parts = addressString.split(','); // Split address by comma
    return {
      unitNumber: parts[0] || '',
      streetNumber: parts[1] || '',
      streetName: parts[2] || '',
      city: parts[3] || '',
      province: parts[4] || '',
      country: parts[5] || '',
      postalCode: parts[6] || '',
    };
  };

  const { unitNumber, streetNumber, streetName, city, province, country, postalCode } =
    parseAddress(clientDetails.address);

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
          overflow: 'hidden',
        }}
      >
        {/* Sidebar */}
        <Grow in={true} timeout={1500}>
          <Paper
            elevation={3}
            sx={{
              width: 300,
              backgroundColor: '#31478F',
              color: 'white',
              padding: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 0,
              boxShadow: '4px 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
              {userName}
            </Typography>
            <Typography variant="body2" sx={{ marginBottom: 3 }}>
              {role}
            </Typography>
            <Divider sx={{ width: '100%', backgroundColor: 'white', marginBottom: 3 }} />
            <Typography variant="body2" sx={{ textAlign: 'center' }}>
              Welcome to your profile! Here you can view and manage your details.
            </Typography>
          </Paper>
        </Grow>

        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            padding: 4,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,0,0,.1)',
              outline: '1px solid slategrey',
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              marginBottom: 4,
              color: 'primary.main',
              animation: 'fadeIn 1s ease-in-out',
            }}
          >
            Profile Details
          </Typography>

          {/* User Details */}
          <Grow in={true} timeout={2000}>
            <Paper
              elevation={3}
              sx={{
                marginBottom: 4,
                padding: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                <PersonIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                User Information
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary={email} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Role" secondary={role} />
                </ListItem>
              </List>
            </Paper>
          </Grow>

          {/* Client Details */}
          <Grow in={true} timeout={2500}>
            <Paper
              elevation={3}
              sx={{
                marginBottom: 4,
                padding: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  <WorkIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                  Client Information
                </Typography>
                {editMode ? (
                  <Box>
                    <IconButton onClick={handleSaveClick} color="primary">
                      <SaveIcon />
                    </IconButton>
                    <IconButton onClick={handleCancelClick} color="secondary">
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <IconButton onClick={handleEditClick} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Customer Account"
                    secondary={
                      clientID === '100'
                        ? `${clientDetails.clientName}`
                        : `${clientID}-${clientDetails.customerID}-${clientDetails.clientName}`
                    }
                  />
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Primary Contact"
                      name="pointOfContact"
                      value={editedDetails.pointOfContact}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText
                      primary="Primary Contact"
                      secondary={clientDetails.pointOfContact}
                    />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Secondary Contact"
                      name="secondaryPointOfContact"
                      value={editedDetails.secondaryPointOfContact}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText
                      primary="Secondary Contact"
                      secondary={clientDetails.secondaryPointOfContact}
                    />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Primary Email"
                      name="primaryEmail"
                      value={editedDetails.primaryEmail}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Primary Email" secondary={clientDetails.primaryEmail} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Secondary Email"
                      name="secondaryEmail"
                      value={editedDetails.secondaryEmail}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText
                      primary="Secondary Email"
                      secondary={clientDetails.secondaryEmail}
                    />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Primary Contact Number"
                      name="primaryContactNumber"
                      value={editedDetails.primaryContactNumber}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText
                      primary="Primary Contact Number"
                      secondary={clientDetails.primaryContactNumber}
                    />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Secondary Contact Number"
                      name="secondaryContactNumber"
                      value={editedDetails.secondaryContactNumber}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText
                      primary="Secondary Contact Number"
                      secondary={clientDetails.secondaryContactNumber}
                    />
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grow>

          {/* Address Details */}
          <Grow in={true} timeout={3000}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                backgroundColor: 'background.paper',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                <LocationIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                Address Information
              </Typography>
              <List>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Unit Number"
                      name="unitNumber"
                      value={editedDetails.unitNumber}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Unit Number" secondary={unitNumber} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Street Number"
                      name="streetNumber"
                      value={editedDetails.streetNumber}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Street Number" secondary={streetNumber} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Street Name"
                      name="streetName"
                      value={editedDetails.streetName}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Street Name" secondary={streetName} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      value={editedDetails.city}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="City" secondary={city} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      value={editedDetails.country}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Country" secondary={country} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="State"
                      name="province"
                      value={editedDetails.province}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="State" secondary={province} />
                  )}
                </ListItem>
                <ListItem>
                  {editMode ? (
                    <TextField
                      fullWidth
                      label="Zip Code"
                      name="postalCode"
                      value={editedDetails.postalCode}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <ListItemText primary="Zip Code" secondary={postalCode} />
                  )}
                </ListItem>
              </List>
            </Paper>
          </Grow>
        </Box>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Fade>
  );
};

export default Profile;
