import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector
} from '@mui/x-data-grid';
//import { Typography } from '@mui/material';

const CustomToolbar = ({ showExport }) => {
  return (
    <GridToolbarContainer>
      {/* <Typography variant="h6" sx={{ flex: 1 }}>
        Custom Toolbar
      </Typography> */}
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {showExport && <GridToolbarExport />}
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
