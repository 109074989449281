import { HomeFilled, CreditCardFilled, ContactsFilled, SnippetsFilled } from '@ant-design/icons';

const icons = {
  HomeFilled,
  CreditCardFilled,
  ContactsFilled,
  SnippetsFilled
};

const dashboard = {
  id: 'group-dashboard',
  title: 'Features',
  type: 'group',
  children: [
    // {
    //   id: 'home',
    //   title: 'Home',
    //   type: 'item',
    //   url: `/dashboard/${localStorage.getItem('id')}`,
    //   icon: icons.HomeFilled,
    //   breadcrumbs: false,
    //   validateRole: false,
    // },
    {
      id: 'fuelCard',
      title: 'Fuel Card',
      type: 'item',
      url: '/fuel-card',
      icon: icons.CreditCardFilled,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin', 'Admin']
    },
    {
      id: 'transactionReport',
      title: 'Transaction Report',
      type: 'item',
      url: '/transaction-report',
      icon: icons.ContactsFilled,
      breadcrumbs: false,
      validateRole: true,
      validRoles: ['Super Admin', 'Admin']
    }
    // {
    //   id: 'invoices',
    //   title: 'Invoices',
    //   type: 'item',
    //   url: '/invoices',
    //   icon: icons.SnippetsFilled,
    //   breadcrumbs: false,
    //   validateRole: false
    // }
  ]
};

export default dashboard;
