import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ManageClients from 'pages/manage-clients/index';
import ManageUsers from 'pages/manage-users/index';
// import ManageCards from 'pages/manage-cards/index';
import ManageDiscount from 'pages/manage-discount/index';
import Finish from './finish';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const steps = ['Create Customer Profile', 'Create First User', 'Set Discounts', 'Finish Up']; //'Set Discounts', //'Assign Cards',

const OnboardClient = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isNextActive, setIsNextActive] = React.useState(false);
  const [onboardingObject, setOnboardingObject] = React.useState({});

  const isStepOptional = (step) => {
    return step === 6;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      setIsNextActive(true);
      newSkipped.delete(activeStep);
    } else if (activeStep === steps.length - 1) {
      setIsNextActive(true);
    } else {
      setIsNextActive(false);
    }

    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }, 2000);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsNextActive(true);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCreateClient = (createClientObject) => {
    let newCreateClientObject = {
      ...createClientObject
    };

    let newOnboardingObject = {
      ...onboardingObject,
      clientObject: newCreateClientObject
    };
    setOnboardingObject(newOnboardingObject);
    moveNext();
  };

  const moveNext = () => {
    setTimeout(() => {
      handleNext();
    }, 2000);
  };

  const handleCreateUser = (createUserObject) => {
    let newCreateUserObject = {
      ...createUserObject,
      clientID: onboardingObject?.clientObject?.clientID
    };

    let newOnboardingObject = {
      ...onboardingObject,
      userObject: newCreateUserObject
    };
    setOnboardingObject(newOnboardingObject);
    moveNext();
  };

  // const handleAssignCards = (assignCardsObject) => {
  //   let newOnboardingObject = {
  //     ...onboardingObject,
  //     cardsObject: assignCardsObject
  //   }
  //   setOnboardingObject(newOnboardingObject)
  //   moveNext()
  // }

  const handleConfigureDiscount = (assignDiscountObject) => {
    let newCreateDiscountObject = {
      ...assignDiscountObject,
      clientID: onboardingObject?.clientObject?.clientID,
      customerID: onboardingObject?.clientObject?.customerID
    }

    let newOnboardingObject = {
      ...onboardingObject,
      discountsObject: newCreateDiscountObject
    }
    setOnboardingObject(newOnboardingObject)
    moveNext()
  }

  const handleFinishOnboarding = async () => {
    await createClient(onboardingObject?.clientObject);
    await createUser(onboardingObject?.userObject);
    await createDiscount(onboardingObject?.discountsObject?.clientID, onboardingObject?.discountsObject?.customerID, onboardingObject?.discountsObject?.discount);
    moveNext();
  };

  const createClient = async (createClientObject) => {
    await fetch(`${BACKEND_URL}/v1/client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(createClientObject)
    }).then((response) => response.json());
  };

  const createUser = async (createUserObject) => {
    console.log("User", createUserObject)
    await fetch(`${BACKEND_URL}/v1/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(createUserObject)
    }).then((response) => response.json());
  };

  const createDiscount = async (clientId, customerId, discount) => {
    let createDiscountUrl = `${BACKEND_URL}/v1/update-discount?clientID=${clientId}&customerID=${customerId}&discount=${discount}`;
    await fetch(createDiscountUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());
  }

  const renderSwitch = () => {
    switch (activeStep) {
      case 0:
        return <ManageClients isOnboard={true} onSuccess={handleCreateClient} />;
      case 1:
        return (
          <ManageUsers
            isOnboard={true}
            onSuccess={handleCreateUser}
            client={`${onboardingObject?.clientObject?.clientID}-${onboardingObject?.clientObject?.customerID}-${onboardingObject?.clientObject.clientName}`}
          />
        );
      // case 2:
      //   return <ManageCards isOnboard={true} onSuccess={handleAssignCards}
      //   client={`${onboardingObject?.clientObject?.clientID}-${onboardingObject?.clientObject?.customerID}-${onboardingObject?.clientObject.clientName}`}/>
      case 2:
        return <ManageDiscount isOnboard={true} onSuccess={handleConfigureDiscount}
        client={`${onboardingObject?.clientObject?.clientID}-${onboardingObject?.clientObject?.customerID}-${onboardingObject?.clientObject.clientName}`}/>
      case 3:
        return <Finish onboard={onboardingObject} onSuccess={handleFinishOnboarding} />;
      default:
        return (
          <Typography variant="h1" sx={{ mt: 2, mb: 1, textAlign: 'center' }}>
            Onboarding completed successfully!!
          </Typography>
        );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Onboard another</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              <NavigateBeforeIcon />
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
                <SkipNextIcon />
              </Button>
            )}

            <Button onClick={handleNext} disabled={!isNextActive}>
              {activeStep < steps.length - 1 && 'Next'}
              {activeStep < steps.length - 1 && <NavigateNextIcon />}
            </Button>
          </Box>
        </React.Fragment>
      )}
      {renderSwitch()}
    </Box>
  );
};

export default OnboardClient;
