import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
// import CustomToolbar from './custom-toolbar'; // Import the custom toolbar
import './UploadFile.css'; // Import the CSS file
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Date Adapter for MUI
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const UploadFile = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [fileError, setFileError] = useState('');
  const [priceQuotes, setPriceQuotes] = useState([]);
  const [priceQuotesCAD, setPriceQuotesCAD] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [effectiveDateCAD, setEffectiveDateCAD] = useState('');
  const [date, setDate] = useState('');
  const [discount, setDiscount] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const [clientDetails, setClientDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  const buttonBackground = '#011f4b';
  const fullPlaceholder = 'Search...';
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState('');
  let index = 0;
  // const [disableCAD, setDisableCAD] = useState(false);
  // const [disableUSD, setDisableUSD] = useState(false);
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (!loggedInUserClientId) {
        setError('Client ID not found in localStorage.');
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/v1/client?clientID=${loggedInUserClientId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientDetails(data);
        console.log('clientDetails currency', data[0]?.currency);
        console.log('clientDetails', data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClientDetails();
  }, [loggedInUserClientId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedPlaceholder(fullPlaceholder.slice(0, index + 1)); // Show letters one by one
      index++;

      if (index > fullPlaceholder.length) {
        index = 0; // Reset animation after completion
      }
    }, 200); // Adjust speed of animation

    return () => clearInterval(interval);
  }, []);

  // Function to format text to uppercase, handling null or undefined values
  const formatTextToUppercase = (text) => {
    return text ? text.toUpperCase() : '';
  };

  // Handle file change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileError('');
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = `${BACKEND_URL}/v1/upload/priceQuote`;

    setUploading(true);
    setError(null);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
      setFile(null); // Clear the file input after successful upload
      // fetchPriceQuotes(); // Fetch price quotes after successful upload
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Handle file upload CAD
  const handleFileUploadCAD = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError('Please select a file first');
      return;
    }

    // Function to add one day to a date
    const addOneDay = (date) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1); // Add one day
      return newDate;
    };
    // Get the effective date in 'YYYY-MM-DD' format (America/Chicago timezone)
    const effectiveDate = addOneDay(effectiveDateCAD);
    console.log('effectiveDate', effectiveDate);

    // Format dates to 'YYYY-MM-DD'
    const options = {
      timeZone: 'America/Chicago',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    let formattedEffectiveDateCAD = new Date(effectiveDate).toLocaleDateString('en-CA', options);

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = `${BACKEND_URL}/v1/upload-csv?effectiveDate=${formattedEffectiveDateCAD}`;

    setUploading(true);
    setError(null);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response Data: ', data);
      setResponse(data);
      setFile(null); // Clear the file input after successful upload
      fetchPriceQuotesCAD(); // Fetch price quotes after successful upload

      // Alert customer that the file is uploaded and email will be sent soon
      alert(
        'File uploaded successfully! We are now sending email notifications to all clients. Please wait... No Action Required from your end',
      );

      // Now trigger the email notifications after successful upload
      const emailNotificationUrl = `${BACKEND_URL}/v1/send-email-notifications?effectiveDate=${formattedEffectiveDateCAD}`;

      console.log('Email URL Data: ', emailNotificationUrl);
      const emailResponse = await fetch(emailNotificationUrl, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      });

      if (!emailResponse.ok) {
        throw new Error(`Error sending email notifications! Status: ${emailResponse.status}`);
      }
      console.log('Email Response Data: ', emailResponse);

      const emailData = await emailResponse.json();
      console.log('Email notification response:', emailData);

      // Once email is successfully sent, alert the user
      alert('Email notifications sent successfully to all clients!');
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Fetch discount details for the logged-in client
  const getDiscountDetailsForClient = async () => {
    let getDiscountDetailsForCustomerUrl = `${BACKEND_URL}/v1/discounts?clientID=${loggedInUserClientId}`;

    try {
      const response = await fetch(getDiscountDetailsForCustomerUrl);
      if (!response.ok) {
        console.error(
          `Error fetching data for client ${loggedInUserClientId}: ${response.statusText}`,
        );
        return;
      }

      const data = await response.json();
      console.log(`Response data for client ${loggedInUserClientId}:`, data);

      let clientDiscount = data.discount !== undefined ? data.discount : 0;
      setDiscount(clientDiscount);
      console.log('Discount:', clientDiscount);
    } catch (error) {
      console.error(`Error processing client ${loggedInUserClientId}:`, error);
    }
  };

  // Fetch Profit Margin details for the logged-in client
  const getProfitMarginDetailsForClient = async () => {
    let getProfitMarginDetailsForCustomerUrl = `${BACKEND_URL}/v1/profit-margin?clientID=${loggedInUserClientId}`;

    try {
      const response = await fetch(getProfitMarginDetailsForCustomerUrl);
      if (!response.ok) {
        console.error(
          `Error fetching data for client ${loggedInUserClientId}: ${response.statusText}`,
        );
        return;
      }

      const data = await response.json();
      console.log(`Response data for client ${loggedInUserClientId}:`, data);

      let clientProfitMargin = data.profitMargin !== undefined ? data.profitMargin : 0.0;
      setProfitMargin(clientProfitMargin);
      console.log('Profit Margin:', clientProfitMargin);
    } catch (error) {
      console.error(`Error processing client ${loggedInUserClientId}:`, error);
    }
  };

  // Fetch price quotes and calculate savings based on discount
  // const fetchPriceQuotes = async () => {
  //   try {
  //     const response = await fetch(`${BACKEND_URL}/v1/priceQuotes`);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! status: ${response.status}`);
  //     }
  //     const data = await response.json();

  //     // Calculate savings based on discount
  //     const updatedPriceQuotes = data.map((quote) => ({
  //       ...quote,
  //       savingsTotal: doNotPerformCustomerFiltration
  //         ? quote.savingsTotal
  //         : (quote.savingsTotal * (discount / 100)).toFixed(4),
  //       yourPrice: doNotPerformCustomerFiltration
  //         ? quote.yourPrice
  //         : (
  //             quote.discRetail -
  //             (quote.savingsTotal * (discount / 100)).toFixed(4)
  //           ).toFixed(4),
  //     }));

  //     setPriceQuotes(updatedPriceQuotes);

  //     // Set the effective date (assuming it's the same for all quotes)
  //     if (data.length > 0) {
  //       setEffectiveDate(data[0].effectiveDate); // Adjust based on your actual data structure
  //     }
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  const provinceTaxRates = {
    NS: { gst: 0.15, pst: 0.0 }, // 15% GST, 0% PST
    BC: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    AB: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    SK: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    MB: { gst: 0.05, pst: 0.0 }, // 5% GST, 0% PST
    ON: { gst: 0.13, pst: 0.0 }, // 13% GST, 0% PST
    QC: { gst: 0.05, pst: 0.0998 }, // 5% GST, 9.98% QST
  };

  // Fetch price quotes and calculate savings based on profitMargin
  const fetchPriceQuotesCAD = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/get-all-quotes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Update price quotes for DIESEL LS
      const updatedPriceQuotes = data.map((quote) => {
        if (quote.product === 'DIESEL LS') {
          const province = quote.province?.toUpperCase(); // Ensure province code is uppercase
          const taxRates = provinceTaxRates[province] || { gst: 0, pst: 0 };

          const updatedProfitMargin = parseFloat(profitMargin) || 0;
          const pricePerLiter = parseFloat(quote.pricePerLiter) || 0;

          const updatedPricePerLtr = pricePerLiter + updatedProfitMargin;
          const updatedGST = updatedPricePerLtr * taxRates.gst;
          const updatedPST = updatedPricePerLtr * taxRates.pst;
          const updatedTotalPrice = updatedPricePerLtr + updatedGST + updatedPST;

          return {
            ...quote,
            netPrice: parseFloat(quote.netPrice).toFixed(4),
            fet: parseFloat(quote.fet).toFixed(4),
            pft: parseFloat(quote.pft).toFixed(4),
            pct: parseFloat(quote.pct).toFixed(4),
            localTax: parseFloat(quote.localTax).toFixed(4),
            pricePerLiter: doNotPerformCustomerFiltration
              ? quote.pricePerLiter.toFixed(4)
              : updatedPricePerLtr.toFixed(4),
            gstHst: doNotPerformCustomerFiltration
              ? quote.gstHst.toFixed(4)
              : updatedGST.toFixed(4),
            pstQst: doNotPerformCustomerFiltration
              ? quote.pstQst.toFixed(4)
              : updatedPST.toFixed(4),
            totalPrice: doNotPerformCustomerFiltration
              ? quote.totalPrice.toFixed(4)
              : updatedTotalPrice.toFixed(4),
          };
        }
        // Ensure all other quotes have 4 decimal points for these columns
        return {
          ...quote,
          netPrice: parseFloat(quote.netPrice).toFixed(4),
          fet: parseFloat(quote.fet).toFixed(4),
          pft: parseFloat(quote.pft).toFixed(4),
          pct: parseFloat(quote.pct).toFixed(4),
          localTax: parseFloat(quote.localTax).toFixed(4),
          pricePerLiter: parseFloat(quote.pricePerLiter).toFixed(4),
          gstHst: parseFloat(quote.gstHst).toFixed(4),
          pstQst: parseFloat(quote.pstQst).toFixed(4),
          totalPrice: parseFloat(quote.totalPrice).toFixed(4),
        };
      });

      setPriceQuotesCAD(updatedPriceQuotes);

      // Set the effective date (assuming it's the same for all quotes)
      if (data.length > 0) {
        setDate(data[0].effectiveDate); // Adjust based on your actual data structure
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getDiscountDetailsForClient();
    getProfitMarginDetailsForClient();
  }, []); // Fetch discount details only once on component mount

  useEffect(() => {
    // fetchPriceQuotes();
    fetchPriceQuotesCAD();
  }, [discount, profitMargin]); // Fetch price quotes when discount changes

  // Add incremental ID to each row and format text
  const formattedRows = priceQuotes.map((quote) => ({
    ...quote,
    // id: index + 1,
    truckShopName: formatTextToUppercase(quote.truckShopName),
    city: formatTextToUppercase(quote.city),
  }));

  // Filter rows based on search query
  const filteredRows = formattedRows.filter((row) => {
    return (
      row.truckShopName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.state.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const formattedRowsCAD = priceQuotesCAD.map((quote) => ({
    ...quote,
    // id: index + 1,
    name: formatTextToUppercase(quote.name),
    location: formatTextToUppercase(quote.location),
    province: formatTextToUppercase(quote.province),
  }));

  // Filter rows based on search query
  const filteredRowsCAD = formattedRowsCAD.filter((row) => {
    return (
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.province.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const columns = [
    {
      field: 'id',
      headerName: 'SITE ID',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'truckShopName',
      headerName: 'TRUCK SHOP',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'city',
      headerName: 'CITY',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'state',
      headerName: 'STATE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'discRetail',
      headerName: 'DISCOUNT RETAIL',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'yourPrice',
      headerName: 'YOUR PRICE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'savingsTotal',
      headerName: 'SAVINGS TOTAL',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
  ];

  const columnsCAD = [
    // { field: 'id', headerName: 'SITE ID', flex: 0.1, headerAlign: 'left', align: 'left' },
    {
      field: 'siteNumber',
      headerName: 'SITE NUMBER',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'name',
      headerName: 'NAME',
      flex: 0.2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'location',
      headerName: 'CITY',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'province',
      headerName: 'STATE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'product',
      headerName: 'PRODUCT',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'netPrice',
      headerName: 'NET PRICE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'fet',
      headerName: 'FET',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'pft',
      headerName: 'PFT',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'pct',
      headerName: 'PCT',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'localTax',
      headerName: 'LOCAL TAX',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'pricePerLiter',
      headerName: 'PRICE PER LITER',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'gstHst',
      headerName: 'GST/HST',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'pstQst',
      headerName: 'PST/QST',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'totalPrice',
      headerName: 'TOTAL PRICE',
      flex: 0.1,
      headerAlign: 'left',
      align: 'left',
    },
    // { field: 'priceChange', headerName: 'PRICE CHANGE', flex: 0.1, headerAlign: 'left', align: 'left' },
  ];

  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);

    // Re-fetch clients based on selected currency
    // getClients();

    // if (currency === 'CAD') {
    //   setDisableCAD(true);
    //   setDisableUSD(false); // Enable USD button
    //   // Disable the USD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableCAD(false);
    //   }, 10000); // 10 seconds
    // } else if (currency === 'USD') {
    //   setDisableUSD(true);
    //   setDisableCAD(false); // Enable CAD button
    //   // Disable the CAD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableUSD(false);
    //   }, 10000); // 10 seconds
    // }
  };

  // Ensure the date is valid before setting it
  const handleEffectiveDateChange = (date) => {
    // if (date instanceof Date && !isNaN(date)) {
    setEffectiveDateCAD(date); // Set the valid date
    setError(''); // Clear any previous errors
    // } else {
    //   setError('Please select a valid date.');
    // }
  };

  // Convert the date to string format (YYYY-MM-DD) for display purposes
  // const formattedEffectiveDate = effectiveDateCAD
  //   ? effectiveDateCAD.toLocaleDateString('en-US', {
  //       timeZone: 'America/Chicago',
  //       year: 'numeric',
  //       month: '2-digit',
  //       day: '2-digit',
  //     })
  //     // .split('/').reverse().join('-') // This converts MM/DD/YYYY -> YYYY-MM-DD
  //   : 'Not set'; // Default if no date is selected

  // console.log("formattedEffectiveDate", formattedEffectiveDate)

  return (
    <div style={{ marginLeft: '10px' }}>
      {/* Button Container */}
      <div style={{ marginBottom: '150px' }}>
        {clientDetails && clientDetails.length > 0 && (
          <Box display="flex" mb={2} gap={2}>
            {/* Show both buttons if currency is 'CAD, USD' or empty */}
            {clientDetails[0]?.currency === 'CAD, USD' || !clientDetails[0]?.currency ? (
              <>
                <Button
                  variant="contained"
                  style={{
                    marginTop: '5px',
                    fontFamily: 'DM Sans',
                    fontSize: '12px', // Reduce font size
                    backgroundColor: getButtonBackground('CAD'),
                    padding: '4px 8px', // Adjust padding for a smaller button
                    minWidth: '50px', // Set a minimum width
                    height: '30px', // Reduce height
                  }}
                  onClick={() => handleCurrencySelect('CAD')}
                  sx={{
                    fontSize: '12px',
                    textTransform: 'none',
                    minWidth: '50px',
                    height: '30px',
                  }}
                >
                  CAD
                </Button>

                <Button
                  variant="contained"
                  style={{
                    marginTop: '5px',
                    fontFamily: 'Sans-serif',
                    fontSize: '12px', // Reduce font size
                    padding: '4px 8px', // Adjust padding for a smaller button
                    minWidth: '50px', // Set a minimum width
                    height: '30px', // Reduce height
                    backgroundColor: getButtonBackground('USD'),
                  }}
                  onClick={() => handleCurrencySelect('USD')}
                  sx={{
                    fontSize: '12px',
                    textTransform: 'none',
                    minWidth: '50px',
                    height: '30px',
                  }}
                >
                  USD
                </Button>
              </>
            ) : null}

            {/* Show only CAD button if currency is 'CAD' */}
            {clientDetails[0]?.currency === 'CAD' && (
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'Sans-serif',
                  fontSize: '12px', // Reduce font size
                  backgroundColor: getButtonBackground('CAD'),
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                }}
                onClick={() => handleCurrencySelect('CAD')}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  minWidth: '50px',
                  height: '30px',
                }}
              >
                CAD
              </Button>
            )}

            {/* Show only USD button if currency is 'USD' */}
            {clientDetails[0]?.currency === 'USD' && (
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'DM Sans',
                  fontSize: '12px', // Reduce font size
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                  backgroundColor: getButtonBackground('USD'),
                }}
                onClick={() => handleCurrencySelect('USD')}
                sx={{
                  fontSize: '12px',
                  textTransform: 'none',
                  minWidth: '50px',
                  height: '30px',
                }}
              >
                USD
              </Button>
            )}
          </Box>
        )}
      </div>

      {selectedCurrency === 'USD' && (
        <>
          {doNotPerformCustomerFiltration && (
            <div
              className="container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
              }}
            >
              <div className="card" style={{ flex: '1', minWidth: '100%' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '15.5px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontFamily: 'Open Sans, sans-serif',
                  }}
                  gutterBottom
                >
                  {/* USD Upload File */}
                </Typography>
                {/* <Card sx={{ minWidth: '100%', minHeight: 150 }}> */}
                <CardContent>
                  <form id="upload-file-form" onSubmit={handleFileUpload}>
                    <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <Box sx={{ width: '250px', margin: '0 auto' }}>
                        <Typography
                          style={{
                            fontSize: '12px',
                            color: 'rgb(26, 33, 56)',
                            fontWeight: 'bold',
                            marginLeft: '10px', // Adjusted to prevent unnecessary shifts
                            fontFamily: 'Open Sans, sans-serif',
                            marginTop: '-150px', // Removed negative margin
                            whiteSpace: 'nowrap',
                            marginLeft: '10px',
                            marginBottom: '6px', // Adjusted to align properly
                          }}
                        >
                          Select USD Price Quotes (PDF File)
                        </Typography>
                        <TextField
                          type="file"
                          inputProps={{
                            accept: '.pdf',
                            style: {
                              fontSize: '10px',
                              padding: '4px',
                              height: '20px',
                            },
                          }}
                          onChange={handleFileChange}
                          fullWidth
                          helperText={fileError}
                          error={!!fileError}
                          sx={{
                            width: '180px', // Reduce width further
                            ml: '10px', // Adjust margin-left
                            mt: '-1px',
                            '& .MuiInputBase-root': {
                              height: '25px', // Reduce height further
                              fontSize: '9px', // Reduce font size
                              padding: '0px 5px', // Adjust padding
                            },
                            '& input[type="file"]': {
                              padding: '4x', // Reduce padding inside input
                              height: '20px', // Reduce input height
                              fontSize: '10px', // Reduce font size
                            },
                            '& .MuiFormHelperText-root': {
                              fontSize: '8px', // Reduce helper text size further
                            },
                          }}
                        />
                        {fileError && (
                          <FormHelperText id="file-error-text" sx={{ fontSize: '9px' }}>
                            {fileError}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1.5}
                      columnSpacing={2.75}
                      style={{ marginTop: '2px', marginBottom: '2px' }}
                    >
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4} align="center">
                        <Button
                          style={{
                            marginTop: '-150px',
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '10px', // Smaller font size
                            backgroundColor: '#31478F',
                            padding: '3px 10px', // Reduce padding
                            minWidth: '60px', // Set a smaller minimum width
                            height: '28px', // Decrease height
                            marginLeft: '-60px',
                          }}
                          type="submit"
                          variant="contained"
                          disabled={uploading}
                          sx={{ mt: 2, mb: 5, borderRadius: '5px' }}
                        >
                          {uploading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Upload File'
                          )}
                        </Button>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </form>
                  {response && (
                    <Box mt={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: 'DM Sans, sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        Upload Successful
                      </Typography>
                    </Box>
                  )}
                  {error && (
                    <Box mt={2}>
                      <Typography
                        variant="h6"
                        color="error"
                        sx={{
                          fontFamily: 'DM Sans, sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        Error: {error}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
                {/* </Card> */}
              </div>
            </div>
          )}

          <Box mt={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: '14px',
                marginLeft: '-20px',
                fontWeight: '800',
                fontFamily: 'Open Sans, sans-serif',
                marginTop: '-20px',
              }}
              gutterBottom
            >
              {/* USD Price Quotes */}
            </Typography>
          </Box>

          <Box mt={2}>
            {/* <Card> */}
            <CardContent>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: 'bold',
                    }}
                  >
                    {/* RETAIL PRICES ARE SUBJECT TO CHANGE AT ANY TIME */}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'Open Sans, sans-serif',
                      fontWeight: 'bold',
                      position: 'absolute',
                      marginTop: '-100px',
                      marginLeft: '290px',
                    }}
                  >
                    Effective Date: {effectiveDate}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  position: 'absolute',
                  marginTop: '15px',
                  marginLeft: '100px',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={3.5}
                  sx={{ position: 'relative', top: '-300px', width: '500px' }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder={animatedPlaceholder}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputLabelProps={{
                      sx: {
                        fontSize: '11px', // Adjust label size & position
                        transition: 'all 0.3s ease', // Animation for the label
                        '&.Mui-focused': {
                          fontSize: '10px', // Shrink font size when focused
                          transform: 'translateY(-12px)', // Move the placeholder up
                          opacity: 0.7, // Change opacity on focus
                        },
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px', // Adjust border radius
                        '& fieldset': {
                          borderColor: '#31478F', // Set the border color
                          borderWidth: '2px', // Set the border width to 2px
                          borderStyle: 'solid', // Make the border solid
                        },
                        '&:hover fieldset': {
                          borderColor: '#31478F', // Set the border color on hover
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#31478F', // Set the border color when focused
                        },
                      },
                      '& .MuiInputBase-root': { height: 24, minHeight: 24 },
                      '& .MuiInputBase-input': { fontSize: 12, padding: '8px' },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ fontSize: 14 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  height: 700,
                  width: '100%',
                  marginTop: '20px',
                  overflow: 'hidden',
                  marginTop: '-60px',
                }}
              >
                <DataGrid
                  rows={filteredRows}
                  columns={columns.map((col) => ({
                    ...col,
                    minWidth: 100,
                    maxWidth: 200,
                  }))}
                  pageSize={rowsPerPageOptions[0]}
                  rowsPerPageOptions={rowsPerPageOptions}
                  slots={{ toolbar: CustomToolbar }}
                  pagination
                  paginationMode="client"
                  rowCount={filteredRows.length}
                  getRowId={(row) => row.id}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
                  }
                  rowHeight={25}
                  columnHeaderHeight={32}
                  disableColumnResize
                  autoHeight
                  sx={{
                    '& .MuiDataGrid-root': { width: '100%' },
                    '& .MuiDataGrid-columnHeaders': {
                      fontSize: '10px',
                      backgroundColor: '#31478F',
                      color: 'white',
                    },
                    '& .MuiDataGrid-cell': { fontSize: '10px' },
                    '& .even': { backgroundColor: '#F8F8F8' },
                    '& .odd': { backgroundColor: '#E6F0FF' },
                  }}
                />
              </div>
            </CardContent>
            {/* </Card> */}
          </Box>
        </>
      )}

      {selectedCurrency === 'CAD' && (
        <>
          {doNotPerformCustomerFiltration && (
            <div
              className="container"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                width: '100%',
                marginTop: '-105px',
              }}
            >
              <div className="card" style={{ flex: '1', minWidth: '100%' }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    fontFamily: 'Roboto',
                    marginTop: '10px',
                    marginLeft: '-500px',
                  }}
                  gutterBottom
                >
                  {/* CAD Upload File */}
                </Typography>
                {/* <Card sx={{ minWidth: '100%', minHeight: 150 }}> */}
                <CardContent>
                  <form id="upload-file-form" onSubmit={handleFileUploadCAD}>
                    {/* Date Picker for Effective Date */}
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginTop: '10px',
                        marginBottom: '2px',
                        marginLeft: '50px',
                      }}
                    >
                      <Box sx={{ width: '250px', margin: '0 auto' }}>
                        {/* <Typography style={{ fontSize: '15px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}>
                            Effective Date
                          </Typography> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Select Effective Date"
                            value={effectiveDateCAD} // Ensure this is a valid Date or null
                            onChange={handleEffectiveDateChange}
                            slotProps={{
                              textField: {
                                error: false, // Ensure no error styling is applied
                                helperText: '', // Prevents error text from appearing
                              },
                            }}
                            sx={{
                              width: '100%',
                              maxWidth: '300px',
                              mt: '-46px',
                              '& .MuiInputBase-root': {
                                height: '24px',
                                minHeight: '24px',
                                fontSize: '12px',
                                borderColor: 'rgba(0, 0, 0, 0.23)',
                              },
                              '& .MuiInputBase-input': {
                                fontSize: '11px',
                                padding: '2px',
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: '14px',
                              },
                              '& .MuiInputLabel-root': {
                                fontSize: '12px', // Reduce label font size
                              },
                            }}
                          />
                        </LocalizationProvider>
                        {error && <FormHelperText id="date-error-text">{error}</FormHelperText>}
                      </Box>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                      <Box sx={{ width: '250px', margin: '0 auto' }}>
                        <div style={{ textAlign: 'left' }}>
                          <Typography
                            style={{
                              fontSize: '12px',
                              color: 'rgb(26, 33, 56)',
                              fontWeight: 'bold',
                              marginTop: '-70px',
                              marginBottom: '10px',
                              marginLeft: '-340px',
                              paddingLeft: '0px',
                            }}
                          >
                            Select CAD Price Quotes (CSV File)
                          </Typography>
                        </div>
                        <TextField
                          type="file"
                          inputProps={{ accept: '.csv' }}
                          onChange={handleFileChange}
                          fullWidth
                          helperText={fileError}
                          error={!!fileError}
                          sx={{
                            width: '180px', // Adjust width
                            marginLeft: '-130px',

                            mt: '-30px',
                            '& .MuiInputBase-root': {
                              height: '30px', // Increase height slightly for better visibility
                              fontSize: '9px', // Adjust font size
                              padding: '1px 3px', // Adjust padding
                              borderBottom: '1px solid rgba(0, 0, 0, 0.42)', // Ensure bottom border is visible
                              minHeight: '25px',
                            },
                            '& .MuiFormHelperText-root': {
                              fontSize: '8px', // Reduce helper text size
                            },
                            '& input': {
                              padding: '3px', // Adjust input padding
                              cursor: 'pointer', // Improve user experience
                            },

                            '& .Mui-error .MuiInputBase-root': {
                              borderBottom: '2px solid red', // Error state styling
                            },
                          }}
                        />

                        {fileError && (
                          <FormHelperText id="file-error-text" sx={{ fontSize: '9px' }}>
                            {fileError}
                          </FormHelperText>
                        )}
                      </Box>
                    </Grid>

                    <Grid
                      container
                      rowSpacing={1.5}
                      columnSpacing={2.75}
                      style={{
                        marginTop: '2px',
                        marginBottom: '2px',
                        marginLeft: '3px',
                      }}
                    >
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <Button
                          style={{
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '8px', // Smaller font size
                            backgroundColor: '#31478F',
                            padding: '3px 10px', // Reduce padding
                            minWidth: '60px', // Set a smaller minimum width
                            height: '28px', // Decrease height
                            marginLeft: '-1px',
                            marginTop: '-45px',
                          }}
                          type="submit"
                          variant="contained"
                          disabled={uploading}
                          sx={{ mt: 2, mb: 5, borderRadius: '5px' }}
                        >
                          {uploading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Upload File'
                          )}
                        </Button>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </form>
                  {response && (
                    <Box mt={2}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: 'DM Sans, sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        Upload Successful
                      </Typography>
                    </Box>
                  )}
                  {error && (
                    <Box mt={2}>
                      <Typography
                        variant="h6"
                        color="error"
                        sx={{
                          fontFamily: 'DM Sans, sans-serif',
                          textAlign: 'center',
                        }}
                      >
                        Error: {error}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
                {/* </Card> */}
              </div>
            </div>
          )}
          {/* 
          <Box mt={2} ml={2}>
            <Typography
              variant="h2"
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily: "Roboto",
                marginTop: "-60px",
              }}
              gutterBottom
            >
              {/* CAD Price Quotes */}
          {/* </Typography>
          </Box> */}

          <Box mt={2}>
            {/* <Card> */}
            <CardContent>
              <Grid container alignItems="center">
                {/* <Grid item xs={6}>
                  <Typography variant="h6" sx={{ fontSize: '18px', fontFamily: 'DM Sans, sans-serif', fontWeight: '800' }}>
                    RETAIL PRICES ARE SUBJECT TO CHANGE AT ANY TIME
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    // 11-inch MacBook Air (older models) - 1366x768 resolution
                    '@media (min-width: 1350px) and (max-width: 1380px) and (min-height: 750px) and (max-height: 780px)':
                      {
                        marginTop: '80px', // Adjusted for smaller screens
                        marginLeft: '60px', // Adjusted for proper positioning
                      },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '14px',
                      fontFamily: 'Roboto',
                      fontWeight: 'bold',
                      marginRight: '-415px',
                      whiteSpace: 'nowrap',
                      marginTop: '-118px',
                    }}
                  >
                    Effective Date: {date}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                sx={{
                  position: 'relative',
                  marginTop: '15px',
                  marginLeft: '100px',
                }}
              >
                {!doNotPerformCustomerFiltration && (
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    sx={{ position: 'absolute', top: '-225px', width: '500px' }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={animatedPlaceholder}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputLabelProps={{
                        sx: {
                          fontSize: '11px', // Adjust label size & position
                          transition: 'all 0.3s ease', // Animation for the label
                          '&.Mui-focused': {
                            fontSize: '10px', // Shrink font size when focused
                            transform: 'translateY(-12px)', // Move the placeholder up
                            opacity: 0.7, // Change opacity on focus
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px', // Adjust border radius
                          '& fieldset': {
                            borderColor: '#31478F', // Set the border color
                            borderWidth: '2px', // Set the border width to 2px
                            borderStyle: 'solid', // Make the border solid
                          },
                          '&:hover fieldset': {
                            borderColor: '#31478F', // Set the border color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#31478F', // Set the border color when focused
                          },
                        },
                        '& .MuiInputBase-root': { height: 24, minHeight: 24 },
                        '& .MuiInputBase-input': {
                          fontSize: 12,
                          padding: '8px',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 14 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}

                {doNotPerformCustomerFiltration && (
                  <Grid
                    item
                    xs={12}
                    sm={3.5}
                    sx={{ position: 'absolute', top: '-250px', width: '500px' }}
                  >
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder={animatedPlaceholder}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      InputLabelProps={{
                        sx: {
                          fontSize: '11px', // Adjust label size & position
                          transition: 'all 0.3s ease', // Animation for the label
                          '&.Mui-focused': {
                            fontSize: '10px', // Shrink font size when focused
                            transform: 'translateY(-12px)', // Move the placeholder up
                            opacity: 0.7, // Change opacity on focus
                          },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px', // Adjust border radius
                          '& fieldset': {
                            borderColor: '#31478F', // Set the border color
                            borderWidth: '2px', // Set the border width to 2px
                            borderStyle: 'solid', // Make the border solid
                          },
                          '&:hover fieldset': {
                            borderColor: '#31478F', // Set the border color on hover
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#31478F', // Set the border color when focused
                          },
                        },
                        '& .MuiInputBase-root': { height: 24, minHeight: 24 },
                        '& .MuiInputBase-input': {
                          fontSize: 12,
                          padding: '8px',
                        },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 14 }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </Grid>

              <div style={{ height: 700, maxWidth: '95%', marginTop: '-105px' }}>
                <DataGrid
                  rows={filteredRowsCAD}
                  columns={columnsCAD}
                  pageSize={rowsPerPageOptions[0]}
                  rowsPerPageOptions={rowsPerPageOptions}
                  slots={{ toolbar: CustomToolbar }}
                  pagination
                  paginationMode="client"
                  rowCount={filteredRowsCAD.length}
                  getRowId={(row) => row.id}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
                  }
                  rowHeight={25}
                  columnHeaderHeight={32}
                  sx={{
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#31478F',
                      color: 'white',
                      fontSize: '11px', // Reduce the font size of the header
                    },
                    '& .MuiDataGrid-cell': {
                      textAlign: 'center',
                      fontSize: '11px',
                    },
                    '& .MuiDataGrid-root': {
                      overflowX: 'auto',
                    },
                    '& .MuiDataGrid-row': {
                      height: 'auto',
                    },
                    '& .even-row': {
                      backgroundColor: '#F8F8F8',
                    },
                    '& .odd-row': {
                      backgroundColor: '#E6F0FF',
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                      minHeight: '30px',
                      padding: '4px',
                      fontSize: '10px !important',
                    },
                    // Add specific column widths here
                    '& .MuiDataGrid-columnHeader': {
                      // Set custom column width for headers
                      minWidth: '120px', // Example of expanding each header
                    },
                  }}
                  sortModel={[
                    {
                      field: 'province',
                      sort: 'asc',
                    },
                  ]}
                />
              </div>
            </CardContent>
            {/* </Card> */}
          </Box>
        </>
      )}
    </div>
  );
};

export default UploadFile;
