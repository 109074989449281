import { Box, Card, CardContent, Grid, Typography, CircularProgress } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';

// Styled components for animations
const AnimatedCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StatCard = ({ icon, title, value, color, onClick, delay = 0 }) => (
  <AnimatedCard
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
    whileHover={{ scale: 1.03 }}
  >
    <Card
      sx={{
        height: '100%',
        backgroundColor: color,
        cursor: 'pointer',
        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 8px 30px 0 rgba(0,0,0,0.15)',
        },
      }}
      onClick={onClick}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          {icon}
          <Typography variant="h6" ml={1} sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
        </Box>
        <Typography variant="h3" fontWeight="bold" sx={{ fontSize: '2.5rem' }}>
          {value}
        </Typography>
      </CardContent>
    </Card>
  </AnimatedCard>
);

const DashboardChartCard = styled(Card)(({ theme }) => ({
  borderRadius: '12px',
  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.08)',
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 8px 30px 0 rgba(0,0,0,0.12)',
  },
}));

const UserDashboard = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    blockedUsers: 0,
  });
  const [fuelCardStats, setFuelCardStats] = useState({
    activeCards: 0,
    inactiveCards: 0,
    totalCards: 0,
  });
  const [clientStats, setClientStats] = useState({
    totalClients: 0,
    activeClients: 0,
    inactiveClients: 0,
  });
  const [transactionStats, setTransactionStats] = useState({
    monthlyTransactions: [],
    totalTransactions: 0,
  });
  const [invoiceStats, setInvoiceStats] = useState({
    pending: 0,
    approved: 0,
    declined: 0,
    total: 0,
  });
  const [currentWeekInvoiceStats, setCurrentWeekInvoiceStats] = useState({
    pending: 0,
    approved: 0,
    declined: 0,
    total: 0,
  });
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`);
    } else {
      setLoggedIn(true);
      fetchUserData();
      fetchFuelCardData();
      fetchClientData();
      fetchTransactionData();
      fetchInvoiceData();
    }
  }, []);

  const getCurrentWeekStats = (invoices) => {
    const now = new Date();
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1));
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const weekInvoices = invoices.filter((invoice) => {
      const invoiceDate = new Date(invoice.createdAt || invoice.date);
      return invoiceDate >= startOfWeek && invoiceDate <= endOfWeek;
    });

    return {
      pending: weekInvoices.filter((i) => i.status?.toUpperCase() === 'PENDING').length,
      approved: weekInvoices.filter((i) => i.status?.toUpperCase() === 'APPROVED').length,
      declined: weekInvoices.filter((i) => i.status?.toUpperCase() === 'DECLINED').length,
      total: weekInvoices.length,
    };
  };

  const fetchUserData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${BACKEND_URL}/v1/user`);
      const data = await response.json();

      const filteredUsers = data.filter((user) => ['Admin', 'User'].includes(user?.role));

      setUsers(filteredUsers);

      const total = filteredUsers.length;
      const active = filteredUsers.filter((user) => user.userStatus).length;
      const blocked = total - active;

      setStats({
        totalUsers: total,
        activeUsers: active,
        blockedUsers: blocked,
      });

      setLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
      setLoading(false);
    }
  };

  const fetchFuelCardData = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/card/all`);
      const data = await response.json();

      const activeCards = data.filter((card) => card.status === 'ACTIVE').length;
      const inactiveCards = data.filter((card) => card.status === 'INACTIVE').length;

      setFuelCardStats({
        activeCards,
        inactiveCards,
        totalCards: data.length,
      });
    } catch (error) {
      console.error('Error fetching fuel card data:', error);
    }
  };

  const fetchClientData = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/client`);
      const data = await response.json();

      const total = data.length;
      const active = data.filter((client) => client.clientStatus === true).length;
      const inactive = total - active;

      setClientStats({
        totalClients: total,
        activeClients: active,
        inactiveClients: inactive,
      });
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  const fetchTransactionData = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const startDate = new Date(currentYear, 0, 1);
      const endDate = new Date();

      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const url = `${BACKEND_URL}/v1/transaction?startDate=${formatDate(
        startDate,
      )}&endDate=${formatDate(endDate)}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      const monthlyCounts = Array.from({ length: 12 }, (_, i) => ({
        date: new Date(currentYear, i, 1),
        month: new Date(currentYear, i, 1).toLocaleString('default', {
          month: 'short',
        }),
        year: currentYear,
        count: 0,
      }));

      data.forEach((transaction) => {
        try {
          const transactionDate = new Date(transaction.date || transaction.transactionDate);

          if (transactionDate.getFullYear() === currentYear) {
            const monthIndex = transactionDate.getMonth();
            monthlyCounts[monthIndex].count++;
          }
        } catch (e) {
          console.error('Error processing transaction:', transaction, e);
        }
      });

      setTransactionStats({
        monthlyTransactions: monthlyCounts,
        totalTransactions: data.length,
      });
    } catch (error) {
      console.error('Error fetching transaction data:', error);
      setTransactionStats({
        monthlyTransactions: Array.from({ length: 12 }, (_, i) => ({
          month: new Date(new Date().getFullYear(), i, 1).toLocaleString('default', {
            month: 'short',
          }),
          count: 0,
        })),
        totalTransactions: 0,
      });
    }
  };

  const fetchInvoiceData = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/get-invoice-details`);
      if (!response.ok) {
        throw new Error('Failed to fetch invoice data');
      }
      const data = await response.json();

      let pending = 0;
      let approved = 0;
      let declined = 0;

      data.forEach((invoice) => {
        const status = invoice.status?.toUpperCase().trim();
        switch (status) {
          case 'PENDING':
            pending++;
            break;
          case 'APPROVED':
            approved++;
            break;
          case 'DECLINED':
            declined++;
            break;
          default:
            console.warn('Unknown invoice status:', invoice.status);
        }
      });

      setInvoiceStats({
        pending,
        approved,
        declined,
        total: data.length,
      });

      const weekStats = getCurrentWeekStats(data);
      setCurrentWeekInvoiceStats(weekStats);
    } catch (error) {
      console.error('Error fetching invoice data:', error);
      setInvoiceStats({
        pending: 0,
        approved: 0,
        declined: 0,
        total: 0,
      });
      setCurrentWeekInvoiceStats({
        pending: 0,
        approved: 0,
        declined: 0,
        total: 0,
      });
    }
  };

  const UserStatusBarChart = () => {
    const data = [
      {
        name: 'Active',
        value: stats.activeUsers,
        color: 'rgb(255, 220, 229)',
      },
      {
        name: 'Inactive',
        value: stats.blockedUsers,
        color: '#CCCCFF',
      },
    ];

    return (
      <DashboardChartCard>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: 'text.secondary',
            }}
          >
            User Status
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Total Users: {stats.totalUsers}
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              {/* Remove CartesianGrid to eliminate grid lines */}
              <XAxis
                dataKey="name"
                tick={{ fill: '#6b7280' }}
                // axisLine={false}  // This removes the axis line
                tickLine={false} // This removes the tick lines
              />
              <YAxis
                tick={{ fill: '#6b7280' }}
                // axisLine={false}  // This removes the axis line
                tickLine={false} // This removes the tick lines
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.96)',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: 'none',
                }}
                formatter={(value) => [`${value} users`, 'Count']}
                labelFormatter={(label) => `${label} Users`}
              />
              <Bar
                dataKey="value"
                name="Users"
                animationDuration={1500}
                radius={[4, 4, 0, 0]}
                onClick={(data) => {
                  if (data.name === 'Active') {
                    navigate('/manage-users?status=active');
                  } else {
                    navigate('/manage-users?status=blocked');
                  }
                }}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} cursor="pointer" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </DashboardChartCard>
    );
  };

  const FuelCardPieChart = () => {
    const data = [
      {
        name: 'Active Cards',
        value: fuelCardStats.activeCards,
        status: 'ACTIVE',
        color: '#87CEEB',
      },
      {
        name: 'Inactive Cards',
        value: fuelCardStats.inactiveCards,
        status: 'INACTIVE',
        color: '#FFE0CC',
      },
    ];

    const handlePieClick = (data, index) => {
      if (index === 0) {
        navigate('/fuel-card?status=active');
      } else if (index === 1) {
        navigate('/fuel-card?status=inactive');
      }
    };

    return (
      <DashboardChartCard>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: 'text.secondary',
            }}
          >
            Fuel Card Status
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Total Cards: {fuelCardStats.totalCards}
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                onClick={handlePieClick}
                animationDuration={1500}
                animationEasing="ease-out"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.96)',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: 'none',
                }}
              />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </CardContent>
      </DashboardChartCard>
    );
  };

  const InvoiceStatusBarChart = () => {
    const data = [
      {
        name: 'Pending',
        value: invoiceStats.pending,
        color: '#E7D27C',
      },
      {
        name: 'Approved',
        value: invoiceStats.approved,
        color: '#D1FEB8',
      },
      {
        name: 'Declined',
        value: invoiceStats.declined,
        color: '#F1BEB5',
      },
    ];

    return (
      <DashboardChartCard>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: 'text.secondary',
            }}
          >
            Invoice Status
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Total Invoices: {invoiceStats.total}
          </Typography>
          {invoiceStats.total > 0 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                layout="vertical"
                data={data}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                {/* Remove CartesianGrid to eliminate grid lines */}
                <XAxis
                  type="number"
                  tick={{ fill: '#6b7280' }}
                  // axisLine={false}
                  tickLine={false}
                />
                <YAxis
                  dataKey="name"
                  type="category"
                  tick={{ fill: '#6b7280' }}
                  // axisLine={false}
                  tickLine={false}
                />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    border: 'none',
                  }}
                  formatter={(value) => [`${value} invoices`, 'Count']}
                  labelFormatter={(label) => `${label} Invoices`}
                />
                <Bar
                  dataKey="value"
                  name="Invoices"
                  animationDuration={1500}
                  radius={[0, 4, 4, 0]}
                  // onClick={(data) => {
                  //   // navigate(`/invoice-management?status=${data.name.toLowerCase()}`);
                  // }}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} cursor="pointer" />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
              No invoice data available
            </Typography>
          )}
        </CardContent>
      </DashboardChartCard>
    );
  };

  const CurrentWeekInvoiceStats = () => {
    const data = [
      { name: 'Pending', value: currentWeekInvoiceStats.pending, color: '#E7D27C' },
      { name: 'Approved', value: currentWeekInvoiceStats.approved, color: '#D1FEB8' },
      { name: 'Declined', value: currentWeekInvoiceStats.declined, color: '#F1BEB5' },
    ];

    return (
      <DashboardChartCard>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: 'text.secondary',
            }}
          >
            Current Week Invoice Status
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Total Invoices: {currentWeekInvoiceStats.total}
          </Typography>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Pending
              </Typography>
              <Typography variant="h4" color="#fbbf24" sx={{ fontWeight: 700 }}>
                {currentWeekInvoiceStats.pending}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Approved
              </Typography>
              <Typography variant="h4" color="#10b981" sx={{ fontWeight: 700 }}>
                {currentWeekInvoiceStats.approved}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2" color="text.secondary">
                Declined
              </Typography>
              <Typography variant="h4" color="#ef4444" sx={{ fontWeight: 700 }}>
                {currentWeekInvoiceStats.declined}
              </Typography>
            </Grid>
          </Grid>
          {currentWeekInvoiceStats.total > 0 ? (
            <ResponsiveContainer width="100%" height={215}>
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <XAxis type="number" tick={{ fill: '#6b7280' }} tickLine={false} />
                <YAxis dataKey="name" type="category" tick={{ fill: '#6b7280' }} tickLine={false} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.96)',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    border: 'none',
                  }}
                />
                <Bar dataKey="value" name="Invoices" animationDuration={1500}>
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Bar>

                <Legend
                  wrapperStyle={{
                    paddingTop: '10px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px',
                  }}
                  formatter={(value) => (
                    <span
                      style={{
                        display: 'inline-block',
                        padding: '0 8px',
                        ...(value === 'Approved' && {
                          marginLeft: '8px',
                          fontWeight: 'bold',
                        }),
                      }}
                    >
                      {value}
                    </span>
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Typography variant="body1" color="text.secondary">
              No invoices this week
            </Typography>
          )}
        </CardContent>
      </DashboardChartCard>
    );
  };

  const TransactionsBarChart = () => {
    const currentYear = new Date().getFullYear();
    const colors = [
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
      '#D6B3E1',
    ];

    return (
      <DashboardChartCard>
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
              color: 'text.secondary',
            }}
          >
            {currentYear} Monthly Transactions
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Total Transactions: {transactionStats.totalTransactions}
          </Typography>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={transactionStats.monthlyTransactions}
              margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
              <XAxis
                type="number"
                tick={{ fill: '#6b7280' }}
                // axisLine={false}
                tickLine={false}
              />
              <YAxis
                dataKey="name"
                type="category"
                tick={{ fill: '#6b7280' }}
                // axisLine={false}
                tickLine={false}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: 'rgba(255, 255, 255, 0.96)',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  border: 'none',
                }}
                formatter={(value) => [`${value} transactions`, 'Count']}
                labelFormatter={(label) => `${label} ${currentYear}`}
              />
              <Bar
                dataKey="count"
                name="Transactions"
                animationDuration={2000}
                radius={[4, 4, 0, 0]}
              >
                {transactionStats.monthlyTransactions.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </DashboardChartCard>
    );
  };

  return (
    loggedIn && (
      <Box sx={{ p: 3 }}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
            <CircularProgress size={60} thickness={4} />
          </Box>
        ) : (
          <motion.div initial="hidden" animate="visible" variants={containerVariants}>
            {/* Stats Cards Row */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={4}>
                <motion.div variants={itemVariants}>
                  <StatCard
                    icon={<PeopleIcon fontSize="large" color="primary" />}
                    title="Total Clients"
                    value={clientStats.totalClients}
                    color="#E3F2FD"
                    onClick={() => navigate('/manage-customers')}
                    delay={0.1}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={4}>
                <motion.div variants={itemVariants}>
                  <StatCard
                    icon={<CheckCircleIcon fontSize="large" color="success" />}
                    title="Active Clients"
                    value={clientStats.activeClients}
                    color="#E8F5E9"
                    onClick={() => navigate('/manage-customers?status=active')}
                    delay={0.2}
                  />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={4}>
                <motion.div variants={itemVariants}>
                  <StatCard
                    icon={<BlockIcon fontSize="large" color="error" />}
                    title="Inactive Clients"
                    value={clientStats.inactiveClients}
                    color="#fff1f2"
                    onClick={() => navigate('/manage-customers?status=inactive')}
                    delay={0.3}
                  />
                </motion.div>
              </Grid>
            </Grid>

            {/* Invoice Charts Row */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <motion.div variants={itemVariants}>
                  <InvoiceStatusBarChart />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div variants={itemVariants}>
                  <CurrentWeekInvoiceStats />
                </motion.div>
              </Grid>
            </Grid>

            {/* User and Fuel Card Charts Row */}
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={6}>
                <motion.div variants={itemVariants}>
                  <UserStatusBarChart />
                </motion.div>
              </Grid>
              <Grid item xs={12} md={6}>
                <motion.div variants={itemVariants}>
                  <FuelCardPieChart />
                </motion.div>
              </Grid>
            </Grid>

            {/* Transactions Chart Row */}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <motion.div variants={itemVariants}>
                  <TransactionsBarChart />
                </motion.div>
              </Grid>
            </Grid>
          </motion.div>
        )}
      </Box>
    )
  );
};

export default UserDashboard;
