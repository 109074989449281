import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  Alert,
  AlertTitle,
} from '@mui/material'; // Tooltip, Paper, , Chip, Alert
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import { darken, lighten, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { TextField, InputAdornment } from '@mui/material';
// import Loader from 'components/Loader';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useSearchParams } from 'react-router-dom';

// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the DeleteIcon
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const ManageUsers = ({ isOnboard = false, client }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get('status');
  const [loggedIn, setLoggedIn] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const buttonsFontSize = '16px';

  const loggedInUserClientId = localStorage.getItem('clientId');
  const loggedInUserName = localStorage.getItem('userName');
  const loggedInRole = localStorage.getItem('role');
  const doNotPerformUserFiltration = localStorage.getItem('role') === 'Super Admin';

  const [userName, setUserName] = React.useState('');
  const [userNameError, setUserNameError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [passwordVisibility, setPasswordVisibility] = React.useState('');
  const [role, setRole] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [accountId, setAccountId] = React.useState('');
  const [userStatus, setUserStatus] = useState('Inactive'); // default to 'Inactive'
  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false);

  const toUpperCase = (text) => text.toUpperCase();
  const [isEmailAvailable, setIsEmailAvailable] = useState(true); // Assuming the email is available initially

  // const [onboardAccountDetails, setOnboardAccountDetails] = React.useState('');
  // const [isOnboardAccountDetailsAvailable, setIsOnboardAccountDetailsAvailable] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Alerts
  const [isUserCreated, setIsUserCreated] = React.useState(false);
  const [isUserUpdated, setIsUserUpdated] = React.useState(false);

  const [userListView, setUserListView] = React.useState([]);
  const [isAddUserSelected, setAddUserSelected] = React.useState(isOnboard);
  const [isUpdateUserSelected, setUpdateUserSelected] = React.useState(false);
  const [activeAccounts] = useState([]); // Array to hold active account data
  const [accountError, setAccountError] = useState(false); // Error state for account selection
  const [, setErrors] = useState({});

  // const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);
  const defaultButtonBackground = '#011f4b';
  const chosenButtonBackground = '#03396c';
  const [accountDetails, setAccountDetails] = React.useState([]);
  const userStatuses = [
    { id: 'Active', value: 'Active' },
    { id: 'Inactive', value: 'Inactive' },
  ];

  const userRoles = [
    { id: 2, value: 'Admin' },
    { id: 3, value: 'User' },
  ];

  // Keep only the original declaration (around line 150):
  const NoRecordsFoundRow = [
    {
      id: '',
      userName: '',
      account: 'No records found',
      userStatus: '',
      role: '',
    },
  ];

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`);
    } else {
      setLoggedIn(true);
      setLoading(true);
      if (!isOnboard) generateListViewDetails();
      if (isOnboard) {
        // getClientById(clientId)
      }
      setLoading(false);
    }
  }, []);

  const generateListViewDetails = async () => {
    let users = await getUsers();
    let accounts = await getClients();
    if (users.length > 0 && accounts.length > 0) {
      let userListViewDetails = [];
      let userListViewId = 1;
      for (let u = 0; u < users.length; u++) {
        let user = users[u];

        // Apply status filter if present
        if (statusFilter === 'active' && !user.userStatus) continue;
        if (statusFilter === 'blocked' && user.userStatus) continue;

        if (user.role != 'Super Admin' && user.role != 'Broker' && user.role != 'Broker-user') {
          let clientAccount = accounts.filter((account) => account.clientID == user.clientID);
          if (clientAccount.length > 0) {
            if (doNotPerformUserFiltration) {
              let userListView = {
                id: userListViewId++,
                userName: user.userName,
                account: toUpperCase(clientAccount[0].clientName),
                userStatus: user.userStatus ? 'ACTIVE' : 'BLOCKED',
                role: toUpperCase(user.role),
              };
              userListViewDetails.push(userListView);
            } else if (
              !doNotPerformUserFiltration &&
              loggedInUserClientId === user.clientID &&
              loggedInUserName !== user.userName
            ) {
              let userListView = {
                id: userListViewId++,
                userName: user.userName,
                account: toUpperCase(clientAccount[0].clientName),
                userStatus: user.userStatus ? 'ACTIVE' : 'BLOCKED',
                role: user.role,
              };
              userListViewDetails.push(userListView);
            }
          }
        }
      }
      setUserListView(userListViewDetails);
    }
  };

  const userListViewColumns = [
    // {
    //   field: 'id',
    //   headerName: 'SERIAL NO.',
    //   width: 150,
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerClassName: 'super-app-theme--header',
    //   flex: 1
    // },
    {
      field: 'userName',
      headerName: 'USER NAME',
      width: 180,
      align: 'Left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'account',
      headerName: 'CUSTOMER ACCOUNT',
      width: 120,
      align: 'Left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'userStatus',
      headerName: 'STATUS',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'role',
      headerName: 'ROLE',
      width: 120,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <div>
      //       <DeleteIcon
      //         sx={{ cursor: "pointer", fontSize: 16, color: "grey" }}
      //         onClick={() => handleDelete(params.row.userName)}
      //         color="error"
      //       />
      //     </div>
      //   );
      // },
      renderCell: (params) => {
        return params.row.role?.toLowerCase() !== 'admin' ? (
          <DeleteIcon
            sx={{ cursor: 'pointer', fontSize: 16, color: 'grey' }}
            onClick={() => handleDelete(params.row.userName)}
            color="error"
          />
        ) : null; // Return null if the role is "Admin"
      },
    },
  ];

  const handleDelete = (username, role) => {
    // Log the action of initiating user deletion with role
    console.log(`Attempting to delete user: ${username} with role: ${role}`);

    // Get the logged-in user's username from localStorage (adjust key as needed)
    const loggedInUsername = localStorage.getItem('username'); // Assuming "username" is stored
    console.log(`Logged-in user: ${loggedInUsername}`);

    // Check if the user is trying to delete themselves
    if (username === loggedInUsername) {
      console.log(`User ${username} cannot delete themselves`);
      alert('You cannot delete yourself.');
      return; // Exit early, no further action
    }

    // Check if the role from the table is "Admin"
    if (role === 'Admin') {
      console.log(`User ${username} is an Admin; deletion not allowed, no email will be triggered`);
      alert('admin cannot be deleted by anyone but you can change the admin status as inactive');
      return; // Exit early, ensuring no email is triggered
    }

    // For non-Admin roles, proceed with confirmation and email
    const confirmation = window.confirm(`Are you sure you want to delete the user: ${username}?`);

    if (confirmation) {
      // Log that the deletion was confirmed
      console.log(`User ${username} confirmed for deletion by ${loggedInUsername}`);
      // Trigger the email approval process for non-Admin users
      sendApprovalEmail(username);
    } else {
      // Log that the deletion was canceled
      console.log(`User ${username} deletion canceled by ${loggedInUsername}`);
    }
  };

  // Function to send approval email (only called for non-Admin users)
  const sendApprovalEmail = (username) => {
    // Log that the email request is being sent
    console.log(`Sending approval email request for user: ${username}`);

    // Make the POST request to send the approval email
    fetch(`${BACKEND_URL}/v1/send-approval-email?username=${username}`, {
      method: 'POST',
    })
      .then((response) => {
        console.log(`Response status for ${username}: ${response.status}`);
        if (!response.ok) {
          return response.json().then((data) => {
            throw { status: response.status, message: data.message };
          });
        }
        return response.json();
      })
      .then((data) => {
        // Success case: Email sent successfully
        console.log(`Approval email sent successfully for user: ${username}`, data);
        alert('Approval email sent. Please check your inbox for further instructions.');
      })
      .catch((error) => {
        console.log(`Handling error for ${username}:`, error);
        if (error.status === 404) {
          console.log(`User ${username} not found`);
          alert('User not found in the system.');
        } else {
          console.error(`Unexpected error for ${username}:`, error);
          alert(`There was an issue: ${error.message || 'Unknown error'}`);
        }
      });
  };

  // const deleteUser = (username) => {
  //   // Log the attempt to send the DELETE request
  //   console.log(`Sending DELETE request for user: ${username}`);

  //   // Call the delete API with the provided username
  //   fetch(`${BACKEND_URL}/v1/user?username=${username}`, {
  //     method: 'DELETE',
  //   })
  //     .then((response) => {
  //       if (response.ok) {
  //         // Log successful deletion
  //         console.log(`User ${username} deleted successfully.`);
  //         alert(`User ${username} deleted successfully`);
  //         // Optionally, update the UI, like removing the user from a list
  //       } else {
  //         // Log failure to delete
  //         console.error(`Failed to delete user ${username}. Response: ${response.status}`);
  //         alert("Failed to delete the user.");
  //       }
  //     })
  //     .catch((error) => {
  //       // Log error in case of network failure or any other issue
  //       console.error("Error deleting user:", error);
  //       alert("An error occurred while deleting the user.");
  //     });
  // };

  // const handleAccountChange = (event) => {
  //   setAccountId(event.target.value);
  // };

  const handleAccountChange = (event) => {
    const selectedAccountId = event.target.value;

    // Simulate an API call to check for active logins for the selected account
    const activeLoginCount = activeAccounts.filter(
      (account) => account.clientID === selectedAccountId,
    ).length;

    if (activeLoginCount >= 2) {
      setAccountError(true); // Account already has 2 logins, show error
    } else {
      setAccountError(false); // Allow selection if less than 2 logins
    }
    setAccountId(selectedAccountId);
  };

  const handleAddUserSelection = (event) => {
    event.preventDefault();
    setUserListView([]);
    setLoading(true);
    setTimeout(() => {
      setAddUserSelected(true);
      setUpdateUserSelected(false);
      if (!isOnboard) generateListViewDetails();
      resetFields();
      // if(isOnboard)
      // setAccountId(clientId)
      setLoading(false);
    }, 2000);
  };

  const checkEmailExistence = async (email) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?emailID=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log('Backend response:', data);

      if (response.ok) {
        // Check if email exists
        const emailExists = data.some((user) => user.emailId === email);

        if (emailExists) {
          setIsEmailAvailable(false);
          setEmailError('Email already exists'); // Set error message if email exists
        } else {
          setIsEmailAvailable(true);
          setEmailError(null); // Clear error if email is available
        }
      } else {
        setIsEmailAvailable(false);
        setEmailError('Error checking email availability'); // Set a general error message
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
      setEmailError('Error checking email availability'); // Handle unexpected errors
    }
  };

  // const generateListViewDetails = async () => {
  //   let users = await getUsers();
  //   let accounts = await getClients();
  //   if (users.length > 0 && accounts.length > 0) {
  //     let userListViewDetails = [];
  //     let userListViewId = 1;
  //     for (let u = 0; u < users.length; u++) {
  //       let user = users[u];
  //       if (
  //         user.role != "Super Admin" &&
  //         user.role != "Broker" &&
  //         user.role != "Broker-user"
  //       ) {
  //         let clientAccount = accounts.filter(
  //           (account) => account.clientID == user.clientID
  //         );
  //         if (clientAccount.length > 0) {
  //           if (doNotPerformUserFiltration) {
  //             let userListView = {
  //               id: userListViewId++,
  //               userName: user.userName,
  //               account: toUpperCase(clientAccount[0].clientName),
  //               userStatus: user.userStatus ? "ACTIVE" : "BLOCKED",
  //               role: toUpperCase(user.role),
  //             };
  //             userListViewDetails.push(userListView);
  //           } else if (
  //             !doNotPerformUserFiltration &&
  //             loggedInUserClientId == user.clientID
  //           ) {
  //             let userListView = {
  //               id: userListViewId++,
  //               userName: user.userName,
  //               account: toUpperCase(clientAccount[0].clientName),
  //               userStatus: user.userStatus ? "ACTIVE" : "BLOCKED",
  //               role: user.role,
  //             };
  //             userListViewDetails.push(userListView);
  //           }
  //         }
  //       }
  //     }
  //     setUserListView(userListViewDetails);
  //   }
  // };

  const handleUpdateUserSelection = (event) => {
    event.preventDefault();
    setUserListView([]);
    setLoading(true);
    setTimeout(() => {
      setAddUserSelected(false);
      setUpdateUserSelected(true);
      generateListViewDetails();
      resetFields();
      setLoading(false);
    }, 2000);
  };

  const resetFields = () => {
    setUserName('');
    setUserNameError('');
    setPassword('');
    setPasswordError('');
    setRole('');
    setEmail('');
    setEmailError('');
    setAccountId('');
    setUserStatus('');
  };

  const getUsers = async () => {
    let getUsersUrl = `${BACKEND_URL}/v1/user`;
    const response = await fetch(getUsersUrl);
    const data = await response.json();

    console.log('userdata ', data);
    if (doNotPerformUserFiltration) {
      let filteredData = data.filter((user) => ['Admin', 'User'].includes(user?.role));
      setUsers(filteredData);
    } else {
      let filteredData = data.filter((user) => user?.clientID == loggedInUserClientId);
      setUsers(filteredData);
    }
    return data;
  };

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    const response = await fetch(getClientsUrl);
    const data = await response.json();
    setAccountDetails(data);
    return data;
  };

  const handleUserNameChange = (event) => {
    const username = event.target.value;
    const regex = /^[a-zA-Z0-9]+$/;

    // Check if the username is alphanumeric
    if (regex.test(username)) {
      setUserName(username);
      setUserNameError(''); // Clear previous error

      // Check if username exists in the database
      checkUsernameExistence(username);
    } else {
      setUserNameError('Username can contain only alphanumeric values (a-zA-Z0-9)');
    }
  };

  const checkUsernameExistence = async (username) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?username=${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setUserNameError(null); // No error if username doesn't exist
      } else {
        const data = await response.json();
        // If username exists, show error
        if (Array.isArray(data) && data.length > 0) {
          setUserNameError('Username already exists');
        } else {
          setUserNameError(null);
        }
      }
    } catch (error) {
      setUserNameError('Error occurred while checking username'); // Handle network errors
    }
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Regular expression for email format validation
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (regex.test(newEmail)) {
      setEmailError(''); // Clear error if the email format is valid
      checkEmailExistence(newEmail); // Trigger email existence check if format is valid
    } else {
      setEmailError('Email should end with @domain.com or @domain.in'); // Set email format error
      setIsEmailAvailable(true); // Assume email is available if format is invalid (no API call will be made)
    }
  };

  const handlePasswordChange = (event) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setPassword(event.target.value);
    if (regex.test(event.target.value)) {
      setPassword(event.target.value);
      setPasswordError('');
    } else {
      setPasswordError(
        'Password should contain atleast 8 characters, one uppercase letter, one lowercase letter, one digit and one special character',
      );
    }
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  // const handleCreateUser = (event) => {
  //   event.preventDefault();
  //   setLoading(true);

  //   // Validate form inputs
  //   const errors = validateForm(); // Assuming validateForm() returns an error object

  //   if (Object.keys(errors).length > 0) {
  //     setErrorMessages(errors); // Set error messages to show on the form
  //     setLoading(false); // Stop loading while there are errors
  //     return; // Prevent form submission if there are errors
  //   }

  //   // Step 1: Fetch existing users by clientID
  //   fetch(`${BACKEND_URL}/v1/usersbyid?clientID=${accountId}`, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   })
  //     .then((response) => {
  //       console.log('Response from /v1/usersbyid:', response); // Log response for debugging
  //       if (!response.ok) {
  //         // If the response is not OK (status code not in 2xx)
  //         throw new Error('Error fetching users for the client.');
  //       }
  //       return response.json(); // Proceed to parse the response as JSON
  //     })
  //     .then((users) => {
  //       console.log('Users for client:', users); // Log users for debugging

  //       // Step 2: Check if the client already has 2 users
  //       if (users.length >= 2) {
  //         console.error('Client already has 2 users. Cannot add more.');
  //         // Prevent user creation if the client already has 2 users
  //         throw new Error('This client already has 2 users. Cannot add more.');
  //       }

  //       // Step 3: Proceed with creating the user if fewer than 2 users exist
  //       const createUserObject = {
  //         userName: userName,
  //         password: password,
  //         role: role,
  //         emailId: email,
  //         clientID: accountId,
  //         userStatus: userStatus === 1,
  //         address: null,
  //       };

  //       console.log('Creating user with data:', createUserObject); // Log create user object for debugging

  //       return fetch(`${BACKEND_URL}/v1/user`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(createUserObject),
  //       });
  //     })
  //     .then((response) => {
  //       console.log('Response from /v1/user POST:', response); // Log response for debugging
  //       if (!response.ok) {
  //         // Handle non-OK responses (error case)
  //         return response.json().then((data) => {
  //           console.error('Error from backend during user creation:', data.message); // Log backend error
  //           throw new Error(data.message || 'Unexpected error occurred while creating user.');
  //         });
  //       }

  //       return response.json(); // If the response is OK, proceed with response data
  //     })
  //     .then(() => {
  //       // Proceed with success
  //       console.log('User created successfully');
  //       getUsers(); // Fetch updated user data
  //     })
  //     .then(() => {
  //       // Update the list view
  //       generateListViewDetails();
  //       setIsUserCreated(true); // Notify that the user has been created
  //       resetFields(); // Reset form fields
  //       setLoading(false); // Stop loading
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error); // Log the error for debugging
  //       setErrorMessages({ user: error.message }); // Display error message in the form
  //       setLoading(false); // Stop loading in case of error
  //     });
  // };

  const handleCreateUser = (event) => {
    event.preventDefault();
    setLoading(true);

    // Validate form inputs
    const errors = validateForm(); // Assuming validateForm() returns an error object

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors); // Set error messages to show on the form
      setLoading(false); // Stop loading while there are errors
      return; // Prevent form submission if there are errors
    }

    // Step 1: Check the role and fetch users accordingly
    let fetchUrl = '';
    if (role === 'Admin') {
      // Fetch existing admin users if the role is Admin
      fetchUrl = `${BACKEND_URL}/v1/adminusersbyid?clientID=${accountId}`;
    } else if (role === 'User') {
      // Fetch existing users if the role is User
      fetchUrl = `${BACKEND_URL}/v1/usersbyid?clientID=${accountId}`;
    }

    fetch(fetchUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(`Response from ${fetchUrl}:`, response); // Log response for debugging
        if (!response.ok) {
          // If the response is not OK (status code not in 2xx)
          throw new Error(`Error fetching users for the client at ${fetchUrl}.`);
        }
        return response.json(); // Proceed to parse the response as JSON
      })
      .then((users) => {
        console.log('Users for client:', users); // Log users for debugging

        // Step 2: Check if the client already has the allowed number of users based on the role
        if (role === 'Admin' && users.length >= 1) {
          console.error('Client already has an Admin user. Cannot add more.');
          // Prevent user creation if the client already has an admin user
          throw new Error('This client already has an Admin user. Cannot add more.');
        }

        if (role === 'User' && users.length >= 2) {
          console.error('Client already has 2 users. Cannot add more.');
          // Prevent user creation if the client already has 2 users
          throw new Error('This client already has 2 users. Cannot add more.');
        }

        // Step 3: Proceed with creating the user if allowed
        const createUserObject = {
          userName: userName,
          password: password,
          role: role,
          emailId: email,
          clientID: accountId,
          userStatus: userStatus === 1,
          address: null,
        };

        console.log('Creating user with data:', createUserObject); // Log create user object for debugging

        return fetch(`${BACKEND_URL}/v1/user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(createUserObject),
        });
      })
      .then((response) => {
        console.log('Response from /v1/user POST:', response); // Log response for debugging
        if (!response.ok) {
          // Handle non-OK responses (error case)
          return response.json().then((data) => {
            console.error('Error from backend during user creation:', data.message); // Log backend error
            throw new Error(data.message || 'Unexpected error occurred while creating user.');
          });
        }

        return response.json(); // If the response is OK, proceed with response data
      })
      .then(() => {
        // Proceed with success
        console.log('User created successfully');
        getUsers(); // Fetch updated user data
      })
      .then(() => {
        // Update the list view
        generateListViewDetails();
        setIsUserCreated(true); // Notify that the user has been created
        resetFields(); // Reset form fields
        setLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error('Error:', error); // Log the error for debugging
        setErrorMessages({ user: error.message }); // Display error message in the form
        setLoading(false); // Stop loading in case of error
      });
  };

  const handleUpdateUserSelectionChange = (event) => {
    setLoading(true);
    setTimeout(() => {
      let chosenUserForUpdate = users.filter((user) => user.userName === event.target.value);
      if (chosenUserForUpdate) {
        let user = chosenUserForUpdate[0];
        setUserName(user.userName);
        let role = userRoles.filter((role) => role.value === user.role);
        if (role.length > 0) {
          setRole(role[0].value);
        }
        setEmail(user.emailId);
        setAccountId(user.clientID);
        setUserStatus(user.userStatus == false ? 'Inactive' : 'Active');
        setLoading(false);
      }
    }, 1000);
  };

  const handleUserStatusChange = (event) => {
    // let selectedStatus = userStatuses.filter(status => status.value === event.target.value);
    let userStatus = event.target.value;
    setUserStatus(userStatus);
  };

  const setErrorMessages = (messages) => {
    setErrors(messages); // Use setErrors to update the error state

    // Display an alert for each error
    Object.keys(messages).forEach((field) => {
      alert(`Need to fix the error message for ${field}: ${messages[field]}`);
    });
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    setLoading(true);

    // Validate form inputs
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors); // Set error messages to show on the form
      setLoading(false);
      return; // Prevent submission if there are errors
    }

    console.log('Updating user status to:', userStatus); // Log before sending the request

    // Check if the logged-in user is trying to set their own status to "Inactive"
    if (loggedInUserName === userName && userStatus === 'Inactive') {
      // setErrorMessages({
      //   general: 'Can\'t change inactive by self account', // Error message for this case
      // });
      alert("Can't change inactive by self account");
      setLoading(false);
      return; // Prevent submission if trying to set their own status to Inactive
    }

    if (loggedInRole === 'User' && userStatus === 'Inactive') {
      // setErrorMessages({
      //   general: 'Can\'t change inactive by self account', // Error message for this case
      // });
      alert('User cannot inactive other Users or Admin');
      setLoading(false);
      return; // Prevent submission if trying to set their own status to Inactive
    }

    const updateUserObject = {
      userName: userName,
      role: role,
      emailId: email,
      accountId: accountId,
      userStatus: userStatus === 'Active', // Convert 'Active' to true, 'Inactive' to false
      name: userName,
      phoneNo: '',
    };

    fetch(`${BACKEND_URL}/v1/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUserObject),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('User update response:', data); // Log backend response

        // Re-fetch the user data after update (if applicable)
        getUsers(); // Assuming this updates the user list from the backend
        generateListViewDetails(); // Assuming this updates the UI details accordingly

        setIsUserUpdated(true); // Notify user update success
        resetFields(); // Clear form fields
        console.log('User status after update:', userStatus); // Log status after update
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        setLoading(false);
      });
  };

  // Form validation function
  const validateForm = () => {
    const errors = {};
    if (!userName) {
      errors.userName = 'User name is required';
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Valid email is required';
    }
    if (!role) {
      errors.role = 'Role is required';
    }
    if (!accountId) {
      errors.accountId = 'Account ID is required';
    }
    // Add more validation checks as needed
    return errors;
  };

  // const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode),
      },
    },
  }));

  // const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  // const cardHeight = 650;
  const inputLabelStyles = {
    fontSize: '13px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600,
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '13px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600,
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  const handlePasswordGenerator = () => {
    const length = 12;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]|;:,.<>?';

    let newPassword = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
    setPasswordError('');
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setIsCopiedToClipboard(true);
    // alert('Password copied to clipboard!');
  };

  const optionsWidth = isOnboard ? 2 : 4;

  return (
    loggedIn && (
      <div>
        <Typography variant="h5" style={{ marginBottom: '20px', fontWeight: 'bold' }}>
          {statusFilter === 'active' && 'Active Users'}
          {statusFilter === 'blocked' && 'Blocked Users'}
          {!statusFilter && 'All Users'}
        </Typography>
        {/* <div className="container" style={{ display: 'flex' }}> */}
        <div className="card" style={{ flex: '1' }}>
          {/* <Card sx={{ minWidth: '100%', minHeight: cardHeight }}> */}
          {/* <CardContent> */}
          {isUserCreated && (
            <Alert
              severity="success"
              variant="filled"
              size="large"
              onClose={() => setIsUserCreated(false)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '16px',
              }}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '16px',
              }}
            >
              <AlertTitle>Success, User created successfully</AlertTitle>
            </Alert>
          )}
          {isUserUpdated && (
            <Alert
              severity="success"
              variant="filled"
              size="large"
              onClose={() => setIsUserUpdated(false)}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '16px',
              }}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: '16px',
              }}
            >
              <AlertTitle>Success, User updated successfully</AlertTitle>
            </Alert>
          )}
          <Grid container spacing={6}>
            <Grid item xs={8} sm={8} md={8} lg={8} style={gridRowStyles}></Grid>
            {isOnboard && <Grid item xs={2} sm={2} md={2} lg={2} style={gridRowStyles}></Grid>}
            <Grid
              item
              xs={optionsWidth}
              sm={optionsWidth}
              md={optionsWidth}
              lg={optionsWidth}
              style={gridRowStyles}
            >
              <Button
                style={{
                  fontFamily: 'DM Sans',
                  fontSize: '10px',
                  padding: '12px 16px',
                  backgroundColor: '#31478F',
                  marginTop: '-10px',
                  marginLeft: '110px',
                }}
                type="submit"
                variant="contained"
                sx={{
                  mr: 2,
                  borderRadius: 2,
                  height: '28px', // Same height for both buttons
                  width: 'auto', // Let the width adjust automatically based on content
                }}
                onClick={handleAddUserSelection}
              >
                <Typography sx={{ fontSize: '10px' }}>New User</Typography> {/* Same font size */}
              </Button>

              {!isOnboard && (
                <Button
                  style={{
                    fontFamily: 'DM Sans',
                    fontSize: '10px',
                    padding: '5px 7px',
                    backgroundColor: '#31478F',
                    marginBottom: '12px',
                    marginLeft: '210px',
                    whiteSpace: 'nowrap',
                    marginTop: '-33px',
                  }}
                  type="submit"
                  variant="contained"
                  sx={{
                    mr: 2,
                    borderRadius: 2,
                    height: '28px', // Same height for both buttons
                    width: 'auto', // Let the width adjust automatically based on content
                  }}
                  onClick={handleUpdateUserSelection}
                >
                  <Typography sx={{ fontSize: '10px' }}>Update User</Typography>{' '}
                  {/* Same font size */}
                </Button>
              )}
            </Grid>
          </Grid>
          {/* {loading && <Loader />} */}
          {isAddUserSelected && (
            <form id="add-user" onSubmit={handleCreateUser}>
              <div>
                {isOnboard && (
                  <Typography variant="h5" sx={{ m: 1 }}>
                    Account: {client}
                  </Typography>
                )}
              </div>
              <div>
                <Grid container spacing={2}>
                  {!isOnboard && (
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small" error={accountError}>
                          <InputLabel id="accountId" sx={{ fontSize: '10px' }}>
                            Account
                          </InputLabel>
                          <Select
                            labelId="accountId"
                            id="accountId"
                            value={accountId}
                            label="Account ID"
                            onChange={handleAccountChange}
                            InputLabelProps={{
                              sx: {
                                fontSize: '11px',
                                top: '-50%', // Initially center the label vertically
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 22,
                                minHeight: 22,
                              }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 11,
                                padding: '2px',
                              }, // Reduce font size & padding
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: '200px', // Adjust the maximum height of the dropdown
                                  minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                  '& .MuiMenuItem-root': {
                                    fontSize: '10px', // Reduce font size inside the dropdown
                                    marginLeft: '5px', // Adjust left margin
                                    padding: '5px 15px', // Adjust the padding inside the items to control height
                                  },
                                },
                              },
                            }}
                          >
                            {doNotPerformUserFiltration &&
                              accountDetails.map((account) => (
                                <MenuItem key={account.clientID} value={account.clientID}>
                                  {account.clientID} - {account.customerID} - {account.clientName}
                                </MenuItem>
                              ))}
                            {!doNotPerformUserFiltration &&
                              accountDetails
                                .filter((account) =>
                                  loggedInUserClientId?.includes(account.clientID),
                                )
                                .map((client) => (
                                  <MenuItem key={client.clientID} value={client.clientID}>
                                    {client.clientID} - {client.customerID} - {client.clientName}
                                  </MenuItem>
                                ))}
                          </Select>
                          {accountError && (
                            <FormHelperText id="input-error-text">
                              This account already has two active logins.
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small" error={userNameError}>
                        <TextField
                          style={inputLabelStyles}
                          id="userName"
                          label={userName ? 'User Name' : 'User Name'}
                          value={userName}
                          onChange={handleUserNameChange}
                          // InputLabelProps={textFieldLabelStyles}
                          required
                          autoComplete="new-username" // Disable autofill for username
                          error={Boolean(userNameError)}
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '4px',
                            }, // Reduce font size & padding
                          }}
                        />
                        {userNameError && (
                          <FormHelperText id="input-error-text">{userNameError}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box sx={{ flex: 'column', position: 'relative' }}>
                      <FormControl sx={{ minWidth: '100%' }} size="small" error={passwordError}>
                        <TextField
                          style={inputLabelStyles}
                          id="password"
                          type={passwordVisibility ? 'text' : 'password'}
                          label={password ? 'Password' : 'Password'}
                          value={password}
                          onChange={handlePasswordChange}
                          // InputLabelProps={textFieldLabelStyles}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                {passwordVisibility ? (
                                  <VisibilityOffIcon
                                    onClick={handlePasswordVisibility}
                                    sx={{ fontSize: '16px' }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    onClick={handlePasswordVisibility}
                                    sx={{ fontSize: '16px' }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          required
                          autoComplete="new-password" // Disable autofill for password
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-50%' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '1px',
                            }, // Reduce font size & padding
                          }}
                        />
                        {password?.length == 0 && (
                          <Typography
                            variant="h6"
                            onClick={handlePasswordGenerator}
                            style={{
                              cursor: 'pointer',
                              color: 'blue',
                              fontSize: '12px',
                              marginLeft: '5px',
                            }}
                          >
                            Generate Password
                          </Typography>
                        )}

                        {password?.length > 0 && (
                          <Typography
                            variant="h6"
                            onClick={handleCopyToClipboard}
                            style={{
                              cursor: 'pointer',
                              color: 'blue',
                              fontSize: '12px',
                            }}
                          >
                            {isCopiedToClipboard ? 'Copied to clipboard' : 'Copy to clipboard'}
                          </Typography>
                        )}
                        {passwordError && (
                          <FormHelperText id="input-error-text">{passwordError}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <InputLabel
                          id="role"
                          sx={{
                            fontSize: '11px',
                            top: '-10%', // Initially center the label vertically
                          }}
                        >
                          Role
                        </InputLabel>
                        <Select
                          labelId="role"
                          // sx={{ minHeight: '20px' }}
                          id="role"
                          value={role}
                          label="Role"
                          onChange={handleRoleChange}
                          sx={{
                            minHeight: '22px',
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22, // Adjust height
                            },
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px', // Reduce font size & padding
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '200px', // Adjust the maximum height of the dropdown
                                minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                '& .MuiMenuItem-root': {
                                  fontSize: '10px', // Reduce font size inside the dropdown
                                  marginLeft: '5px', // Adjust left margin
                                  padding: '5px 15px', // Adjust the padding inside the items to control height
                                },
                              },
                            },
                          }}
                        >
                          {userRoles.map((userRole) => (
                            <MenuItem key={userRole.id} value={userRole.value}>
                              {userRole.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small" error={emailError}>
                        <TextField
                          style={inputLabelStyles}
                          id="email"
                          label="Email"
                          value={email} // Bind to email state
                          onChange={handleEmailChange} // Handle email change and check availability
                          // InputLabelProps={textFieldLabelStyles}
                          required
                          error={!isEmailAvailable} // Show error if the email is not available
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 26 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '4px',
                            }, // Reduce font size & padding
                          }}
                        />

                        {/* Show error message if email is not available */}
                        {emailError && (
                          <FormHelperText id="input-error-text">{emailError}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <InputLabel
                          id="userStatus"
                          sx={{
                            fontSize: '11px',
                            top: '-10%',
                          }}
                        >
                          User Status
                        </InputLabel>
                        <Select
                          labelId="userStatus"
                          id="userStatus"
                          value={userStatus} // Ensure this is the same as the status ID value
                          label="User Status"
                          onChange={handleUserStatusChange} // Handle status change
                          sx={{
                            minHeight: '20px',
                            '& .MuiInputBase-root': {
                              height: 20,
                              minHeight: 20, // Adjust height
                            },
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px', // Reduce font size & padding
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '200px', // Adjust the maximum height of the dropdown
                                minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                '& .MuiMenuItem-root': {
                                  fontSize: '10px', // Reduce font size inside the dropdown
                                  marginLeft: '5px', // Adjust left margin
                                  padding: '5px 15px', // Adjust the padding inside the items to control height
                                },
                              },
                            },
                          }}
                        >
                          {userStatuses.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.value} {/* Display the value (Active/Inactive) */}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container rowSpacing={0.5} columnSpacing={2.75} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                    <Box>
                      <Button
                        style={{
                          fontFamily: 'DM Sans',
                          fontSize: '12px', // You can reduce this to a smaller value if needed
                          backgroundColor: '#31478F',
                          padding: '4px 10px', // Adjust padding to reduce button size
                        }}
                        type="submit"
                        variant="contained"
                        sx={{
                          borderRadius: '5px',
                          minWidth: '100px', // Set a minimum width (optional)
                          height: '30px', // Adjust height to reduce the button size
                          fontSize: '12px', // Optional: Reduce the font size directly in sx for consistency
                          padding: '1px 6px', // You can tweak the padding further for a more compact button
                          marginLeft: '-40px',
                        }}
                      >
                        {isOnboard ? 'Proceed' : 'Create'}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                </Grid>
              </div>
            </form>
          )}
          {isUpdateUserSelected && (
            <form id="add-user" onSubmit={handleUpdateUser}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <InputLabel
                          id="userName"
                          sx={{
                            fontSize: '11px',
                            top: '-10%', // Initially center the label vertically
                          }}
                        >
                          User Name
                        </InputLabel>
                        <Select
                          labelId="userName"
                          id="userName"
                          value={userName}
                          label="User Name"
                          onChange={handleUpdateUserSelectionChange}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22, // Adjust height
                            },
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px', // Reduce font size & padding
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '200px', // Adjust the maximum height of the dropdown
                                minHeight: '100px', // Adjust the minimum height of the dropdown (optional)

                                '& .MuiMenuItem-root': {
                                  fontSize: '10px', // Reduce font size inside the dropdown
                                  marginLeft: '5px', // Adjust left margin
                                  padding: '5px 15px', // Adjust the padding inside the items to control height
                                },
                              },
                            },
                          }}
                        >
                          {users.map((user) => (
                            <MenuItem key={user.userName} value={user.userName}>
                              {user.userName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box sx={{ flex: 'column', position: 'relative' }}>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={passwordError}>
                              <TextField
                                style={inputLabelStyles}
                                id="password"
                                type={passwordVisibility ? 'text' : 'password'}
                                label={password ? 'Password' : 'Password'}
                                value={password}
                                onChange={handlePasswordChange}
                                InputLabelProps={textFieldLabelStyles}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {passwordVisibility ? (
                                        <VisibilityOffIcon onClick={handlePasswordVisibility} />
                                      ) : (
                                        <VisibilityIcon onClick={handlePasswordVisibility} />
                                      )}
                                    </InputAdornment>
                                  )
                                }}
                                required
                                autoComplete="new-password"  // Disable autofill for password
                              />
                              {password?.length == 0 && (
                                <Typography variant="h6" onClick={handlePasswordGenerator} style={{ cursor: 'pointer', color: 'blue' }}>
                                  Generate Password
                                </Typography>
                              )}

                              {password?.length > 0 && (
                                <Typography variant="h6" onClick={handleCopyToClipboard} style={{ cursor: 'pointer', color: 'blue' }}>
                                  {isCopiedToClipboard ? 'Copied to clipboard' : 'Copy to clipboard'}
                                </Typography>
                              )}
                              {passwordError && <FormHelperText id="input-error-text">{passwordError}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid> */}

                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <InputLabel
                          id="role"
                          sx={{
                            fontSize: '11px',
                            top: '-10%',
                          }}
                        >
                          Role
                        </InputLabel>
                        <Select
                          labelId="role"
                          id="role"
                          value={role}
                          label="Role"
                          onChange={handleRoleChange}
                          disabled
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22, // Adjust height
                            },
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px', // Reduce font size & padding
                            },
                          }}
                        >
                          {userRoles.map((userRole) => (
                            <MenuItem key={userRole.id} value={userRole.value}>
                              {userRole.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small" error={emailError}>
                        <TextField
                          style={inputLabelStyles}
                          id="email"
                          label="Email"
                          value={email} // Bind to email state
                          onChange={handleEmailChange} // Handle email change and check availability
                          // InputLabelProps={textFieldLabelStyles}
                          required
                          error={!isEmailAvailable} // Show error if the email is not available
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '4px',
                            }, // Reduce font size & padding
                          }}
                        />

                        {/* Show error message if email is not available */}
                        {emailError && (
                          <FormHelperText id="input-error-text">{emailError}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid container spacing={2}>
                    {!isOnboard && (
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        lg={3.95}
                        style={gridRowStyles}
                        sx={{ ml: 2 }}
                      >
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <InputLabel id="accountId" sx={{ fontSize: '10px' }}>
                              Account
                            </InputLabel>
                            <Select
                              labelId="accountId"
                              id="accountId"
                              value={accountId}
                              label="Account ID"
                              onChange={handleAccountChange}
                              InputLabelProps={{
                                sx: {
                                  fontSize: '11px',
                                  top: '-50%', // Initially center the label vertically
                                },
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: 22,
                                  minHeight: 22,
                                }, // Adjust height
                                '& .MuiInputBase-input': {
                                  fontSize: 11,
                                  padding: '2px',
                                }, // Reduce font size & padding
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: '200px', // Adjust the maximum height of the dropdown
                                    minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                    '& .MuiMenuItem-root': {
                                      fontSize: '10px', // Reduce font size inside the dropdown
                                      marginLeft: '5px', // Adjust left margin
                                      padding: '5px 15px', // Adjust the padding inside the items to control height
                                    },
                                  },
                                },
                              }}
                            >
                              {doNotPerformUserFiltration &&
                                accountDetails.map((account) => (
                                  <MenuItem key={account.clientID} value={account.clientID}>
                                    {account.clientID} - {account.customerID} - {account.clientName}
                                  </MenuItem>
                                ))}
                              {!doNotPerformUserFiltration &&
                                accountDetails
                                  .filter((account) =>
                                    loggedInUserClientId?.includes(account.clientID),
                                  )
                                  .map((client) => (
                                    <MenuItem key={client.clientID} value={client.clientID}>
                                      {client.clientID} - {client.customerID} - {client.clientName}
                                    </MenuItem>
                                  ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={4} sm={4} md={4} lg={3.95} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                          <InputLabel id="userStatus" sx={{ fontSize: '10px' }}>
                            User Status
                          </InputLabel>
                          <Select
                            labelId="userStatus"
                            id="userStatus"
                            value={userStatus}
                            label="User Status"
                            onChange={handleUserStatusChange}
                            InputLabelProps={{
                              sx: {
                                fontSize: '11px',
                                top: '-50%', // Initially center the label vertically
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 22,
                                minHeight: 22,
                              }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 11,
                                padding: '2px',
                              }, // Reduce font size & padding
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  maxHeight: '200px', // Adjust the maximum height of the dropdown
                                  minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                  '& .MuiMenuItem-root': {
                                    fontSize: '10px', // Reduce font size inside the dropdown
                                    marginLeft: '5px', // Adjust left margin
                                    padding: '5px 15px', // Adjust the padding inside the items to control height
                                  },
                                },
                              },
                            }}
                          >
                            {userStatuses.map((status) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container rowSpacing={0.5} columnSpacing={2.75} style={gridRowStyles}>
                    <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                      <Box>
                        <Button
                          style={{
                            fontFamily: 'DM Sans',
                            fontSize: '12px', // Adjust font size here
                            backgroundColor: '#31478F',
                          }}
                          type="submit"
                          variant="contained"
                          sx={{
                            borderRadius: '5px',
                            marginTop: 0,
                            padding: '6px 12px', // Adjust padding to reduce the button size
                            fontSize: '12px', // You can also set the font size in sx
                          }}
                        >
                          Update
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}

          {!isOnboard &&
            (userListView.length > 0 ? (
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Grid container rowSpacing={0.5} columnSpacing={2.75}>
                    <Grid item xs={8} sm={12} md={12} lg={12} style={gridRowStyles}>
                      {/* <Card> */}
                      <CardContent>
                        <Grid container alignItems="center" justifyContent="flex-start">
                          <Grid item>
                            <Typography
                              variant="h5"
                              style={{
                                textAlign: 'left',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                fontFamily: 'sans-serif',
                                textTransform: 'uppercase',
                                paddingLeft: '0px',
                                marginLeft: '-10px',
                              }}
                            >
                              User Details
                            </Typography>
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            height: 700,
                            maxWidth: '95%',
                            marginTop: '5px',
                            align: 'center',
                          }}
                        >
                          <StyledDataGrid
                            rows={userListView}
                            columns={userListViewColumns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            rowHeight={25}
                            columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                            sx={{
                              width: '1000px', // Expand width to fill the parent container
                              marginLeft: '-15px',

                              '& .MuiDataGrid-columnHeaders': {
                                fontSize: '10px', // Reduce font size of header text
                                backgroundColor: '#31478F', // Set custom header background color
                                color: 'white', // Set font color to white for better contrast
                              },
                              '& .MuiDataGrid-cell': {
                                fontSize: '10px', // Reduced font size for table cells
                              },
                              '& .MuiDataGrid-iconButton': {
                                fontSize: '12', // Reduce icon size in the toolbar
                                color: 'grey', // Set the icon color to grey
                              },
                              '& .even': {
                                backgroundColor: '#F8F8F8', // Light blue color for even rows
                              },
                              '& .odd': {
                                backgroundColor: '#E6F0FF', // Darker blue for odd rows
                              },
                            }}
                            slots={{ toolbar: CustomToolbar }}
                            getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                          />
                        </div>
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : loading ? (
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Grid container rowSpacing={0.5} columnSpacing={2.75}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                      {/* <Card> */}
                      <CardContent>
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            {/* <Typography variant="h5" style={fontStylesChartTypography}>
                                User Details
                              </Typography> */}
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            <CircularProgress color="success" />
                          </Grid>
                        </Grid>
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Grid container rowSpacing={0.5} columnSpacing={2.75}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                      {/* <Card> */}
                      <CardContent>
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            {/* <Typography variant="h5" style={fontStylesChartTypography}>
                                User Details
                              </Typography> */}
                          </Grid>
                        </Grid>
                        <div
                          style={{
                            height: 700,
                            maxWidth: '80%',
                            marginTop: '20px',
                            align: 'center',
                          }}
                        >
                          <StyledDataGrid
                            sx={{
                              '& .super-app-theme--header': {
                                backgroundColor: '#31478F', // Header background color
                                color: 'white', // Header text color
                                fontSize: '12px', // Reduce font size in the header
                              },
                              '& .MuiDataGrid-columnHeaders': {
                                height: 36, // Adjust header height
                              },
                              '& .MuiDataGrid-cell': {
                                padding: '4px 8px', // Reduce row padding
                                fontSize: '12px', // Reduce font size for rows
                              },
                              '& .MuiDataGrid-iconButton': {
                                fontSize: '12', // Reduce icon size in the toolbar
                                color: 'grey', // Set the icon color to grey
                              },
                              '& .MuiDataGrid-row': {
                                minHeight: '30px', // Reduce the height of rows
                              },
                              '& .MuiDataGrid-row.even': {
                                backgroundColor: '#F8F8F8', // Even row background color
                              },
                              '& .MuiDataGrid-row.odd': {
                                backgroundColor: '#E6F0FF', // Odd row background color
                              },
                              // Add media queries for responsiveness
                              '@media (max-width: 768px)': {
                                '& .super-app-theme--header': {
                                  fontSize: '10px', // Smaller font size on mobile
                                },
                                '& .MuiDataGrid-cell': {
                                  padding: '2px 4px', // Smaller padding for mobile
                                  fontSize: '10px', // Smaller font size for rows
                                },
                                '& .MuiDataGrid-iconButton': {
                                  fontSize: '10px', // Smaller icon size on mobile
                                },
                                '& .MuiDataGrid-row': {
                                  minHeight: '28px', // Adjust row height for mobile
                                },
                              },
                              '@media (max-width: 480px)': {
                                '& .super-app-theme--header': {
                                  fontSize: '8px', // Even smaller font size for smaller screens
                                },
                                '& .MuiDataGrid-cell': {
                                  padding: '1px 2px', // Adjust padding for very small screens
                                  fontSize: '8px', // Smaller font size for rows
                                },
                                '& .MuiDataGrid-iconButton': {
                                  fontSize: '8px', // Further reduce icon size
                                },
                                '& .MuiDataGrid-row': {
                                  minHeight: '24px', // Even smaller row height for very small screens
                                },
                              },
                            }}
                            rows={NoRecordsFoundRow}
                            columnHeaderHeight={36}
                            columns={userListViewColumns}
                            slots={{ toolbar: CustomToolbar }}
                            getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                          />
                        </div>
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            ))}
          {/* </CardContent> */}
          {/* </Card> */}
        </div>
        {/* </div> */}
      </div>
    )
  );
};

export default ManageUsers;
