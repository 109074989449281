import React, { useState, useEffect } from 'react';
import {
  Container,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  Alert,
  AlertTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormHelperText } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const ManageUserPage = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL; // Replace with your backend URL

  const [usersList, setUsersList] = useState([]);
  const [brokersList, setBrokersList] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailError] = useState('');
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  const [userNameError, setUserNameError] = useState(null);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddFormOpen, setIsAddFormOpen] = useState(false); // For Add New User form
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false); // For Update User form

  const [isLoading, setIsLoading] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [formData, setFormData] = useState({
    brokerID: '',
    userName: '',
    role: 'Broker-user', // Default value for role
    status: '',
    password: '',
    email: '',
  });
  const [isEditMode, setIsEditMode] = useState(false); // Track whether it's in edit mode

  useEffect(() => {
    fetchUsers();
    fetchBrokers(); // Fetch the list of brokers
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = 'You have unsaved changes. Do you want to leave without saving?';
        event.returnValue = message;
        return message;
      }
    };

    const handlePopState = (event) => {
      if (isFormDirty) {
        const message = 'You have unsaved changes. Do you want to leave without saving?';
        const confirmation = window.confirm(message);
        if (!confirmation) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFormDirty]);

  // const fetchUsers = async () => {
  //   try {
  //     const response = await axios.get(`${BACKEND_URL}/v1/all/broker-users`);
  //     const updatedUsersList = response.data.map((user, index) => ({
  //       ...user,
  //       id: user.brokerID || index,
  //     }));
  //     setUsersList(updatedUsersList);
  //     console.log("Fetched users:", updatedUsersList);
  //   } catch (error) {
  //     setIsError(true);
  //     setErrorMessage('Failed to fetch users: ' + (error.message || 'Unknown error'));
  //     console.error('Error fetching users:', error);
  //   }
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      setIsFormDirty(true);
      return updatedData;
    });

    if (name === 'email') {
      if (validateEmail(value)) {
        setEmailError('');
        checkEmailExistence(value);
      } else {
        setEmailError('Please enter a valid email address with a proper domain.');
        setIsEmailAvailable(true);
      }
    }

    if (name === 'password') {
      if (!passwordRegex.test(value)) {
        setPasswordError(
          'Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.',
        );
      } else {
        setPasswordError('');
      }
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const checkEmailExistence = async (email) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?emailID=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      console.log('Backend response:', data);

      if (response.ok) {
        const emailExists = data.some((user) => user.emailId === email);
        if (emailExists) {
          setIsEmailAvailable(false);
          setEmailError('Email already exists');
        } else {
          setIsEmailAvailable(true);
          setEmailError(null);
        }
      } else {
        setIsEmailAvailable(false);
        setEmailError('Error checking email availability');
      }
    } catch (error) {
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
      setEmailError('Error checking email availability');
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  const fetchUserData = async (userName) => {
    try {
      console.log(`Fetching data for user: ${userName}`);
      const response = await axios.get(`${BACKEND_URL}/v1/broker-all-users/${userName}`, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data && response.status === 200) {
        console.log('Fetched user data:', response.data);
        const user = response.data;
        setFormData({
          brokerID: user.brokerID || '',
          userName: user.userName || '',
          email: user.emailId || '',
          role: user.role || 'Broker-user',
          status: user.userStatus ? 'Active' : 'Inactive',
          password: '',
        });
        setIsError(false);
        setErrorMessage('');
      } else {
        throw new Error('No user data returned or invalid response');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setIsError(true);
      setErrorMessage('Failed to fetch user data: ' + (error.message || 'Unknown error'));
    }
  };

  const checkUsernameExistence = async (username) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?username=${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setUserNameError(null);
      } else {
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setUserNameError('Username already exists');
        } else {
          setUserNameError(null);
        }
      }
    } catch (error) {
      setUserNameError('Error occurred while checking username');
    }
  };

  const fetchBrokers = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/brokers`);
      setBrokersList(response.data);
    } catch (error) {
      setIsError(true);
      setErrorMessage('Failed to fetch brokers: ' + (error.message || 'Unknown error'));
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/all/broker-users`);
      console.log('Raw API Response:', response.data);

      // Ensure unique `id` for each user by using the `userName` or another unique field
      const updatedUsersList = response.data.map((user, index) => ({
        ...user,
        id: user.userName || index, // Use `userName` or fallback to `index` to ensure unique `id`
      }));

      setUsersList(updatedUsersList); // Update users list
      console.log('Fetched users:', updatedUsersList); // Verify structure
    } catch (error) {
      setIsError(true);
      setErrorMessage('Failed to fetch users: ' + (error.message || 'Unknown error'));
      console.error('Error fetching users:', error);
    }
  };

  const columns = [
    {
      field: 'brokerID',
      headerName: 'Broker Account',
      width: 150,
      renderCell: (params) => {
        const broker = brokersList.find((b) => b.id === params.value);
        return broker ? `${broker.id} - ${broker.brokerName}` : params.value;
      },
    },
    {
      field: 'userName', // Ensure this matches the field in `updatedUsersList`
      headerName: 'Name',
      width: 150,
    },
    { field: 'emailId', headerName: 'Email', width: 200 },
    { field: 'role', headerName: 'Role', width: 150 },
    {
      field: 'userStatus',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => <span>{params.row.userStatus ? 'Active' : 'Inactive'}</span>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <IconButton color="secondary" onClick={() => handleDelete(params.row.userName)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleDelete = async (username) => {
    if (!username || typeof username !== 'string') {
      console.error('Invalid username provided');
      alert('Please provide a valid username');
      return;
    }

    try {
      console.log(`Attempting to delete user: ${username}`);
      const confirmation = window.confirm(`Are you sure you want to delete the user: ${username}?`);
      if (!confirmation) {
        console.log(`User ${username} deletion canceled.`);
        return;
      }

      document.body.style.cursor = 'wait';
      console.log(`User ${username} confirmed for deletion.`);
      await sendApprovalEmail(username);
    } catch (error) {
      console.error(`Deletion process failed for ${username}:`, error);
      alert(`Failed to process deletion: ${error.message}`);
    } finally {
      document.body.style.cursor = 'default';
    }
  };

  const sendApprovalEmail = async (username) => {
    const controller = new AbortController();
    const timeout = setTimeout(() => controller.abort(), 10000);

    try {
      console.log('Sending approval email...');
      const response = await fetch(`${BACKEND_URL}/v1/send-approval-email?username=${username}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`Server error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      console.log(`Approval email sent for user: ${username}`);
      alert('Approval email sent. Please check your inbox for further instructions.');
      return data;
    } catch (error) {
      let errorMessage = 'Failed to send approval email';
      if (error.name === 'AbortError') {
        errorMessage = 'Request timed out';
      } else if (error.message) {
        errorMessage = error.message;
      }

      console.error('Email sending error:', error);
      throw new Error(errorMessage);
    } finally {
      clearTimeout(timeout);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      userName: formData.userName,
      emailId: formData.email,
      password: formData.password,
      role: formData.role,
      userStatus: formData.status === 'Active',
      brokerID: formData.brokerID,
    };

    console.log('Form submitted:', userData);

    try {
      // First, check existing users for this brokerID
      const existingUsersResponse = await axios.get(
        `${BACKEND_URL}/v1/user?brokerID=${formData.brokerID}`,
      );
      const existingUsers = existingUsersResponse.data;

      // Count existing broker and broker-user roles
      const brokerCount = existingUsers.filter((user) => user.role === 'Broker').length;
      const brokerUserCount = existingUsers.filter((user) => user.role === 'Broker-user').length;

      // Check limits based on the role being added
      if (!isEditMode) {
        // Only check limits for new users, not updates
        if (userData.role === 'Broker' && brokerCount >= 1) {
          throw new Error('One broker has only one Broker login allowed');
        }
        if (userData.role === 'Broker-user' && brokerUserCount >= 2) {
          throw new Error('One broker has only two Broker-user logins allowed');
        }
      }

      let response;
      if (isEditMode) {
        response = await axios.patch(`${BACKEND_URL}/v1/user`, userData, {
          headers: { 'Content-Type': 'application/json' },
        });
      } else {
        response = await axios.post(`${BACKEND_URL}/v1/add-broker-user`, userData, {
          headers: { 'Content-Type': 'application/json' },
        });
      }

      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Network response was not ok: ' + response.statusText);
      }

      console.log('User saved successfully:', response.data);
      setFormData({
        brokerID: '',
        userName: '',
        role: 'Broker-user',
        status: '',
        password: '',
        email: '',
      });
      setIsAddFormOpen(false);
      setIsUpdateFormOpen(false);
      setIsEditMode(false);
      fetchUsers();
      window.location.reload();
    } catch (error) {
      console.error('Error saving user:', error);
      setIsError(true);
      setErrorMessage('Failed to save user: ' + (error.message || 'Unknown error'));
    }
  };

  const handleFormChange = async (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'userName' && isEditMode && value) {
      fetchUserData(value);
    }

    if (name === 'userName') {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      const newTimer = setTimeout(async () => {
        setIsLoading(true);
        await checkUsernameExistence(value);
        setIsLoading(false);
      }, 500);

      setDebounceTimer(newTimer);
    }
  };

  const handleEditUser = async (user) => {
    setFormData({
      brokerID: user.brokerID || '',
      userName: user.userName || '',
      role: user.role || 'Broker-user',
      status: user.userStatus ? 'Active' : 'Inactive',
      password: '',
      email: user.emailId || '',
    });

    await fetchUserData(user.userName);

    setIsUpdateFormOpen(true);
    setIsAddFormOpen(false);
    setIsEditMode(true);
  };

  return (
    <Container maxWidth="lg" style={{ paddingTop: '20px' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          fontSize: '14px',
          marginTop: '-10px',
          textTransform: 'uppercase',
        }}
      >
        Manage Users
      </Typography>

      {isError && (
        <Alert severity="error" variant="filled" style={{ marginBottom: 20 }}>
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      {/* Buttons to toggle between forms */}
      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            setIsAddFormOpen(true);
            setIsUpdateFormOpen(false);
            setIsEditMode(false);
            setFormData({
              brokerID: '',
              userName: '',
              role: 'Broker-user',
              status: '',
              password: '',
              email: '',
            });
          }}
          style={{
            marginRight: 10,
            marginLeft: '714px',
            padding: '3px 8px', // Adjust padding to reduce button size
            fontSize: '11px', // Reduce font size
            minWidth: '100px', // Set a smaller width
            backgroundColor: '#31478F',
          }}
        >
          Add New User
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            setIsUpdateFormOpen(true);
            setIsAddFormOpen(false);
            setIsEditMode(true);
            setFormData({
              brokerID: '',
              userName: '',
              role: 'Broker-user',
              status: '',
              password: '',
              email: '',
            });
          }}
          style={{
            backgroundColor: '#31478F',
            padding: '3px 8px', // Adjust padding to reduce button size
            fontSize: '11px', // Reduce font size
            minWidth: '100px', // Set a smaller width
          }}
        >
          Update User
        </Button>
      </Box>

      {/* Show Add New User form only when isAddFormOpen is true */}
      {isAddFormOpen && !isUpdateFormOpen && (
        <Box component="form" onSubmit={handleFormSubmit} sx={{ marginBottom: 3 }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: '12px',
              textTransform: 'uppercase',
              fontFamily: 'sans-serif',
              marginTop: '20px',
            }}
          >
            Add New User
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  }}
                >
                  Broker Account
                </InputLabel>
                <Select
                  name="brokerID"
                  value={formData.brokerID}
                  onChange={handleFormChange}
                  label="Broker Account"
                  required
                  MenuProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  {brokersList.map((broker) => (
                    <MenuItem key={broker.id} value={broker.id}>
                      {broker.id}-{broker.brokerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="User Name"
                variant="outlined"
                fullWidth
                name="userName"
                value={formData.userName}
                onChange={handleFormChange}
                required
                error={!!userNameError}
                helperText={userNameError}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
              />
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ position: 'absolute', top: '50%', left: '50%' }}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                error={!isEmailAvailable}
                helperText={emailError}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
              />
              {!isEmailAvailable && <FormHelperText error>{emailError}</FormHelperText>}
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  value={formData.role}
                  onChange={handleFormChange}
                  label="Role"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="Broker-user">Broker-user</MenuItem>
                  <MenuItem value="Broker">Broker</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  required
                  name="status"
                  value={formData.status}
                  onChange={handleFormChange}
                  label="Status"
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={formData.password}
                onChange={handleInputChange}
                error={!!passwordError}
                helperText={passwordError}
                autoComplete="new-password"
                required
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  height: 30,
                  minHeight: 30,
                  fontSize: '12px',
                  padding: '4px 10px',
                  backgroundColor: '#31478F',
                  marginLeft: '400px',
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Show Update User form only when isUpdateFormOpen is true */}
      {isUpdateFormOpen && !isAddFormOpen && (
        <Box component="form" onSubmit={handleFormSubmit} sx={{ marginBottom: 3 }}>
          <Typography
            sx={{
              textTransform: 'uppercase',
              fontSize: '12px',
              fontWeight: 'bold',
              fontFamily: 'sans-serif',
            }}
          >
            Update User
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                variant="outlined"
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: '11px',
                    top: '-50%',
                  }}
                >
                  User Name
                </InputLabel>
                <Select
                  label="User Name"
                  name="userName"
                  value={formData.userName}
                  onChange={handleFormChange}
                  required
                >
                  {usersList.length > 0 ? (
                    usersList.map((user) => (
                      <MenuItem key={user.id} value={user.userName}>
                        {user.userName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No users available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                required
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px',
                      minHeight: '100px',
                      '& .MuiMenuItem-root': {
                        fontSize: '10px',
                        marginLeft: '5px',
                        padding: '5px 15px',
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              >
                <InputLabel>Role</InputLabel>
                <Select
                  name="role"
                  value={formData.role}
                  onChange={handleFormChange}
                  label="Role"
                  required
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="Broker-user" sx={{ fontSize: '12px' }}>
                    Broker-user
                  </MenuItem>
                  <MenuItem value="Broker" sx={{ fontSize: '12px' }}>
                    Broker
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  }}
                >
                  Broker Account
                </InputLabel>
                <Select
                  name="brokerID"
                  value={formData.brokerID}
                  onChange={handleFormChange}
                  label="Broker Account"
                  required
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  {brokersList.map((broker) => (
                    <MenuItem key={broker.id} value={broker.id}>
                      {broker.id}-{broker.brokerName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                sx={{
                  '& .MuiInputBase-root': {
                    height: 22,
                    minHeight: 22,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: '11px',
                    top: '-50%', // Initially center the label vertically
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleFormChange}
                  label="Status"
                  required
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="Active" sx={{ fontSize: '12px' }}>
                    Active
                  </MenuItem>
                  <MenuItem value="Inactive" sx={{ fontSize: '12px' }}>
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.5}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  height: 30,
                  minHeight: 30,
                  fontSize: '12px',
                  padding: '4px 10px',
                  backgroundColor: '#31478F',
                  marginTop: '50px',
                  marginLeft: '-230px',
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* Show DataGrid only when neither form is open */}
      {!isAddFormOpen && !isUpdateFormOpen && (
        <Box sx={{ marginTop: '30px' }}>
          <DataGrid
            rows={usersList}
            columns={columns}
            getRowId={(row) => row.id}
            pageSize={5}
            rowsPerPageOptions={[5]}
            rowHeight={25}
            columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
            autoHeight
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                fontSize: '10px', // Reduce font size of header text
                backgroundColor: '#31478F', // Set custom header background color
                color: 'white', // Set font color to white for better contrast
                textAlign: 'center',
              },
              '& .MuiDataGrid-cell': {
                fontSize: '10px', // Reduced font size for table cells
                textAlign: 'center',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '14px', // Reducing all icons in the DataGrid
                color: 'black',
              },

              '& .even': {
                backgroundColor: '#F8F8F8', // Light blue color for even rows
              },
              '& .odd': {
                backgroundColor: '#E6F0FF', // Darker blue for odd rows
              },
              // marginLeft: "-17px",
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            onRowClick={(e) => handleEditUser(e.row)}
          />
        </Box>
      )}
    </Container>
  );
};

export default ManageUserPage;
