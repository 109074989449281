// import PropTypes from 'prop-types';
// import { useRef, useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import React from 'react';
// import { useEffect } from 'react';
// // material-ui
// import { useTheme } from '@mui/material/styles';
// import {
//   Avatar,
//   Box,
//   ButtonBase,
//   CardContent,
//   ClickAwayListener,
//   Grid,
//   Paper,
//   Popper,
//   Stack,
//   Typography
// } from '@mui/material'; // IconButton

// // project import
// import MainCard from 'components/MainCard';
// import Transitions from 'components/@extended/Transitions';
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// // assets
// // import avatar1 from 'assets/images/users/avatar-1.png';
// //import { SettingOutlined, UserOutlined } from '@ant-design/icons'; // LogoutOutlined

// // tab panel wrapper
// function TabPanel({ children, value, index, ...other }) {
//   return (
//     <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
//       {value === index && children}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired
// };

// // function a11yProps(index) {
// //   return {
// //     id: `profile-tab-${index}`,
// //     'aria-controls': `profile-tabpanel-${index}`
// //   };
// // }

// // ==============================|| HEADER CONTENT - PROFILE ||============================== //

// const Profile = () => {
//   const theme = useTheme();
//   const navigate = useNavigate();

//   const [clients, setClients] = React.useState([]);
//   const [, setCustomerId ] = React.useState('');
//   const [clientId, setClientId ] = React.useState('');
//   const loggedInUserClientId = localStorage.getItem('clientId');
//   const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
//   const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin'

//   useEffect(() => {
//     getClients()
//   }, [])

//   // Use Effect to handle single client case
// useEffect(() => {

//   console.log('loggedInUserClientId',loggedInUserClientId);
//   if (clients.length === 1) {
//     const singleClient = clients[0];
//    // const singleClientValue = `${singleClient.clientID}-${singleClient.customerID}`;
//     setClientId(singleClient.clientID);
//     setCustomerId(singleClient.customerID);

//     // Trigger handleSelectChange programmatically
//     //handleSelectChange({ target: { value: singleClientValue } });
//   }
// }, [clients]);  // Only depends on clients array

//   const getClients = async () => {
//     let getClientsUrl = `${BACKEND_URL}/v1/client`;
//     console.log('loggedInUserClientId in getcleinets',loggedInUserClientId);
//     if(doNotPerformCustomerFiltration) {
//       getClientsUrl += `?clientID=${loggedInUserClientId}`
//     }

//     const response = await fetch(getClientsUrl);
//     const data = await response.json();
//     data.sort((a, b) => a.clientID - b.clientID);
//     setClients(data[0].clientName);
//     console.log('Data from Profile',data[0].clientName);
//     return data;
//   };
//   const handleLogout = async () => {
//     // logout
//     localStorage.clear()
//     navigate('/')
//     window.location.reload();
//   };

//   const anchorRef = useRef(null);
//   const [open, setOpen] = useState(false);
//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }
//     setOpen(false);
//   };

//   const [value, ] = useState(0);

//   // const handleChange = (event, newValue) => {
//   //   setValue(newValue);
//   // };

//   const iconBackColorOpen = 'grey.300';

//   return (
//     <Box sx={{ flexShrink: 0, ml: 0.75 }}>
//       <ButtonBase
//         sx={{
//           p: 0.25,
//           bgcolor: open ? iconBackColorOpen : 'transparent',
//           borderRadius: 1,
//           '&:hover': { bgcolor: 'secondary.lighter' }
//         }}
//         aria-label="open profile"
//         ref={anchorRef}
//         aria-controls={open ? 'profile-grow' : undefined}
//         aria-haspopup="true"
//         onClick={handleToggle}
//       >
//         <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
//           <Avatar alt="profile user" src={`https://ui-avatars.com/api/?name=${localStorage.getItem('userName')}&rounded=true`} sx={{ width: 32, height: 32 }} />
//         </Stack>
//       </ButtonBase>
//       <Popper
//         placement="bottom-end"
//         open={open}
//         anchorEl={anchorRef.current}
//         role={undefined}
//         transition
//         disablePortal
//         popperOptions={{
//           modifiers: [
//             {
//               name: 'offset',
//               options: {
//                 offset: [0, 9]
//               }
//             }
//           ]
//         }}
//       >
//         {({ TransitionProps }) => (
//           <Transitions type="fade" in={open} {...TransitionProps}>
//             {open && (
//               <Paper
//                 sx={{
//                   boxShadow: theme.customShadows.z1,
//                   width: 290,
//                   minWidth: 240,
//                   maxWidth: 290,
//                   [theme.breakpoints.down('md')]: {
//                     maxWidth: 250
//                   }
//                 }}
//               >
//                 <ClickAwayListener onClickAway={handleClose}>
//                   <MainCard elevation={0} border={false} content={false}>
//                     <CardContent sx={{ px: 2.5, pt: 3 }}>
//                       <Grid container justifyContent="space-between" alignItems="center">
//                         <Grid item>
//                           <Stack direction="row" spacing={1.25} alignItems="center">
//                             <Avatar alt="profile user" src={`https://ui-avatars.com/api/?name=${localStorage.getItem('userName')}&rounded=true`} sx={{ width: 32, height: 32 }} />
//                             <Stack>
//                               <Typography variant="h6">{localStorage.getItem('name')}</Typography>
//                               <Typography variant="body2" color="textSecondary">
//                                 {clientId ? clientId : 'Loading...'}
//                               </Typography>
//                             </Stack>
//                           </Stack>
//                         </Grid>
//                         {/* <Grid item>
//                           <IconButton size="large" color="secondary" onClick={handleLogout}>
//                             <LogoutOutlined />
//                           </IconButton>
//                         </Grid> */}
//                       </Grid>
//                     </CardContent>
//                     {open && (
//                       <>
//                         {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
//                           <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
//                             <Tab
//                               sx={{
//                                 display: 'flex',
//                                 flexDirection: 'row',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 textTransform: 'capitalize'
//                               }}
//                               icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
//                               label="Profile"
//                               {...a11yProps(0)}
//                             />
//                             <Tab
//                               sx={{
//                                 display: 'flex',
//                                 flexDirection: 'row',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 textTransform: 'capitalize'
//                               }}
//                               icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
//                               label="Setting"
//                               {...a11yProps(1)}
//                             />
//                           </Tabs>
//                         </Box> */}
//                         <TabPanel value={value} index={0} dir={theme.direction}>
//                           <ProfileTab handleLogout={handleLogout} />
//                         </TabPanel>
//                         <TabPanel value={value} index={1} dir={theme.direction}>
//                           <SettingTab />
//                         </TabPanel>
//                       </>
//                     )}
//                   </MainCard>
//                 </ClickAwayListener>
//               </Paper>
//             )}
//           </Transitions>
//         )}
//       </Popper>
//     </Box>
//   );
// };

// export default Profile;

// import PropTypes from 'prop-types';
// import { useRef, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import React from 'react';
// import { useTheme } from '@mui/material/styles';
// import {

//   Box,
//   ButtonBase,
//   CardContent,
//   ClickAwayListener,
//   Grid,
//   Paper,
//   Popper,
//   Stack,
//   Typography,

// } from '@mui/material'; // Import only what is needed
// //import LogoutOutlined from '@mui/icons-material/Logout'; // Assuming you want an icon for logout

// import MainCard from 'components/MainCard';
// import Transitions from 'components/@extended/Transitions';
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';

// // TabPanel Component
// function TabPanel({ children, value, index, ...other }) {
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`profile-tabpanel-${index}`}
//       aria-labelledby={`profile-tab-${index}`}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired
// };

// const Profile = () => {
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const [clients, setClients] = useState([]);
//   const [, setClientId] = useState('');
//   const [clientName, setClientName] = useState(''); // State to store client name
//   const loggedInUserClientId = localStorage.getItem('clientId');
//   const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
//   const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';

//   useEffect(() => {
//     getClients();
//   }, []);

//   useEffect(() => {
//     if (clients.length > 0 && loggedInUserClientId) {
//       const matchedClient = clients.find(client => client.clientID === loggedInUserClientId);
//       if (matchedClient) {
//         setClientName(matchedClient.clientName);
//         setClientId(matchedClient.clientID);
//       }
//     }
//   }, [clients, loggedInUserClientId]);

//   const getClients = async () => {
//     let getClientsUrl = `${BACKEND_URL}/v1/client`;
//     if (!doNotPerformCustomerFiltration) {
//       getClientsUrl += `?clientID=${loggedInUserClientId}`;
//     }

//     try {
//       const response = await fetch(getClientsUrl);
//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }
//       const data = await response.json();
//       data.sort((a, b) => a.clientID - b.clientID);
//       setClients(data);
//     } catch (error) {
//       console.error('Failed to fetch clients:', error);
//     }
//   };

//   const handleLogout = async () => {
//     localStorage.clear();
//     navigate('/');
//     window.location.reload();
//   };

//   const anchorRef = useRef(null);
//   const [open, setOpen] = useState(false);
//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };

//   const handleClose = (event) => {
//     if (anchorRef.current && anchorRef.current.contains(event.target)) {
//       return;
//     }
//     setOpen(false);
//   };

//   const [value, ] = useState(0);

//   return (
//     <Box sx={{ flexShrink: 0, ml: 0.75 }}>
//       <ButtonBase
//         sx={{
//           p: 0.25,
//           bgcolor: open ? 'grey.300' : 'transparent',
//           borderRadius: 1,
//           '&:hover': { bgcolor: 'secondary.lighter' }
//         }}
//         aria-label="open profile"
//         ref={anchorRef}
//         aria-controls={open ? 'profile-grow' : undefined}
//         aria-haspopup="true"
//         onClick={handleToggle}
//       >
//         <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
//           {/* Display client's name */}
//           <Typography
//             variant="h6"
//             sx={{
//               bgcolor: 'primary.main',
//               color: 'white',
//               borderRadius: 1,
//               minWidth: 72, // Adjust as needed
//               maxWidth: 200, // Adjust as needed
//               height: 32,
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               fontSize: '0.875rem',
//               fontWeight: 'bold',
//               textOverflow: 'ellipsis',
//               overflow: 'hidden',
//               whiteSpace: 'nowrap'
//             }}
//           >
//             {clientName || 'Loading...'}
//           </Typography>
//         </Stack>
//       </ButtonBase>
//       <Popper
//         placement="bottom-end"
//         open={open}
//         anchorEl={anchorRef.current}
//         role={undefined}
//         transition
//         disablePortal
//         popperOptions={{
//           modifiers: [
//             {
//               name: 'offset',
//               options: {
//                 offset: [0, 9],
//               },
//             },
//           ],
//         }}
//       >
//         {({ TransitionProps }) => (
//           <Transitions type="fade" in={open} {...TransitionProps}>
//             {open && (
//               <Paper
//                 sx={{
//                   boxShadow: theme.customShadows.z1,
//                   width: 290,
//                   minWidth: 240,
//                   maxWidth: 290,
//                   [theme.breakpoints.down('md')]: {
//                     maxWidth: 250,
//                   },
//                 }}
//               >
//                 <ClickAwayListener onClickAway={handleClose}>
//                   <MainCard elevation={0} border={false} content={false}>
//                     <CardContent sx={{ px: 2.5, pt: 3 }}>
//                       <Grid container justifyContent="space-between" alignItems="center">
//                         <Grid item>
//                           <Stack direction="row" spacing={1.25} alignItems="center">
//                             {/* Display client's name */}
//                             {/* <Typography
//                               variant="h6"
//                               sx={{
//                                 bgcolor: 'primary.main',
//                                 color: 'white',
//                                 borderRadius: 1,
//                                 minWidth: 72, // Adjust as needed
//                                 maxWidth: 200, // Adjust as needed
//                                 height: 32,
//                                 display: 'flex',
//                                 alignItems: 'center',
//                                 justifyContent: 'center',
//                                 fontSize: '0.875rem',
//                                 fontWeight: 'bold',
//                                 textOverflow: 'ellipsis',
//                                 overflow: 'hidden',
//                                 whiteSpace: 'nowrap'
//                               }}
//                             >
//                               {clientName || 'Loading...'}
//                             </Typography> */}
//                             <Stack>
//                               <Typography variant="h6">
//                                 {clientName || 'Loading...'}
//                               </Typography>
//                               {/* <Typography variant="body2" color="textSecondary">
//                                 {clientId || 'Loading...'}
//                               </Typography> */}
//                             </Stack>
//                           </Stack>
//                         </Grid>
//                         {/* <Grid item>
//                           <IconButton
//                             onClick={handleLogout}
//                             sx={{ color: theme.palette.error.main }}
//                           >
//                             <LogoutOutlined />
//                           </IconButton>
//                         </Grid> */}
//                       </Grid>
//                     </CardContent>
//                     {open && (
//                       <>
//                         <TabPanel value={value} index={0} dir={theme.direction}>
//                           <ProfileTab handleLogout={handleLogout} />
//                         </TabPanel>
//                         <TabPanel value={value} index={1} dir={theme.direction}>
//                           <SettingTab />
//                         </TabPanel>
//                       </>
//                     )}
//                   </MainCard>
//                 </ClickAwayListener>
//               </Paper>
//             )}
//           </Transitions>
//         )}
//       </Popper>
//     </Box>
//   );
// };

// export default Profile;

import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Typography } from '@mui/material'; // Import only what is needed

import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';
import SettingTab from './SettingTab';

// TabPanel Component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [, setClientId] = useState('');
  const [clientName, setClientName] = useState(''); // State to store client name
  const loggedInUserClientId = localStorage.getItem('clientId');
  const role = localStorage.getItem('role'); // Retrieve the role from local storage
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const doNotPerformCustomerFiltration = role === 'Super Admin';

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (clients.length > 0 && loggedInUserClientId) {
      const matchedClient = clients.find((client) => client.clientID === loggedInUserClientId);
      if (matchedClient) {
        setClientName(matchedClient.clientName);
        setClientId(matchedClient.clientID);
      }
    }
  }, [clients, loggedInUserClientId]);

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    try {
      const response = await fetch(getClientsUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      data.sort((a, b) => a.clientID - b.clientID);
      setClients(data);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
  };

  const handleLogout = async () => {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value] = useState(0);

  // Helper function to get the initials
  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? 'grey.300' : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: 'secondary.lighter' }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          {/* Display the initials of the client name */}
          <Typography
            variant="h6"
            sx={{
              bgcolor: 'grey',
              color: 'white',
              borderRadius: '50%',
              width: 40, // Adjust as needed
              height: 40, // Adjust as needed
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '1rem', // Adjust as needed
              fontWeight: 'bold',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {getInitials(clientName) || '?'}
          </Typography>

          {/* Display the role */}
          <Typography
            variant="body2"
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.secondary
            }}
          >
            {role || 'Role not found'}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={1.25} alignItems="center">
                            {/* Display client's name */}
                            <Stack>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontSize: '1.25rem', // Increase font size
                                  fontWeight: 'bold', // Make font bold
                                  color: theme.palette.text.primary // Use the primary text color
                                }}
                              >
                                {clientName || 'Loading...'}
                              </Typography>
                              {/* <Typography variant="body2" color="textSecondary">
                                {clientId || 'Loading...'}
                              </Typography> */}
                            </Stack>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardContent>
                    {open && (
                      <>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>
                      </>
                    )}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
