import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Login from './components/Login'; // Import LoginPage
import OnboardClient from './pages/onboard-client';
import ManageUsers from './pages/manage-users';
import ManageUnitNumber from './pages/manage-unit-number';
import ManageDiscount from './pages/manage-discount';
import ManageProfitMargin from './pages/manage-profit-margin';
import ManageDriver from './pages/manage-driver';
import BrokerCommission from './pages/manage-brokers/BrokerCommission';
import LocationPricing from './pages/manage-location-pricing/location-pricing';
import Invoice from './pages/manage-invoice/index';
import InvoiceGenerate from './pages/manage-invoice/invoice';
import PreviousInvoice from './pages/manage-invoice/previous-invoice';
import SalesReport from './pages/manage-invoice/sales-report';
import VerifyInvoice from './pages/manage-invoice/verify-invoice';
import CreateBroker from './pages/manage-brokers/CreateBroker'; // Correct path to CreateBroker component
import BrokerList from './pages/manage-brokers/BrokerList';
import ManageClients from './pages/manage-clients';
import ClientDetailedView from './pages/manage-clients/detailed-view';
import Dashboard from './pages/manage-dashboard/index';
import TransactionReport from './pages/transaction-report/index';
import FuelCardListView from './pages/fuel-card/list-view';
import InactivityTimer from './components/InactivityTimer'; // Adjust path as needed
import BrokerUsers from './pages/manage-brokers/BrokerUsers';
import Profile from './components/profile';

const AppContent = () => {
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  useEffect(() => {
    if (!isLoggedIn && location.pathname !== '/') {
      // Redirect to login if not logged in
      window.location.href = '/';
    }
  }, [isLoggedIn, location.pathname]);

  return (
    <div style={{ display: 'flex' }}>
      {/* Conditionally render Sidebar based on login status and current route */}
      {isLoggedIn && location.pathname !== '/' && (
        <Sidebar isSidebarVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
      )}
      <div
        style={{
          marginLeft: isSidebarVisible && location.pathname !== '/' ? '200px' : '50px',
          width:
            isSidebarVisible && location.pathname !== '/'
              ? 'calc(100% - 200px)'
              : 'calc(100% - 50px)',
          transition: 'margin-left 0.3s ease, width 0.3s ease',
          padding: '20px',
        }}
      >
        <Routes>
          {/* Login route (Only accessible if not logged in) */}
          <Route path="/" element={<Login />} />

          {isLoggedIn ? (
            <>
              <Route path="/manage-dashboard" element={<Dashboard />} />
              <Route path="/onboard-client" element={<OnboardClient />} />
              <Route path="/manage-users" element={<ManageUsers />} />
              <Route path="/manage-unit-number" element={<ManageUnitNumber />} />
              <Route path="/manage-discount" element={<ManageDiscount />} />
              <Route path="/manage-profit-margin" element={<ManageProfitMargin />} />
              <Route path="/manage-driver" element={<ManageDriver />} />
              <Route path="/manage-brokers/BrokerCommission" element={<BrokerCommission />} />
              <Route path="/manage-location-pricing" element={<LocationPricing />} />
              <Route path="/manage-invoice" element={<Invoice />} />
              <Route path="/manage-invoice/generate" element={<InvoiceGenerate />} />
              <Route path="/manage-invoice/verify" element={<VerifyInvoice />} />
              <Route path="/manage-invoice/past" element={<PreviousInvoice />} />
              <Route path="/manage-invoice/sales-report" element={<SalesReport />} />
              <Route path="/manage-brokers/BrokerOnboard" element={<CreateBroker />} />
              <Route path="/manage-brokers/list" element={<BrokerList />} />
              <Route path="/manage-brokers/BrokerUsers" element={<BrokerUsers />} />
              <Route path="/manage-customers" element={<ManageClients />} />
              <Route path="/customers/:clientId" element={<ClientDetailedView />} />
              <Route path="/manage-dashboard" element={<Dashboard />} />
              <Route path="/transaction-report" element={<TransactionReport />} />
              <Route path="/fuel-card" element={<FuelCardListView />} />
              <Route path="/profile" element={<Profile />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    window.location.href = '/';
  };

  return (
    <Router>
      <InactivityTimer onLogout={handleLogout} />
      <AppContent />
    </Router>
  );
};

export default App;
