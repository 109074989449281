import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from 'components/Loader';
//import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/images/logo/ksg-logo.png';

function Invoice() {
  const [pdfUrl] = useState('');
  const buttonsFontSize = '16px';
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonBackground = '#011f4b';
  const [clients, setClients] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [currency, setCurrency] = React.useState('USD'); // Set default currency to USD
  const [todayDate, setTodayDate] = React.useState('');
  const [dateErrorText, setDateErrorText] = React.useState('');
  const [, setCardsForClient] = React.useState([]);
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const [, setFetchedWithNoRecords] = React.useState(false);
  const [, setRows] = React.useState([]);
  const [, setTransactionsList] = React.useState([]);

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const cardHeight = 100;
  const inputLabelStyles = { fontSize: '14px', color: 'rgb(26, 33, 56)' };
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif'
    }
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  useEffect(() => {
    getClients();
  }, []);

  // Use Effect to handle single client case
  useEffect(() => {
    if (clients.length === 1) {
      const singleClient = clients[0];
      const singleClientValue = `${singleClient.clientID}-${singleClient.customerID}`;
      setClientId(singleClient.clientID);
      setCustomerId(singleClient.customerID);

      // Trigger handleSelectChange programmatically
      handleSelectChange({ target: { value: singleClientValue } });
    }
  }, [clients]); // Only depends on clients array

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);
    return data;
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    // Check if selectedValue is not undefined and contains '-'
    if (selectedValue && selectedValue.includes('-')) {
      // Extract clientID and customerID from the selected value
      const [clientID, customerID] = selectedValue.split('-').map((value) => value.trim());

      if (clientID && customerID) {
        setClientId(clientID); // Update clientId state
        setCustomerId(customerID); // Update customerId state

        // Call the function to handle customer selection change with clientId and customerId
        handleCustomerSelectionChange(clientID, customerID);
      } else {
        console.error('Failed to extract clientID and customerID from the selected value:', selectedValue);
      }
    } else {
      console.error('Selected value is not in the expected format:', selectedValue);
    }
  };

  const handleCustomerSelectionChange = async (clientID, customerID) => {
    setLoading(true);
    setRows([]);
    console.log('Client inside handlecustomer ', clientID);
    try {
      // Use clientID in the getClientsUrl
      const getClientsUrl = `${BACKEND_URL}/v1/client?clientID=${clientID}`;
      const getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerID}&maxRows=1000&pageNumber=1000`;

      const [clientResponse, cardResponse] = await Promise.all([fetch(getClientsUrl), fetch(getCardDetailsForCustomerUrl)]);

      if (!clientResponse.ok) {
        throw new Error('Failed to fetch client data');
      }

      if (!cardResponse.ok) {
        throw new Error('Failed to fetch card details');
      }

      const clientData = await clientResponse.json();
      const cardData = await cardResponse.json();

      setSelectedClient(clientData);
      console.log('Data client', cardData?.records);
      setCardsForClient(cardData?.records?.card);
      setCurrency('USD');
      console.log('Customer Details new', clientData);
    } catch (error) {
      console.error('Error fetching client or card details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDate = (date) => {
    setTodayDate(date);
    setDateErrorText('');
  };

  // Set the customerId to the first item if only one item is present
  useEffect(() => {
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      const firstClient = clients[0];
      setCustomerId(firstClient.customerID);
      handleCustomerSelectionChange({ target: { value: firstClient.customerID } });
    }
  }, [doNotPerformCustomerFiltration, loggedInUserClientId, BACKEND_URL]);

  const createData = (transactionDetail) => {
    let others = [];
    let totalDieselCost = 0.0;
    let totalDieselQuantity = 0.0;
    let totalDieselUnitPrice = 0.0;
    let totalDefCost = 0.0;
    let totalDefQuantity = 0.0;
    let totalDefUnitPrice = 0.0;
    let totalReeferCost = 0.0;
    let totalReeferQuantity = 0.0;
    let totalReeferUnitPrice = 0.0;
    let totalCashCost = 0.0;
    let totalCashQuantity = 0.0;
    let totalCashUnitPrice = 0.0;

    if (transactionDetail) {
      // Diesel Row
      if (transactionDetail?.tractorFuelPricePerGallon > 0) {
        let rebateAmount = doNotPerformCustomerFiltration ? transactionDetail?.rebateAmount : transactionDetail?.customerRebateAmount;
        let transactionFee = doNotPerformCustomerFiltration ? 1 : 0;
        let dieselGallons = transactionDetail?.numTractorGallons;
        let unitPrice = transactionDetail?.tractorFuelPricePerGallon;
        let invoiceAmount = transactionDetail?.costOfTractorFuel;
        let totalInvoice = invoiceAmount + transactionFee;
        let discountPerGallon = transactionDetail?.customerDiscountPerGallon;

        let dieselRow = {
          type: 'Diesel',
          gallons: dieselGallons.toFixed(2),
          unitPrice: unitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: invoiceAmount,
          totalInvoice: totalInvoice,
          discountPerGallon: +discountPerGallon,
          discountAmount: (discountPerGallon * dieselGallons.toFixed(2)).toFixed(2),
          rebate: rebateAmount,
          cost: totalInvoice > rebateAmount ? totalInvoice - rebateAmount : 0
        };
        others.push(dieselRow);
        totalDieselCost += dieselRow?.cost;
        totalDieselQuantity += dieselRow?.gallons; // Add to total quantity
        totalDieselUnitPrice += dieselRow?.unitPrice;
      }

      // Def Row
      if (transactionDetail?.otherFuelPPG > 0.0) {
        let defDieselGallons = transactionDetail?.otherFuelGallons;
        let defUnitPrice = transactionDetail?.otherFuelPPG;
        let defInvoiceAmount = transactionDetail?.otherFuelCost;

        let defRow = {
          type: 'Def',
          gallons: defDieselGallons.toFixed(2),
          unitPrice: defUnitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: defInvoiceAmount,
          totalInvoice: defInvoiceAmount,
          discountPerGallon: 0,
          discountAmount: 0,
          rebate: 0,
          cost: defInvoiceAmount
        };
        others.push(defRow);
        totalDefCost += defRow?.cost;
        totalDefQuantity += defRow?.gallons; // Add to total quantity
        totalDefUnitPrice += defRow?.unitPrice;
      }

      // Reefer Row
      if (transactionDetail && transactionDetail?.reeferPricePerGallon > 0.0) {
        let reeferDieselGallons = transactionDetail?.numReeferGallons;
        let reeferUnitPrice = transactionDetail?.reeferPricePerGallon;
        let reeferInvoiceAmount = transactionDetail?.costOfReeferFuel;
        let discountPerGallon = transactionDetail?.reeferCustomerDiscountPerGallon;

        let reeferRow = {
          type: 'Reefer',
          gallons: reeferDieselGallons.toFixed(2),
          unitPrice: reeferUnitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: reeferInvoiceAmount,
          totalInvoice: reeferInvoiceAmount,
          discountPerGallon: +discountPerGallon,
          discountAmount: (discountPerGallon * reeferDieselGallons.toFixed(2)).toFixed(2),
          rebate: 0,
          cost: reeferInvoiceAmount
        };
        others.push(reeferRow);
        totalReeferCost += reeferRow?.cost;
        totalReeferQuantity += reeferRow?.gallons; // Add to total quantity
        totalReeferUnitPrice += reeferRow?.unitPrice;
      }

      // Cash Row
      if (transactionDetail && transactionDetail?.cashAdvanceAmount > 0.0) {
        let cashAdvanceAmount = transactionDetail?.cashAdvanceAmount;
        let transactionFee = transactionDetail?.customerCostForCashAdvance;
        let invoiceAmount = cashAdvanceAmount + transactionFee;

        let cashRow = {
          type: 'Cash',
          gallons: '',
          unitPrice: '',
          transactionFee: transactionFee,
          invoiceAmount: cashAdvanceAmount,
          totalInvoice: invoiceAmount,
          discountPerGallon: 0,
          discountAmount: 0,
          rebate: 0,
          cost: invoiceAmount
        };
        others.push(cashRow);
        totalCashCost += cashRow?.cost;
        totalCashQuantity += cashRow?.gallons; // Add to total quantity
        totalCashUnitPrice += cashRow?.unitPrice;
      }
    }

    return {
      customerId: transactionDetail?.customerID,
      transactionDateTime: `${transactionDetail?.transactionDate} ${transactionDetail?.transactionTime}`,
      cardNumber: transactionDetail?.cardNumber,
      unitNumber: transactionDetail?.unitNumber,
      truckStopCity: transactionDetail?.stopCity,
      truckStopState: transactionDetail?.truckStopState,
      driverName: transactionDetail?.driverName,
      site: transactionDetail?.truckStopInvoiceNumber,
      siteName: transactionDetail?.stopName,
      cost: totalDieselCost?.toFixed(2),
      quantity: totalDieselQuantity, // Include total quantity
      unitPrice: totalDieselUnitPrice,
      defcost: totalDefCost?.toFixed(2),
      defquantity: totalDefQuantity, // Include total quantity
      defunitPrice: totalDefUnitPrice,
      reefercost: totalReeferCost?.toFixed(2),
      reeferquantity: totalReeferQuantity, // Include total quantity
      reeferunitPrice: totalReeferUnitPrice,
      cashcost: totalCashCost,
      cashquantity: totalCashQuantity, // Include total quantity
      cashunitPrice: totalCashUnitPrice,
      others: others
    };
  };

  const handleGenerateInvoice = async (transactionsList) => {
    try {
      console.log(`Generating PDF with invoice number: ${invoiceNumber}`);
      const doc = new jsPDF();
      const today = new Date(todayDate); // Use current date

      // Function to format date as yyyy-mm-dd
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || {};
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber = customerDetails[0]?.contactNumber || 'N/A';
      const email = customerDetails[0]?.email || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      const currentDate = new Date(today);
      const startOfCurrentWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)); // Monday of current week
      const startOfPreviousWeek = new Date(startOfCurrentWeek);
      startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week
      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

      const startOfPreviousWeekFormatted = formatDate(startOfPreviousWeek);
      const endOfPreviousWeekFormatted = formatDate(endOfPreviousWeek);

      // Calculate the due date (3 days from the end of the previous week)
      const dueDate = new Date(endOfPreviousWeek);
      dueDate.setDate(endOfPreviousWeek.getDate() + 3);
      const dueDateFormatted = formatDate(dueDate);

      // Define data for the invoice info table
      const invoiceInfoData = [
        [invoiceNumber, formatDate(today), startOfPreviousWeekFormatted, endOfPreviousWeekFormatted, dueDateFormatted]
      ];

      // Define column headers for the invoice info table
      const invoiceInfoHeaders = ['Invoice Number', 'Invoice Date', 'Start Date', 'End Date', 'Due Date'];

      // Draw the black rectangle behind the logo
      doc.setFillColor(0, 0, 0); // RGB for black
      doc.rect(10, 10, 35, 25, 'F'); // Rectangle position and size

      const img = new Image();
      img.src = logo; // Ensure this path is correct

      img.onload = () => {
        doc.addImage(img, 'PNG', 10, 10, 35, 25);

        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('KSG Fuel', 50, 15);
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('Address: 2575 Steeles Ave E, Unit 1, Brampton,', 50, 18);
        doc.text('Ontario, Canada, L6T 5T1', 50, 21);
        doc.text('Office: +1-(905) 965-0308', 50, 24);
        doc.text('Toll free: ', 50, 27);
        doc.text('www.ksgfuel.com', 50, 30);
        doc.text('Email: support@ksgfuel.com', 50, 33);

        // Add client information in the top right corner
        const pageWidth = doc.internal.pageSize.getWidth();
        const rightMargin = 75; // Distance from the right edge of the page
        const startX = pageWidth - rightMargin;

        // Client Info title
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('Client Info', startX, 15, { align: 'left' });

        // Client Details
        doc.setFontSize(9);
        doc.setFont('times', 'bold');
        doc.text(customerName, startX, 20, { align: 'left' });
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('Address: ' + customerAddress, startX, 23, { align: 'left' });
        doc.text('Phone: ' + contactNumber, startX, 26, { align: 'left' });
        doc.text('Email: ' + email, startX, 29, { align: 'left' });

        // Add invoice info table
        doc.autoTable({
          head: [invoiceInfoHeaders],
          body: invoiceInfoData,
          startY: 40,
          margin: { left: 10, right: 90 },
          theme: 'striped',
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 6,
            cellPadding: 2
          },
          styles: {
            fontSize: 6, // Set font size for body cells
            cellPadding: 2,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto' // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' }
          }
        });

        // Group transactions by card number and calculate totals
        const groupedByCard = transactionsList.reduce((acc, item) => {
          if (!acc[item.cardNumber]) {
            acc[item.cardNumber] = {
              cardNumber: item.cardNumber,
              diesel: { quantity: 0, cost: 0, unitPrice: 0 },
              def: { quantity: 0, cost: 0, unitPrice: 0 },
              reefer: { quantity: 0, cost: 0, unitPrice: 0 },
              cash: { quantity: 0, cost: 0, unitPrice: 0 },
              transactions: []
            };
          }

          // Iterate through each type in the others array
          item.others.forEach((other) => {
            const type = other.type;
            const transaction = acc[item.cardNumber];

            if (type === 'Diesel') {
              transaction.diesel.quantity += parseFloat(other.gallons) || 0;
              transaction.diesel.cost += parseFloat(other.cost) || 0;
              transaction.diesel.unitPrice += parseFloat(other.unitPrice) || 0;
            } else if (type === 'Def') {
              transaction.def.quantity += parseFloat(item.defquantity) || 0;
              transaction.def.cost += parseFloat(item.defcost) || 0;
              transaction.def.unitPrice += parseFloat(item.defunitPrice) || 0;
            } else if (type === 'Reefer') {
              transaction.reefer.quantity += parseFloat(other.gallons) || 0;
              transaction.reefer.cost += parseFloat(other.cost) || 0;
              transaction.reefer.unitPrice += parseFloat(other.unitPrice) || 0;
            } else if (type === 'Cash') {
              transaction.cash.quantity += parseFloat(item.cashquantity) || 0;
              transaction.cash.cost += parseFloat(item.cashcost) || 0;
              transaction.cash.unitPrice += parseFloat(item.cashunitPrice) || 0;
            }
          });

          acc[item.cardNumber].transactions.push(item);
          return acc;
        }, {});

        let yOffset = doc.lastAutoTable.finalY + 10;

        // Handle empty transactions list
        if (transactionsList.length === 0) {
          // Define column headers for the transaction table
          const headersNoRecords = [
            'Driver Name',
            'Unit Number',
            'Date',
            'Site #',
            'Site Name',
            'Site City',
            'Prov/State',
            'Prod',
            'QTY',
            'Retail',
            'Billed',
            'Pre Tax Amt',
            'HST',
            'GST',
            'PST',
            'QST',
            'Trans Fee',
            'Disc Rate',
            'Disc Amt',
            'Final Amt',
            'CUR'
          ];

          const noRecordsData = [
            ['', '', '', '', '', '', '', '', '', 'No Transaction Results Found', '', '', '', '', '', '', '', '', '', '', '']
          ];

          // Add table for this card number
          doc.autoTable({
            head: [headersNoRecords],
            body: noRecordsData,
            startY: yOffset,
            theme: 'striped',
            margin: { left: 10, right: 10 },
            headStyles: {
              fillColor: [1, 31, 75], // Hex color #8e918f in RGB
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              fontSize: 3,
              cellPadding: 1
            },
            styles: {
              fontSize: 3, // Set font size for body cells
              cellPadding: 1,
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              overflow: 'linebreak',
              cellWidth: 'auto' // Adjust column widths as needed
            },
            columnStyles: {
              0: { cellWidth: 'auto' }, // Adjust column widths as needed
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' }
            }
          });
          // Update yOffset for the next table
          yOffset = doc.lastAutoTable.finalY + 10;
        } else {
          Object.values(groupedByCard).forEach((group) => {
            // Add card number text
            doc.setFontSize(8);
            doc.setFont('times', 'bold');
            doc.text(`Transaction for Card: ${group.cardNumber}`, 10, yOffset);
            yOffset += 5; // Add some space between the card number and the table

            const transactions = group.transactions;

            console.log('Transaction for card', transactions);

            // Add card number and transactions table
            const transactionsTableHeader = [
              'Driver Name',
              'Unit Number',
              'Date',
              'Site #',
              'Site Name',
              'Site City',
              'Prov/State',
              'Prod',
              'QTY',
              'Retail',
              'Billed',
              'Pre Tax Amt',
              'HST',
              'GST',
              'PST',
              'QST',
              'Trans Fee',
              'Disc Rate',
              'Disc Amt',
              'Final Amt',
              'CUR'
            ];

            // Prepare the table data with subtotals
            const transactionsTableData = [];

            // Initialize total accumulators
            let totalPretaxamt = 0;
            let totalFinalamt = 0;

            // Iterate over each transaction
            transactions.forEach((tr) => {
              let subtotalGallons = 0;
              let subtotalPretaxamt = 0;
              let subtotalFinalamt = 0;

              // Ensure the `others` property exists and is an array
              if (tr.others && Array.isArray(tr.others)) {
                tr.others.forEach((other) => {
                  // Check if 'cash' value is present and use it for 'finalamt'
                  const invoiceAmount = parseFloat(other.invoiceAmount);
                  const transactionFee = parseFloat(other.transactionFee);
                  const final = parseFloat(other.invoiceAmount).toFixed(2);
                  const finalCash = (invoiceAmount + transactionFee).toFixed(2);
                  const finalOther = final - other.discountAmount;
                  const finalamt = other.type == 'Cash' ? finalCash : finalOther.toFixed(2);
                  subtotalGallons += parseFloat(other.gallons) || 0;
                  subtotalPretaxamt += parseFloat(final) || 0;
                  subtotalFinalamt += parseFloat(finalamt) || 0;

                  // Push each transaction row
                  transactionsTableData.push([
                    tr.driverName || 'N/A',
                    tr.unitNumber || 'N/A',
                    tr.transactionDateTime || 'N/A',
                    tr.site || 'N/A',
                    tr.siteName || 'N/A',
                    tr.truckStopCity || 'N/A',
                    tr.truckStopState || 'N/A',
                    other.type || '',
                    other.gallons || '0',
                    other.unitPrice || '0',
                    other.unitPrice || '0',
                    other.invoiceAmount || '0',
                    '0', // Placeholder for 'HST'
                    '0', // Placeholder for 'GST'
                    '0', // Placeholder for 'PST'
                    '0', // Placeholder for 'QST',
                    other.transactionFee || 0,
                    other.discountPerGallon.toFixed(2) || '0', // Placeholder for 'Disc Rate'
                    other.discountAmount || '0', // Placeholder for 'Disc Amt'
                    finalamt || '0',
                    'US' // Currency
                  ]);
                });

                // Add subtotal row after processing others data
                transactionsTableData.push([
                  '',
                  '',
                  '',
                  '',
                  'Subtotal',
                  '',
                  '',
                  '',
                  subtotalGallons.toFixed(2),
                  '',
                  '',
                  subtotalPretaxamt.toFixed(2),
                  '0', // Placeholder for 'HST'
                  '0', // Placeholder for 'GST'
                  '0', // Placeholder for 'PST'
                  '0', // Placeholder for 'QST'
                  '',
                  '0', // Placeholder for 'Disc Rate'
                  '0', // Placeholder for 'Disc Amt'
                  subtotalFinalamt.toFixed(2),
                  'US' // Currency
                ]);

                // Update total accumulators
                totalPretaxamt += subtotalPretaxamt;
                totalFinalamt += subtotalFinalamt;
              }
            });

            // Add total row after processing all transactions
            transactionsTableData.push([
              '',
              '',
              '',
              '',
              'Total',
              '',
              '',
              '',
              '',
              '',
              '',
              totalPretaxamt.toFixed(2),
              '0', // Placeholder for 'HST'
              '0', // Placeholder for 'GST'
              '0', // Placeholder for 'PST'
              '0', // Placeholder for 'QST'
              '',
              '0', // Placeholder for 'Disc Rate'
              '0', // Placeholder for 'Disc Amt'
              totalFinalamt.toFixed(2),
              'US' // Currency
            ]);

            doc.autoTable({
              head: [transactionsTableHeader],
              body: transactionsTableData,
              startY: yOffset,
              theme: 'striped',
              margin: { left: 10, right: 10 },
              headStyles: {
                fillColor: [1, 31, 75],
                halign: 'center',
                valign: 'middle',
                fontSize: 4,
                cellPadding: 1
              },
              styles: {
                fontSize: 4,
                cellPadding: 1,
                halign: 'center',
                valign: 'middle',
                overflow: 'linebreak',
                cellWidth: 'auto'
              },
              columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 'auto' },
                13: { cellWidth: 'auto' },
                14: { cellWidth: 'auto' },
                15: { cellWidth: 'auto' },
                16: { cellWidth: 'auto' },
                17: { cellWidth: 'auto' },
                18: { cellWidth: 'auto' },
                19: { cellWidth: 'auto' }
              },
              didDrawCell: (data) => {
                const lastRowIndex = transactionsTableData.length - 1;
                //const secondLastRowIndex = transactionsTableData.length - 2;

                // Check if it's the last or second last row
                if (data.row.index === lastRowIndex) {
                  // Apply dark blue background color
                  doc.setFillColor(1, 31, 75); // Dark blue background
                  doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

                  // Apply white text color
                  doc.setTextColor(255, 255, 255);

                  // Ensure text is a string
                  const text = String(data.cell.text);

                  // Calculate horizontal alignment
                  const textWidth = doc.getTextWidth(text);
                  const x = data.cell.x + (data.cell.width - textWidth) / 2;

                  // Calculate vertical alignment
                  const fontSize = doc.internal.getFontSize();
                  const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

                  // Draw the text centered in the cell
                  doc.text(text, x, y);
                }
              }
            });

            yOffset = doc.lastAutoTable.finalY + 10;
          });
        }

        // Add card number text
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text(`Grand Total: `, 10, yOffset);
        yOffset += 5; // Add some space between the card number and the table

        // Initialize grand total accumulators
        const grandTotals = {
          Diesel: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
          Def: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
          Reefer: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
          Cash: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 }
        };

        // Iterate over each transaction and accumulate totals
        transactionsList.forEach((item) => {
          item.others.forEach((other) => {
            const type = other.type;
            if (grandTotals[type]) {
              grandTotals[type].quantity += parseFloat(other.gallons) || 0;
              grandTotals[type].cost += parseFloat(other.invoiceAmount) || 0;
              grandTotals[type].unitPrice += parseFloat(other.unitPrice) || 0;
              grandTotals[type].discountPerGallon += parseFloat(other.discountPerGallon) || 0;
              grandTotals[type].discountAmount += parseFloat(other.discountAmount) || 0;
              grandTotals[type].transactionFee += parseFloat(other.transactionFee) || 0;
            }
          });
        });

        // Define headers for the grand total table
        const grandTotalHeaders = [
          'Prod',
          'QTY',
          'Pre Tax Amt',
          'HST',
          'GST',
          'PST',
          'QST',
          'Trans Fee',
          'Disc Rate',
          'Disc Amt',
          'Final Amt',
          'CUR'
        ];

        // Define data for the grand total table
        const grandTotalData = Object.keys(grandTotals).map((type) => {
          const total = grandTotals[type];
          const grandPretaxamt = total.cost.toFixed(2);
          const grandFinalamt = total.discountAmount
            ? (parseFloat(grandPretaxamt) - parseFloat(total.discountAmount)).toFixed(2)
            : (parseFloat(grandPretaxamt) + parseFloat(total.transactionFee)).toFixed(2);

          return [
            type, // Product type
            total.quantity.toFixed(2), // Quantity
            grandPretaxamt, // Pre Tax Amount
            '0', // HST
            '0', // GST
            '0', // PST
            '0', // QST
            total.transactionFee.toFixed(2) || 0, //Transaction Fee
            total.discountPerGallon.toFixed(2) || 0, // Disc Rate
            total.discountAmount.toFixed(2) || 0, // Disc Amount
            grandFinalamt, // Final Amount
            'US' // Currency
          ];
        });

        // Calculate grand total row
        const grandTotalRow = Object.values(grandTotals).reduce(
          (acc, typeTotals) => {
            acc.quantity += typeTotals.quantity;
            acc.cost += typeTotals.cost;
            acc.discountPerGallon += typeTotals.discountPerGallon;
            acc.discountAmount += typeTotals.discountAmount;
            acc.transactionFee += typeTotals.transactionFee;
            return acc;
          },
          { quantity: 0, cost: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 }
        );

        const grandTotalPretaxamt = grandTotalRow.cost.toFixed(2);
        const grandTotalFinalamt = grandTotalRow.discountAmount
          ? (parseFloat(grandTotalPretaxamt) - parseFloat(grandTotalRow.discountAmount) + parseFloat(grandTotalRow.transactionFee)).toFixed(
              2
            )
          : parseFloat(grandTotalPretaxamt).toFixed(2);
        // Define total row data
        const totalRow = [
          'Total',
          grandTotalRow.quantity.toFixed(2), // Total Quantity
          grandTotalPretaxamt, // Total Pre Tax Amount
          '0', // Total HST
          '0', // Total GST
          '0', // Total PST
          '0', // Total QST
          grandTotalRow.transactionFee.toFixed(2) || 0,
          grandTotalRow.discountPerGallon.toFixed(2) || 0, // Disc Rate (Left blank if not applicable)
          grandTotalRow.discountAmount.toFixed(2), // Total Disc Amount
          grandTotalFinalamt || 0, // Total Final Amount
          'US' // Currency
        ];

        // Add grand total table
        doc.autoTable({
          head: [grandTotalHeaders],
          body: [...grandTotalData, totalRow],
          startY: yOffset,
          theme: 'striped',
          margin: { left: 10, right: 10 },
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 5,
            cellPadding: 1
          },
          styles: {
            fontSize: 5, // Set font size for body cells
            cellPadding: 1,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto' // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
            5: { cellWidth: 'auto' },
            6: { cellWidth: 'auto' },
            7: { cellWidth: 'auto' },
            8: { cellWidth: 'auto' },
            9: { cellWidth: 'auto' },
            10: { cellWidth: 'auto' },
            11: { cellWidth: 'auto' },
            12: { cellWidth: 'auto' },
            13: { cellWidth: 'auto' }
          },
          didDrawCell: (data) => {
            // Check if it's the last row (total row)
            if (data.row.index === grandTotalData.length) {
              // Apply dark blue background color
              doc.setFillColor(1, 31, 75);
              doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

              // Apply white text color
              doc.setTextColor(255, 255, 255);

              // Redraw the text with adjusted x and y for alignment
              // Adjust the x and y offsets to properly align the text within the cell
              const text = String(data.cell.text);

              // Calculate horizontal alignment
              const textWidth = doc.getTextWidth(text);
              const x = data.cell.x + (data.cell.width - textWidth) / 2;

              // Calculate vertical alignment
              const fontSize = doc.internal.getFontSize();
              const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

              // Draw the text centered in the cell
              doc.text(text, x, y);
            }
          }
        });

        // Update yOffset for the next section
        yOffset = doc.lastAutoTable.finalY + 10;

        // Convert the PDF to a Blob and create a URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Display the PDF in an iframe
        const iframe = document.createElement('iframe');
        iframe.src = pdfUrl;
        iframe.style.width = '100%';
        iframe.style.height = '600px';
        iframe.style.border = 'none';

        // Append the iframe to a container element
        const pdfContainer = document.getElementById('pdf-container');
        pdfContainer.innerHTML = ''; // Clear previous content
        pdfContainer.appendChild(iframe);

        doc.save('invoice.pdf');
      };

      img.onerror = (error) => {
        console.error('Error loading image:', error);
        // Handle the error case where the image couldn't be loaded
      };
    } catch (error) {
      console.error('Error generating invoice:', error);
    }
  };

  const handleViewReport = async (event, action) => {
    event.preventDefault();

    // Validate dates
    if (!todayDate) {
      setDateErrorText('Date is required');
      return;
    }

    // Generate a random 9-digit invoice number
    const generatedInvoiceNumber = `#${Math.floor(100000000 + Math.random() * 900000000)}`;
    setInvoiceNumber(generatedInvoiceNumber);

    // Function to format date to 'YYYY-MM-DD'
    function formatDate(date) {
      return date.toISOString().split('T')[0];
    }

    // Assuming todayDate is defined, create the current date object
    const today = new Date(todayDate);

    // Calculate the start of the current week (Monday)
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 1); // Monday of current week

    // Calculate the start of the previous week (Monday)
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week

    // Calculate the end of the previous week (Sunday)
    const endOfPreviousWeek = new Date(startOfPreviousWeek);
    endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

    // Format the dates
    const formattedStartDate = formatDate(startOfPreviousWeek);
    const formattedEndDate = formatDate(endOfPreviousWeek);

    let getTransactionDetailsUrl = `${BACKEND_URL}/v1/transaction`;

    let retrieveTransactionsObject = {
      customerID: customerId,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    };

    await fetch(getTransactionDetailsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(retrieveTransactionsObject)
    })
      .then((response) => response.json())
      .then((transactionDetails) => {
        let transactionsList = [];
        setFetchedWithNoRecords(transactionDetails.length === 0);
        for (let transactionDetail of transactionDetails) {
          transactionsList.push(createData(transactionDetail));
        }
        setRows(transactionsList);
        setTransactionsList(transactionsList);
        console.log('transactionList', transactionsList);

        if (action === 'generate') {
          handleGenerateInvoice(transactionsList);
        } else if (action === 'download') {
          handleDownloadCSV(transactionsList);
        }
      })
      .catch((error) => {
        console.error('Error fetching transactions:', error);
      });
  };

  // CSV download handler
  const handleDownloadCSV = async (transactionsList) => {
    try {
      if (!invoiceNumber) return;
      console.log(`Generating CSV with invoice number: ${invoiceNumber}`);

      const today = new Date(todayDate);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || [];
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber = customerDetails[0]?.contactNumber || 'N/A';
      const email = customerDetails[0]?.email || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      const currentDate = new Date(today);
      const startOfCurrentWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)); // Monday of current week
      const startOfPreviousWeek = new Date(startOfCurrentWeek);
      startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week
      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

      const startOfPreviousWeekFormatted = formatDate(startOfPreviousWeek);
      const endOfPreviousWeekFormatted = formatDate(endOfPreviousWeek);

      // Calculate the due date (3 days from the end of the previous week)
      const dueDate = new Date(endOfPreviousWeek);
      dueDate.setDate(endOfPreviousWeek.getDate() + 3);
      const dueDateFormatted = formatDate(dueDate);

      // Prepare CSV content
      const csvRows = [
        ['Invoice Number', 'Invoice Date', 'Start Date', 'End Date', 'Due Date'],
        [invoiceNumber, formatDate(today), startOfPreviousWeekFormatted, endOfPreviousWeekFormatted, dueDateFormatted],
        [], // Empty row for separation
        ['Customer Name', 'Address', 'Phone', 'Email'],
        [customerName, `"${customerAddress}"`, contactNumber, email]
      ];

      // Group transactions by card number
      const groupedByCard = transactionsList.reduce((acc, transaction) => {
        const cardNumber = transaction.cardNumber; // Change this if the attribute name is different
        if (!acc[cardNumber]) {
          acc[cardNumber] = { cardNumber, transactions: [] };
        }
        acc[cardNumber].transactions.push(transaction);
        return acc;
      }, {});

      // Handle empty transactions list
      if (transactionsList.length === 0) {
        const headersNoRecords = [
          'Driver Name',
          'Unit Number',
          'Date',
          'Site #',
          'Site Name',
          'Site City',
          'Prov/State',
          'Prod',
          'QTY',
          'Retail',
          'Billed',
          'Pre Tax Amt',
          'HST',
          'GST',
          'PST',
          'QST',
          'Trans Fee',
          'Disc Rate',
          'Disc Amt',
          'Final Amt',
          'CUR'
        ];
        // Add table header to csvRows
        csvRows.push(headersNoRecords);

        const noRecordsData = [
          ['', '', '', '', '', '', '', '', '', 'No Transaction Results Found', '', '', '', '', '', '', '', '', '', '', '']
        ];

        csvRows.push(...noRecordsData);
      } else {
        // Iterate over each group
        for (const group of Object.values(groupedByCard)) {
          // Add card number
          csvRows.push([`Transaction for Card: ${group.cardNumber}`]);
          csvRows.push([]); // Empty row for separation

          // Define headers for the transactions table
          const transactionsTableHeader = [
            'Driver Name',
            'Unit Number',
            'Date',
            'Site #',
            'Site Name',
            'Site City',
            'Prov/State',
            'Prod',
            'QTY',
            'Retail',
            'Billed',
            'Pre Tax Amt',
            'HST',
            'GST',
            'PST',
            'QST',
            'Trans Fee',
            'Disc Rate',
            'Disc Amt',
            'Final Amt',
            'CUR'
          ];

          // Add table header to csvRows
          csvRows.push(transactionsTableHeader);

          // Prepare the table data with subtotals
          const transactionsTableData = [];
          let totalPretaxamt = 0;
          let totalFinalamt = 0;

          // Iterate over each transaction
          group.transactions.forEach((tr) => {
            let subtotalGallons = 0;
            let subtotalPretaxamt = 0;
            let subtotalFinalamt = 0;

            // Ensure the `others` property exists and is an array
            if (tr.others && Array.isArray(tr.others)) {
              tr.others.forEach((other) => {
                const invoiceAmount = parseFloat(other.invoiceAmount);
                const transactionFee = parseFloat(other.transactionFee);
                const final = parseFloat(other.invoiceAmount).toFixed(2);
                const finalCash = (invoiceAmount + transactionFee).toFixed(2);
                const finalOther = final - other.discountAmount;
                const finalamt = other.type === 'Cash' ? finalCash : finalOther;

                subtotalGallons += parseFloat(other.gallons) || 0;
                subtotalPretaxamt += parseFloat(finalamt) || 0;
                subtotalFinalamt += parseFloat(finalamt) || 0;

                // Push each transaction row
                transactionsTableData.push([
                  tr.driverName || 'N/A',
                  tr.unitNumber || 'N/A',
                  tr.transactionDateTime || 'N/A',
                  tr.site || 'N/A',
                  tr.siteName || 'N/A',
                  tr.truckStopCity || 'N/A',
                  tr.truckStopState || 'N/A',
                  other.type || '',
                  other.gallons || '0',
                  other.unitPrice || '0',
                  other.unitPrice || '0',
                  other.invoiceAmount || '0',
                  '0', // Placeholder for 'HST'
                  '0', // Placeholder for 'GST'
                  '0', // Placeholder for 'PST'
                  '0', // Placeholder for 'QST'
                  other.transactionFee || 0,
                  other.discountPerGallon || '0', // Placeholder for 'Disc Rate'
                  other.discountAmount || '0', // Placeholder for 'Disc Amt'
                  finalamt || '0',
                  'US' // Currency
                ]);
              });

              // Add subtotal row after processing others data
              transactionsTableData.push([
                '',
                '',
                '',
                '',
                'Subtotal',
                '',
                '',
                '',
                subtotalGallons.toFixed(2),
                '',
                '',
                subtotalPretaxamt.toFixed(2),
                '0', // Placeholder for 'HST'
                '0', // Placeholder for 'GST'
                '0', // Placeholder for 'PST'
                '0', // Placeholder for 'QST'
                '',
                '0', // Placeholder for 'Disc Rate'
                '0', // Placeholder for 'Disc Amt'
                subtotalFinalamt.toFixed(2),
                'US' // Currency
              ]);

              // Update total accumulators
              totalPretaxamt += subtotalPretaxamt;
              totalFinalamt += subtotalFinalamt;
            }
          });

          // Add total row after processing all transactions
          transactionsTableData.push([
            '',
            '',
            '',
            '',
            'Total',
            '',
            '',
            '',
            '',
            '',
            '',
            totalPretaxamt.toFixed(2),
            '0', // Placeholder for 'HST'
            '0', // Placeholder for 'GST'
            '0', // Placeholder for 'PST'
            '0', // Placeholder for 'QST'
            '',
            '0', // Placeholder for 'Disc Rate'
            '0', // Placeholder for 'Disc Amt'
            totalFinalamt.toFixed(2),
            'US' // Currency
          ]);

          csvRows.push(...transactionsTableData);
          csvRows.push([]); // Empty row for separation
        }
      }

      // Calculate grand totals
      const grandTotals = {
        Diesel: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
        Def: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
        Reefer: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
        Cash: { quantity: 0, cost: 0, unitPrice: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 }
      };

      transactionsList.forEach((item) => {
        item.others.forEach((other) => {
          const type = other.type;
          if (grandTotals[type]) {
            grandTotals[type].quantity += parseFloat(other.gallons) || 0;
            grandTotals[type].cost += parseFloat(other.invoiceAmount) || 0;
            grandTotals[type].unitPrice += parseFloat(other.unitPrice) || 0;
            grandTotals[type].discountPerGallon += parseFloat(other.discountPerGallon) || 0;
            grandTotals[type].discountAmount += parseFloat(other.discountAmount) || 0;
            grandTotals[type].transactionFee += parseFloat(other.transactionFee) || 0;
          }
        });
      });

      // Define headers for the grand total table
      const grandTotalHeaders = [
        'Prod',
        'QTY',
        'Pre Tax Amt',
        'HST',
        'GST',
        'PST',
        'QST',
        'Trans Fee',
        'Disc Rate',
        'Disc Amt',
        'Final Amt',
        'CUR'
      ];

      // Define data for the grand total table
      const grandTotalData = Object.keys(grandTotals).map((type) => {
        const total = grandTotals[type];
        const grandPretaxamt = total.cost.toFixed(2);
        const grandFinalamt = total.discountAmount ? (grandPretaxamt - total.discountAmount).toFixed(2) : grandPretaxamt;

        return [
          type, // Product type
          total.quantity.toFixed(2), // Quantity
          grandPretaxamt, // Pre Tax Amount
          '0', // HST
          '0', // GST
          '0', // PST
          '0', // QST
          total.transactionFee.toFixed(2) || 0, // Transaction Fee
          total.discountPerGallon.toFixed(2) || 0, // Disc Rate
          total.discountAmount.toFixed(2) || 0, // Disc Amount
          grandFinalamt, // Final Amount
          'US' // Currency
        ];
      });

      // Add grand total data to CSV content
      csvRows.push([]);
      csvRows.push(['Grand Total']);

      // Add table header to csvRows
      csvRows.push(grandTotalHeaders);
      csvRows.push(...grandTotalData);

      // Convert CSV array to string
      const csvContent = csvRows.map((row) => row.join(',')).join('\n');

      // Create a Blob and URL for the CSV file
      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'invoice.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };

  return (
    <div>
      <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
        INVOICE GENERATION
      </Typography>
      <form>
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1' }}>
            <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
              <CardContent>
                {/* // <form id="transaction-report" onSubmit={handleViewReport}> */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <InputLabel id="customerID" style={{ ...inputLabelStyles }}>
                          Customer
                        </InputLabel>
                        {doNotPerformCustomerFiltration ? (
                          <Select
                            labelId="customerID"
                            id="customerID"
                            value={`${clientId}-${customerId}`}
                            label="Customer ID"
                            onChange={handleSelectChange}
                            required
                          >
                            {clients.length > 0 &&
                              clients.map((client) => (
                                <MenuItem key={client.customerID} value={`${client.clientID}-${client.customerID}`}>
                                  {client.clientID} - {client.customerID} - {client.clientName}
                                </MenuItem>
                              ))}
                          </Select>
                        ) : clients.length === 1 ? (
                          <div style={{ marginTop: '30px', marginLeft: '16px' }}>
                            <Typography variant="body1">
                              {clients[0].clientID} - {clients[0].customerID} - {clients[0].clientName}
                            </Typography>
                          </div>
                        ) : (
                          <Select
                            labelId="customerID"
                            id="customerID"
                            value={`${clientId}-${customerId}`}
                            label="Customer ID"
                            onChange={handleSelectChange}
                            required
                          >
                            {clients.length > 0 &&
                              clients.map((client) => (
                                <MenuItem key={client.customerID} value={`${client.clientID}-${client.customerID}`}>
                                  {client.clientID} - {client.customerID} - {client.clientName}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small" error={dateErrorText}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label="Date"
                            value={todayDate}
                            InputLabelProps={textFieldLabelStyles}
                            onChange={handleDate}
                            shouldDisableDate={(date) => date.getDay() !== 1} // 1 corresponds to Monda
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <TextField
                        label="Currency"
                        value={currency}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{ readOnly: true }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                {loading && <Loader />}
              </CardContent>
            </Card>
          </div>
        </div>
        <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} align="left">
            <Button
              style={{ marginTop: '5px', fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: buttonBackground }}
              type="submit"
              variant="contained"
              sx={{ borderRadius: 0 }}
              onClick={(event) => handleViewReport(event, 'generate')}
            >
              Generate Invoice
            </Button>
          </Grid>

          {/* <Grid item xs={4} sm={4} md={4} lg={4}></Grid> */}
          <Grid item xs={4} sm={4} md={4} lg={4} align="left">
            <Button
              style={{ marginTop: '5px', fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: buttonBackground }}
              type="submit"
              variant="contained"
              sx={{ borderRadius: 0 }}
              onClick={(event) => handleViewReport(event, 'download')}
            >
              Download CSV
            </Button>
          </Grid>

          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
        </Grid>
      </form>

      <div id="pdf-container" style={{ marginTop: '20px' }}></div>
      {pdfUrl && (
        <div>
          <h2>View Invoice</h2>
          <iframe
            src={pdfUrl}
            style={{ width: '100%', height: '600px', border: 'none' }}
            // width="100%"
            // height="600px"
            title="Invoice"
          />
        </div>
      )}
    </div>
  );
}

export default Invoice;
