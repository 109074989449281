// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.even-row {
  background-color: #eff3f6; /* Light blue */
}

.odd-row {
  background-color: #b1d1e1; /* Darker blue */
}
`, "",{"version":3,"sources":["webpack://./src/pages/manage-location-pricing/UploadFile.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB,EAAE,eAAe;AAC5C;;AAEA;EACE,yBAAyB,EAAE,gBAAgB;AAC7C","sourcesContent":[".even-row {\n  background-color: #eff3f6; /* Light blue */\n}\n\n.odd-row {\n  background-color: #b1d1e1; /* Darker blue */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
