import React, { useEffect, useState } from 'react';
import { Typography, Grid, Card, CardContent } from '@mui/material';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, LabelList } from 'recharts';

const DashboardPage = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [cards, setCards] = useState([]);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [transactions, setTransactions] = useState([]);

  // Fetch card details
  const fetchCardDetails = async () => {
    const getAllCardsUrl = `${BACKEND_URL}/v1/all-cards`;
    try {
      const response = await fetch(getAllCardsUrl);
      const data = await response.json();
      setCards(data);
    } catch (error) {
      console.error('Error fetching card details:', error);
    }
  };

  // Fetch client details
  const fetchClientDetails = async () => {
    const getAllClientsUrl = `${BACKEND_URL}/v1/client`;
    try {
      const response = await fetch(getAllClientsUrl);
      const data = await response.json();
      setClients(data);
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };

  // Fetch user details
  const fetchUserDetails = async () => {
    const getAllUsersUrl = `${BACKEND_URL}/v1/user`;
    try {
      const response = await fetch(getAllUsersUrl);
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  // Fetch transaction details
  const fetchTransactionDetails = async () => {
    const getAllTransactionsUrl = `${BACKEND_URL}/v1/transaction`;
    try {
      const response = await fetch(getAllTransactionsUrl);
      const data = await response.json();
      setTransactions(data);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
    }
  };

  useEffect(() => {
    fetchCardDetails();
    fetchClientDetails();
    fetchUserDetails();
    fetchTransactionDetails();
  }, []);

  // Card data processing
  //const totalCards = cards.length;
  const activeCards = cards.filter((card) => card.status === 'A').length;
  const blockedCards = cards.filter((card) => card.status === 'B').length;

  const cardData = [
    { name: 'Active Cards', value: activeCards },
    { name: 'Blocked Cards', value: blockedCards }
  ];

  // Define Pie Chart Colors (Update these colors as needed)
  const CARD_COLORS = ['#4F81BD', '#C0504D']; // Light blue and dark blue for card pie chart
  const USER_PIE_COLORS = ['#ADD8E6', '#0000FF']; // Light blue and blue for user pie chart

  // Client data processing
  const totalClients = clients.length;
  const activeClients = clients.filter((client) => client.clientStatus === true).length;
  const blockedClients = clients.filter((client) => client.clientStatus === false).length;

  const clientData = [
    { name: 'Active Clients', value: activeClients },
    { name: 'Blocked Clients', value: blockedClients }
  ];

  // User data processing
  const activeUsers = users.filter((user) => user.userStatus === true).length;
  const inactiveUsers = users.filter((user) => user.userStatus === false).length;

  const userData = [
    { name: 'Active Users', value: activeUsers },
    { name: 'Inactive Users', value: inactiveUsers }
  ];

  // Transaction data processing
  const transactionCounts = Array(12).fill(0); // For 12 months
  transactions.forEach((transaction) => {
    const month = new Date(transaction.date).getMonth(); // Get month (0-11)
    transactionCounts[month] += 1;
  });

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const monthlyTransactionData = monthNames.map((month, index) => ({
    name: month,
    value: transactionCounts[index]
  }));

  return (
    <>
      <Grid container spacing={2}>
        {/* First Column: Pie Chart with Card Data */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Overall Cards
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie data={cardData} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                    {cardData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={CARD_COLORS[index % CARD_COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Column: Bar Chart with Client Data */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Total Clients: {totalClients}
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={clientData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#8884d8">
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Third Column: Pie Chart with User Data */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                User Distribution
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie data={userData} cx="50%" cy="50%" outerRadius={100} fill="#8884d8" dataKey="value" label>
                    {userData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={USER_PIE_COLORS[index % USER_PIE_COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* New Row: Bar Chart with Monthly Transaction Data */}
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Monthly Transactions
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={monthlyTransactionData}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" fill="#B3C8CF">
                    {' '}
                    {/* Navy blue */}
                    <LabelList dataKey="value" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
