import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
// import Loader from 'components/Loader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin
import utc from 'dayjs/plugin/utc'; // Import utc plugin to handle UTC
// import '../src/style.css';
const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};
const ManageDiscount = ({ isOnboard = false, onSuccess, client }) => {
  const [loading, setLoading] = React.useState(false);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonsFontSize = '16px';
  const [chosenAccount, setChosenAccount] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [discountPercentage, setDiscountPercentage] = React.useState('');
  const [discountHistoryListView, setDiscountHistoryListView] = React.useState([]);
  const [acknowledgementMessage, setAcknowledgementMessage] = React.useState('');
  const [isDiscountAcknowledged, setIsDiscountAcknowledged] = React.useState(false);
  const [percentageError, setPercentageError] = React.useState('');
  const buttonBackground = '#011f4b';
  const [accountDetails, setAccountDetails] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [effectiveDate, setEffectiveDate] = React.useState(null);

  const [effectiveDateErrorText, setEffectiveDateErrorText] = React.useState('');
  const fullText = 'Search Discount History';
  const [animatedPlaceholder, setAnimatedPlaceholder] = useState('');
  const indexRef = useRef(0);

  const handleAccountChange = (event) => {
    const selectedAccount = accountDetails.find(
      (account) => account.clientID === event.target.value,
    );
    if (selectedAccount) {
      const { clientID, customerID } = selectedAccount;
      setChosenAccount(clientID);
      setCustomerId(customerID);
      getDiscountByAccountId(clientID);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (indexRef.current <= fullText.length) {
        setAnimatedPlaceholder(fullText.slice(0, indexRef.current + 1));
        indexRef.current++;
      } else {
        setTimeout(() => {
          indexRef.current = 0; // Reset animation after a brief pause
        }, 1000); // 1-second pause before restarting
      }
    }, 200); // Adjust animation speed

    return () => clearInterval(interval);
  }, []);

  const handleDiscountChange = (event) => {
    event.preventDefault();
    // Validate effective dates
    if (!effectiveDate) {
      setEffectiveDateErrorText('Effective Date is required');
      return;
    }

    // UseEffect moved outside the handleDiscountChange function

    if (isOnboard) {
      const createDiscountObject = {
        discount: discountPercentage,
      };
      onSuccess(createDiscountObject);
    } else {
      const sendApprovalEmailUrl = `${BACKEND_URL}/v1/send-approval-email-discount?clientID=${chosenAccount}&customerID=${customerId}&discount=${discountPercentage}&effectiveDate=${effectiveDate}`;
      setLoading(true);
      fetch(sendApprovalEmailUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          alert('Approval email sent successfully');
          setChosenAccount('');
          setDiscountPercentage('');
          setEffectiveDate(null);
          setAcknowledgementMessage('');
          setIsDiscountAcknowledged(false);
          getDiscountByAccountId(chosenAccount);
        })
        .catch((error) => {
          console.error('Error sending approval email:', error);
        })
        .finally(() => setLoading(false));
    }
  };

  const getDiscountByAccountId = (accountId) => {
    const getDiscountUrl = `${BACKEND_URL}/v1/discounts?clientID=${accountId}`;
    setLoading(true);
    fetch(getDiscountUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          // Check if the expected properties are present in the response
          const { discount, effectiveDate, discountHistory, discountHistory2 } = data;

          // If the required data is not present, return early and handle accordingly
          if (discount === undefined || effectiveDate === undefined) {
            console.error('Missing discountPercentage or effectiveDate in the response');
            return;
          }

          // Update the state with the fetched data
          setDiscountPercentage(discount);
          // setEffectiveDate(effectiveDate);
          // setEffectiveDate(effectiveDate ? dayjs(effectiveDate) : null);  // Ensure dayjs conversion

          // Convert discountPercentageHistory2 into a usable format (list)
          if (discountHistory2 == null) {
            convertDiscountHistory(discountHistory);
          } else {
            convertDiscountHistory(discountHistory2);
          }
        } else {
          // If the response body is empty or invalid
          console.error('Invalid response data:', data);
          setDiscountPercentage(0);
          setEffectiveDate(null);
          convertDiscountHistory({}); // Empty history
        }
      })
      .catch((error) => {
        console.error('Error fetching profit margin details:', error);
        setDiscountPercentage(0);
        setEffectiveDate(null);
        convertDiscountHistory({}, ''); // Empty history
      })
      .finally(() => setLoading(false)); // Always stop loading after the request
  };

  const discountHistoryListViewColumns = [
    // {
    //   field: 'id',
    //   headerName: 'Serial No.',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerClassName: 'super-app-theme--header'
    // },
    {
      field: 'date',
      headerName: 'Discount Date',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'discount',
      headerName: 'Discount Applied',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
  ];

  // const convertDiscountHistory = (history) => {
  //   const historyList = [];
  //   let historyId = 1;
  //   for (const [key, value] of Object.entries(history)) {
  //     const date = new Date(key);
  //     const options = {
  //       year: 'numeric',
  //       month: 'long',
  //       day: 'numeric',
  //       hour: 'numeric',
  //       minute: 'numeric',
  //       second: 'numeric',
  //       timeZoneName: 'short',
  //     };
  //     const readableDate = date.toLocaleString('en-US', options);
  //     historyList.push({
  //       id: historyId++,
  //       date: readableDate,
  //       discount: value,
  //     });
  //   }

  //   historyList.sort((a, b) => new Date(a.date) - new Date(b.date));
  //   setDiscountHistoryListView(historyList);
  // };

  const convertDiscountHistory = (history = {}) => {
    if (!history || Object.keys(history).length === 0) {
      console.log('Discount History is empty or invalid.');
      return; // Early return if history is empty
    }

    const historyList = [];
    let historyId = 1;

    for (const [historyEntry, effDate] of Object.entries(history)) {
      try {
        let parsedEntry;

        // Handle {timestamp=discount} format (if present)
        if (historyEntry.startsWith('{') && historyEntry.endsWith('}')) {
          parsedEntry = parseHistoryEntry(historyEntry);
        } else {
          // Standard timestamp format
          parsedEntry = {
            timestamp: historyEntry,
            discount: effDate, // Assuming value in history maps to discount
          };
        }

        if (parsedEntry) {
          const { timestamp, discount } = parsedEntry;
          const date = new Date(timestamp);

          // Format date to readable format
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
          };
          const readableDate = date.toLocaleString('en-US', options);

          // Set effectiveDate only if entry is in `{}` format
          const effectiveDate =
            historyEntry.startsWith('{') && historyEntry.endsWith('}') ? effDate : null;

          setEffectiveDate(effectiveDate ? dayjs(effectiveDate) : null); // Ensure correct dayjs conversion

          // Push formatted data into history list
          historyList.push({
            id: historyId++,
            date: readableDate,
            discount: discount,
            effectiveDate: effectiveDate,
          });
        }
      } catch (error) {
        console.error('Error parsing discount history entry:', error);
      }
    }

    // Sort by date
    historyList.sort((a, b) => new Date(a.date) - new Date(b.date));
    setDiscountHistoryListView(historyList);
  };

  // Function to manually parse {timestamp=value} format
  const parseHistoryEntry = (historyEntry) => {
    const regex = /{([^=]+)=([^}]+)}/;
    const match = historyEntry.match(regex);

    if (match && match.length === 3) {
      return {
        timestamp: match[1], // Extract timestamp
        discount: match[2], // Extract discount value
      };
    } else {
      console.error('Invalid discount history entry format:', historyEntry);
      return null;
    }
  };

  const acknowledgeDiscount = () => {
    setIsDiscountAcknowledged(!isDiscountAcknowledged);
  };

  const handlePercentageChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value) && value >= 0 && value <= 100) {
      setDiscountPercentage(value);
      setPercentageError('');
      setTimeout(() => {
        setAcknowledgementMessage(
          <div style={{ marginLeft: '-200px', marginTop: '-40px' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                marginLeft: '-200px', // Move this line slightly to the left
              }}
            >
              {`Are you sure about applying a discount of ${value}% on invoices`}
            </span>
            <br />
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                marginLeft: '-390px', // Keep the second line at the original position
              }}
            >
              {`generated for client ${isOnboard ? client : chosenAccount}?`}
            </span>
          </div>,
        );
      }, 500);
    } else {
      setPercentageError('Percentage can be only between 0 - 100');
    }
  };

  useEffect(() => {
    if (isOnboard) {
      // Implement onboard-specific logic if needed
    } else {
      const getClientsUrl = `${BACKEND_URL}/v1/client`;

      fetch(getClientsUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          data.sort((a, b) => a.clientID - b.clientID);
          setAccountDetails(data);
        })
        .catch((error) => {
          console.error('Error fetching client details:', error);
        });
    }
  }, [isOnboard]);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode),
      },
    },
  }));

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const cardHeight = 300;
  const inputLabelStyles = {
    fontSize: '12px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600,
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '12px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600,
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  const filteredDiscountHistoryListView = discountHistoryListView
    .filter(
      (entry) =>
        entry.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.discount.toString().includes(searchTerm),
    )
    .sort((a, b) => {
      if (a.date.toLowerCase().includes(searchTerm.toLowerCase())) return -1;
      if (b.date.toLowerCase().includes(searchTerm.toLowerCase())) return 1;
      return 0;
    });

  // Extend dayjs with plugins
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const handleEffectiveDateChange = (date) => {
    console.log('Original Effective Date:', date);

    // Convert the selected date to CST (Central Standard Time)
    const dateInCST = dayjs(date).tz('America/Chicago', true); // CST timezone conversion

    console.log('Effective Date in CST:', dateInCST.format()); // Log the date in CST

    // Set the state with the CST formatted date
    setEffectiveDate(dateInCST);

    // Reset error text
    setEffectiveDateErrorText('');
  };

  return (
    <div style={{ marginLeft: '10px', marginTop: '40px' }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: '14px',
          fontFamily: 'sans-serif',
          marginLeft: '18px',
          marginTop: '-35px',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }}
        gutterBottom
      >
        Manage Discount
      </Typography>
      {/* {loading && <Loader />} */}
      <div className="container" style={{ display: 'flex' }}>
        <div className="card" style={{ flex: '1' }}>
          {/* <Card sx={{ minWidth: '100%', minHeight: cardHeight }}> */}
          <CardContent>
            <form id="check-available-inspectors" onSubmit={handleDiscountChange}>
              <div>
                {isOnboard && (
                  <Typography variant="h5" sx={{ m: 1 }}>
                    Account: {client}
                  </Typography>
                )}
              </div>
              {!isOnboard && (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4.5}
                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <InputLabel id="chosenAccount" sx={{ fontSize: '10px' }}>
                          Account
                        </InputLabel>
                        <Select
                          labelId="chosenAccount"
                          id="chosenAccount"
                          value={chosenAccount}
                          label="Account"
                          onChange={handleAccountChange}
                          required
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22,
                            },
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '200px',
                                minHeight: '100px',
                                '& .MuiMenuItem-root': {
                                  fontSize: '10px',
                                  marginLeft: '5px',
                                  padding: '5px 15px',
                                },
                              },
                            },
                          }}
                        >
                          {accountDetails.map((account) => (
                            <MenuItem key={account.clientID} value={account.clientID}>
                              {account.clientID} - {account.customerID} - {account.clientName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              )}

              {/* Effective Date */}
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={4}
                  mt={2}
                  ml={4}
                  lg={4.5}
                  sx={{ display: 'flex', marginLeft: '1px' }}
                >
                  <Box sx={{ width: '100%' }}>
                    <FormControl
                      sx={{ minWidth: '100%' }}
                      size="small"
                      error={effectiveDateErrorText}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Effective Date"
                          value={effectiveDate}
                          InputLabelProps={textFieldLabelStyles}
                          onChange={handleEffectiveDateChange}
                          shouldDisableDate={(date) => {
                            const day = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                            const today = dayjs().startOf('day'); // Get today without time

                            return !(day === 1 || day === 4) || date.isBefore(today); // Allow only Monday (1) & Thursday (4) and disable past dates
                          }}
                          slotProps={{
                            textField: {
                              size: 'small',
                              InputLabelProps: {
                                sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                              },
                              sx: {
                                '& .MuiInputBase-root': { height: 25 },
                                '& .MuiInputBase-input': {
                                  fontSize: 11,
                                  padding: '4px',
                                },
                                '& .MuiSvgIcon-root': { fontSize: 16 }, // Reduce icon size
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {effectiveDateErrorText && (
                        <FormHelperText id="input-error-text">
                          {effectiveDateErrorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>

                {/* Discount Percentage */}
                <Grid item xs={5} sm={5} md={4} lg={4.5} mt={2} sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', marginLeft: '-240px' }}>
                    <FormControl
                      sx={{ minWidth: '100%', display: 'flex' }}
                      size="small"
                      error={Boolean(percentageError)}
                    >
                      <TextField
                        style={inputLabelStyles}
                        id="discountPercentage"
                        label={
                          discountPercentage ? 'Discount Percentage' : 'Discount percentage (0-100)'
                        }
                        value={discountPercentage}
                        onChange={handlePercentageChange}
                        required
                        size="small"
                        InputLabelProps={{
                          sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                        }}
                        sx={{
                          '& .MuiInputBase-root': { height: 25 },
                          '& .MuiInputBase-input': {
                            fontSize: 11,
                            padding: '4px',
                          },
                        }}
                      />
                      {percentageError && (
                        <FormHelperText id="input-error-text">{percentageError}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                  {acknowledgementMessage && (
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={acknowledgeDiscount}
                            sx={{ marginTop: '-58px', marginLeft: '-100px' }}
                          />
                        }
                        label={acknowledgementMessage}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'DM Sans, sans-serif',
                          },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between', // Pushes the checkbox further right
                          width: '100%', // Ensures full width usage
                        }}
                      />
                    </Box>
                  )}
                  <Grid item xs={12}>
                    <Button
                      style={{
                        top: '-1px',
                        fontFamily: 'DM Sans, sans-serif',
                        fontSize: '10px',
                        backgroundColor: '#31478F',
                        color: 'white',
                        marginLeft: '-240px',

                        padding: '5px 10px',
                      }}
                      type="submit"
                      variant="contained"
                      disabled={!isDiscountAcknowledged}
                      sx={{ mt: 0, mb: 0, borderRadius: '5px' }}
                    >
                      {isOnboard ? 'Set Discount' : 'Apply Discount'}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              </Grid>
            </form>

            {filteredDiscountHistoryListView.length > 0 && (
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                      {/* <Card> */}
                      <CardContent>
                        <Grid container alignItems="center" justifyContent="space-between">
                          {/* Discount History Typography */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                fontSize: '14px',
                                fontFamily: 'sans-serif',
                                marginBottom: '8px',
                                fontWeight: 'bold',
                                marginLeft: '-12px',
                                textTransform: 'uppercase',
                              }}
                            >
                              Discount History
                            </Typography>
                          </Grid>

                          {/* Search Discount History TextField */}
                          <Grid item xs={5} sm={5} md={4} lg={4.5} sx={{ marginBottom: '5px' }}>
                            <Box>
                              <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                placeholder="Search Discount History..."
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon sx={{ fontSize: 14 }} />
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px',
                                    '& fieldset': {
                                      borderColor: '#31478F',
                                      borderWidth: '2px',
                                      borderStyle: 'solid',
                                    },
                                    '&:hover fieldset': {
                                      borderColor: '#31478F',
                                    },
                                    '&.Mui-focused fieldset': {
                                      borderColor: '#31478F',
                                    },
                                  },
                                  '& .MuiInputBase-root': {
                                    height: 24,
                                    minHeight: 24,
                                  },
                                  '& .MuiInputBase-input': {
                                    fontSize: 12,
                                    padding: '8px',
                                  },
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        <StyledDataGrid
                          rows={filteredDiscountHistoryListView}
                          columns={discountHistoryListViewColumns}
                          slots={{ toolbar: CustomToolbar }}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          rowHeight={25}
                          columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                          autoHeight
                          sx={{
                            '& .MuiDataGrid-columnHeaders': {
                              fontSize: '10px', // Reduce font size of header text
                              backgroundColor: '#31478F', // Set custom header background color
                              color: 'white', // Set font color to white for better contrast
                              textAlign: 'center',
                            },
                            '& .MuiDataGrid-cell': {
                              fontSize: '10px', // Reduced font size for table cells
                              textAlign: 'center',
                            },

                            '& .even': {
                              backgroundColor: '#F8F8F8', // Light blue color for even rows
                            },
                            '& .odd': {
                              backgroundColor: '#E6F0FF', // Darker blue for odd rows
                            },
                            marginLeft: '-17px',
                          }}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                          }
                        />
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </CardContent>
          {/* </Card> */}
        </div>
      </div>
    </div>
  );
};

export default ManageDiscount;
