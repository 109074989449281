// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-table-row-odd {
  background-color: rgb(180, 200, 220);
}

.main-table-row-even {
  background-color: rgb(248, 248, 248);
}

.sub-table-header {
  background-color: rgb(0, 9, 81);
  text-align: left;
  font-size: 15px;
  margin: 0%;
  padding: 0%;
  width: 98%;
}

.sub-table-header-cell {
  color: white;
  text-align: left;
}

.sub-table-row-even {
  background-color: rgb(248, 248, 248);
}

.sub-table-row-odd {
  background-color: rgb(180, 200, 220);
}

.icon-container {
  display: flex;
  gap: 1px;
  align-items: center;
  font-size: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/manage-invoice/verify-invoice.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;EACf,UAAU;EACV,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".main-table-row-odd {\n  background-color: rgb(180, 200, 220);\n}\n\n.main-table-row-even {\n  background-color: rgb(248, 248, 248);\n}\n\n.sub-table-header {\n  background-color: rgb(0, 9, 81);\n  text-align: left;\n  font-size: 15px;\n  margin: 0%;\n  padding: 0%;\n  width: 98%;\n}\n\n.sub-table-header-cell {\n  color: white;\n  text-align: left;\n}\n\n.sub-table-row-even {\n  background-color: rgb(248, 248, 248);\n}\n\n.sub-table-row-odd {\n  background-color: rgb(180, 200, 220);\n}\n\n.icon-container {\n  display: flex;\n  gap: 1px;\n  align-items: center;\n  font-size: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
