import { Card, CardContent, Grid, Typography, Button, Box, FormControl, Collapse, CardActions } from '@mui/material';
import { useEffect } from 'react';
import * as React from 'react';

// Icons
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactsIcon from '@mui/icons-material/Contacts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Finish = ({ onboard, onSuccess }) => {
  const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesSectionTypography = { fontSize: '18px', textAlign: 'left', fontWeight: '600', fontFamily: 'DM Sans, sans-serif' };
  const buttonBackground = '#011f4b';
  const buttonsFontSize = '16px';
  const gridRowStyles = { marginTop: '5px' };
  const [customerExpanded, setCustomerExpanded] = React.useState(false);
  const [userExpanded, setUserExpanded] = React.useState(false);
  // const [cardsExpanded, setCardsExpanded] = React.useState(false);
  const [discountsExpanded, setDiscountsExpanded] = React.useState(false);

  const handleFinish = (event) => {
    event.preventDefault();
    onSuccess();
  };

  useEffect(() => {
    console.log(onboard);
  }, []);

  return (
    <div>
      <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
        Summary
      </Typography>
      <form className="container" onSubmit={handleFinish}>
        <div className="card" style={{ flex: '1', margin: '5px' }}>
          <Card sx={{ minWidth: '100%' }}>
            <CardActions disableSpacing>
              <Typography variant="h4" sx={fontStylesSectionTypography}>
                CUSTOMER
              </Typography>
              <Button
                onClick={() => {
                  setCustomerExpanded(!customerExpanded);
                }}
              >
                {!customerExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </Button>
            </CardActions>
            <Collapse in={customerExpanded} timeout="auto" unmountOnExit>
              <CardContent>
                {/* ROW 1 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Account Number</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.clientID}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 2 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Comdata Reference</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.customerID}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 3 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Customer Name</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.clientName}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 4 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>
                          <ContactsIcon /> Contact
                        </Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Grid container spacing={2} style={gridRowStyles}>
                      {/* ROW 1 */}
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Primary Contact</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.pointOfContact}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      {/* ROW 2 */}
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Secondary Contact</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.secondaryPointOfContact}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      {/* ROW 3 */}
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}> Primary Email</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.primaryEmail}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}> Secondary Email</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.secondaryEmail}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      {/* ROW 4 */}
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Primary Phone Number</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.primaryContactNumber}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Secondary Phone Number</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.secondaryContactNumber}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      {/* ROW 5 */}
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Address</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={8}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.address}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </div>
        <div className="card" style={{ flex: '1', margin: '5px' }}>
          <Card sx={{ minWidth: '100%' }}>
            <CardActions disableSpacing>
              <Typography variant="h4" sx={fontStylesSectionTypography}>
                USER
              </Typography>
              <Button
                onClick={() => {
                  setUserExpanded(!userExpanded);
                }}
              >
                {!userExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </Button>
            </CardActions>
            <Collapse in={userExpanded} timeout="auto" unmountOnExit>
              <CardContent>
                {/* ROW 1 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Account Number</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.clientID}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 2 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>User Name</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.userObject?.userName}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 3 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Email ID</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.userObject?.emailId}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                {/* ROW 4 */}
                <Grid container spacing={2} style={gridRowStyles}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Role</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8} lg={8}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <Typography style={{ fontSize: '12px' }}>{onboard?.userObject?.role}</Typography>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </div>
        {/*    <div className="card" style={{ flex: '1', margin: '5px' }}>
                    <Card sx={{ minWidth: '100%' }}>
                        <CardActions disableSpacing>
                            <Typography variant="h4" sx={fontStylesSectionTypography}>
                                CARD
                            </Typography>                           
                            <Button sx={{ align: "right" }} onClick={() => {setCardsExpanded(!cardsExpanded)}}>
                                { !cardsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon/>}
                            </Button>
                        </CardActions>
                        <Collapse in={cardsExpanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                {/* ROW 1 
                                <Grid container spacing={2} style={gridRowStyles}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                                <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Account Number</Typography>
                                            </FormControl>         
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                                <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.clientID}</Typography>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/* ROW 2 
                                <Grid container spacing={2} style={gridRowStyles}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                                <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Card Numbers</Typography>
                                            </FormControl>         
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                        <Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                              {
                                                onboard?.cardsObject?.cardNumbers?.map((card) => (                    
                                                    <Chip key={card} label={card}/>
                                                ))
                                              }
                                            </Box>                                        
                                        </Box>
                                    </Grid>
                                </Grid>
                                {/* ROW 3 
                                <Grid container spacing={2} style={gridRowStyles}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                                <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Maximum Gallon Limit</Typography>
                                            </FormControl>         
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                                <Typography style={{ fontSize: '12px' }}>{onboard?.cardsObject?.maxGallon}</Typography>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>                                
                            </CardContent>
                        </Collapse>
                    </Card>
                </div> */}
               <div className="card" style={{ flex: '1', margin: '5px' }}>
                    <Card sx={{ minWidth: '100%' }}>
                        <CardActions disableSpacing>
                            <Typography variant="h4" sx={fontStylesSectionTypography}>
                                DISCOUNT
                            </Typography>                           
                            <Button onClick={() => {setDiscountsExpanded(!discountsExpanded)}}>
                                { !discountsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon/>}
                            </Button> 
                        </CardActions>
                        <Collapse in={discountsExpanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                 {/* ROW 1  */}
                                <Grid container spacing={2} style={gridRowStyles}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                                <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Account Number</Typography>
                                            </FormControl>         
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                                <Typography style={{ fontSize: '12px' }}>{onboard?.clientObject?.clientID}</Typography>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>
                                 {/* ROW 2  */}
                               <Grid container spacing={2} style={gridRowStyles}>
                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                                <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Discount</Typography>
                                            </FormControl>         
                                        </Box>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                        <Box>
                                            <FormControl sx={{ minWidth: '100%' }} size="small">
                                                <Typography style={{ fontSize: '12px' }}>{onboard?.discountsObject?.discount}%</Typography>
                                            </FormControl>
                                        </Box>
                                    </Grid>
                                </Grid>                             
                            </CardContent>
                        </Collapse>
                    </Card>
                </div>
        <div style={{ flex: '1', margin: '5px' }}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} align="center">
              <Button
                style={{ fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: buttonBackground }}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 5, borderRadius: 1 }}
                onClick={handleFinish}
              >
                Complete
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default Finish;
