import { Box, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  useEffect(() => {}, [localStorage.getItem('isLoggedIn')]);
  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {localStorage.getItem('isLoggedIn') && (
        <div style={{ display: 'inline-flex' }}>
          <Notification />
          {!matchesXs && <Profile />}
          {matchesXs && <MobileSection />}
        </div>
      )}
    </>
  );
};

export default HeaderContent;
