import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import axios from 'axios';
import { FormHelperText } from '@mui/material';

const CustomerOnboarding = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // const [errorMessage, setErrorMessage] = useState("");

  const [isFormDirty, setIsFormDirty] = useState(false);
  // const [addressSuggestions, setAddressSuggestions] = useState([]);

  const [primaryEmailError, setPrimaryEmailError] = useState('');
  const [secondaryEmailError, setSecondaryEmailError] = useState('');

  const [primaryContactNumberError, setPrimaryContactNumberError] = useState('');
  const [secondaryContactNumberError, setSecondaryContactNumberError] = useState('');

  const [primaryContactError, setPrimaryContactError] = useState('');
  const [secondaryContactError, setSecondaryContactError] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [usernameError, setUsernameError] = useState('');
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(true);

  const [isEmailAvailable, setIsEmailAvailable] = useState(true);
  const [emailError, setEmailError] = useState(null);

  const [formData, setFormData] = useState({
    clientID: '',
    clientName: '',
    clientStatus: 'Inactive',
    pointOfContact: '',
    secondaryPointOfContact: '',
    primaryEmail: '',
    primaryContactNumber: '+1',
    secondaryEmail: '',
    secondaryContactNumber: '+1',
    unitNumber: ' ',
    streetNumber: '',
    streetName: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    currency: 'Both',
    xreferenceID: '',
    subfleetID: '',
    profitMargin: '',
    clientReference: '',
    discount: '',
    username: '',
    password: '',
    role: 'Admin',
    email: '',
    userStatus: 'Inactive',
    adminUsers: ['admin1'], // Assuming first user is admin
    endUsers: [],
    cards: [],
    invoiceCycle: '', // Added for invoice cycle
  });

  const [errors, setErrors] = useState({
    invoiceCycle: '', // To track errors for the invoice cycle field
  });

  // Regular expression for password validation
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;

  // Handle input change for both email and password
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // Mark the form as dirty when any input field changes
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      setIsFormDirty(true); // Mark form as dirty
      return updatedData;
    });

    if (name === 'username') {
      // Check if username is empty or contains invalid characters, reset error in that case
      if (value.trim() === '') {
        setIsUsernameAvailable(true);
        setUsernameError(null); // Reset error when empty
      } else {
        checkUsernameExistence(value); // Call the username existence check API
      }
    }

    if (name === 'email') {
      // Step 1: Validate the email format
      if (validateEmail(value)) {
        setEmailError(''); // Clear error if email format is valid
        // Step 2: Check if email exists in the database
        checkEmailExistence(value);
      } else {
        setEmailError('Please enter a valid email address with a proper domain.');
        setIsEmailAvailable(true); // Don't check existence if format is invalid
      }
    }

    // Handle Password validation (if applicable)
    if (name === 'password') {
      if (!passwordRegex.test(value)) {
        setPasswordError(
          'Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.',
        );
      } else {
        setPasswordError(''); // Clear error if valid
      }
    }
  };

  // Handle select change for Invoice Cycle
  const handleInvoiceCycleChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      invoiceCycle: value,
    });

    // Validate the invoice cycle (optional: add other custom validations if needed)
    if (value === '') {
      setErrors({
        ...errors,
        invoiceCycle: 'Invoice Cycle is required',
      });
    } else {
      setErrors({
        ...errors,
        invoiceCycle: '', // Clear error if valid selection
      });
    }
  };

  const checkEmailExistence = async (email) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?emailID=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json(); // Get the response data
      console.log('Backend response:', data); // Log the response for debugging

      if (response.ok) {
        // If response is OK, check if the email exists in the array of users
        const emailExists = data.some((user) => user.emailId === email); // Compare emailId with the provided email

        if (emailExists) {
          // If email exists in the array of users
          setIsEmailAvailable(false);
          setEmailError('Email already exists');
        } else {
          // If email doesn't exist in the array
          setIsEmailAvailable(true);
          setEmailError(null);
        }
      } else {
        // Handle non-OK responses (e.g., server errors)
        setIsEmailAvailable(false);
        setEmailError('Error checking email availability');
      }
    } catch (error) {
      // Handle any errors (e.g., network issues)
      console.error('Error checking email:', error);
      setIsEmailAvailable(false);
      setEmailError('Error checking email availability');
    }
  };

  const checkUsernameExistence = async (username) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/user?username=${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        // If the response is not OK (e.g., 404 or any error), assume username does not exist
        setUsernameError(null); // No error
        setIsUsernameAvailable(true);
      } else {
        const data = await response.json(); // In case the response contains data
        console.log(data);
        // // If username exists, set error
        // setIsUsernameAvailable(false);
        // setUsernameError('Username already exists');

        // Check if the response is an empty array or contains data
        if (Array.isArray(data) && data.length === 0) {
          // Username doesn't exist, no error
          setIsUsernameAvailable(true);
          setUsernameError(null);
        } else {
          // Username exists, show error
          setIsUsernameAvailable(false);
          setUsernameError('Username already exists');
        }
      }
    } catch (error) {
      // If error occurs (e.g., user not found), handle accordingly
      setIsUsernameAvailable(true);
      setUsernameError(null); // No error, proceed with the valid username
    }
  };

  useEffect(() => {
    // Fetch the next Client ID when the component mounts
    fetchNextClientId();
    fetchNextCustomerId();
  }, []);

  // Handle changes (although we won't allow manual input)

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  // Email validation function
  const validateEmail = (email) => {
    // Regular expression to check for a valid email format with domain (must have '@' and a valid domain)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  // const handlePhoneNumberChange = (e, field) => {
  //   const value = e.target.value;

  //   // Ensure it starts with +1 and restrict to 13 characters (+1 + 10 digits)
  //   if (field === 'primaryContactNumber') {
  //     // Check if the value starts with '+1' and restrict to 13 characters
  //     if (value.length <= 12 && (value === '+1' || value.startsWith('+1'))) {
  //       setFormData({
  //         ...formData,
  //         primaryContactNumber: value, // Update primary contact number
  //       });
  //     }
  //   } else if (field === 'secondaryContactNumber') {
  //     // Check if the value starts with '+1' and restrict to 13 characters
  //     if (value.length <= 12 && (value === '+1' || value.startsWith('+1'))) {
  //       setFormData({
  //         ...formData,
  //         secondaryContactNumber: value, // Update secondary contact number
  //       });
  //     }
  //   }
  // };

  const validateContactNumber = (number) => {
    // Regular expression to validate a number starting with +1 followed by exactly 10 digits
    const phoneRegex = /^\+1\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePrimaryContactChange = (e) => {
    const newValue = e.target.value;
    handleInputChange(e); // Update form data

    // Validate primary contact number (should start with +1 and be followed by 10 digits)
    if (newValue !== formData.secondaryContactNumber) {
      if (validateContactNumber(newValue)) {
        setPrimaryContactNumberError(''); // Clear error if valid
      } else {
        setPrimaryContactNumberError(
          'Primary Contact Number must be a valid 10-digit number with country code (+1).',
        );
      }
    } else {
      setPrimaryContactNumberError('Primary and Secondary Contact Numbers must be different.');
    }
  };

  const handleSecondaryContactChange = (e) => {
    const newValue = e.target.value;
    handleInputChange(e); // Update form data

    // Validate secondary contact number (should start with +1 and be followed by 10 digits)
    if (newValue !== formData.primaryContactNumber) {
      if (validateContactNumber(newValue)) {
        setSecondaryContactNumberError(''); // Clear error if valid
      } else {
        setSecondaryContactNumberError(
          'Secondary Contact Number must be a valid 10-digit number with country code (+1).',
        );
      }
    } else {
      setSecondaryContactNumberError('Primary and Secondary Contact Numbers must be different.');
    }
  };

  // Effect for fetching next IDs on mount
  useEffect(() => {
    // Fetch the next Client ID and Customer ID when the component mounts
    fetchNextClientId();
    fetchNextCustomerId();
  }, []);

  // Effect for handling the "beforeunload" event when there are unsaved changes
  useEffect(() => {
    // Function to handle the page unload prompt (for page reload or navigating away)
    const handleBeforeUnload = (event) => {
      if (isFormDirty) {
        const message = 'You have unsaved changes. Do you want to leave without saving?';
        event.returnValue = message; // For modern browsers (this triggers the generic message)
        return message; // For older browsers (e.g., IE)
      }
    };

    // Function to handle the back/forward navigation (popstate event)
    const handlePopState = (event) => {
      if (isFormDirty) {
        // Use window.confirm to manually prompt the user for navigation
        const message = 'You have unsaved changes. Do you want to leave without saving?';
        const confirmation = window.confirm(message); // Show the prompt
        if (!confirmation) {
          event.preventDefault(); // Prevent navigation if user clicks "Cancel"
        }
      }
    };

    // Add event listeners for beforeunload (for page refresh/close) and popstate (for back/forward navigation)
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isFormDirty]); // Re-run the effect when form is dirty
  // Re-run when the form's dirty state changes

  const handleCurrencyChange = (event) => {
    setFormData((prevState) => ({
      ...prevState,
      currency: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    // Check if any errors are present before proceeding
    if (primaryContactNumberError || secondaryContactNumberError || emailError) {
      alert('Please fix the errors before submitting the form.');
      return;
    }
    // List of required fields
    const requiredFields = [
      'clientName',
      'pointOfContact',
      'secondaryPointOfContact',
      'primaryEmail',
      'secondaryEmail',
      'primaryContactNumber',
      'secondaryContactNumber',
      'streetNumber',
      'streetName',
      'city',
      'province',
      'country',
      'postalCode',
      'email',
      'username',
      'password',
      'invoiceCycle',
    ];

    // Helper function to format field names for better display
    const formatFieldName = (field) => {
      return field
        .replace(/^ +| +$/g, '') // Remove extra spaces
        .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    // Check if any required field is missing or empty
    for (const field of requiredFields) {
      const value = formData[field];

      // Ensure the value is not empty, null, or undefined
      if (
        !value ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      ) {
        alert(`Please fill out the ${formatFieldName(field)} field.`);
        return;
      }
    }

    // Format address and handle empty fields as empty space
    const addressComponents = [
      formData.unitNumber || '  ',
      formData.streetNumber,
      formData.streetName,
      formData.city,
      formData.province,
      formData.country,
      formData.postalCode,
    ];

    const address = addressComponents.filter((component) => component).join(',');

    const clientPayload = {
      clientID: formData.clientID,
      clientName: formData.clientName,
      clientStatus: formData.clientStatus === 'Active', // Convert to boolean
      pointOfContact: formData.pointOfContact,
      secondaryPointOfContact: formData.secondaryPointOfContact,
      primaryEmail: formData.primaryEmail,
      primaryContactNumber: formData.primaryContactNumber,
      secondaryEmail: formData.secondaryEmail,
      secondaryContactNumber: formData.secondaryContactNumber,
      address: address, // Use the formatted address
      currency:
        formData.currency === 'Both' ? 'CAD, USD' : formData.currency === 'Canada' ? 'CAD' : 'USD',
      xreferenceID: formData.xreferenceID,
      subfleetID: formData.subfleetID,
      profitMargin: formData.profitMargin,
      customerID: formData.clientReference,
      discount: formData.discount,
      users: {
        [formData.username]: formData.role,
      },
      adminUsers: formData.adminUsers,
      endUsers: formData.endUsers,
      cards: formData.cards,
      invoiceCycle: formData.invoiceCycle,
    };

    try {
      // Client request
      const clientResponse = await fetch(`${BACKEND_URL}/v1/client`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(clientPayload),
      });

      if (!clientResponse.ok) {
        const errorDetails = await clientResponse.json();
        throw new Error(`Failed to add client: ${errorDetails.message || errorDetails.error}`);
      }

      const clientResult = await clientResponse.json();
      console.log('Client added successfully:', clientResult);

      // User Payload
      const userPayload = {
        userName: formData.username || '',
        emailId: formData.email || '',
        password: formData.password || '',
        role: formData.role || 'User',
        userStatus: formData.userStatus === 'Active', // Convert to boolean
        name: formData.clientName,
        address:
          `${formData.unitNumber || ''} ${formData.streetNumber} ${formData.streetName} ${
            formData.city
          } ${formData.province} ${formData.country} ${formData.postalCode}`.trim() || null,
        phoneNo: formData.primaryContactNumber || null,
        currency:
          formData.currency === 'Both' ? 'Both' : formData.currency === 'Canada' ? 'CAD' : 'USD',
        apiToken: formData.apiToken || 'ac4dd4532f61458297fb213938a95a7b',
        resetToken: null,
        resetTokenExpiry: null,
        updatedAt: new Date().toISOString(),
        clientID: formData.clientID || '101',
      };

      console.log('User Payload Before Sending:', userPayload); // Log the user payload

      const userResponse = await fetch(`${BACKEND_URL}/v1/user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userPayload),
      });

      if (!userResponse.ok) {
        const errorDetails = await userResponse.json();
        throw new Error(`Failed to add user: ${errorDetails.message || errorDetails.error}`);
      }

      const userResult = await userResponse.json();
      console.log('User added successfully:', userResult);

      // Reset the form data state after successful submission
      setFormData({
        clientID: '',
        clientName: '',
        clientStatus: '',
        pointOfContact: '',
        secondaryPointOfContact: '',
        primaryEmail: '',
        primaryContactNumber: '',
        secondaryEmail: '',
        secondaryContactNumber: '',
        unitNumber: '',
        streetNumber: '',
        streetName: '',
        city: '',
        province: '',
        country: '',
        postalCode: '',
        currency: '',
        xreferenceID: '',
        subfleetID: '',
        profitMargin: '',
        clientReference: '',
        discount: '',
        username: '',
        role: '',
        adminUsers: [],
        endUsers: [],
        cards: [],
        emailId: '',
        password: '',
        userStatus: '',
        apiToken: '',
        invoiceCycle: '',
      });

      alert('Client and user added successfully!');

      // Page refresh to reset everything (this will reload the page)
      window.location.reload(); // Forces a full page reload
    } catch (error) {
      console.error('Error:', error.message);
      alert('There was an error while onboarding. Please try again.');
    }
  };

  // Fetch address suggestions based on user input
  // const fetchAddressSuggestions = async (query) => {
  //   if (query) {
  //     try {
  //       // Fetch address suggestions from the backend API
  //       const response = await fetch(`${BACKEND_URL}/v1/addresses?query=${encodeURIComponent(query)}`);
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch address suggestions');
  //       }
  //       const suggestions = await response.json();
  //       setAddressSuggestions(suggestions); // Update address suggestions state
  //     } catch (error) {
  //       console.error('Error fetching address suggestions:', error);
  //     }
  //   } else {
  //     setAddressSuggestions([]); // Clear suggestions if the input is empty
  //   }
  // };

  const fetchNextClientId = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/nextClientId`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // Add any necessary headers here, like Authorization if needed
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch Client ID: ${response.statusText}`);
      }

      const nextClientId = await response.json(); // Assuming the backend returns a JSON response
      console.log('Fetched next Client ID:', nextClientId);

      // Update formData to sync clientID, xReferenceID, and subfleetID
      setFormData((prevState) => ({
        ...prevState,
        clientID: nextClientId, // Set the fetched Client ID
        xreferenceID: nextClientId, // Sync xReferenceID with Client ID
        subfleetID: nextClientId, // Sync subfleetID with Client ID
      }));
    } catch (error) {
      console.error('Error fetching next Client ID:', error);
    }
  };

  const handleCountryChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      country: value,
      province: '', // Reset province when country changes
      postalCode: '', // Reset postal code when country changes
    }));
  };

  const fetchNextCustomerId = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/next-customer-id`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch Customer ID: ${response.statusText}`);
      }

      const nextCustomerId = await response.text();
      console.log('Fetched next Customer ID:', nextCustomerId);

      setFormData((prevState) => ({
        ...prevState,
        clientReference: nextCustomerId, // Update with the fetched Customer ID
      }));
    } catch (error) {
      console.error('Error fetching next Customer ID:', error);
    }
  };

  useEffect(() => {
    console.log('Client ID updated to:', formData.clientID);
    console.log('Customer ID updated to:', formData.clientReference);
  }, [formData.clientID, formData.clientReference]); // Log whenever formData.clientID changes

  // Handle input change in the street name field
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update form data with the new street value
    setFormData({
      ...formData,
      [name]: value,
    });

    // Fetch address suggestions when the street name input changes
    // if (name === 'street') {
    //   fetchAddressSuggestions(value);
    // }
  };

  // Handle selecting an address suggestion
  // const handleAddressSelect = (suggestion) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     street: suggestion, // Update street with selected suggestion
  //   }));
  //   setAddressSuggestions([]); // Clear the suggestions after selection
  // };

  // Disable user status if client status is inactive
  const handleClientStatusChange = (e) => {
    const newClientStatus = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      clientStatus: newClientStatus,
      userStatus: newClientStatus === 'Inactive' ? 'Inactive' : prevData.userStatus,
    }));
  };

  return (
    <Box
      sx={{
        p: 4,
        maxWidth: 2000,
        margin: 'auto',
        borderRadius: 2,
        marginLeft: '10px',
      }}
    >
      {/* <Typography sx={{ fontSize: "1rem", fontWeight: "bold"  }} align="center" gutterBottom>
        Customer Onboarding
    </Typography> */}

      {/* General Information Header */}
      <Typography
        sx={{
          fontSize: '13px',
          fontWeight: 'bold',
          marginBottom: '40px',
          marginBottom: '20px',
        }}
      >
        General Information
      </Typography>
      {/* <Grid container spacing={4}> </Grid> */}
      <Grid container spacing={2.5} sx={{ marginBottom: '16px' }}>
        {/* Client ID */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Client ID"
            name="clientID"
            value={formData.clientID} // This should update after fetching
            onChange={handleInputChange}
            disabled // Prevent manual editing
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '12px' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Customer ID */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Customer ID"
            name="clientReference"
            value={formData.clientReference}
            onChange={handleInputChange}
            disabled
            // margin="normal"
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '12px' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Customer Name */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Customer Name"
            name="clientName"
            value={formData.clientName}
            onChange={handleInputChange}
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Client Status */}
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel
              sx={{ fontSize: '10.5px', color: 'grey' }} // Reduce placeholder size
            >
              Client Status
            </InputLabel>
            <Select
              value={formData.clientStatus}
              onChange={handleClientStatusChange}
              name="clientStatus"
              label="client status"
              required
              sx={{
                '& .MuiInputBase-root': { height: 22, minHeight: 22 }, // Adjust height
                '& .MuiInputBase-input': { fontSize: 12, padding: '2px' }, // Reduce font size & padding
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    fontSize: '10px', // Reduce font size of dropdown items
                    maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                  },
                },
              }}
            >
              <MenuItem value="Active" sx={{ fontSize: '10px' }}>
                Active
              </MenuItem>
              <MenuItem value="Inactive" sx={{ fontSize: '10px' }}>
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Primary Contact"
            name="pointOfContact"
            value={formData.pointOfContact}
            onChange={(e) => {
              const newValue = e.target.value;
              // Check if the value contains only alphabetic characters and is not a number
              if (/\d/.test(newValue)) {
                setPrimaryContactError('Point of Contact cannot contain numbers.');
              } else if (newValue !== formData.secondaryPointOfContact) {
                setPrimaryContactError(''); // Clear error if valid
                handleInputChange(e); // Update the formData if valid
              } else {
                setPrimaryContactError(
                  'Point of Contact and Secondary Point of Contact should be different.',
                );
              }
            }}
            error={!!primaryContactError} // Show error state if there's an error message
            helperText={primaryContactError} // Display the error message
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Secondary Contact"
            name="secondaryPointOfContact"
            value={formData.secondaryPointOfContact}
            onChange={(e) => {
              const newValue = e.target.value;
              // Check if the value contains only alphabetic characters and is not a number
              if (/\d/.test(newValue)) {
                setSecondaryContactError('Secondary Point of Contact cannot contain numbers.');
              } else if (newValue !== formData.pointOfContact) {
                setSecondaryContactError(''); // Clear error if valid
                handleInputChange(e); // Update the formData if valid
              } else {
                setSecondaryContactError(
                  'Point of Contact and Secondary Point of Contact should be different.',
                );
              }
            }}
            error={!!secondaryContactError} // Show error state if there's an error message
            helperText={secondaryContactError} // Display the error message
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Primary Email */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Primary Email"
            name="primaryEmail"
            value={formData.primaryEmail}
            onChange={(e) => {
              const newValue = e.target.value;
              // Update the form data first
              handleInputChange(e);

              // Check if primary email is different from secondary email
              if (newValue !== formData.secondaryEmail) {
                if (validateEmail(newValue)) {
                  setPrimaryEmailError(''); // Clear error if valid
                } else {
                  setPrimaryEmailError('Primary Email must be a valid email address.');
                }
              } else {
                setPrimaryEmailError('Primary Email and Secondary Email should be different.');
              }
            }}
            error={!!primaryEmailError} // Show error if there's an error message
            helperText={primaryEmailError} // Display the error message
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Secondary Email */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Secondary Email"
            name="secondaryEmail"
            value={formData.secondaryEmail}
            onChange={(e) => {
              const newValue = e.target.value;
              // Update the form data first
              handleInputChange(e);

              // Check if secondary email is different from primary email
              if (newValue !== formData.primaryEmail) {
                if (validateEmail(newValue)) {
                  setSecondaryEmailError(''); // Clear error if valid
                } else {
                  setSecondaryEmailError('Secondary Email must be a valid email address.');
                }
              } else {
                setSecondaryEmailError('Primary Email and Secondary Email should be different.');
              }
            }}
            error={!!secondaryEmailError} // Show error if there's an error message
            helperText={secondaryEmailError} // Display the error message
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Primary Contact Number */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Primary Contact Number"
            name="primaryContactNumber"
            value={formData.primaryContactNumber} // Bind to the state value for primary contact number
            onChange={handlePrimaryContactChange} // Use handlePrimaryContactChange
            error={!!primaryContactNumberError} // Show error if there's an error message
            helperText={primaryContactNumberError} // Display the error message
            required
            inputProps={{ maxLength: 12 }} // Total length = 13, i.e., "+1" + 10 digits
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Secondary Contact Number */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Secondary Contact Number"
            name="secondaryContactNumber"
            value={formData.secondaryContactNumber} // Bind to the state value for secondary contact number
            onChange={handleSecondaryContactChange} // Use handleSecondaryContactChange
            error={!!secondaryContactNumberError} // Show error if there's an error message
            helperText={secondaryContactNumberError} // Display the error message
            required
            inputProps={{ maxLength: 12 }} // Total length = 13, i.e., "+1" + 10 digits
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Unit Number */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Unit Number"
            name="unitNumber"
            value={formData.unitNumber}
            onChange={handleInputChange}
            type="number" // Ensure this field accepts only numbers
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Street Number */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Street Number"
            name="streetNumber"
            value={formData.streetNumber}
            onChange={handleInputChange}
            type="number" // Ensure this field accepts only numbers
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            label="Street Name"
            name="streetName"
            value={formData.streetName}
            onChange={handleChange}
            fullWidth
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
          {/* Render address suggestions */}
          {/* {addressSuggestions.length > 0 && (
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                marginTop: 5,
                border: '1px solid #ccc',
                maxHeight: '150px',
                overflowY: 'auto',
              }}
            >
              {addressSuggestions.map((suggestion, index) => (
                <li
                  key={index}
                  style={{
                    padding: '8px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #ccc',
                  }}
                >
                  <button
                    onClick={() => handleAddressSelect(suggestion)} // Handle click to select suggestion
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAddressSelect(suggestion); // Handle Enter key for keyboard interaction
                      }
                    }}
                    style={{
                      all: 'unset',
                      display: 'block',
                      width: '100%',
                      padding: '8px',
                      cursor: 'pointer',
                      textAlign: 'left',
                      border: 'none',
                      background: 'transparent',
                    }}
                  >
                    {suggestion}
                  </button>
                </li>
              ))}
            </ul>
          )} */}
        </Grid>
        {/* City */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.city}
            onChange={handleInputChange}
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Country */}
        <Grid item xs={4}>
          <FormControl
            fullWidth
            size="small"
            required
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          >
            <InputLabel
              sx={{
                fontSize: '11px',
                top: '-10%',
              }}
            >
              Country
            </InputLabel>
            <Select
              name="country"
              value={formData.country}
              onChange={handleCountryChange}
              label="Country"
              sx={{
                '& .MuiInputBase-root': { height: 25 }, // Adjust height
                '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    fontSize: '10px', // Reduce font size of dropdown items
                    maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                  },
                },
              }}
            >
              <MenuItem value="Canada" sx={{ fontSize: '10px' }}>
                Canada
              </MenuItem>
              <MenuItem value="United States" sx={{ fontSize: '10px' }}>
                United States
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Province */}
        {/* Province/State */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={formData.country === 'United States' ? 'State' : 'Province'}
            name="province"
            value={formData.province}
            onChange={handleInputChange}
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Postal Code/Zip Code */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label={formData.country === 'United States' ? 'Zip Code' : 'Postal Code'}
            name="postalCode"
            value={formData.postalCode}
            onChange={(e) => {
              const newPostalCode = e.target.value;
              const country = formData.country;

              // Validate based on selected country
              if (country === 'Canada') {
                // Canada postal code allows alphanumeric input (6 characters max)
                if (newPostalCode.length <= 6) {
                  handleInputChange(e); // Only update the formData if it's 6 or fewer characters
                }
              } else if (country === 'United States') {
                // US postal code should be numeric and 5 characters (or 9 with the extended ZIP+4 format)
                if (/^\d*$/.test(newPostalCode) && newPostalCode.length <= 6) {
                  handleInputChange(e); // Only update the formData if it's 5 or fewer numeric characters
                }
              }
            }}
            required
            inputProps={{
              maxLength: formData.country === 'United States' ? 9 : 6,
            }} // Adjust max length based on country
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
        </Grid>

        {/* Invoice Cycle Select */}
        <Grid item xs={4}>
          <FormControl
            fullWidth
            required
            error={Boolean(errors.invoiceCycle)}
            size="small"
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          >
            <InputLabel
              sx={{
                fontSize: '11px',
                top: '-10%',
              }}
              shrink={false} // Prevents floating label
            >
              Invoice Cycle
            </InputLabel>
            <Select
              name="invoiceCycle"
              value={formData.invoiceCycle}
              onChange={handleInvoiceCycleChange}
            >
              <MenuItem value="Cycle 1" sx={{ fontSize: '12px' }}>
                Cycle 1 (Mon-Sun)
              </MenuItem>
              <MenuItem value="Cycle 2" sx={{ fontSize: '12px' }}>
                Cycle 2 (Mon-Wed, Thur-Sun)
              </MenuItem>
            </Select>
            {errors.invoiceCycle && <FormHelperText>{errors.invoiceCycle}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>

      {/* Country Selection */}
      <FormControl component="fieldset" margin="normal" fullWidth>
        <FormLabel component="legend" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          Currency Selection
        </FormLabel>
        <RadioGroup row value={formData.currency} onChange={handleCurrencyChange}>
          <FormControlLabel
            value="Canada"
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
            label="Canada (CAD)"
            componentsProps={{ typography: { fontSize: 12 } }}
          />
          <FormControlLabel
            value="United States"
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
            label="United States (USD)"
            componentsProps={{ typography: { fontSize: 12 } }}
          />
          <FormControlLabel
            value="Both"
            control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
            label="Both"
            componentsProps={{ typography: { fontSize: 12 } }}
          />
        </RadioGroup>
      </FormControl>
      {/* <Grid container spacing={2}> */}
      {/* Canada Specific Information */}
      {['Both', 'Canada'].includes(formData.currency) && (
        <Box>
          <Typography variant="subtitle1" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            For Canada
          </Typography>

          <Grid container spacing={2.5}>
            {' '}
            {/* Add container to wrap the items */}
            <Grid item xs={3.2}>
              {' '}
              {/* Set each Grid item to take 4 columns */}
              <TextField
                fullWidth
                label="xReference ID"
                name="xreferenceID"
                value={formData.xreferenceID}
                onChange={handleInputChange}
                margin="normal"
                disabled
                required
                size="small"
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {' '}
              {/* Set each Grid item to take 4 columns */}
              <TextField
                fullWidth
                label="Subfleet ID"
                name="subfleetID"
                value={formData.subfleetID}
                onChange={handleInputChange}
                margin="normal"
                disabled
                required
                size="small"
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              {' '}
              {/* Set each Grid item to take 4 columns */}
              <TextField
                fullWidth
                label="Profit Margin ($)"
                name="profitMargin"
                value={formData.profitMargin}
                onChange={(e) => {
                  const newValue = e.target.value;

                  // Ensure the value consists only of digits or one decimal point
                  if (/^\d*\.?\d*$/.test(newValue)) {
                    handleInputChange(e);
                  }
                }}
                margin="normal"
                required
                size="small"
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {/* United States Specific Information */}
      {['Both', 'United States'].includes(formData.currency) && (
        <Box>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '14px', fontWeight: 'bold', marginTop: '2px' }}
          >
            For United States
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Discount (%)"
                name="discount"
                value={formData.discount}
                onChange={(e) => {
                  const newValue = e.target.value;

                  // Ensure the value consists only of digits or one decimal point
                  if (/^\d*\.?\d*$/.test(newValue)) {
                    handleInputChange(e);
                  }
                }}
                margin="normal"
                required
                size="small"
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
                }}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {/* </Grid> */}

      {/* User Section */}
      <Typography
        variant="h6"
        sx={{ mt: 4, fontSize: '14px', fontWeight: 'bold', marginTop: '5px' }}
      >
        Create User
      </Typography>

      <Grid container spacing={1.7} sx={{ marginTop: '5px' }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            autoComplete="new-username" // Disable autofill for username
            required
            error={!isUsernameAvailable} // Show error if the username is not available
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
          {!isUsernameAvailable && <FormHelperText error>{usernameError}</FormHelperText>}
        </Grid>
        <Grid item xs={4}>
          <TextField
            fullWidth
            type={showPassword ? 'text' : 'password'} // Toggle between password and text type
            label="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!passwordError} // Show error if there's an error message
            helperText={passwordError} // Display the error message
            autoComplete="new-password" // Disable autofill for password
            required
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: '11px' }}>Role</InputLabel>
            <Select
              value={formData.role}
              label="Role"
              onChange={handleInputChange}
              name="role"
              required
              sx={{
                '& .MuiInputBase-root': {
                  height: 18, // Reduce height further
                  minHeight: 18,
                },
                '& .MuiInputBase-input': {
                  fontSize: 10, // Reduce font size
                  padding: '2px', // Adjust padding
                },
              }}
            >
              <MenuItem value="Admin" sx={{ fontSize: '12px' }}>
                Admin
              </MenuItem>
              {/* <MenuItem value="User">User</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        {/* Email Field */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            autoComplete="new-email" // Disable autofill for email
            required
            error={!isEmailAvailable} // Show error if the email is not available
            size="small"
            InputLabelProps={{
              sx: { fontSize: '11px', top: '-20%' }, // Reduce placeholder size
            }}
            sx={{
              '& .MuiInputBase-root': { height: 25 }, // Adjust height
              '& .MuiInputBase-input': { fontSize: 12, padding: '4px' }, // Reduce font size & padding
            }}
          />
          {!isEmailAvailable && <FormHelperText error>{emailError}</FormHelperText>}
        </Grid>

        {/* User Status Field */}
        <Grid item xs={4}>
          <FormControl fullWidth size="small">
            <InputLabel sx={{ fontSize: '11px', top: '-5%' }}>User Status</InputLabel>
            <Select
              name="userStatus"
              label="userStatus"
              value={formData.userStatus}
              onChange={handleInputChange}
              required
              disabled={formData.clientStatus === 'Inactive'} // Disable dropdown if clientStatus is Inactive
              sx={{
                '& .MuiInputBase-root': { height: 20 }, // Adjust height
                '& .MuiInputBase-input': { fontSize: 12, padding: '1.5px' }, // Reduce font size & padding
              }}
            >
              <MenuItem value="Active" disabled={formData.clientStatus === 'Inactive'}>
                Active
              </MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            mt: 2,
            backgroundColor: '#31478F',
            padding: '4px 12px', // Reduce padding
            fontSize: '12px', // Reduce text size
            minWidth: '100px', // Adjust button width if needed
            height: '30px', // Reduce button height
          }}
          onClick={handleSubmit}
        >
          Submit Onboarding
        </Button>
      </Grid>
    </Box>
  );
};

export default CustomerOnboarding;
