import React from 'react';
import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Login from 'pages/login/Login';
import OnboardClient from 'pages/onboard-client/index';
import ClientDetailedView from 'pages/manage-clients/detailed-view';
import NewLogin from 'pages/login/index';
import Invoice from 'pages/manage-invoice/index';
import InvoiceGenerate from 'pages/manage-invoice/invoice';
import VerifyInvoice from 'pages/manage-invoice/verify-invoice';
import CreateBroker from 'pages/manage-brokers/CreateBroker';
import BrokerList from 'pages/manage-brokers/BrokerList';
import BrokerCommission from 'pages/manage-brokers/BrokerCommission';
import LocationPricing from 'pages/manage-location-pricing/location-pricing';
import Dashboard from 'pages/manage-dashboard/index';

const FuelCardListView = Loadable(lazy(() => import('pages/fuel-card/list-view')));
const ManageDiscount = Loadable(lazy(() => import('pages/manage-discount')));
const ManageProfit = Loadable(lazy(() => import('pages/manage-profit-margin')));
const ManageUsers = Loadable(lazy(() => import('pages/manage-users')));
const ManageClients = Loadable(lazy(() => import('pages/manage-clients')));
const ManageTransaction = Loadable(
  lazy(() => import('pages/transaction-report/manage-transaction')),
);
const ErrorPage = Loadable(lazy(() => import('pages/extra-pages/Error')));
const TransactionReport = Loadable(lazy(() => import('pages/transaction-report/')));
const ManageCards = Loadable(lazy(() => import('pages/manage-cards')));
const ManageDriver = Loadable(lazy(() => import('pages/manage-drivers')));
const ManageUnitNumber = Loadable(lazy(() => import('pages/manage-unit-number')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Login />,
    },
    {
      path: '/new-login',
      element: <NewLogin />,
    },
    {
      path: 'fuel-card',
      element: <FuelCardListView />,
    },
    {
      path: 'transaction-report',
      element: <TransactionReport />,
    },
    {
      path: 'manage-discount',
      element: <ManageDiscount />,
    },
    {
      path: 'manage-profit',
      element: <ManageProfit />,
    },
    {
      path: 'manage-users',
      element: <ManageUsers />,
    },
    {
      path: 'manage-cards',
      element: <ManageCards />,
    },
    {
      path: 'manage-customers',
      element: <ManageClients />,
    },
    {
      path: 'manage-transaction',
      element: <ManageTransaction />,
    },
    {
      path: 'manage-driver',
      element: <ManageDriver />,
    },
    {
      path: 'manage-unit-number',
      element: <ManageUnitNumber />,
    },
    {
      path: 'onboard-client',
      element: <OnboardClient />,
    },
    {
      path: 'manage-invoice',
      element: <Invoice />,
    },
    {
      path: 'manage-invoice/generate',
      element: <InvoiceGenerate />,
    },
    {
      path: 'manage-invoice/verify',
      element: <VerifyInvoice />,
    },
    {
      path: 'customers/:clientId',
      element: <ClientDetailedView />,
    },
    // Use a nested route or different paths for CreateBroker and BrokerList
    {
      path: 'manage-brokers',
      element: <CreateBroker />,
    },
    {
      path: 'manage-brokers/list',
      element: <BrokerList />,
    },
    {
      path: 'manage-brokers/BrokerCommission',
      element: <BrokerCommission />,
    },
    {
      path: 'manage-location-pricing',
      element: <LocationPricing />,
    },
    {
      path: 'manage-dashboard',
      element: <Dashboard />,
    },
    {
      path: '*',
      element: <ErrorPage />,
    },
  ],
};

export default MainRoutes;
