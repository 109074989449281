import { Box, Card, CardContent, Grid, Typography, Button, Alert, AlertTitle, FormHelperText } from '@mui/material'; // Tooltip, Paper, , Chip,
import * as React from 'react';
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { darken, lighten, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Loader from 'components/Loader';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';

const ManageClients = ({ isOnboard = false, onSuccess }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = React.useState(false);

  // Alerts
  const [isClientCreated, setIsClientCreated] = React.useState(false);
  const [isClientUpdated, setIsClientUpdated] = React.useState(false);

  //const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin'
  //const [weeklyVolume, setWeeklyVolume] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const buttonsFontSize = '16px';

  const toUpperCase = (text) => text.toUpperCase();
  const customerDetails = [
    {
      clientId: 101,
      customerId: 'DGCQ4',
      clientName: 'Royal Truck'
    },
    {
      clientId: 102,
      customerId: 'DGCQ5',
      clientName: 'Triple Gold'
    },
    {
      clientId: 103,
      customerId: 'DGCQ6',
      clientName: 'Ramos'
    },
    {
      clientId: 104,
      customerId: 'DGCQ7',
      clientName: 'Can-EX'
    },
    {
      clientId: 105,
      customerId: 'DGCQ8',
      clientName: 'GT'
    },
    {
      clientId: 106,
      customerId: 'DGCQ9',
      clientName: 'Nildiya'
    },
    {
      clientId: 107,
      customerId: 'DGCRA',
      clientName: ''
    },
    {
      clientId: 108,
      customerId: 'DGCRB',
      clientName: ''
    },
    {
      clientId: 109,
      customerId: 'DGCRC',
      clientName: ''
    },
    {
      clientId: 110,
      customerId: 'DGCRD',
      clientName: ''
    },
    {
      clientId: 111,
      customerId: 'DGCRE',
      clientName: ''
    },
    {
      clientId: 112,
      customerId: 'DGCRF',
      clientName: ''
    },
    {
      clientId: 113,
      customerId: 'DGCRG',
      clientName: ''
    },
    {
      clientId: 114,
      customerId: 'DGCRH',
      clientName: ''
    },
    {
      clientId: 115,
      customerId: 'DGCRI',
      clientName: ''
    },
    {
      clientId: 116,
      customerId: 'DGCRJ',
      clientName: ''
    },
    {
      clientId: 117,
      customerId: 'DGCRK',
      clientName: ''
    },
    {
      clientId: 118,
      customerId: 'DGCRL',
      clientName: ''
    },
    {
      clientId: 119,
      customerId: 'DGCRM',
      clientName: ''
    },
    {
      clientId: 120,
      customerId: 'DGCRN',
      clientName: ''
    },
    {
      clientId: 121,
      customerId: 'DGCRO',
      clientName: ''
    },
    {
      clientId: 122,
      customerId: 'DGCRP',
      clientName: ''
    },
    {
      clientId: 123,
      customerId: 'DGCRQ',
      clientName: ''
    },
    {
      clientId: 124,
      customerId: 'DGCRR',
      clientName: ''
    },
    {
      clientId: 125,
      customerId: 'DGCRS',
      clientName: ''
    }
  ];

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`);
    } else {
      setLoggedIn(true);
      generateListViewDetails();
    }
  }, []);

  // Create
  const [clientID, setClientID] = React.useState('');
  const [clientIdError, setClientIdError] = React.useState(false);
  const [clientIdErrorMessage, setClientIdErrorMessage] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [clientName, setClientName] = React.useState('');
  const [clientStatus, setClientStatus] = React.useState('Inactive');
  const [pointOfContact, setPointOfContact] = React.useState('');
  const [secondaryPointOfContact, setSecondaryPointOfContact] = React.useState('');
  const [primaryEmail, setPrimaryEmail] = React.useState('');
  const [primaryContactNumber, setPrimaryContactNumber] = React.useState('');
  const [secondaryEmail, setSecondaryEmail] = React.useState('');
  const [secondaryContactNumber, setSecondaryContactNumber] = React.useState('');
  const [unitNumber, setUnitNumber] = React.useState('');
  const [streetNumber, setStreetNumber] = React.useState('');
  const [streetName, setStreetName] = React.useState('');
  const [city, setCity] = React.useState('');
  const [province, setProvince] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');

  // Client List View
  const [clientListView, setClientListView] = React.useState([]);

  const [clients, setClients] = React.useState([]);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [isAddCardSelected, setAddCardSelected] = React.useState(isOnboard);
  const [isUpdateCardSelected, setUpdateCardSelected] = React.useState(false);
  const defaultButtonBackground = '#011f4b';
  const chosenButtonBackground = '#03396c';
  const clientStatuses = [
    {
      id: 1,
      value: 'Active',
      shortForm: 'A'
    },
    {
      id: 2,
      value: 'Inactive',
      shortForm: 'I'
    }
  ];

  const clientListViewColumns = [
    {
      field: 'id',
      headerName: 'Serial No.',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'accountNumber',
      headerName: 'Account Number',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'customerId',
      headerName: 'Customer ID',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'customerStatus',
      headerName: 'Customer Status',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'activeDiscount',
      headerName: 'Active Discount',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'volumesPerWeek',
      headerName: 'Volumes Per Week',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'volumesPerMonth',
      headerName: 'Volumes Per Month',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'yearToDateVolume',
      headerName: 'Year to Date Volume',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'profitWeekly',
      headerName: 'Weekly Profit',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'profitMonthly',
      headerName: 'Monthly Profit',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    },
    {
      field: 'profitYear',
      headerName: 'Yearly Profit',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header'
    }
  ];

  const NoRecordsFoundRow = [
    {
      id: '',
      customerName: '',
      accountNumber: '',
      customerId: '',
      customerStatus: '',
      activeDiscount: 'No records found',
      volumesPerWeek: '',
      volumesPerMonth: '',
      yearToDateVolume: '',
      profitWeekly: '',
      profitMonthly: '',
      profitYear: ''
    }
  ];

  const addNewClientDiscount = () => {
    let updateDiscountUrl = `${BACKEND_URL}/v1/update-discount?clientID=${clientID}&customerID=${customerId}&discount=0`;
    fetch(updateDiscountUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => response.json());
    // .then(data => {
    //     //console.log(data);
    // });
  };

  const handleAddCustomerSelection = (event) => {
    event.preventDefault();
    setLoading(true);
    generateListViewDetails();
    let newClientId = 100;
    setTimeout(() => {
      setAddCardSelected(true);
      setUpdateCardSelected(false);
      if (clients.length > 0) {
        clients.sort((client1, client2) => +client1.clientID - +client2.clientID);
        newClientId = +clients[clients.length - 1].clientID;
      }
      newClientId = typeof newClientId === 'number' ? newClientId + 1 : '';
      resetForm(newClientId);
      let customerInfoDetails = customerDetails.filter((detail) => detail.clientId == newClientId);
      if (customerInfoDetails && customerInfoDetails[0]) {
        setCustomerId(customerInfoDetails[0].customerId);
        setClientName(customerInfoDetails[0].clientName);
      }
      setLoading(false);
    }, 2000);
  };

  const resetForm = (newClientId) => {
    setClientID(newClientId);
    setCustomerId('');
    setClientName('');
    setClientStatus('Inactive');
    setPointOfContact('');
    setSecondaryPointOfContact('');
    setPrimaryEmail('');
    setPrimaryContactNumber('');
    setSecondaryEmail('');
    setSecondaryContactNumber('');
    setUnitNumber('');
    setStreetNumber('');
    setStreetName('');
    setCity('');
    setProvince('');
    setCountry('');
    setPostalCode('');
  };

  const handleUpdateCustomerSelection = (event) => {
    event.preventDefault();
    setLoading(true);
    generateListViewDetails();
    setTimeout(() => {
      setAddCardSelected(false);
      setUpdateCardSelected(true);
      resetForm('');
      setLoading(false);
    }, 2000);
  };

  const generateListViewDetails = async () => {
    setLoading(true);
    let clients = await getClients();
    let customerListViewDetails = await getDiscountDetailsForClients(clients);

    setClientListView(customerListViewDetails);
    setLoading(false);
  };

  const getDiscountDetailsForClients = async (inputClients) => {
    let discountDetailsForCustomerList = [];
    let clientIndex = 1;

    for (let c = 0; c < inputClients.length; c++) {
      let client = inputClients[c];
      //console.log("Processing client:", client);

      // Fetch discount details
      let getDiscountDetailsForCustomerUrl = `${BACKEND_URL}/v1/discounts?clientID=${client.clientID}`;

      try {
        // Fetch discount details
        const discountResponse = await fetch(getDiscountDetailsForCustomerUrl);
        if (!discountResponse.ok) {
          throw new Error(`Error fetching discount data for client ${client.clientID}`);
        }
        const discountData = await discountResponse.json();
        let discount = discountData.discount !== undefined ? discountData.discount : 0;

        // Fetch volume details for weekly, monthly, and yearly periods
        const volumeResponses = await Promise.all([
          fetch(`${BACKEND_URL}/v1/volume?clientID=${client.customerID}&periodType=weekly`),
          fetch(`${BACKEND_URL}/v1/volume?clientID=${client.customerID}&periodType=monthly`),
          fetch(`${BACKEND_URL}/v1/volume?clientID=${client.customerID}&periodType=yearly`)
        ]);

        const [weeklyVolumeResponse, monthlyVolumeResponse, yearlyVolumeResponse] = volumeResponses;

        if (!weeklyVolumeResponse.ok || !monthlyVolumeResponse.ok || !yearlyVolumeResponse.ok) {
          throw new Error(`Error fetching volume data for client ${client.clientID}`);
        }

        const [weeklyVolumeData, monthlyVolumeData, yearlyVolumeData] = await Promise.all([
          weeklyVolumeResponse.json(),
          monthlyVolumeResponse.json(),
          yearlyVolumeResponse.json()
        ]);

        let profitWeekly = weeklyVolumeData.profit;
        let profitMonthly = monthlyVolumeData.profit;
        let profitYearly = yearlyVolumeData.profit;

        let clientDetails = {
          id: clientIndex++,
          customerName: toUpperCase(client.clientName),
          accountNumber: client.clientID,
          customerId: client.customerID,
          customerStatus: client.clientStatus ? 'ACTIVE' : 'INACTIVE',
          activeDiscount: `${discount}%`,
          volumesPerWeek: weeklyVolumeData.weekly || 0,
          volumesPerMonth: monthlyVolumeData.monthly || 0,
          yearToDateVolume: yearlyVolumeData.yearly || 0,
          profitWeekly: profitWeekly.toFixed(2) || 0,
          profitMonthly: profitMonthly.toFixed(2) || 0,
          profitYear: profitYearly.toFixed(2) || 0
        };

        discountDetailsForCustomerList.push(clientDetails);
      } catch (error) {
        console.error(`Error processing client ${client.clientID}:`, error);
        let clientDetails = {
          id: clientIndex++,
          customerName: toUpperCase(client.clientName),
          accountNumber: client.clientID,
          customerId: client.customerID,
          customerStatus: client.clientStatus ? 'ACTIVE' : 'INACTIVE',
          activeDiscount: `0%`, // Default value
          volumesPerWeek: 0,
          volumesPerMonth: 0,
          yearToDateVolume: 0,
          profitWeekly: 0,
          profitMonthly: 0,
          profitYear: 0
        };
        discountDetailsForCustomerList.push(clientDetails);
      }
    }

    return discountDetailsForCustomerList;
  };

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);
    return data;
  };

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  const handlePointOfContactChange = (event) => {
    setPointOfContact(event.target.value);
  };

  const handleSecondaryPointOfContactChange = (event) => {
    setSecondaryPointOfContact(event.target.value);
  };

  const handlePrimaryEmailChange = (event) => {
    setPrimaryEmail(event.target.value);
  };

  const handlePrimaryContactNumberChange = (event) => {
    setPrimaryContactNumber(event.target.value);
  };

  const handleSecondaryEmailChange = (event) => {
    setSecondaryEmail(event.target.value);
  };

  const handleSecondaryContactNumberChange = (event) => {
    setSecondaryContactNumber(event.target.value);
  };

  const handleCustomerIdChange = (event) => {
    setCustomerId(event.target.value);
  };

  const handleCreateClient = (event) => {
    event.preventDefault();
    setLoading(true);
    if (isOnboard) {
      let selectedStatus = clientStatuses.filter((status) => status.value === clientStatus);
      const createClientObject = {
        clientID: clientID,
        customerID: customerId,
        clientStatus: selectedStatus[0].value === 'Inactive',
        clientName: clientName,
        pointOfContact: pointOfContact,
        secondaryPointOfContact: secondaryPointOfContact,
        primaryEmail: primaryEmail,
        primaryContactNumber: primaryContactNumber,
        secondaryEmail: secondaryEmail,
        secondaryContactNumber: secondaryContactNumber,
        address: `${unitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
        postalCode: postalCode
      };

      setTimeout(() => {
        onSuccess(createClientObject);
      }, 1000);
    } else {
      setTimeout(async () => {
        let selectedStatus = clientStatuses.filter((status) => status.value === clientStatus);
        const createClientObject = {
          clientID: clientID,
          customerID: customerId,
          clientStatus: selectedStatus[0].value === 'Inactive',
          clientName: clientName,
          pointOfContact: pointOfContact,
          secondaryPointOfContact: secondaryPointOfContact,
          primaryEmail: primaryEmail,
          primaryContactNumber: primaryContactNumber,
          secondaryEmail: secondaryEmail,
          secondaryContactNumber: secondaryContactNumber,
          address: `${unitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
          postalCode: postalCode
        };

        await fetch(`${BACKEND_URL}/v1/client`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(createClientObject)
        })
          .then((response) => response.json())
          .then(() => {
            generateListViewDetails();
            setIsClientCreated(true);
            addNewClientDiscount();
          });
        resetForm('');
        setLoading(false);
      }, 2000);
    }
  };

  const handleUpdateClientSelectionChange = (event) => {
    setLoading(true);
    setTimeout(() => {
      let chosenClientForUpdate = clients.filter((client) => client.clientID === event.target.value);
      if (chosenClientForUpdate) {
        setClientID(chosenClientForUpdate[0].clientID);
        setCustomerId(chosenClientForUpdate[0].customerID);
        setClientStatus(chosenClientForUpdate[0].clientStatus ? 'Active' : 'Inactive');
        setClientName(chosenClientForUpdate[0].clientName);
        setPointOfContact(chosenClientForUpdate[0].pointOfContact);
        setSecondaryPointOfContact(chosenClientForUpdate[0].secondaryPointOfContact);
        setPrimaryEmail(chosenClientForUpdate[0].primaryEmail);
        setPrimaryContactNumber(chosenClientForUpdate[0].primaryContactNumber);
        setSecondaryEmail(chosenClientForUpdate[0].secondaryEmail);
        setSecondaryContactNumber(chosenClientForUpdate[0].secondaryContactNumber);
        let incomingAddress = chosenClientForUpdate[0].address;
        if (incomingAddress) {
          let splitAddress = incomingAddress.split(',');
          console.log(incomingAddress);
          console.log(splitAddress);
          setUnitNumber(splitAddress.length > 0 ? splitAddress[0] : 'N/A');
          setStreetNumber(splitAddress.length > 0 ? splitAddress[0] : 'N/A');
          setStreetName(splitAddress.length > 1 ? splitAddress[1] : 'N/A');
          setCity(splitAddress.length > 2 ? splitAddress[2] : 'N/A');
          setProvince(splitAddress.length > 3 ? splitAddress[3] : 'N/A');
          setCountry(splitAddress.length > 4 ? splitAddress[4] : 'N/A');
          setPostalCode(splitAddress.length > 5 ? splitAddress[5].trim() : 'N/A');
        }
        setLoading(false);
      }
    }, 1000);
  };

  const handleClientStatusChange = (event) => {
    let selectedStatus = clientStatuses.filter((status) => status.value === event.target.value);
    setClientStatus(selectedStatus[0].value);
  };

  const handleUpdateClient = (event) => {
    event.preventDefault();
    setLoading(true);
    let selectedStatus = clientStatuses.filter((status) => status.value === clientStatus);
    const updateClientObject = {
      clientID: clientID,
      customerID: customerId,
      clientStatus: selectedStatus[0].value === 'Inactive',
      clientName: clientName,
      pointOfContact: pointOfContact,
      secondaryPointOfContact: secondaryPointOfContact,
      primaryEmail: primaryEmail,
      primaryContactNumber: primaryContactNumber,
      secondaryEmail: secondaryEmail,
      secondaryContactNumber: secondaryContactNumber,
      address: `${unitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
      postalCode: postalCode
    };
    setTimeout(() => {
      // fetch(`${BACKEND_URL}/v1/client?clientID=${clientID}&clientStatus=${clientStatus === "Active"}`, {
      fetch(`${BACKEND_URL}/v1/client`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateClientObject)
      })
        .then((response) => response.json())
        .then(() => {
          getClients();
        });
      resetForm('');
      setLoading(false);
    }, 1000);

    setTimeout(() => {
      fetch(`${BACKEND_URL}/v1/client-status?clientID=${clientID}&clientStatus=${clientStatus === 'Active'}`, {
        // fetch(`${BACKEND_URL}/v1/client`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then(() => {
          getClients();
        });
      setIsClientUpdated(true);
      setLoading(false);
    }, 1000);
  };

  const handleClientIDChange = (event) => {
    let clientId = event.target.value;
    setClientID(clientId);
    let existingClient = clients.filter((client) => client.clientID == clientId);
    if (existingClient && existingClient.length > 0) {
      setClientIdError(true);
      setClientIdErrorMessage(`Client ID ${clientId} already exists!`);
      return;
    } else {
      setClientIdError(false);
      setClientIdErrorMessage('');
    }
    let customerInfoDetails = customerDetails.filter((detail) => detail.clientId == clientId);
    if (customerInfoDetails && customerInfoDetails[0]) {
      setCustomerId(customerInfoDetails[0].customerId);
      setClientName(customerInfoDetails[0].clientName);
    } else {
      setCustomerId('');
      setClientName('');
    }
  };

  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer'
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode)
      }
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode)
      }
    },
    '& .MuiDataGrid-row': {
      height: '16px' // Set row height to 16px (1rem)
    },
    '& .MuiDataGrid-cell': {
      lineHeight: '16px' // Ensure text aligns properly within the row
    }
  }));

  const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesChartTypography = { fontSize: '18px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const cardHeight = 650;
  const inputLabelStyles = { fontSize: '12px', color: 'rgb(26, 33, 56)', fontWeight: 600 };
  const textFieldLabelStyles = {
    style: {
      fontSize: '12px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      // width: 300,
      fontWeight: 600
    }
  };
  const gridRowStyles = { marginTop: '0', marginBottom: '15px' };

  const gridRowClicked = (event) => {
    let rowDetails = event?.row;
    navigate(`/customers/${rowDetails.accountNumber}`, {
      state: {
        chosenClient: rowDetails
      }
    });
    // setCurrentRowDetails(rowDetails)
    // setIsDetailedViewOpen(!isDetailedViewOpen)
  };

  return (
    loggedIn && (
      <div>
        {isClientCreated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsClientCreated(false)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
          >
            <AlertTitle>Success, Customer created successfully</AlertTitle>
          </Alert>
        )}
        {isClientUpdated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsClientUpdated(false)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
          >
            <AlertTitle>Success, Customer updated successfully</AlertTitle>
          </Alert>
        )}
        <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
          Manage Customer
        </Typography>

        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1' }}>
            <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={9} sm={9} md={9} lg={9} style={gridRowStyles} />
                  <Grid item xs={3} sm={3} md={3} lg={3} style={gridRowStyles}>
                    {isOnboard && (
                      <Button
                        style={{
                          marginTop: '0',
                          fontFamily: 'DM Sans',
                          fontSize: buttonsFontSize,
                          backgroundColor: isAddCardSelected ? chosenButtonBackground : defaultButtonBackground
                        }}
                        type="submit"
                        variant="contained"
                        sx={{ borderRadius: 0 }}
                        onClick={handleAddCustomerSelection}
                      >
                        <Typography>New Customer</Typography>
                        <AddIcon />
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {loading && <Loader />}
                {isAddCardSelected && (
                  <form id="add-cards" onSubmit={handleCreateClient}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={clientIdError}>
                              <TextField
                                style={inputLabelStyles}
                                id="clientID"
                                label={clientID ? 'Client ID' : 'Client ID'}
                                value={clientID}
                                onChange={handleClientIDChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                              {clientIdError && <FormHelperText id="input-error-text">{clientIdErrorMessage}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="customerId"
                                label={customerId ? 'Customer ID (Comdata Reference)' : 'Customer ID (Comdata Reference)'}
                                value={customerId}
                                onChange={handleCustomerIdChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="clientName"
                                label={clientName ? 'Client Name' : 'Client Name'}
                                value={clientName}
                                onChange={handleClientNameChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <InputLabel id="clientStatus" style={inputLabelStyles}>
                                Client Status
                              </InputLabel>
                              <Select
                                labelId="clientStatus"
                                id="clientStatus"
                                value={clientStatus}
                                label="Client Status"
                                onChange={handleClientStatusChange}
                              >
                                {clientStatuses.map((status) => (
                                  <MenuItem key={status.value} value={status.value}>
                                    {status.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="pointOfContact"
                                label={pointOfContact ? 'Primary Contact' : 'Primary Contact'}
                                value={pointOfContact}
                                onChange={handlePointOfContactChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryPointOfContact"
                                label={secondaryPointOfContact ? 'Secondary Contact' : 'Secondary Contact'}
                                value={secondaryPointOfContact}
                                onChange={handleSecondaryPointOfContactChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="primaryEmail"
                                label={primaryEmail ? 'Primary Email' : 'Primary Email'}
                                value={primaryEmail}
                                onChange={handlePrimaryEmailChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryEmail"
                                label={secondaryEmail ? 'Secondary Email' : 'Secondary Email'}
                                value={secondaryEmail}
                                onChange={handleSecondaryEmailChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="primaryContactNumber"
                                label={primaryContactNumber ? 'Primary Contact Number' : 'Primary Contact Number'}
                                value={primaryContactNumber}
                                onChange={handlePrimaryContactNumberChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryContactNumber"
                                label={secondaryContactNumber ? 'Secondary Contact Number' : 'Secondary Contact Number'}
                                value={secondaryContactNumber}
                                onChange={handleSecondaryContactNumberChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="unitNumber"
                                label={unitNumber ? 'Unit Number' : 'Unit Number'}
                                value={unitNumber}
                                onChange={(event) => {
                                  setUnitNumber(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="streetNumber"
                                label={streetNumber ? 'Street Number' : 'Street Number'}
                                value={streetNumber}
                                onChange={(event) => {
                                  setStreetNumber(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="streetName"
                                label={streetName ? 'Street Name' : 'Street Name'}
                                value={streetName}
                                onChange={(event) => {
                                  setStreetName(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="city"
                                label={city ? 'City' : 'City'}
                                value={city}
                                onChange={(event) => {
                                  setCity(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="province"
                                label={province ? 'Province' : 'Province'}
                                value={province}
                                onChange={(event) => {
                                  setProvince(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="country"
                                label={country ? 'Country' : 'Country'}
                                value={country}
                                onChange={(event) => {
                                  setCountry(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="postalCode"
                                label={postalCode ? 'Postal Code' : 'Postal Code'}
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                          <Box>
                            <Button
                              style={{
                                marginTop: '0',
                                fontFamily: 'DM Sans',
                                fontSize: buttonsFontSize,
                                backgroundColor: isOnboard ? defaultButtonBackground : chosenButtonBackground
                              }}
                              type="submit"
                              variant="contained"
                              sx={{ mt: 3, mb: 5, borderRadius: 0 }}
                            >
                              {isOnboard ? 'Proceed' : 'Create'}
                              <DoneIcon />
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                      </Grid>
                    </div>
                  </form>
                )}
                {isUpdateCardSelected && (
                  <form id="get-cards" onSubmit={handleUpdateClient}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <InputLabel id="clientID" style={inputLabelStyles}>
                                Client ID
                              </InputLabel>
                              <Select
                                labelId="clientID"
                                id="clientID"
                                value={clientID}
                                label="Client ID"
                                onChange={handleUpdateClientSelectionChange}
                              >
                                {clients.length > 0 &&
                                  clients.map((client) => (
                                    <MenuItem key={client.clientID} value={client.clientID}>
                                      {client.clientID} - {client.customerID} - {client.clientName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="customerId"
                                label="Customer ID"
                                value={customerId}
                                InputLabelProps={textFieldLabelStyles}
                                // disabled
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="clientName"
                                label="Client Name"
                                value={clientName}
                                onChange={handleClientNameChange}
                                InputLabelProps={textFieldLabelStyles}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <InputLabel id="clientStatus" style={inputLabelStyles}>
                                Client Status
                              </InputLabel>
                              <Select
                                labelId="clientStatus"
                                id="clientStatus"
                                value={clientStatus}
                                label="Client Status"
                                onChange={handleClientStatusChange}
                              >
                                {clientStatuses.map((status) => (
                                  <MenuItem key={status.value} value={status.value}>
                                    {status.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="pointOfContact"
                                label="Primary Contact"
                                value={pointOfContact}
                                onChange={handlePointOfContactChange}
                                InputLabelProps={textFieldLabelStyles}
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryPointOfContact"
                                label={secondaryPointOfContact ? 'Secondary Contact' : 'Secondary Contact'}
                                value={secondaryPointOfContact}
                                onChange={handleSecondaryPointOfContactChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="primaryEmail"
                                label={primaryEmail ? 'Primary Email' : 'Primary Email'}
                                value={primaryEmail}
                                onChange={handlePrimaryEmailChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryEmail"
                                label={secondaryEmail ? 'Secondary Email' : 'Secondary Email'}
                                value={secondaryEmail}
                                onChange={handleSecondaryEmailChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="primaryContactNumber"
                                label={primaryContactNumber ? 'Primary Contact Number' : 'Primary Contact Number'}
                                value={primaryContactNumber}
                                onChange={handlePrimaryContactNumberChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="secondaryContactNumber"
                                label={secondaryContactNumber ? 'Secondary Contact Number' : 'Secondary Contact Number'}
                                value={secondaryContactNumber}
                                onChange={handleSecondaryContactNumberChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="unitNumber"
                                label={unitNumber ? 'Unit Number' : 'Unit Number'}
                                value={unitNumber}
                                onChange={(event) => {
                                  setUnitNumber(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                              />
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="streetNumber"
                                label={streetNumber ? 'Street Number' : 'Street Number'}
                                value={streetNumber}
                                onChange={(event) => {
                                  setStreetNumber(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="streetName"
                                label={streetName ? 'Street Name' : 'Street Name'}
                                value={streetName}
                                onChange={(event) => {
                                  setStreetName(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="city"
                                label={city ? 'City' : 'City'}
                                value={city}
                                onChange={(event) => {
                                  setCity(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="province"
                                label={province ? 'Province' : 'Province'}
                                value={province}
                                onChange={(event) => {
                                  setProvince(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="country"
                                label={country ? 'Country' : 'Country'}
                                value={country}
                                onChange={(event) => {
                                  setCountry(event.target.value);
                                }}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <TextField
                                style={inputLabelStyles}
                                id="postalCode"
                                label={postalCode ? 'Postal Code' : 'Postal Code'}
                                value={postalCode}
                                onChange={(event) => setPostalCode(event.target.value)}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                            <Box>
                              <Button
                                style={{
                                  fontFamily: 'DM Sans',
                                  fontSize: buttonsFontSize,
                                  backgroundColor: defaultButtonBackground
                                }}
                                type="submit"
                                variant="contained"
                                sx={{ borderRadius: 0 }}
                              >
                                Save
                                <SaveIcon />
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                )}

                {!isOnboard &&
                  (!loading && clientListView.length > 0 ? (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1', margin: '2px' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      Customer Details
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      style={{
                                        marginTop: '0',
                                        fontFamily: 'DM Sans',
                                        fontSize: buttonsFontSize,
                                        backgroundColor: isUpdateCardSelected ? chosenButtonBackground : defaultButtonBackground
                                      }}
                                      type="submit"
                                      variant="contained"
                                      sx={{ borderRadius: 0 }}
                                      onClick={handleUpdateCustomerSelection}
                                    >
                                      <Typography>Update Customer</Typography>
                                      <EditIcon />
                                    </Button>
                                  </Grid>
                                </Grid>
                                <div style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}>
                                <StyledDataGrid
                                  onRowClick={gridRowClicked}
                                  sx={{
                                    '& .super-app-theme--header': {
                                      backgroundColor: 'rgba(0, 9, 81)',
                                      color: 'white'
                                    }
                                  }}
                                  rows={clientListView}
                                  columns={clientListViewColumns}
                                  slots={{ toolbar: GridToolbar }}
                                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                                  rowHeight={36}
                                />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : loading ? (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1', margin: '2px' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="space-between">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      Customer Details
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <CircularProgress color="success" />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1', margin: '2px' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      Customer Details
                                    </Typography>
                                  </Grid>
                                </Grid>

                                <div style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}>
                                 <StyledDataGrid
                                  // onRowClick={gridRowClicked}
                                  sx={{
                                    '& .super-app-theme--header': {
                                      backgroundColor: 'rgba(0, 9, 81)',
                                      color: 'white'
                                    }
                                  }}
                                  rows={NoRecordsFoundRow}
                                  columns={clientListViewColumns}
                                  slots={{ toolbar: GridToolbar }}
                                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                                />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  );
};

export default ManageClients;
