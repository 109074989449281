import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  CircularProgress,
  TextField,
  InputAdornment
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import CustomToolbar from './custom-toolbar'; // Import the custom toolbar
import './UploadFile.css'; // Import the CSS file

const UploadFile = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonsFontSize = '16px';
  const rowsPerPageOptions = [10, 25, 50, 100];
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [fileError, setFileError] = useState('');
  const [priceQuotes, setPriceQuotes] = useState([]);
  const [effectiveDate, setEffectiveDate] = useState('');
  const [discount, setDiscount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');

  // Function to format text to uppercase, handling null or undefined values
  const formatTextToUppercase = (text) => {
    return text ? text.toUpperCase() : '';
  };

  // Handle file change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileError('');
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    event.preventDefault();

    if (!file) {
      setFileError('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    const uploadUrl = `${BACKEND_URL}/v1/upload/priceQuote`;

    setUploading(true);
    setError(null);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResponse(data);
      setFile(null); // Clear the file input after successful upload
      fetchPriceQuotes(); // Fetch price quotes after successful upload
    } catch (err) {
      setError(err.message);
    } finally {
      setUploading(false);
    }
  };

  // Fetch discount details for the logged-in client
  const getDiscountDetailsForClient = async () => {
    let getDiscountDetailsForCustomerUrl = `${BACKEND_URL}/v1/discounts?clientID=${loggedInUserClientId}`;

    try {
      const response = await fetch(getDiscountDetailsForCustomerUrl);
      if (!response.ok) {
        console.error(`Error fetching data for client ${loggedInUserClientId}: ${response.statusText}`);
        return;
      }

      const data = await response.json();
      console.log(`Response data for client ${loggedInUserClientId}:`, data);

      let clientDiscount = data.discount !== undefined ? data.discount : 0;
      setDiscount(clientDiscount);
      console.log('Discount:', clientDiscount);
    } catch (error) {
      console.error(`Error processing client ${loggedInUserClientId}:`, error);
    }
  };

  // Fetch price quotes and calculate savings based on discount
  const fetchPriceQuotes = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/priceQuotes`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Calculate savings based on discount
      const updatedPriceQuotes = data.map((quote) => ({
        ...quote,
        savingsTotal: doNotPerformCustomerFiltration ? quote.savingsTotal : (quote.savingsTotal * (discount / 100)).toFixed(4),
        yourPrice: doNotPerformCustomerFiltration
          ? quote.yourPrice
          : (quote.discRetail - (quote.savingsTotal * (discount / 100)).toFixed(4)).toFixed(4)
      }));

      setPriceQuotes(updatedPriceQuotes);

      // Set the effective date (assuming it's the same for all quotes)
      if (data.length > 0) {
        setEffectiveDate(data[0].effectiveDate); // Adjust based on your actual data structure
      }
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getDiscountDetailsForClient();
  }, []); // Fetch discount details only once on component mount

  useEffect(() => {
    fetchPriceQuotes();
  }, [discount]); // Fetch price quotes when discount changes

  // Add incremental ID to each row and format text
  const formattedRows = priceQuotes.map((quote) => ({
    ...quote,
    // id: index + 1,
    truckShopName: formatTextToUppercase(quote.truckShopName),
    city: formatTextToUppercase(quote.city)
  }));

  // Filter rows based on search query
  const filteredRows = formattedRows.filter((row) => {
    return (
      row.truckShopName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.state.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const columns = [
    { field: 'id', headerName: 'Site ID', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'truckShopName', headerName: 'Truck Shop', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'city', headerName: 'City', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'state', headerName: 'State', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'discRetail', headerName: 'Discount Retail', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'yourPrice', headerName: 'Your Price', flex: 0.1, headerAlign: 'left', align: 'left' },
    { field: 'savingsTotal', headerName: 'Savings Total', flex: 0.1, headerAlign: 'left', align: 'left' }
  ];

  return (
    <div>
      {doNotPerformCustomerFiltration && (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', width: '100%' }}>
          <div className="card" style={{ flex: '1', minWidth: '100%' }}>
            <Typography
              variant="h1"
              sx={{ fontSize: '22px', textAlign: 'center', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' }}
              gutterBottom
            >
              Upload File
            </Typography>
            <Card sx={{ minWidth: '100%', minHeight: 150 }}>
              <CardContent>
                <form id="upload-file-form" onSubmit={handleFileUpload}>
                  <Grid container spacing={2} style={{ marginTop: '2px', marginBottom: '2px' }}>
                    <Box sx={{ width: '250px', margin: '0 auto' }}>
                      <Typography style={{ fontSize: '15px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}>Select PDF File</Typography>
                      <TextField
                        type="file"
                        inputProps={{ accept: '.pdf' }}
                        onChange={handleFileChange}
                        fullWidth
                        helperText={fileError}
                        error={!!fileError}
                        style={{ fontSize: '18px', color: 'rgb(26, 33, 56)', fontWeight: 600 }}
                      />
                      {fileError && <FormHelperText id="file-error-text">{fileError}</FormHelperText>}
                    </Box>
                  </Grid>

                  <Grid container rowSpacing={1.5} columnSpacing={2.75} style={{ marginTop: '2px', marginBottom: '2px' }}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} align="center">
                      <Button
                        style={{ marginTop: '5px', fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: '#011f4b' }}
                        type="submit"
                        variant="contained"
                        disabled={uploading}
                        sx={{ mt: 2, mb: 5, borderRadius: 0 }}
                      >
                        {uploading ? <CircularProgress size={24} color="inherit" /> : 'Upload File'}
                      </Button>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </form>
                {response && (
                  <Box mt={2}>
                    <Typography variant="h6" sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}>
                      Upload Successful
                    </Typography>
                  </Box>
                )}
                {error && (
                  <Box mt={2}>
                    <Typography variant="h6" color="error" sx={{ fontFamily: 'DM Sans, sans-serif', textAlign: 'center' }}>
                      Error: {error}
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      )}

      <Box mt={2}>
        <Typography
          variant="h2"
          sx={{ fontSize: '22px', textAlign: 'center', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' }}
          gutterBottom
        >
          Price Quotes
        </Typography>
      </Box>

      <Box mt={2}>
        <Card>
          <CardContent>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6" sx={{ fontSize: '18px', fontFamily: 'DM Sans, sans-serif', fontWeight: '800' }}>
                  RETAIL PRICES ARE SUBJECT TO CHANGE AT ANY TIME
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Typography variant="h6" sx={{ fontSize: '16px', fontFamily: 'DM Sans, sans-serif', fontWeight: '800' }}>
                  Effective Date: {effectiveDate}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} style={{ marginTop: '15px' }}>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <div style={{ height: 700, maxWidth: '90%', marginTop: '20px', align: 'center' }}>
              <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={rowsPerPageOptions[0]}
                rowsPerPageOptions={rowsPerPageOptions}
                components={{ Toolbar: () => <CustomToolbar showExport={doNotPerformCustomerFiltration} /> }} // Use the custom toolbar
                pagination
                paginationMode="client"
                rowCount={filteredRows.length}
                getRowId={(row) => row.id}
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row')}
                rowHeight={30}
                sx={{
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'rgba(0, 9, 81)',
                    color: 'white'
                  },
                  '& .MuiDataGrid-cell': {
                    textAlign: 'center'
                  },
                  '& .MuiDataGrid-root': {
                    overflowX: 'auto'
                  },
                  '& .MuiDataGrid-row': {
                    height: 'auto'
                  }
                }}
                sortModel={[
                  {
                    field: 'state',
                    sort: 'asc'
                  }
                ]}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default UploadFile;
