import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Alert,
  AlertTitle,
  IconButton,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import { darken, lighten, styled } from '@mui/material/styles';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const UnitForm = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // State management
  const [rawUnitsList, setRawUnitsList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [driversList, setDriversList] = useState([]);
  const [cardsForClient, setCardsForClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [isAddUnitSelected, setIsAddUnitSelected] = useState(false);
  const [isUpdateUnitSelected, setIsUpdateUnitSelected] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [ownershipDocument, setOwnershipDocument] = useState(null);
  const [ownershipFileName, setOwnershipFileName] = useState('');

  // Form states
  const [unitNumber, setUnitNumber] = useState('');
  const [unitNumberError, setUnitNumberError] = useState('');
  const [status, setStatus] = useState('Inactive');
  const [make, setMake] = useState('');
  const [vin, setVin] = useState('');
  const [vinError, setVinError] = useState('');
  const [ownedBy, setOwnedBy] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [assignedDriver, setAssignedDriver] = useState('');

  // Role-based filtration
  const loggedInUserClientId = localStorage.getItem('clientId');
  const loggedInUserName = localStorage.getItem('userName');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';

  const unitStatuses = [
    { id: 'Active', value: 'Active' },
    { id: 'Inactive', value: 'Inactive' },
  ];

  const makes = [
    { id: 'volvo', value: 'Volvo' },
    { id: 'freightline', value: 'Freightline' },
    { id: 'peterbilt', value: 'Peterbilt' },
    { id: 'kenorth', value: 'Kenorth' },
    { id: 'mack', value: 'Mack' },
  ];

  const ownershipTypes = [
    { id: 'Company', value: 'Company' },
    { id: 'Owner operator', value: 'Owner operator' },
  ];

  useEffect(() => {
    console.log('Starting initial data fetch...');
    console.log('localStorage:', {
      clientId: loggedInUserClientId,
      userName: loggedInUserName,
      role: localStorage.getItem('role'),
    });
    Promise.all([getClients(), fetchUnits(), fetchDrivers(), fetchCards()])
      .then(([clientsData, unitsData, driversData, cardsData]) => {
        console.log('All data fetched successfully');
        generateListViewDetails(unitsData);
      })
      .catch((error) => {
        console.error('Error during initial fetch:', error);
        setErrorMessage('Failed to load data: ' + error.message);
        setIsError(true);
        setUnitsList([
          {
            id: '1001',
            unitNumber: '1001',
            vin: '1HGCM82633A004352',
            status: 'ACTIVE',
            make: 'VOLVO',
            ownedBy: 'Company',
            cardNumber: '',
            assignedDriver: '',
            ownershipFileName: 'document.pdf',
          },
        ]);
      });
  }, []);

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }
    const response = await axios.get(getClientsUrl);
    const data = response.data.sort((a, b) => a.clientID - b.clientID);
    console.log('Clients fetched:', data);
    setClients(data);
    return data;
  };

  const fetchCards = async () => {
    const response = await axios.get(`${BACKEND_URL}/v1/card/all`);
    const inactiveCards = response.data.filter((card) => card.status === 'INACTIVE');
    console.log('Cards fetched:', inactiveCards);
    setCardsForClient(inactiveCards);
    return inactiveCards;
  };

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/unit`);
      console.log('Raw units fetched:', response.data);
      setRawUnitsList(response.data || []);
      return response.data || [];
    } catch (error) {
      console.error('Error fetching units:', error);
      return [];
    }
  };

  const fetchDrivers = async () => {
    const response = await axios.get(`${BACKEND_URL}/v1/drivers`);
    console.log('Drivers fetched:', response.data);
    setDriversList(response.data || []);
    return response.data || [];
  };

  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode),
      },
    },
  }));

  const generateListViewDetails = (units) => {
    console.log('Generating list view with units:', units);
    if (!units || units.length === 0) {
      console.log('No units from API, setting empty list');
      setUnitsList([]);
      return;
    }

    const unitListViewDetails = units.map((unit, index) => {
      const transformedUnit = {
        id: unit.unitNumber || `unit-${index}`,
        unitNumber: unit.unitNumber || 'N/A',
        vin: unit.vin || '',
        status: unit.status === true || unit.status === 'Active' ? 'ACTIVE' : 'INACTIVE',
        make: (unit.make || '').toUpperCase(),
        ownedBy: unit.ownedBy || '',
        cardNumber: unit.cardNumber || '',
        assignedDriver: unit.assignedDriver || '',
        ownershipFileName: unit.ownershipFileName || '',
        ownershipDocumentUrl: unit.ownershipDocumentUrl || '',
      };
      console.log(`Transformed unit ${index + 1}:`, transformedUnit);
      return transformedUnit;
    });

    console.log('Final units list for table:', unitListViewDetails);
    setUnitsList(unitListViewDetails);
  };

  const handleUnitNumberChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9]+$/;
    if (regex.test(value)) {
      setUnitNumber(value);
      setUnitNumberError('');
    } else {
      setUnitNumberError('Unit Number must be numeric');
    }
  };

  const handleVinChange = (event) => {
    const value = event.target.value;
    const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
    setVin(value);
    if (!regex.test(value)) {
      setVinError('VIN must be 17 alphanumeric characters');
    } else {
      setVinError('');
    }
  };

  const resetFields = () => {
    setUnitNumber('');
    setUnitNumberError('');
    setStatus('Inactive');
    setMake('');
    setVin('');
    setVinError('');
    setOwnedBy('');
    setCardNumber('');
    setAssignedDriver('');
    setOwnershipDocument(null);
    setOwnershipFileName('');
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      setOwnershipDocument(file);
      setOwnershipFileName(file.name);
    } else {
      setErrorMessage('Please upload a PDF file');
      setIsError(true);
    }
  };

  const downloadOwnershipDocument = async (unitNumber) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/ownership/${unitNumber}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ownership_${unitNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setErrorMessage('Failed to download document: ' + error.message);
      setIsError(true);
    }
  };

  const handleCreateUnit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessage(Object.values(errors).join(', '));
      setIsError(true);
      return;
    }

    if (ownedBy && !ownershipDocument) {
      setErrorMessage('Ownership document is required when ownedBy is specified');
      setIsError(true);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('unitNumber', unitNumber);
      formData.append('status', status === 'Active');
      formData.append('make', make);
      formData.append('vin', vin);
      formData.append('cardNumber', cardNumber);
      formData.append('assignedDriver', assignedDriver);
      formData.append('ownedBy', ownedBy);
      formData.append('ownershipDocument', ownershipDocument);
      formData.append('ownershipFileName', ownershipFileName);

      console.log('Creating unit with data:', formData);
      await axios.post(`${BACKEND_URL}/v1/addunit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const updatedUnits = await fetchUnits();
      generateListViewDetails(updatedUnits);
      setSuccessMessage('Unit created successfully!');
      setIsSuccess(true);
      setIsError(false);
      resetFields();
      window.location.reload();
    } catch (error) {
      setErrorMessage('Failed to create unit: ' + (error.response?.data?.message || error.message));
      setIsError(true);
      setIsSuccess(false);
    }
  };

  const handleUpdateUnit = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessage(Object.values(errors).join(', '));
      setIsError(true);
      return;
    }

    try {
      const formData = new FormData();

      // Create the unit object as per the backend's expectation
      const unitData = {
        unitNumber: unitNumber,
        status: status === 'Active',
        make: make,
        vin: vin,
        cardNumber: cardNumber,
        assignedDriver: assignedDriver,
        ownedBy: ownedBy,
      };

      // Append the unit data as a JSON string under the 'unit' key
      formData.append('unit', new Blob([JSON.stringify(unitData)], { type: 'application/json' }));

      // Append the ownership file if a new one was selected
      if (ownershipDocument) {
        formData.append('ownershipFile', ownershipDocument);
      }

      console.log('Updating unit with data:', unitData);
      const response = await axios.put(`${BACKEND_URL}/v1/${unitNumber}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Update response:', response.data);
      const updatedUnits = await fetchUnits();
      generateListViewDetails(updatedUnits);
      setSuccessMessage('Unit updated successfully!');
      setIsSuccess(true);
      setIsError(false);
      resetFields();
      window.location.reload();
    } catch (error) {
      console.error('Error updating unit:', error);
      setErrorMessage('Failed to update unit: ' + (error.response?.data?.message || error.message));
      setIsError(true);
      setIsSuccess(false);
    }
  };

  const handleUpdateUnitSelectionChange = (event) => {
    const selectedUnitNumber = event.target.value;
    setUnitNumber(selectedUnitNumber);
    const unit = rawUnitsList.find((u) => u.unitNumber === selectedUnitNumber);
    if (unit) {
      console.log('Selected unit for update:', unit);
      setStatus(unit.status === true || unit.status === 'Active' ? 'Active' : 'Inactive');
      setMake(unit.make || '');
      setVin(unit.vin || '');
      setOwnedBy(unit.ownedBy || '');
      setCardNumber(unit.cardNumber || '');
      setAssignedDriver(unit.assignedDriver || '');
      setOwnershipFileName(unit.ownershipFileName || '');
    }
  };

  const handleDelete = async (unitNumber) => {
    const confirmation = window.confirm(`Are you sure you want to delete unit: ${unitNumber}?`);
    if (confirmation) {
      try {
        const response = await axios.post(
          `${BACKEND_URL}/v1/send-unit-deletion-approval-email?unitNumber=${unitNumber}`,
        );
        setSuccessMessage(response.data.message || 'Approval email sent successfully!');
        setIsSuccess(true);
        setIsError(false);
        window.location.reload();
      } catch (error) {
        setErrorMessage('Failed to send approval email: ' + error.message);
        setIsError(true);
        setIsSuccess(false);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!unitNumber) errors.unitNumber = 'Unit Number is required';
    if (!vin) errors.vin = 'VIN is required';
    if (!make) errors.make = 'Make is required';
    if (!ownedBy) errors.ownedBy = 'Owned By is required';
    return errors;
  };

  const columns = [
    {
      field: 'unitNumber',
      headerName: 'UNIT #',
      width: 120,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'vin',
      headerName: 'VIN #',
      width: 150,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 100,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'make',
      headerName: 'MAKE',
      width: 140,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'ownedBy',
      headerName: 'OWNED BY',
      width: 140,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'cardNumber',
      headerName: 'CARD #',
      width: 140,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'assignedDriver',
      headerName: 'ASSIGNED DRIVER',
      width: 180,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'ownershipDocument',
      headerName: 'OWNERSHIP DOC',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        params.row.ownershipFileName ? (
          <IconButton onClick={() => downloadOwnershipDocument(params.row.unitNumber)} size="small">
            <DownloadIcon fontSize="small" />
          </IconButton>
        ) : null,
    },
  ];

  const buttonsFontSize = '16px';
  const inputLabelStyles = {
    fontSize: '10px',
    color: 'rgb(26, 33, 56)',
  };

  return (
    <Container maxWidth="lg" style={{ paddingTop: '20px' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          textTransform: 'uppercase',
          fontSize: '14px',
          marginTop: '-8px',
        }}
      >
        Manage Units
      </Typography>

      {isSuccess && (
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setIsSuccess(false)}
          sx={{ mb: 2, fontSize: '16px', textAlign: 'center' }}
        >
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      )}
      {isError && (
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setIsError(false)}
          sx={{ mb: 2, fontSize: '16px', textAlign: 'center' }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Button
            style={{
              fontSize: buttonsFontSize,
              backgroundColor: '#31478F',
            }}
            variant="contained"
            sx={{
              mr: 2,
              mb: 3,
              borderRadius: 2,
              padding: '11px 18px',
              fontSize: '10px',
              height: '30px',
            }}
            onClick={() => {
              setIsAddUnitSelected(true);
              setIsUpdateUnitSelected(false);
              resetFields();
            }}
          >
            <Typography sx={{ fontSize: '10px' }}>New Unit</Typography>
          </Button>
          <Button
            style={{
              backgroundColor: '#31478F',
            }}
            variant="contained"
            sx={{
              mr: 2,
              mb: 3,
              borderRadius: 2,
              padding: '6px 12px',
              fontSize: '12px',
              height: '30px',
            }}
            onClick={() => {
              setIsAddUnitSelected(false);
              setIsUpdateUnitSelected(true);
              resetFields();
            }}
          >
            <Typography sx={{ fontSize: '10px' }}>Update Unit</Typography>
          </Button>
        </Grid>
      </Grid>

      {isAddUnitSelected && (
        <form onSubmit={handleCreateUnit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Customer Account"
                fullWidth
                value={`${loggedInUserClientId}-${loggedInUserName}`}
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 25,
                    minHeight: 25,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" error={!!unitNumberError}>
                <TextField
                  label="Unit #"
                  value={unitNumber}
                  onChange={handleUnitNumberChange}
                  required
                  error={!!unitNumberError}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 25,
                      minHeight: 25,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                />
                {unitNumberError && <FormHelperText>{unitNumberError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Status</InputLabel>
                <Select
                  label="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  {unitStatuses.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Make</InputLabel>
                <Select
                  label="make"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                  required
                >
                  {makes.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" error={!!vinError}>
                <TextField
                  label="VIN #"
                  value={vin}
                  onChange={handleVinChange}
                  required
                  error={!!vinError}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-40%',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 26,
                      minHeight: 26,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                />
                {vinError && <FormHelperText>{vinError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Owned By</InputLabel>
                <Select
                  label="Owned By"
                  value={ownedBy}
                  onChange={(e) => setOwnedBy(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                  required
                >
                  {ownershipTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Card #</InputLabel>
                <Select
                  label="Card #"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a card</em>
                  </MenuItem>
                  {cardsForClient.map((card) => (
                    <MenuItem key={card.cardNumber} value={card.cardNumber}>
                      {card.cardNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Assigned Driver</InputLabel>
                <Select
                  label="Assigned Driver"
                  value={assignedDriver}
                  onChange={(e) => setAssignedDriver(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a driver</em>
                  </MenuItem>
                  {driversList.map((driver) => (
                    <MenuItem key={driver.id} value={driver.driverName}>
                      {driver.driverName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  fontSize: '10px',
                  height: '30px',
                  backgroundColor: '#31478F',
                }}
              >
                Upload Ownership PDF
                <input
                  type="file"
                  hidden
                  accept="application/pdf"
                  onChange={handleFileChange}
                  required={ownedBy !== ''}
                />
              </Button>
              {ownershipFileName && (
                <Typography variant="caption" sx={{ ml: 1, fontSize: '10px' }}>
                  {ownershipFileName}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Box mt={2} textAlign="center">
            <Button
              style={{
                fontSize: buttonsFontSize,
                backgroundColor: '#31478F',
                color: 'white',
                height: '30px',
                fontSize: '10px',
              }}
              variant="contained"
              sx={{
                mr: 2,
                borderRadius: '5px',
                padding: '5px 10px',
                color: 'white',
              }}
              type="submit"
            >
              Create
            </Button>
          </Box>
        </form>
      )}

      {isUpdateUnitSelected && (
        <form onSubmit={handleUpdateUnit}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Customer Account"
                fullWidth
                value={`${loggedInUserClientId}-${loggedInUserName}`}
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 25.5,
                    minHeight: 25.5,
                  },
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Unit #</InputLabel>
                <Select
                  label="Unit #"
                  value={unitNumber}
                  onChange={handleUpdateUnitSelectionChange}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a unit</em>
                  </MenuItem>
                  {rawUnitsList.map((unit) => (
                    <MenuItem key={unit.unitNumber} value={unit.unitNumber}>
                      {unit.unitNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Status</InputLabel>
                <Select
                  label="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  {unitStatuses.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Make</InputLabel>
                <Select
                  label="Make"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  required
                >
                  {makes.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      {m.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" error={!!vinError}>
                <TextField
                  label="VIN #"
                  value={vin}
                  onChange={handleVinChange}
                  required
                  error={!!vinError}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-40%',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 25.3,
                      minHeight: 25.3,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                />
                {vinError && <FormHelperText>{vinError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Owned By</InputLabel>
                <Select
                  label="Owned By"
                  value={ownedBy}
                  onChange={(e) => setOwnedBy(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  required
                >
                  {ownershipTypes.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Card #</InputLabel>
                <Select
                  label="Card #"
                  value={cardNumber}
                  onChange={(e) => setCardNumber(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a card</em>
                  </MenuItem>
                  {cardsForClient.map((card) => (
                    <MenuItem key={card.cardNumber} value={card.cardNumber}>
                      {card.cardNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Assigned Driver</InputLabel>
                <Select
                  label="Assigned Driver"
                  value={assignedDriver}
                  onChange={(e) => setAssignedDriver(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a driver</em>
                  </MenuItem>
                  {driversList.map((driver) => (
                    <MenuItem key={driver.id} value={driver.driverName}>
                      {driver.driverName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  fontSize: '10px',
                  height: '30px',
                  backgroundColor: '#31478F',
                }}
              >
                Upload New PDF
                <input type="file" hidden accept="application/pdf" onChange={handleFileChange} />
              </Button>
              {ownershipFileName && (
                <Typography variant="caption" sx={{ ml: 1, fontSize: '10px' }}>
                  {ownershipFileName}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box mt={2} textAlign="center">
            <Button
              style={{
                fontSize: buttonsFontSize,
                backgroundColor: '#31478F',
                color: 'white',
                height: '30px',
                fontSize: '10px',
              }}
              variant="contained"
              sx={{
                mr: 2,
                borderRadius: '5px',
                padding: '5px 10px',
                color: 'white',
              }}
              type="submit"
              disabled={!unitNumber}
            >
              Update
            </Button>
          </Box>
        </form>
      )}

      <Box sx={{ marginTop: '30px' }}>
        {/* <Typography variant="h6" gutterBottom>
          Units List
        </Typography> */}
        {unitsList.length > 0 ? (
          <StyledDataGrid
            rows={unitsList}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            slots={{ toolbar: CustomToolbar }}
            getRowId={(row) => row.unitNumber}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            columnHeaderHeight={31} // Match DriverForm header height
            rowHeight={22}
            sx={{
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#31478F', // Match DriverForm header background color
                color: 'white', // Header text color
                fontSize: '10px', // Match DriverForm header font size
              },
              '& .MuiDataGrid-cell': {
                padding: '2px 6px', // Match DriverForm row padding
                fontSize: '10px', // Match DriverForm cell font size
              },
              '& .MuiDataGrid-iconButton': {
                fontSize: '12px', // Match DriverForm toolbar icon size
                color: 'grey', // Match DriverForm icon color
              },
              '& .MuiDataGrid-row': {
                minHeight: '15px', // Match DriverForm row height
              },
              '& .MuiDataGrid-row.even': {
                backgroundColor: '#F8F8F8', // Match DriverForm even row background color
              },
              '& .MuiDataGrid-row.odd': {
                backgroundColor: '#E6F0FF', // Match DriverForm odd row background color
              },
              // Media queries for responsiveness (aligned with DriverForm)
              '@media (max-width: 768px)': {
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '10px', // Match DriverForm mobile header font size
                },
                '& .MuiDataGrid-cell': {
                  padding: '2px 4px', // Match DriverForm mobile padding
                  fontSize: '10px', // Match DriverForm mobile cell font size
                },
                '& .MuiDataGrid-iconButton': {
                  fontSize: '10px', // Match DriverForm mobile icon size
                },
                '& .MuiDataGrid-row': {
                  minHeight: '25px', // Match DriverForm mobile row height
                },
              },
              '@media (max-width: 480px)': {
                '& .MuiDataGrid-columnHeaders': {
                  fontSize: '8px', // Match DriverForm smaller screen header font size
                },
                '& .MuiDataGrid-cell': {
                  padding: '1px 2px', // Match DriverForm smaller screen padding
                  fontSize: '8px', // Match DriverForm smaller screen cell font size
                },
                '& .MuiDataGrid-iconButton': {
                  fontSize: '8px', // Match DriverForm smaller screen icon size
                },
                '& .MuiDataGrid-row': {
                  minHeight: '24px', // Match DriverForm smaller screen row height
                },
              },
            }}
          />
        ) : (
          <Typography></Typography>
        )}
      </Box>
    </Container>
  );
};

export default UnitForm;
