import * as React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { darken, lighten, styled } from '@mui/material/styles';
import { useEffect } from 'react';
import FuelCard from '../fuel-card/detailed-view';
import CloseIcon from '@mui/icons-material/Close';
import WorkIcon from '@mui/icons-material/Work';
import BadgeIcon from '@mui/icons-material/Badge';
import InfoIcon from '@mui/icons-material/Info';

const ClientDetailedView = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = React.useState(false);
  const [clientId, setClientId] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [customerName, setCustomerName] = React.useState('');
  const [isDetailedViewOpen, setIsDetailedViewOpen] = React.useState(false);
  const [cardsListView, setCardsListView] = React.useState([]);
  const [currentRowDetails, setCurrentRowDetails] = React.useState({});
  const location = useLocation();
  const fontStylesChartTypography = {
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const gridRowStyles = { marginTop: '10px', marginBottom: '10px' };

  const cardsListViewColumns = [
    {
      field: 'id',
      headerName: 'Serial No.',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardNumber',
      headerName: 'Card Number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'unitNumber',
      headerName: 'Unit Number',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'driver',
      headerName: 'Driver',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'cardStatus',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
  ];

  const NoRecordsFoundRow = [
    {
      id: '',
      cardNumber: '',
      unitNumber: 'No records found',
      driver: '',
      cardStatus: '',
    },
  ];

  useEffect(() => {
    let clientData = location.state.chosenClient;
    if (clientData) {
      let client = clientData.accountNumber;
      let customerId = clientData.customerId;
      let customerName = clientData.customerName;
      setClientId(client);
      setCustomerId(customerId);
      setCustomerName(customerName);

      let getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/card?clientID=${client}`;
      setLoading(true);

      fetch(getCardDetailsForCustomerUrl)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          generateListView(data);
        })
        .then(() => setLoading(false));
    }
  }, []);

  const cardStatuses = [
    {
      id: 1,
      value: 'Active',
      shortForm: 'ACTIVE',
    },
    {
      id: 2,
      value: 'Blocked',
      shortForm: 'INACTIVE',
    },
  ];

  const generateListView = async (cards) => {
    let cardsList = [];
    let cardSerialNo = 1;

    await cards.map((card) => {
      let cardStatus = cardStatuses.filter((status) => status.shortForm === card?.status);
      let cardObject = {
        id: cardSerialNo++,
        cardNumber: card.cardNumber,
        unitNumber: card.unitNumber,
        driver: `${card?.driverName?.trim()}` === 'undefined' ? '' : `${card?.driverName?.trim()}`,
        // cardStatus: cardStatus[0]?.value,
        cardStatus: `${card?.status}` ? cardStatus[0]?.value : '',
      };
      cardsList.push(cardObject);
    });
    setCardsListView(cardsList);
  };

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    // '& .MuiDataGrid-cell:hover': {
    // cursor: 'pointer',
    // },
    // '& .even': {
    // backgroundColor: 'rgb(230, 238, 242)',
    // '&:hover': {
    //     backgroundColor: getHoverBackgroundColor(
    //     'rgb(230, 238, 242)',
    //     theme.palette.mode,
    //     ),
    // }
    // },
    // '& .odd': {
    // backgroundColor: 'rgb(216, 233, 242)',
    // '&:hover': {
    //     backgroundColor: getHoverBackgroundColor(
    //     'rgb(216, 233, 242)',
    //     theme.palette.mode,
    //     ),
    // }
    // }
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Active': {
      backgroundColor: getBackgroundColor(theme.palette.success.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.success.main, theme.palette.mode),
      },
    },
    '& .super-app-theme--Unassigned': {
      backgroundColor: 'rgb(255, 241, 172)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(255, 241, 172)', theme.palette.mode),
      },
    },
    '& .super-app-theme--Blocked': {
      backgroundColor: getBackgroundColor(theme.palette.error.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      fontSize: '10px', // Reduce font size of header text
      backgroundColor: '#31478F', // Set custom header background color
      color: 'white', // Set font color to white for better contrast
      textAlign: 'center',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '10px', // Reduced font size for table cells
      textAlign: 'center',
    },
  }));

  const gridCardRowClicked = (event) => {
    let rowDetails = event?.row;
    setCurrentRowDetails(rowDetails);
    setIsDetailedViewOpen(!isDetailedViewOpen);
  };

  return (
    <div>
      <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box style={{ display: 'flex' }}>
                <WorkIcon sx={{ fontSize: 15 }} />
                &nbsp;
                <Typography variant="h6" sx={{ fontSize: 10 }}>
                  CUSTOMER NAME
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="h6" sx={{ fontSize: 10 }}>
                {customerName}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box style={{ display: 'flex' }}>
                <BadgeIcon sx={{ fontSize: 15 }} />
                &nbsp;
                <Typography variant="h6" sx={{ fontSize: 10 }}>
                  CUSTOMER ID
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="h6" sx={{ fontSize: 10 }}>
                {clientId}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Box style={{ display: 'flex' }}>
                <InfoIcon sx={{ fontSize: 15 }} />
                &nbsp;
                <Typography variant="h6" sx={{ fontSize: 10 }}>
                  CUSTOMER REFERENCE
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography variant="h6" sx={{ fontSize: 10 }}>
                {customerId}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography variant="h5" style={fontStylesChartTypography}>
                      CARD DETAILS
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <CircularProgress color="success" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : cardsListView.length == 0 ? (
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1', margin: '2px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                {/* <Card>
                  <CardContent> */}
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography variant="h5" style={fontStylesChartTypography}>
                      CARD DETAILS
                    </Typography>
                  </Grid>
                </Grid>
                <div
                  style={{
                    height: 700,
                    maxWidth: '95%',
                    marginTop: '20px',
                    align: 'center',
                  }}
                >
                  <StyledDataGrid
                    sx={{
                      '& .super-app-theme--header': {
                        backgroundColor: ' #31478F',
                        color: 'white',
                      },
                      '& .MuiDataGrid-columnHeaders': {
                        fontSize: '10px', // Reduce font size of header text
                        backgroundColor: '#31478F', // Set custom header background color
                        color: 'white', // Set font color to white for better contrast
                        textAlign: 'center',
                      },
                      '& .MuiDataGrid-cell': {
                        fontSize: '10px', // Reduced font size for table cells
                        textAlign: 'center',
                      },
                      '& .MuiDataGrid-toolbar': {
                        fontSize: '8px', // Reduce font size of the toolbar (if applicable)
                      },
                      '& .MuiGridToolbar-root': {
                        fontSize: '8px', // Directly target the GridToolbar's root element if the above doesn't work
                      },
                      '& .MuiGridToolbar-icon': {
                        fontSize: '8px', // Optional: target individual toolbar icons to make them smaller as well
                      },
                      '& .MuiGridToolbar-button': {
                        fontSize: '8px', // Optional: target toolbar buttons to reduce font size
                      },
                    }}
                    rows={NoRecordsFoundRow}
                    columns={cardsListViewColumns}
                    slots={{ toolbar: GridToolbar }}
                    rowHeight={25}
                    columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                    autoHeight
                    getRowClassName={(params) =>
                      params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                  />
                </div>
                {/* </CardContent>
                </Card> */}
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        cardsListView.length > 0 && (
          <div className="container" style={{ display: 'flex' }}>
            <div className="card" style={{ flex: '1', margin: '2px' }}>
              <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                  {/* <Card>
                    <CardContent> */}
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Typography variant="h5" style={fontStylesChartTypography}>
                        CARD DETAILS
                      </Typography>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      height: 700,
                      maxWidth: '95%',
                      marginTop: '20px',
                      align: 'center',
                    }}
                  >
                    <StyledDataGrid
                      onRowClick={gridCardRowClicked}
                      sx={{
                        '& .super-app-theme--header': {
                          backgroundColor: 'rgba(0, 9, 81)',
                          color: 'white',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                          fontSize: '10px', // Reduce font size of header text
                          backgroundColor: '#31478F', // Set custom header background color
                          color: 'white', // Set font color to white for better contrast
                          textAlign: 'center',
                        },
                        '& .MuiDataGrid-cell': {
                          fontSize: '10px', // Reduced font size for table cells
                          textAlign: 'center',
                        },
                        '& .MuiDataGrid-toolbar': {
                          fontSize: '8px', // Reduce font size of the toolbar (if applicable)
                        },
                        '& .MuiGridToolbar-root': {
                          fontSize: '8px', // Directly target the GridToolbar's root element if the above doesn't work
                        },
                        '& .MuiGridToolbar-icon': {
                          fontSize: '8px', // Optional: target individual toolbar icons to make them smaller as well
                        },
                        '& .MuiGridToolbar-button': {
                          fontSize: '8px', // Optional: target toolbar buttons to reduce font size
                        },
                      }}
                      rows={cardsListView}
                      columns={cardsListViewColumns}
                      slots={{ toolbar: GridToolbar }}
                      rowHeight={25}
                      columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                      autoHeight
                      // getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                      getRowClassName={(params) => `super-app-theme--${params.row.cardStatus}`}
                    />
                  </div>
                  {/* </CardContent>
                  </Card> */}
                </Grid>
              </Grid>
            </div>
          </div>
        )
      )}

      <div>
        <Dialog fullScreen open={isDetailedViewOpen} onClose={() => setIsDetailedViewOpen(false)}>
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div"></Typography>
              <IconButton
                edge="end"
                color="inherit"
                onClick={() => setIsDetailedViewOpen(false)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            {currentRowDetails && (
              <FuelCard
                inputCardNumber={currentRowDetails.cardNumber}
                inputClientId={clientId}
                inputCustomerId={customerId}
                inputCustomerName={customerName}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default ClientDetailedView;
