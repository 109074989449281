import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  InputLabel,
  Grid,
  Chip,
  Select,
  FormControl,
  Paper,
  Card,
  IconButton,
  Collapse,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Divider,
  TextField,
  DialogContentText,
  TablePagination,
  ButtonGroup,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
//import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const BrokerList = () => {
  const [brokers, setBrokers] = useState([]);
  // const [buttonClicked, setButtonClicked] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formVisible, setFormVisible] = useState(false);
  const [expandedBroker, setExpandedBroker] = useState({});
  const [newSelectedClient, setNewSelectedClient] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // const [brokerToDelete, setBrokerToDelete] = useState(null);
  const [, setClientToDelete] = useState(null);
  const [filterType] = useState('today');
  const [brokerId, setBrokerId] = useState('');
  const [selectedClients, setSelectedClients] = useState([]);
  const [clients, setClients] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [brokerData, setBrokerData] = useState({});
  const [updateFormVisible, setUpdateFormVisible] = useState(false);

  // const navigate = useNavigate();

  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${BACKEND_URL}/v1/brokers`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Error fetching brokers: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }
      const data = await response.json();
      setBrokers(data);
    } catch (error) {
      console.error('Error fetching brokers:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignClients = (brokerId) => {
    setFormVisible(true);
    setUpdateFormVisible(false); // Ensure update form is hidden
    setBrokerId(brokerId);
  };

  const handleSelectChange = (e) => {
    setSelectedClients(e.target.value);
  };

  const fetchBrokerClients = async (brokerId) => {
    try {
      const response = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`);
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Error fetching broker clients: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }

      const brokerClients = await response.json();
      return brokerClients.length === 0 ? [] : brokerClients;
    } catch (error) {
      console.error('Error fetching broker clients:', error);
      setSnackbarMessage('An error occurred while fetching broker clients.');
      setSnackbarOpen(true);
      throw error;
    }
  };

  const handleAssignClient = async () => {
    if (!brokerId || !selectedClients.length) {
      setSnackbarMessage('Please select both a broker and at least one client.');
      setSnackbarOpen(true);
      return;
    }

    try {
      const brokersResponse = await fetch(`${BACKEND_URL}/v1/brokers`);
      if (!brokersResponse.ok) {
        const errorMessage = await brokersResponse.text();
        throw new Error(
          `Error fetching brokers: ${brokersResponse.status} ${brokersResponse.statusText} - ${errorMessage}`,
        );
      }

      const allBrokers = await brokersResponse.json();
      const assignedToOtherBroker = selectedClients.filter((clientId) =>
        allBrokers.some(
          (broker) =>
            broker.id !== brokerId &&
            Array.isArray(broker.clients) &&
            broker.clients.some((client) => client.clientID === clientId),
        ),
      );

      if (assignedToOtherBroker.length > 0) {
        setSnackbarMessage('One or more clients are already assigned to another broker!');
        setSnackbarOpen(true);
        return;
      }

      const brokerClients = await fetchBrokerClients(brokerId);
      const hasExistingClients = brokerClients && brokerClients.length > 0;

      let clientsToSend;
      if (!hasExistingClients) {
        clientsToSend = [...selectedClients]; // Copy to avoid mutation
      } else {
        const existingClientIds = brokerClients.map((client) => client.clientID);
        const newClients = selectedClients.filter(
          (clientId) => !existingClientIds.includes(clientId),
        );
        clientsToSend = [...existingClientIds, ...newClients]; // Merge existing and new IDs
      }

      console.log('Sending client IDs:', clientsToSend);

      const assignClientResponse = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(clientsToSend),
      });

      if (!assignClientResponse.ok) {
        const errorMessage = await assignClientResponse.text();
        throw new Error(
          `Error assigning clients: ${assignClientResponse.status} ${assignClientResponse.statusText} - ${errorMessage}`,
        );
      }

      await fetchBrokers();
      setSnackbarMessage('Clients assigned successfully!');
      setSnackbarOpen(true);
      window.location.reload();
    } catch (error) {
      console.error('Error assigning clients:', error);
      setError(error.message);
      setSnackbarMessage('An error occurred while assigning the clients.');
      setSnackbarOpen(true);
    }
  };

  const handleUpdateBroker = () => {
    setUpdateFormVisible(true);
    setFormVisible(false); // Ensure assign clients form is hidden
  };

  const handleUpdateSubmit = async () => {
    if (!selectedBroker || !selectedBroker.id) {
      setSnackbarMessage('Please select a broker to update.');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/v1/brokers/${selectedBroker.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedBroker),
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Error updating broker: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }

      await response.json();
      setSnackbarMessage('Broker updated successfully!');
      setSnackbarOpen(true);
      setUpdateFormVisible(false);
      fetchBrokers();
      window.location.reload();
    } catch (error) {
      console.error('Error updating broker:', error);
      setSnackbarMessage(error.message || 'Failed to update broker');
      setSnackbarOpen(true);
    }
  };

  const handleExpandClick = async (brokerId) => {
    setExpandedBroker((prevState) => ({
      ...prevState,
      [brokerId]: !prevState[brokerId],
    }));

    if (!newSelectedClient[brokerId]) {
      try {
        const response = await fetch(`${BACKEND_URL}/v1/broker/${brokerId}/clients`);
        if (!response.ok) throw new Error('Failed to fetch clients');
        const clients = await response.json();

        setNewSelectedClient((prevClients) => ({
          ...prevClients,
          [brokerId]: clients,
        }));

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        for (const client of clients) {
          const clientId = client.clientID;
          const totalVolumeResponse = await fetch(
            `${BACKEND_URL}/v1/${clientId}/quantity?type=total`,
          );
          const monthlyVolumeResponse = await fetch(
            `${BACKEND_URL}/v1/${clientId}/quantity?type=monthly&month=${currentMonth}&year=${currentYear}`,
          );
          const totalCommissionResponse = await fetch(
            `${BACKEND_URL}/v1/${brokerId}/commissions?type=total`,
          );
          const monthlyCommissionResponse = await fetch(
            `${BACKEND_URL}/v1/${brokerId}/commissions?type=monthly`,
          );

          if (
            !totalVolumeResponse.ok ||
            !monthlyVolumeResponse.ok ||
            !totalCommissionResponse.ok ||
            !monthlyCommissionResponse.ok
          ) {
            throw new Error('Failed to fetch one or more data sets');
          }

          const totalVolume = await totalVolumeResponse.json();
          const monthlyVolume = await monthlyVolumeResponse.json();
          const totalCommission = await totalCommissionResponse.json();
          const monthlyCommission = await monthlyCommissionResponse.json();

          setBrokerData((prevData) => ({
            ...prevData,
            [clientId]: {
              totalVolume: totalVolume || 0,
              monthlyVolume: monthlyVolume || 0,
              totalCommission: totalCommission.total || 0,
              monthlyCommission: monthlyCommission.monthly || 0,
            },
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/v1/client`);
        const data = await response.json();
        setClients(data);
      } catch (err) {
        setError('Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    };
    fetchClients();
  }, []);

  const handleDelete = async (broker) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this broker?');
    if (isConfirmed) {
      try {
        const response = await fetch(
          `${BACKEND_URL}/v1/send-broker-deletion-approval-email?brokerId=${broker.id}`,
          {
            method: 'POST',
          },
        );
        if (!response.ok) throw new Error('Failed to send approval email');
        const data = await response.json();
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
        window.alert('Approval email sent successfully');
      } catch (error) {
        setSnackbarMessage('Failed to send approval email. Please try again.');
        setSnackbarOpen(true);
        window.alert('Failed to send approval email');
      }
    }
  };

  const handleClientDelete = async (brokerId, clientId) => {
    // Ask for confirmation before proceeding with delete
    const confirmed = window.confirm('Are you sure you want to delete this client?');

    if (!confirmed) {
      return; // Exit the function if the user clicks "Cancel"
    }

    try {
      const response = await fetch(`${BACKEND_URL}/v1/${brokerId}/clients/${clientId}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setNewSelectedClient((prevClients) => ({
          ...prevClients,
          [brokerId]: prevClients[brokerId].filter((client) => client.clientID !== clientId),
        }));
        setSnackbarMessage('Client removed successfully!');
        setSnackbarOpen(true);
      } else if (response.status === 404) {
        setSnackbarMessage('Client or Broker not found.');
        setSnackbarOpen(true);
      } else {
        const errorMessage = await response.text();
        throw new Error(
          `Error deleting client: ${response.status} ${response.statusText} - ${errorMessage}`,
        );
      }
    } catch (error) {
      console.error('Error deleting client:', error);
      setError('Error deleting client');
    } finally {
      setClientToDelete(null);
    }
  };

  const handleTransactionExpandClick = async (brokerId, clientId) => {
    if (transactionData[clientId]) {
      setTransactionData((prev) => ({ ...prev, [clientId]: null }));
    } else {
      try {
        const response = await fetch(`${BACKEND_URL}/v1/transactions/companyXRef/${clientId}`);
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const transactions = await response.json();
        setTransactionData((prev) => ({ ...prev, [clientId]: transactions }));
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError('Error fetching transactions: ' + error.message);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedBrokers = brokers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box p={3}>
      {/* Show loading or error only when neither form is visible */}
      {!formVisible && !updateFormVisible && loading && !brokers.length && <CircularProgress />}
      {!formVisible && !updateFormVisible && !loading && error && (
        <Typography color="error">{error}</Typography>
      )}

      {/* Header and buttons - hidden when either form is visible */}
      {!formVisible && !updateFormVisible && (
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography
            variant="h5"
            mt={4}
            mb={2}
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              fontFamily: 'sans-serif',
              textTransform: 'uppercase',
            }}
          >
            Brokers List
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="contained"
              onClick={handleUpdateBroker}
              sx={{
                padding: '2px 6px',
                backgroundColor: '#31478F',
                fontFamily: 'sans-serif',
                fontSize: '10px',
                marginRight: '10px',
                '&:hover': {
                  backgroundColor: '#31478F', // Keeps the same color on hover
                },
              }}
            >
              Update Broker
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleAssignClients(1)}
              style={{
                fontFamily: 'sans-serif',
                fontSize: '10px',
                height: '22px',
                backgroundColor: '#31478F',
                color: 'white',
              }}
            >
              Assign Clients
            </Button>
          </Box>
        </Box>
      )}

      {/* Assign Clients Form - shown only when formVisible is true */}
      {formVisible && (
        <Box>
          {/* <Card
            variant="outlined"
            sx={{ width: "800px", padding: 3, justifyContent: "center" }}
          > */}
          <CardContent>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                textTransform: 'uppercase',
                marginTop: '-30px',
                marginBottom: '20px',
              }}
            >
              Manage Broker Clients
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <InputLabel id="brokerDropdown">Select Broker</InputLabel>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                      }}
                    />
                  ) : error ? (
                    <Typography color="error" align="center">
                      {`Error: ${error}`}
                    </Typography>
                  ) : (
                    <Select
                      labelId="brokerDropdown"
                      id="brokerDropdown"
                      value={brokerId}
                      label="Select Broker"
                      onChange={(e) => setBrokerId(e.target.value)}
                      disabled={brokers.length === 0}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: '200px',
                            minHeight: '100px',
                            '& .MuiMenuItem-root': {
                              fontSize: '10px',
                              marginLeft: '5px',
                              padding: '5px 15px',
                            },
                          },
                        },
                      }}
                    >
                      {brokers.map((broker) => (
                        <MenuItem key={broker.id} value={broker.id}>
                          {`${broker.id} - ${broker.brokerName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <InputLabel
                    id="clientDropdown"
                    sx={{
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    }}
                  >
                    Select Client
                  </InputLabel>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                      }}
                    />
                  ) : error ? (
                    <Typography color="error" align="center">
                      {`Error: ${error}`}
                    </Typography>
                  ) : (
                    <Select
                      labelId="clientDropdown"
                      id="clientDropdown"
                      value={selectedClients}
                      label="Select Client"
                      multiple
                      onChange={handleSelectChange}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: '200px',
                            minHeight: '100px',
                            '& .MuiMenuItem-root': {
                              fontSize: '10px',
                              marginLeft: '5px',
                              padding: '5px 15px',
                            },
                          },
                        },
                      }}
                    >
                      {clients.map((client) => (
                        <MenuItem key={client.clientID} value={client.clientID}>
                          {`${client.clientID} - ${client.clientName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {selectedClients.length > 0 && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      fontFamily: 'sans-serif',
                    }}
                  >
                    Selected Clients:
                  </Typography>
                )}
                <div>
                  {selectedClients.map((clientID) => {
                    const client = clients.find((client) => client.clientID === clientID);
                    return (
                      client && (
                        <Chip
                          key={client.clientID}
                          label={`${client.clientID} - ${client.clientName}`}
                          sx={{ margin: '4px' }}
                        />
                      )
                    );
                  })}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Button
                  style={{
                    marginTop: '-15px',
                    fontFamily: 'sans-serif',
                    fontSize: '10px',
                    backgroundColor: '#31478F',
                    marginLeft: '190px',
                  }}
                  variant="contained"
                  onClick={handleAssignClient}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#31478F', // Prevent hover effect by keeping the same color
                    },
                  }}
                >
                  Assign Client
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setFormVisible(false)}
                  sx={{
                    mt: 2,
                    ml: 2,
                    backgroundColor: '#E8F0FE',
                    marginTop: '-14px',
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          {/* </Card> */}
        </Box>
      )}

      {/* Update Broker Form - shown only when updateFormVisible is true */}
      {updateFormVisible && (
        <Box display="flex" justifyContent="center" sx={{ mb: 4 }}>
          {/* <Card variant="outlined" sx={{ width: "800px", padding: 3 }}> */}
          <CardContent>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                fontSize: '14px',
                marginTop: '-30px',
                textTransform: 'uppercase',
                marginBottom: '20px',
              }}
            >
              Update Broker Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <InputLabel
                    id="updateBrokerDropdown"
                    sx={{
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    }}
                  >
                    Select Broker
                  </InputLabel>
                  <Select
                    labelId="updateBrokerDropdown"
                    id="updateBrokerDropdown"
                    value={selectedBroker ? selectedBroker.id : ''}
                    label="Select Broker"
                    onChange={(e) => {
                      const broker = brokers.find((b) => b.id === e.target.value);
                      setSelectedBroker(broker);
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '200px',
                          minHeight: '100px',
                          '& .MuiMenuItem-root': {
                            fontSize: '10px',
                            marginLeft: '5px',
                            padding: '5px 15px',
                          },
                        },
                      },
                    }}
                  >
                    {brokers.map((broker) => (
                      <MenuItem key={broker.id} value={broker.id}>
                        {`${broker.id} - ${broker.brokerName}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Broker Name"
                  value={selectedBroker ? selectedBroker.brokerName || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      brokerName: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: '11px',
                    }}
                  >
                    Broker Status
                  </InputLabel>
                  <Select
                    value={selectedBroker ? selectedBroker.brokerStatus || false : false}
                    label="Broker Status"
                    onChange={(e) =>
                      setSelectedBroker({
                        ...selectedBroker,
                        brokerStatus: e.target.value,
                      })
                    }
                    disabled={!selectedBroker}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: '200px',
                          minHeight: '100px',
                          '& .MuiMenuItem-root': {
                            fontSize: '10px',
                            marginLeft: '5px',
                            padding: '5px 15px',
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value={true}>Active</MenuItem>
                    <MenuItem value={false}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Primary Contact"
                  value={selectedBroker ? selectedBroker.primaryContact || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      primaryContact: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Secondary Contact"
                  value={selectedBroker ? selectedBroker.secondaryContact || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      secondaryContact: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Primary Email"
                  value={selectedBroker ? selectedBroker.primaryEmail || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      primaryEmail: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Secondary Email"
                  value={selectedBroker ? selectedBroker.secondaryEmail || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      secondaryEmail: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Primary Contact Number"
                  value={selectedBroker ? selectedBroker.primaryContactNumber || '+1' : '+1'}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      primaryContactNumber: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Secondary Contact Number"
                  value={selectedBroker ? selectedBroker.secondaryContactNumber || '+1' : '+1'}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      secondaryContactNumber: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Unit Number"
                  value={selectedBroker ? selectedBroker.unitNumber || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      unitNumber: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Street Number"
                  value={selectedBroker ? selectedBroker.streetNumber || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      streetNumber: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Street Name"
                  value={selectedBroker ? selectedBroker.streetName || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      streetName: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="City"
                  value={selectedBroker ? selectedBroker.city || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      city: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Province"
                  value={selectedBroker ? selectedBroker.province || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      province: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Country"
                  value={selectedBroker ? selectedBroker.country || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      country: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Postal Code"
                  value={selectedBroker ? selectedBroker.postalCode || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      postalCode: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Broker Commission"
                  value={selectedBroker ? selectedBroker.brokerComission || '' : ''}
                  onChange={(e) =>
                    setSelectedBroker({
                      ...selectedBroker,
                      brokerComission: e.target.value,
                    })
                  }
                  disabled={!selectedBroker}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    },
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-30%', // Initially center the label vertically
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    size="small"
                    select
                    label="Client"
                    value={selectedBroker ? selectedBroker.clients || '' : ''}
                    onChange={(e) =>
                      setSelectedBroker({ ...selectedBroker, clients: e.target.value })
                    }
                    disabled={!selectedBroker || loading || clients.length === 0}
                  >
                    {clients.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {error && (
                    <Typography color="error" variant="caption">
                      {error}
                    </Typography>
                  )}
                </Grid> */}
              <Grid item xs={12} align="center">
                <Button
                  variant="contained"
                  onClick={handleUpdateSubmit}
                  sx={{ mt: 2, backgroundColor: '#31478F', fontSize: '10px' }}
                >
                  Update Broker
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setUpdateFormVisible(false)}
                  size="small"
                  sx={{ mt: 2, ml: 2, backgroundColor: '#E8F0FE' }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardContent>
          {/* </Card> */}
        </Box>
      )}

      {/* Table - shown only when neither form is visible */}
      {!formVisible && !updateFormVisible && (
        <TableContainer sx={{ maxWidth: '100%' }}>
          <Table sx={{ maxWidth: '100%' }}>
            <TableHead sx={{ maxWidth: '100%' }}>
              <TableRow sx={{ backgroundColor: '#31478F' }}>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  BROKER ID
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  BROKER NAME
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  BROKER STATUS
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  PRIMARY EMAILID
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  SECONDARY EMAILID
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                >
                  ACTIONS
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    padding: '4px',
                    whiteSpace: 'nowrap',
                    fontSize: '10px',
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                // backgroundColor: "#31478F",
                '& .main-table-row-cell': {
                  color: 'black',
                  fontSize: '12px', // Further reduce font size
                  padding: '0px', // Reduce padding
                  // margin: "2px",
                  height: '0px', // Reduce row height
                },
                '& .MuiTableCell-root': {
                  padding: '0px', // Reduce cell padding
                  margin: '1px',
                  fontSize: '10px', // Reduce font size
                },
              }}
            >
              {paginatedBrokers.length > 0 ? (
                paginatedBrokers.map((broker, index) => (
                  <React.Fragment key={broker.id}>
                    <TableRow
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#E6F0FF' : '#F8F8F8',
                        height: '10px', // Set a specific height for the row
                        '& .main-table-row-cell': {
                          color: 'black',
                          fontSize: '10px', // Reduce font size further
                          padding: '0px', // Remove extra padding
                          height: '10px', // Adjust cell height to match the row height
                          lineHeight: '12px', // Reduce line height for a more compact display
                          borderCollapse: 'collapse',
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        {broker.id}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        {broker.brokerName}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        {broker.brokerStatus ? 'Active' : 'Inactive'}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        {broker.primaryEmail}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        {broker.secondaryEmail}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: '2px 8px',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                        }}
                      >
                        <IconButton onClick={() => handleDelete(broker)}>
                          <DeleteIcon style={{ fontSize: '14px', color: 'black' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell sx={{ padding: '4px', whiteSpace: 'nowrap' }}>
                        <IconButton onClick={() => handleExpandClick(broker.id)} color="primary">
                          {expandedBroker[broker.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={10}>
                        <Collapse in={expandedBroker[broker.id]} timeout="auto" unmountOnExit>
                          <CardContent>
                            <Typography mb={2}>Clients for {broker.username}</Typography>
                            <Table sx={{ maxWidth: '100%' }}>
                              <TableHead sx={{ whiteSpace: 'pre-wrap' }}>
                                <TableRow sx={{ backgroundColor: '#31478F' }}>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '5px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Client ID
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '5px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Client Name
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '5px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Total Monthly Volumes (Gallons)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '5px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Total Monthly Commission Volumes (Gallons)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Total Volumes (Litres)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Total Commission Volumes (Litres)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Earned Commission (USD)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Earned Commission (CAD)
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Pending Commissions
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  >
                                    Actions
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      color: 'white',
                                      padding: '4px',
                                      fontSize: '10px',
                                    }}
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody
                                sx={{
                                  '& .main-table-row-cell': {
                                    color: 'black',
                                    fontSize: '10px', // Further reduce font size
                                    padding: '0px', // Reduce padding
                                    // margin: "2px",
                                    height: '0px', // Reduce row height
                                  },
                                  '& .MuiTableCell-root': {
                                    padding: '0px', // Reduce cell padding
                                    margin: '1px',
                                    fontSize: '9px', // Reduce font size
                                  },
                                }}
                              >
                                {newSelectedClient[broker.id] &&
                                newSelectedClient[broker.id].length > 0 ? (
                                  newSelectedClient[broker.id].map((client, index) => {
                                    const clientData = brokerData[client.clientID] || {};
                                    const monthlyGallons = clientData.monthlyVolume || 0;
                                    const monthlyCommissionGallons =
                                      clientData.monthlyCommission || 0;
                                    const totalGallons = clientData.totalVolume || 0;
                                    const totalCommissionGallons = clientData.totalCommission || 0;

                                    return (
                                      <React.Fragment key={client.clientID}>
                                        <TableRow
                                          sx={{
                                            backgroundColor:
                                              index % 2 === 0 ? '#E6F0FF' : '#F8F8F8',
                                          }}
                                        >
                                          <TableCell>{client.clientID}</TableCell>
                                          <TableCell>{client.clientName}</TableCell>
                                          <TableCell>{monthlyGallons.toFixed(3)}</TableCell>
                                          <TableCell>
                                            {monthlyCommissionGallons.toFixed(3)}
                                          </TableCell>
                                          <TableCell>{totalGallons.toFixed(3)}</TableCell>
                                          <TableCell>{totalCommissionGallons.toFixed(3)}</TableCell>
                                          <TableCell>{client.earnedCommissionUSD}</TableCell>
                                          <TableCell>{client.earnedCommissionCAD}</TableCell>
                                          <TableCell>{client.pendingCommissions}</TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleClientDelete(broker.id, client.clientID)
                                              }
                                            >
                                              <DeleteIcon
                                                sx={{
                                                  fontSize: '14px',
                                                  color: 'black',
                                                }}
                                              />
                                            </IconButton>
                                          </TableCell>
                                          <TableCell>
                                            <IconButton
                                              onClick={() =>
                                                handleTransactionExpandClick(
                                                  broker.id,
                                                  client.clientID,
                                                )
                                              }
                                              color="primary"
                                            >
                                              {transactionData[client.clientID] ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )}
                                            </IconButton>
                                          </TableCell>
                                        </TableRow>

                                        <TableRow>
                                          <TableCell colSpan={11}>
                                            <Collapse
                                              in={transactionData[client.clientID]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <CardContent>
                                                <Typography
                                                  sx={{
                                                    fontSize: '12px',
                                                    fontWeight: 'bold',
                                                  }}
                                                  mb={2}
                                                >
                                                  Transaction Details for {client.clientName}
                                                </Typography>
                                                <Box
                                                  display="flex"
                                                  justifyContent="flex-end"
                                                  mb={2}
                                                >
                                                  <ButtonGroup
                                                    variant="outlined"
                                                    aria-label="outlined button group"
                                                  ></ButtonGroup>
                                                </Box>
                                                {transactionData[client.clientID] &&
                                                transactionData[client.clientID].length > 0 ? (
                                                  <TransactionTable
                                                    transactions={transactionData[client.clientID]}
                                                    filterType={filterType}
                                                  />
                                                ) : (
                                                  <TableRow>
                                                    <TableCell
                                                      colSpan={11}
                                                      align="center"
                                                      sx={{
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      No transactions available
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                              </CardContent>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    );
                                  })
                                ) : (
                                  <TableRow>
                                    <TableCell colSpan={11} align="center">
                                      No clients available
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    No brokers available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination - shown only when neither form is visible */}
      {!formVisible && !updateFormVisible && (
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={brokers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this broker?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Rest of the code (RowCAD and TransactionTable) remains unchanged
const RowCAD = ({ row, index, doNotPerformCustomerFiltration }) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        className={index % 2 === 0 ? 'main-table-row-even' : 'main-table-row-odd'}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {doNotPerformCustomerFiltration && (
          <TableCell className="main-table-row-cell">{row.companyXRef}</TableCell>
        )}
        <TableCell className="main-table-row-cell">{row.transactionDate}</TableCell>
        <TableCell className="main-table-row-cell">{row.cardNumber?.slice(-5)}</TableCell>
        <TableCell className="main-table-row-cell">{row.driverName}</TableCell>
        <TableCell className="main-table-row-cell">{row.locationName}</TableCell>
        <TableCell className="main-table-row-cell">{row.locationCity}</TableCell>
        <TableCell className="main-table-row-cell">{row.locationState}</TableCell>
        <TableCell className="main-table-row-cell">{row.unitNumber}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography sx={{ fontSize: '12px' }}>Others</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead sx={{ backgroundColor: '#31478F' }}>
                  <TableRow>
                    <TableCell className="sub-table-header-cell">PRODUCT</TableCell>
                    <TableCell className="sub-table-header-cell">QTY</TableCell>
                    <TableCell className="sub-table-header-cell">RETAIL AMOUNT</TableCell>
                    <TableCell className="sub-table-header-cell">RETAIL PPU</TableCell>
                    <TableCell className="sub-table-header-cell">BILLING PPU</TableCell>
                    <TableCell className="sub-table-header-cell">GST/HST</TableCell>
                    <TableCell className="sub-table-header-cell">PST</TableCell>
                    <TableCell className="sub-table-header-cell">FET</TableCell>
                    <TableCell className="sub-table-header-cell">PFT</TableCell>
                    <TableCell className="sub-table-header-cell">MFT</TableCell>
                    <TableCell className="sub-table-header-cell">CT</TableCell>
                    <TableCell className="sub-table-header-cell">SAVINGS</TableCell>
                    <TableCell className="sub-table-header-cell">BILLING AMOUNT</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.others?.map((otherRow, subIndex) => (
                    <TableRow key={subIndex}>
                      <TableCell
                        className={`sub-table-row-${otherRow.product}`}
                        component="th"
                        scope="row"
                      >
                        {otherRow.product}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.qty}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.retailAmount}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.retailPPU}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.ppu}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.gstHst}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.pst}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.fet}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.pft}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.mft}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.ct}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.savings}
                      </TableCell>
                      <TableCell className={`sub-table-row-${otherRow.product}`}>
                        {otherRow.amount}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const TransactionTable = ({ transactions, doNotPerformCustomerFiltration }) => {
  const [filterType, setFilterType] = useState('today');

  const filterTransactions = (transactions) => {
    const currentDate = new Date();
    const currentDateString = currentDate.toISOString().slice(0, 10);
    const currentDay = currentDate.getDay();
    const currentWeekStart = new Date(currentDate);
    currentWeekStart.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1));

    return transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.transactionDate);
      const transactionDateString = transactionDate.toISOString().slice(0, 10);
      const isSameDay = transactionDateString === currentDateString;

      switch (filterType) {
        case 'today':
          return isSameDay;
        case 'weekly':
          return transactionDate >= currentWeekStart && transactionDate <= currentDate;
        case 'monthly':
          return (
            transactionDate.getFullYear() === currentDate.getFullYear() &&
            transactionDate.getMonth() === currentDate.getMonth()
          );
        case 'yearly':
          return transactionDate.getFullYear() === currentDate.getFullYear();
        default:
          return true;
      }
    });
  };

  const processTransactionDetails = (transactionDetail) => {
    let others = [];
    if (transactionDetail && transactionDetail.lineItems) {
      const ulsdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'ULSD',
      );
      const defdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'DEFD',
      );
      const rfrLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'RFR',
      );

      if (ulsdLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? ulsdLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        others.push({
          product: 'DIESEL',
          qty: (ulsdLineItem.quantity || 0).toFixed(3),
          retailAmount: (ulsdLineItem.retailAmount || 0).toFixed(3),
          retailPPU: (ulsdLineItem.retailAmount / ulsdLineItem.quantity || 0).toFixed(3),
          ppu: doNotPerformCustomerFiltration
            ? (ulsdLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3),
          gstHst: doNotPerformCustomerFiltration
            ? (
                ulsdLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3),
          pst: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ),
          fet: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ),
          pft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ),
          mft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ),
          ct: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3),
          savings: doNotPerformCustomerFiltration
            ? (ulsdLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3),
          amount: doNotPerformCustomerFiltration
            ? (ulsdLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3),
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3),
          customerPst: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3),
          customerFet: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3),
          customerPft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3),
          customerMft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3),
          customerCt: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3),
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3),
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3),
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
      } else if (defdLineItem) {
        const amount = defdLineItem.amount.toFixed(3) || 0;
        const customerAmount = defdLineItem.amount.toFixed(3) || 0;
        others.push({
          product: 'DEF',
          qty: (defdLineItem.quantity || 0).toFixed(3),
          retailAmount: (defdLineItem.retailAmount || 0).toFixed(3),
          retailPPU: (defdLineItem.retailPPU || 0).toFixed(3),
          ppu: (defdLineItem.ppu || 0).toFixed(3),
          gstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3),
          pst: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ),
          fet: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ),
          pft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ),
          mft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ),
          ct: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3),
          savings: (defdLineItem.discAmount || 0).toFixed(3),
          amount: (defdLineItem.amount || 0).toFixed(3),
          customerPpu: (defdLineItem.ppu || 0).toFixed(3),
          customerGstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3),
          customerPst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3),
          customerFet: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3),
          customerPft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3),
          customerMft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3),
          customerCt: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3),
          customerSavings: (defdLineItem.discAmount || 0).toFixed(3),
          customerAmount: (defdLineItem.amount || 0).toFixed(3),
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
      } else if (rfrLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? rfrLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        others.push({
          product: 'RFR',
          qty: (rfrLineItem.quantity || 0).toFixed(3),
          retailAmount: (rfrLineItem.retailAmount || 0).toFixed(3),
          retailPPU: (rfrLineItem.retailAmount / rfrLineItem.quantity || 0).toFixed(3),
          ppu: doNotPerformCustomerFiltration
            ? (rfrLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3),
          gstHst: doNotPerformCustomerFiltration
            ? (
                rfrLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3),
          pst: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(3),
          fet: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(3),
          pft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(3),
          mft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(3),
          ct: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3),
          savings: doNotPerformCustomerFiltration
            ? (rfrLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3),
          amount: doNotPerformCustomerFiltration
            ? (rfrLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3),
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3),
          customerPst: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3),
          customerFet: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3),
          customerPft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3),
          customerMft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3),
          customerCt: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3),
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3),
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3),
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
      }
    }
    return {
      companyXRef: doNotPerformCustomerFiltration ? transactionDetail.companyXRef : '',
      transactionDate: `${transactionDetail?.transactionDate?.slice(0, 10)}`,
      cardNumber: transactionDetail?.cardNumber?.slice(-5),
      driverName: transactionDetail.infos.find((info) => info.type === 'NAME')?.value || '',
      locationName: transactionDetail?.locationName,
      locationCity: transactionDetail?.locationCity,
      locationState: transactionDetail?.locationState,
      unitNumber: transactionDetail.infos.find((info) => info.type === 'UNIT')?.value || '',
      others: others,
    };
  };

  const filteredTransactions = filterTransactions(transactions);

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1.5 }}>
        <ButtonGroup
          sx={{
            borderRadius: '8px',
            overflow: 'hidden',
            border: '1px solid #007bff', // Single border for the group
          }}
        >
          {['today', 'weekly', 'monthly', 'yearly'].map((type) => (
            <Button
              key={type}
              onClick={() => setFilterType(type)}
              sx={{
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 'bold',
                minWidth: '50px',
                padding: '2px 6px', // Adjusted padding for a smaller button
                borderRadius: '4px', // Smaller rounded corners

                color: filterType === type ? '#fff' : '#007bff',
                backgroundColor: filterType === type ? '#007bff' : 'transparent',
                border: '1px solid #007bff',
                '&:hover': {
                  backgroundColor: filterType === type ? '#0056b3' : 'rgba(0, 123, 255, 0.1)',
                  borderColor: '#0056b3',
                },
              }}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Table sx={{ maxWidth: '100%', padding: '4px' }}>
        <TableHead sx={{ whiteSpace: 'prewrap' }}>
          <TableRow sx={{ backgroundColor: '#31478F' }}>
            <TableCell sx={{ color: 'white' }}>Expand</TableCell>
            {doNotPerformCustomerFiltration && (
              <TableCell sx={{ color: 'white' }}>Company XRef</TableCell>
            )}
            <TableCell sx={{ color: 'white' }}>Transaction Date & Time</TableCell>
            <TableCell sx={{ color: 'white' }}>Card Number</TableCell>
            <TableCell sx={{ color: 'white' }}>Driver Name</TableCell>
            <TableCell sx={{ color: 'white' }}>Location Name</TableCell>
            <TableCell sx={{ color: 'white' }}>Location City</TableCell>
            <TableCell sx={{ color: 'white' }}>Location State</TableCell>
            <TableCell sx={{ color: 'white' }}>Unit Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTransactions.length > 0 ? (
            filteredTransactions.map((transaction, index) => (
              <RowCAD
                key={transaction.transactionNumber}
                row={processTransactionDetails(transaction)}
                index={index}
                doNotPerformCustomerFiltration={doNotPerformCustomerFiltration}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={9} align="center">
                No transactions available for {filterType}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

TransactionTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  filterType: PropTypes.string.isRequired,
};

export default BrokerList;
