import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loader from 'components/Loader';
//import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../assets/images/logo/ksg-logo.png';

import DownloadIcon from '@mui/icons-material/Download';

function Invoice() {
  const [pdfUrl] = useState('');
  const buttonsFontSize = '16px';
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const buttonBackground = '#011f4b';
  const [clients, setClients] = React.useState([]);
  const [clientsCAD, setClientsCAD] = React.useState([]);
  const [clientsUSD, setClientsUSD] = React.useState([]);
  const [selectedClient, setSelectedClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [currency, setCurrency] = React.useState('USD'); // Set default currency to USD
  const [todayDate, setTodayDate] = React.useState('');
  const [dateErrorText, setDateErrorText] = React.useState('');
  const [, setCardsForClient] = React.useState([]);
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const [clientDetails, setClientDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  // const [disableCAD, setDisableCAD] = useState(false);
  // const [disableUSD, setDisableUSD] = useState(false);
  const [, setFetchedWithNoRecords] = React.useState(false);
  const [, setRows] = React.useState([]);
  const [, setTransactionsList] = React.useState([]);
  const [uploading, setUploading] = useState(false);

  const [invoices, setInvoices] = useState([]);

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const cardHeight = 100;
  const inputLabelStyles = { fontSize: '14px', color: 'rgb(26, 33, 56)' };
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (!loggedInUserClientId) {
        setError('Client ID not found in localStorage.');
        return;
      }

      try {
        const response = await fetch(
          `https://wex-backend.hub-ksgfuel.com/v1/client?clientID=${loggedInUserClientId}`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientDetails(data);
        console.log('clientDetails currency', data[0]?.currency);
        console.log('clientDetails', data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClientDetails();
  }, [loggedInUserClientId]);

  useEffect(() => {
    getClients();
  }, []);

  // Use Effect to handle single client case
  useEffect(() => {
    if (clients.length === 1) {
      const singleClient = clients[0];
      const singleClientValue = `${singleClient.clientID}-${singleClient.customerID}`;
      setClientId(singleClient.clientID);
      setCustomerId(singleClient.customerID);

      // Trigger handleSelectChange programmatically
      handleSelectChange({ target: { value: singleClientValue } });
    }
  }, [clients]); // Only depends on clients array

  const getClients = async () => {
    let getClientsUrl = `https://wex-backend.hub-ksgfuel.com/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);

    // Create two arrays to hold the clients by currency
    const clientsCADData = [];
    const clientsUSDData = [];

    // Iterate over the client data and segregate into CAD or USD
    data.forEach((client) => {
      const currency = client.currency;

      // Ensure currency is not null or undefined before checking for CAD or USD
      if (currency && typeof currency === 'string') {
        // Check if currency contains "CAD"
        if (currency.includes('CAD')) {
          clientsCADData.push(client);
        }

        // Check if currency contains "USD"
        if (currency.includes('USD')) {
          clientsUSDData.push(client);
        }
      }
    });

    // Set the state variables for CAD and USD clients
    setClientsCAD(clientsCADData); // Set clients for CAD
    setClientsUSD(clientsUSDData); // Set clients for USD
    return data;
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    // Check if selectedValue is not undefined and contains '-'
    if (selectedValue && selectedValue.includes('-')) {
      // Extract clientID and customerID from the selected value
      const [clientID, customerID] = selectedValue.split('-').map((value) => value.trim());

      if (clientID && customerID) {
        setClientId(clientID); // Update clientId state
        setCustomerId(customerID); // Update customerId state

        // Call the function to handle customer selection change with clientId and customerId
        handleCustomerSelectionChange(clientID, customerID);
      } else {
        console.error(
          'Failed to extract clientID and customerID from the selected value:',
          selectedValue,
        );
      }
    } else {
      console.error('Selected value is not in the expected format:', selectedValue);
    }
  };

  const handleCustomerSelectionChange = async (clientID, customerID) => {
    setLoading(true);
    setRows([]);
    console.log('Client inside handlecustomer ', clientID);
    try {
      // Use clientID in the getClientsUrl
      const getClientsUrl = `${BACKEND_URL}/v1/client?clientID=${clientID}`;
      const getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerID}&maxRows=1000&pageNumber=1000`;

      const [clientResponse, cardResponse] = await Promise.all([
        fetch(getClientsUrl),
        fetch(getCardDetailsForCustomerUrl),
      ]);

      if (!clientResponse.ok) {
        throw new Error('Failed to fetch client data');
      }

      if (!cardResponse.ok) {
        throw new Error('Failed to fetch card details');
      }

      const clientData = await clientResponse.json();
      const cardData = await cardResponse.json();

      setSelectedClient(clientData);
      console.log('Data client', cardData?.records);
      setCardsForClient(cardData?.records?.card);
      setCurrency('USD');
      console.log('Customer Details new', clientData);
    } catch (error) {
      console.error('Error fetching client or card details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDate = (date) => {
    setTodayDate(date);
    setDateErrorText('');
  };

  // Set the customerId to the first item if only one item is present
  useEffect(() => {
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      const firstClient = clients[0];
      setCustomerId(firstClient.customerID);
      handleCustomerSelectionChange({ target: { value: firstClient.customerID } });
    }
  }, [doNotPerformCustomerFiltration, loggedInUserClientId, BACKEND_URL]);

  const createData = (transactionDetail) => {
    let others = [];
    let totalDieselCost = 0.0;
    let totalDieselQuantity = 0.0;
    let totalDieselUnitPrice = 0.0;
    let totalDefCost = 0.0;
    let totalDefQuantity = 0.0;
    let totalDefUnitPrice = 0.0;
    let totalReeferCost = 0.0;
    let totalReeferQuantity = 0.0;
    let totalReeferUnitPrice = 0.0;
    let totalCashCost = 0.0;
    let totalCashQuantity = 0.0;
    let totalCashUnitPrice = 0.0;

    if (transactionDetail) {
      // Diesel Row
      if (transactionDetail?.tractorFuelPricePerGallon > 0) {
        let rebateAmount = doNotPerformCustomerFiltration
          ? transactionDetail?.rebateAmount
          : transactionDetail?.customerRebateAmount;
        let transactionFee = doNotPerformCustomerFiltration ? 1 : 0;
        let dieselGallons = transactionDetail?.numTractorGallons;
        let unitPrice = transactionDetail?.tractorFuelPricePerGallon;
        let invoiceAmount = transactionDetail?.costOfTractorFuel;
        let totalInvoice = invoiceAmount + transactionFee;
        let discountPerGallon = transactionDetail?.customerDiscountPerGallon;

        let dieselRow = {
          type: 'Diesel',
          gallons: dieselGallons.toFixed(2),
          unitPrice: unitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: invoiceAmount,
          totalInvoice: totalInvoice,
          discountPerGallon: +discountPerGallon,
          discountAmount: (discountPerGallon * dieselGallons.toFixed(2)).toFixed(2),
          rebate: rebateAmount,
          cost: totalInvoice > rebateAmount ? totalInvoice - rebateAmount : 0,
        };
        others.push(dieselRow);
        totalDieselCost += dieselRow?.cost;
        totalDieselQuantity += dieselRow?.gallons; // Add to total quantity
        totalDieselUnitPrice += dieselRow?.unitPrice;
      }

      // Def Row
      if (transactionDetail?.otherFuelPPG > 0.0) {
        let defDieselGallons = transactionDetail?.otherFuelGallons;
        let defUnitPrice = transactionDetail?.otherFuelPPG;
        let defInvoiceAmount = transactionDetail?.otherFuelCost;

        let defRow = {
          type: 'Def',
          gallons: defDieselGallons.toFixed(2),
          unitPrice: defUnitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: defInvoiceAmount,
          totalInvoice: defInvoiceAmount,
          discountPerGallon: 0,
          discountAmount: 0,
          rebate: 0,
          cost: defInvoiceAmount,
        };
        others.push(defRow);
        totalDefCost += defRow?.cost;
        totalDefQuantity += defRow?.gallons; // Add to total quantity
        totalDefUnitPrice += defRow?.unitPrice;
      }

      // Reefer Row
      if (transactionDetail && transactionDetail?.reeferPricePerGallon > 0.0) {
        let reeferDieselGallons = transactionDetail?.numReeferGallons;
        let reeferUnitPrice = transactionDetail?.reeferPricePerGallon;
        let reeferInvoiceAmount = transactionDetail?.costOfReeferFuel;
        let discountPerGallon = transactionDetail?.reeferCustomerDiscountPerGallon;

        let reeferRow = {
          type: 'Reefer',
          gallons: reeferDieselGallons.toFixed(2),
          unitPrice: reeferUnitPrice.toFixed(2),
          transactionFee: 0,
          invoiceAmount: reeferInvoiceAmount,
          totalInvoice: reeferInvoiceAmount,
          discountPerGallon: +discountPerGallon,
          discountAmount: (discountPerGallon * reeferDieselGallons.toFixed(2)).toFixed(2),
          rebate: 0,
          cost: reeferInvoiceAmount,
        };
        others.push(reeferRow);
        totalReeferCost += reeferRow?.cost;
        totalReeferQuantity += reeferRow?.gallons; // Add to total quantity
        totalReeferUnitPrice += reeferRow?.unitPrice;
      }

      // Cash Row
      if (transactionDetail && transactionDetail?.cashAdvanceAmount > 0.0) {
        let cashAdvanceAmount = transactionDetail?.cashAdvanceAmount;
        let transactionFee = transactionDetail?.customerCostForCashAdvance;
        let invoiceAmount = cashAdvanceAmount + transactionFee;

        let cashRow = {
          type: 'Cash',
          gallons: '',
          unitPrice: '',
          transactionFee: transactionFee,
          invoiceAmount: cashAdvanceAmount,
          totalInvoice: invoiceAmount,
          discountPerGallon: 0,
          discountAmount: 0,
          rebate: 0,
          cost: invoiceAmount,
        };
        others.push(cashRow);
        totalCashCost += cashRow?.cost;
        totalCashQuantity += cashRow?.gallons; // Add to total quantity
        totalCashUnitPrice += cashRow?.unitPrice;
      }
    }

    return {
      customerId: transactionDetail?.customerID,
      transactionDateTime: `${transactionDetail?.transactionDate} ${transactionDetail?.transactionTime}`,
      cardNumber: transactionDetail?.cardNumber,
      unitNumber: transactionDetail?.unitNumber,
      truckStopCity: transactionDetail?.stopCity,
      truckStopState: transactionDetail?.truckStopState,
      driverName: transactionDetail?.driverName,
      site: transactionDetail?.truckStopInvoiceNumber,
      siteName: transactionDetail?.stopName,
      cost: totalDieselCost?.toFixed(2),
      quantity: totalDieselQuantity, // Include total quantity
      unitPrice: totalDieselUnitPrice,
      defcost: totalDefCost?.toFixed(2),
      defquantity: totalDefQuantity, // Include total quantity
      defunitPrice: totalDefUnitPrice,
      reefercost: totalReeferCost?.toFixed(2),
      reeferquantity: totalReeferQuantity, // Include total quantity
      reeferunitPrice: totalReeferUnitPrice,
      cashcost: totalCashCost,
      cashquantity: totalCashQuantity, // Include total quantity
      cashunitPrice: totalCashUnitPrice,
      others: others,
    };
  };

  const handleGenerateInvoice = async (transactionsList) => {
    try {
      console.log(`Generating PDF with invoice number: ${invoiceNumber}`);
      const doc = new jsPDF();
      const today = new Date(todayDate); // Use current date

      // Function to format date as yyyy-mm-dd
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || {};
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber = customerDetails[0]?.contactNumber || 'N/A';
      const email = customerDetails[0]?.email || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      const currentDate = new Date(today);
      const startOfCurrentWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1),
      ); // Monday of current week
      const startOfPreviousWeek = new Date(startOfCurrentWeek);
      startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week
      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

      const startOfPreviousWeekFormatted = formatDate(startOfPreviousWeek);
      const endOfPreviousWeekFormatted = formatDate(endOfPreviousWeek);

      // Calculate the due date (3 days from the end of the previous week)
      const dueDate = new Date(endOfPreviousWeek);
      dueDate.setDate(endOfPreviousWeek.getDate() + 3);
      const dueDateFormatted = formatDate(dueDate);

      // Define data for the invoice info table
      const invoiceInfoData = [
        [
          invoiceNumber,
          formatDate(today),
          startOfPreviousWeekFormatted,
          endOfPreviousWeekFormatted,
          dueDateFormatted,
        ],
      ];

      // Define column headers for the invoice info table
      const invoiceInfoHeaders = [
        'Invoice Number',
        'Invoice Date',
        'Start Date',
        'End Date',
        'Due Date',
      ];

      // Draw the black rectangle behind the logo
      doc.setFillColor(0, 0, 0); // RGB for black
      doc.rect(10, 10, 35, 25, 'F'); // Rectangle position and size

      const img = new Image();
      img.src = logo; // Ensure this path is correct

      img.onload = () => {
        doc.addImage(img, 'PNG', 10, 10, 35, 25);

        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('KSG Fuel', 50, 15);
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('Address: 2575 Steeles Ave E, Unit 1, Brampton,', 50, 18);
        doc.text('Ontario, Canada, L6T 5T1', 50, 21);
        doc.text('Office: +1-(905) 965-0308', 50, 24);
        doc.text('Toll free: ', 50, 27);
        doc.text('www.ksgfuel.com', 50, 30);
        doc.text('Email: support@ksgfuel.com', 50, 33);

        // Add client information in the top right corner
        const pageWidth = doc.internal.pageSize.getWidth();
        const rightMargin = 75; // Distance from the right edge of the page
        const startX = pageWidth - rightMargin;

        // Client Info title
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('Client Info', startX, 15, { align: 'left' });

        // Client Details
        doc.setFontSize(9);
        doc.setFont('times', 'bold');
        doc.text(customerName, startX, 20, { align: 'left' });
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('Address: ' + customerAddress, startX, 23, { align: 'left' });
        doc.text('Phone: ' + contactNumber, startX, 26, { align: 'left' });
        doc.text('Email: ' + email, startX, 29, { align: 'left' });

        // Add invoice info table
        doc.autoTable({
          head: [invoiceInfoHeaders],
          body: invoiceInfoData,
          startY: 40,
          margin: { left: 10, right: 90 },
          theme: 'striped',
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 6,
            cellPadding: 2,
          },
          styles: {
            fontSize: 6, // Set font size for body cells
            cellPadding: 2,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto', // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
          },
        });

        // Group transactions by card number and calculate totals
        const groupedByCard = transactionsList.reduce((acc, item) => {
          if (!acc[item.cardNumber]) {
            acc[item.cardNumber] = {
              cardNumber: item.cardNumber,
              diesel: { quantity: 0, cost: 0, unitPrice: 0 },
              def: { quantity: 0, cost: 0, unitPrice: 0 },
              reefer: { quantity: 0, cost: 0, unitPrice: 0 },
              cash: { quantity: 0, cost: 0, unitPrice: 0 },
              transactions: [],
            };
          }

          // Iterate through each type in the others array
          item.others.forEach((other) => {
            const type = other.type;
            const transaction = acc[item.cardNumber];

            if (type === 'Diesel') {
              transaction.diesel.quantity += parseFloat(other.gallons) || 0;
              transaction.diesel.cost += parseFloat(other.cost) || 0;
              transaction.diesel.unitPrice += parseFloat(other.unitPrice) || 0;
            } else if (type === 'Def') {
              transaction.def.quantity += parseFloat(item.defquantity) || 0;
              transaction.def.cost += parseFloat(item.defcost) || 0;
              transaction.def.unitPrice += parseFloat(item.defunitPrice) || 0;
            } else if (type === 'Reefer') {
              transaction.reefer.quantity += parseFloat(other.gallons) || 0;
              transaction.reefer.cost += parseFloat(other.cost) || 0;
              transaction.reefer.unitPrice += parseFloat(other.unitPrice) || 0;
            } else if (type === 'Cash') {
              transaction.cash.quantity += parseFloat(item.cashquantity) || 0;
              transaction.cash.cost += parseFloat(item.cashcost) || 0;
              transaction.cash.unitPrice += parseFloat(item.cashunitPrice) || 0;
            }
          });

          acc[item.cardNumber].transactions.push(item);
          return acc;
        }, {});

        let yOffset = doc.lastAutoTable.finalY + 10;

        // Handle empty transactions list
        if (transactionsList.length === 0) {
          // Define column headers for the transaction table
          const headersNoRecords = [
            'Driver Name',
            'Unit Number',
            'Date',
            'Site #',
            'Site Name',
            'Site City',
            'Prov/State',
            'Prod',
            'QTY',
            'Retail',
            'Billed',
            'Pre Tax Amt',
            'HST',
            'GST',
            'PST',
            'QST',
            'Trans Fee',
            'Disc Rate',
            'Disc Amt',
            'Final Amt',
            'CUR',
          ];

          const noRecordsData = [
            [
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              'No Transaction Results Found',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
          ];

          // Add table for this card number
          doc.autoTable({
            head: [headersNoRecords],
            body: noRecordsData,
            startY: yOffset,
            theme: 'striped',
            margin: { left: 10, right: 10 },
            headStyles: {
              fillColor: [1, 31, 75], // Hex color #8e918f in RGB
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              fontSize: 3,
              cellPadding: 1,
            },
            styles: {
              fontSize: 3, // Set font size for body cells
              cellPadding: 1,
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              overflow: 'linebreak',
              cellWidth: 'auto', // Adjust column widths as needed
            },
            columnStyles: {
              0: { cellWidth: 'auto' }, // Adjust column widths as needed
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' },
            },
          });
          // Update yOffset for the next table
          yOffset = doc.lastAutoTable.finalY + 10;
        } else {
          Object.values(groupedByCard).forEach((group) => {
            // Add card number text
            doc.setFontSize(8);
            doc.setFont('times', 'bold');
            doc.text(`Transaction for Card: ${group.cardNumber}`, 10, yOffset);
            yOffset += 5; // Add some space between the card number and the table

            const transactions = group.transactions;

            console.log('Transaction for card', transactions);

            // Add card number and transactions table
            const transactionsTableHeader = [
              'Driver Name',
              'Unit Number',
              'Date',
              'Site #',
              'Site Name',
              'Site City',
              'Prov/State',
              'Prod',
              'QTY',
              'Retail',
              'Billed',
              'Pre Tax Amt',
              'HST',
              'GST',
              'PST',
              'QST',
              'Trans Fee',
              'Disc Rate',
              'Disc Amt',
              'Final Amt',
              'CUR',
            ];

            // Prepare the table data with subtotals
            const transactionsTableData = [];

            // Initialize total accumulators
            let totalPretaxamt = 0;
            let totalFinalamt = 0;

            // Iterate over each transaction
            transactions.forEach((tr) => {
              let subtotalGallons = 0;
              let subtotalPretaxamt = 0;
              let subtotalFinalamt = 0;

              // Ensure the `others` property exists and is an array
              if (tr.others && Array.isArray(tr.others)) {
                tr.others.forEach((other) => {
                  // Check if 'cash' value is present and use it for 'finalamt'
                  const invoiceAmount = parseFloat(other.invoiceAmount);
                  const transactionFee = parseFloat(other.transactionFee);
                  const final = parseFloat(other.invoiceAmount).toFixed(2);
                  const finalCash = (invoiceAmount + transactionFee).toFixed(2);
                  const finalOther = final - other.discountAmount;
                  const finalamt = other.type == 'Cash' ? finalCash : finalOther.toFixed(2);
                  subtotalGallons += parseFloat(other.gallons) || 0;
                  subtotalPretaxamt += parseFloat(final) || 0;
                  subtotalFinalamt += parseFloat(finalamt) || 0;

                  // Push each transaction row
                  transactionsTableData.push([
                    tr.driverName || 'N/A',
                    tr.unitNumber || 'N/A',
                    tr.transactionDateTime || 'N/A',
                    tr.site || 'N/A',
                    tr.siteName || 'N/A',
                    tr.truckStopCity || 'N/A',
                    tr.truckStopState || 'N/A',
                    other.type || '',
                    other.gallons || '0',
                    other.unitPrice || '0',
                    other.unitPrice || '0',
                    other.invoiceAmount || '0',
                    '0', // Placeholder for 'HST'
                    '0', // Placeholder for 'GST'
                    '0', // Placeholder for 'PST'
                    '0', // Placeholder for 'QST',
                    other.transactionFee || 0,
                    other.discountPerGallon.toFixed(2) || '0', // Placeholder for 'Disc Rate'
                    other.discountAmount || '0', // Placeholder for 'Disc Amt'
                    finalamt || '0',
                    'US', // Currency
                  ]);
                });

                // Add subtotal row after processing others data
                transactionsTableData.push([
                  '',
                  '',
                  '',
                  '',
                  'Subtotal',
                  '',
                  '',
                  '',
                  subtotalGallons.toFixed(2),
                  '',
                  '',
                  subtotalPretaxamt.toFixed(2),
                  '0', // Placeholder for 'HST'
                  '0', // Placeholder for 'GST'
                  '0', // Placeholder for 'PST'
                  '0', // Placeholder for 'QST'
                  '',
                  '0', // Placeholder for 'Disc Rate'
                  '0', // Placeholder for 'Disc Amt'
                  subtotalFinalamt.toFixed(2),
                  'US', // Currency
                ]);

                // Update total accumulators
                totalPretaxamt += subtotalPretaxamt;
                totalFinalamt += subtotalFinalamt;
              }
            });

            // Add total row after processing all transactions
            transactionsTableData.push([
              '',
              '',
              '',
              '',
              'Total',
              '',
              '',
              '',
              '',
              '',
              '',
              totalPretaxamt.toFixed(2),
              '0', // Placeholder for 'HST'
              '0', // Placeholder for 'GST'
              '0', // Placeholder for 'PST'
              '0', // Placeholder for 'QST'
              '',
              '0', // Placeholder for 'Disc Rate'
              '0', // Placeholder for 'Disc Amt'
              totalFinalamt.toFixed(2),
              'US', // Currency
            ]);

            doc.autoTable({
              head: [transactionsTableHeader],
              body: transactionsTableData,
              startY: yOffset,
              theme: 'striped',
              margin: { left: 10, right: 10 },
              headStyles: {
                fillColor: [1, 31, 75],
                halign: 'center',
                valign: 'middle',
                fontSize: 4,
                cellPadding: 1,
              },
              styles: {
                fontSize: 4,
                cellPadding: 1,
                halign: 'center',
                valign: 'middle',
                overflow: 'linebreak',
                cellWidth: 'auto',
              },
              columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 'auto' },
                13: { cellWidth: 'auto' },
                14: { cellWidth: 'auto' },
                15: { cellWidth: 'auto' },
                16: { cellWidth: 'auto' },
                17: { cellWidth: 'auto' },
                18: { cellWidth: 'auto' },
                19: { cellWidth: 'auto' },
              },
              didDrawCell: (data) => {
                const lastRowIndex = transactionsTableData.length - 1;
                //const secondLastRowIndex = transactionsTableData.length - 2;

                // Check if it's the last or second last row
                if (data.row.index === lastRowIndex) {
                  // Apply dark blue background color
                  doc.setFillColor(1, 31, 75); // Dark blue background
                  doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

                  // Apply white text color
                  doc.setTextColor(255, 255, 255);

                  // Ensure text is a string
                  const text = String(data.cell.text);

                  // Calculate horizontal alignment
                  const textWidth = doc.getTextWidth(text);
                  const x = data.cell.x + (data.cell.width - textWidth) / 2;

                  // Calculate vertical alignment
                  const fontSize = doc.internal.getFontSize();
                  const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

                  // Draw the text centered in the cell
                  doc.text(text, x, y);
                }
              },
            });

            yOffset = doc.lastAutoTable.finalY + 10;
          });
        }

        // Add card number text
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text(`Grand Total: `, 10, yOffset);
        yOffset += 5; // Add some space between the card number and the table

        // Initialize grand total accumulators
        const grandTotals = {
          Diesel: {
            quantity: 0,
            cost: 0,
            unitPrice: 0,
            discountPerGallon: 0,
            discountAmount: 0,
            transactionFee: 0,
          },
          Def: {
            quantity: 0,
            cost: 0,
            unitPrice: 0,
            discountPerGallon: 0,
            discountAmount: 0,
            transactionFee: 0,
          },
          Reefer: {
            quantity: 0,
            cost: 0,
            unitPrice: 0,
            discountPerGallon: 0,
            discountAmount: 0,
            transactionFee: 0,
          },
          Cash: {
            quantity: 0,
            cost: 0,
            unitPrice: 0,
            discountPerGallon: 0,
            discountAmount: 0,
            transactionFee: 0,
          },
        };

        // Iterate over each transaction and accumulate totals
        transactionsList.forEach((item) => {
          item.others.forEach((other) => {
            const type = other.type;
            if (grandTotals[type]) {
              grandTotals[type].quantity += parseFloat(other.gallons) || 0;
              grandTotals[type].cost += parseFloat(other.invoiceAmount) || 0;
              grandTotals[type].unitPrice += parseFloat(other.unitPrice) || 0;
              grandTotals[type].discountPerGallon += parseFloat(other.discountPerGallon) || 0;
              grandTotals[type].discountAmount += parseFloat(other.discountAmount) || 0;
              grandTotals[type].transactionFee += parseFloat(other.transactionFee) || 0;
            }
          });
        });

        // Define headers for the grand total table
        const grandTotalHeaders = [
          'Prod',
          'QTY',
          'Pre Tax Amt',
          'HST',
          'GST',
          'PST',
          'QST',
          'Trans Fee',
          'Disc Rate',
          'Disc Amt',
          'Final Amt',
          'CUR',
        ];

        // Define data for the grand total table
        const grandTotalData = Object.keys(grandTotals).map((type) => {
          const total = grandTotals[type];
          const grandPretaxamt = total.cost.toFixed(2);
          const grandFinalamt = total.discountAmount
            ? (parseFloat(grandPretaxamt) - parseFloat(total.discountAmount)).toFixed(2)
            : (parseFloat(grandPretaxamt) + parseFloat(total.transactionFee)).toFixed(2);

          return [
            type, // Product type
            total.quantity.toFixed(2), // Quantity
            grandPretaxamt, // Pre Tax Amount
            '0', // HST
            '0', // GST
            '0', // PST
            '0', // QST
            total.transactionFee.toFixed(2) || 0, //Transaction Fee
            total.discountPerGallon.toFixed(2) || 0, // Disc Rate
            total.discountAmount.toFixed(2) || 0, // Disc Amount
            grandFinalamt, // Final Amount
            'US', // Currency
          ];
        });

        // Calculate grand total row
        const grandTotalRow = Object.values(grandTotals).reduce(
          (acc, typeTotals) => {
            acc.quantity += typeTotals.quantity;
            acc.cost += typeTotals.cost;
            acc.discountPerGallon += typeTotals.discountPerGallon;
            acc.discountAmount += typeTotals.discountAmount;
            acc.transactionFee += typeTotals.transactionFee;
            return acc;
          },
          { quantity: 0, cost: 0, discountPerGallon: 0, discountAmount: 0, transactionFee: 0 },
        );

        const grandTotalPretaxamt = grandTotalRow.cost.toFixed(2);
        const grandTotalFinalamt = grandTotalRow.discountAmount
          ? (
              parseFloat(grandTotalPretaxamt) -
              parseFloat(grandTotalRow.discountAmount) +
              parseFloat(grandTotalRow.transactionFee)
            ).toFixed(2)
          : parseFloat(grandTotalPretaxamt).toFixed(2);
        // Define total row data
        const totalRow = [
          'Total',
          grandTotalRow.quantity.toFixed(2), // Total Quantity
          grandTotalPretaxamt, // Total Pre Tax Amount
          '0', // Total HST
          '0', // Total GST
          '0', // Total PST
          '0', // Total QST
          grandTotalRow.transactionFee.toFixed(2) || 0,
          grandTotalRow.discountPerGallon.toFixed(2) || 0, // Disc Rate (Left blank if not applicable)
          grandTotalRow.discountAmount.toFixed(2), // Total Disc Amount
          grandTotalFinalamt || 0, // Total Final Amount
          'US', // Currency
        ];

        // Add grand total table
        doc.autoTable({
          head: [grandTotalHeaders],
          body: [...grandTotalData, totalRow],
          startY: yOffset,
          theme: 'striped',
          margin: { left: 10, right: 10 },
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 5,
            cellPadding: 1,
          },
          styles: {
            fontSize: 5, // Set font size for body cells
            cellPadding: 1,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto', // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
            5: { cellWidth: 'auto' },
            6: { cellWidth: 'auto' },
            7: { cellWidth: 'auto' },
            8: { cellWidth: 'auto' },
            9: { cellWidth: 'auto' },
            10: { cellWidth: 'auto' },
            11: { cellWidth: 'auto' },
            12: { cellWidth: 'auto' },
            13: { cellWidth: 'auto' },
          },
          didDrawCell: (data) => {
            // Check if it's the last row (total row)
            if (data.row.index === grandTotalData.length) {
              // Apply dark blue background color
              doc.setFillColor(1, 31, 75);
              doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

              // Apply white text color
              doc.setTextColor(255, 255, 255);

              // Redraw the text with adjusted x and y for alignment
              // Adjust the x and y offsets to properly align the text within the cell
              const text = String(data.cell.text);

              // Calculate horizontal alignment
              const textWidth = doc.getTextWidth(text);
              const x = data.cell.x + (data.cell.width - textWidth) / 2;

              // Calculate vertical alignment
              const fontSize = doc.internal.getFontSize();
              const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

              // Draw the text centered in the cell
              doc.text(text, x, y);
            }
          },
        });

        // Update yOffset for the next section
        yOffset = doc.lastAutoTable.finalY + 10;

        // Convert the PDF to a Blob and create a URL
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Display the PDF in an iframe
        const iframe = document.createElement('iframe');
        iframe.src = pdfUrl;
        iframe.style.width = '100%';
        iframe.style.height = '600px';
        iframe.style.border = 'none';

        // Append the iframe to a container element
        const pdfContainer = document.getElementById('pdf-container');
        pdfContainer.innerHTML = ''; // Clear previous content
        pdfContainer.appendChild(iframe);

        doc.save('invoice.pdf');
      };

      img.onerror = (error) => {
        console.error('Error loading image:', error);
        // Handle the error case where the image couldn't be loaded
      };
    } catch (error) {
      console.error('Error generating invoice:', error);
    }
  };

  const handleViewReport = async (event, action) => {
    event.preventDefault();

    // Validate dates
    if (!todayDate) {
      setDateErrorText('Date is required');
      return;
    }

    // Generate a random 9-digit invoice number
    const generatedInvoiceNumber = `#${Math.floor(100000000 + Math.random() * 900000000)}`;
    setInvoiceNumber(generatedInvoiceNumber);

    // Function to format date to 'YYYY-MM-DD'
    function formatDate(date) {
      return date.toISOString().split('T')[0];
    }

    // Assuming todayDate is defined, create the current date object
    const today = new Date(todayDate);

    // Calculate the start of the current week (Monday)
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 1); // Monday of current week

    // Calculate the start of the previous week (Monday)
    const startOfPreviousWeek = new Date(startOfCurrentWeek);
    startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week

    // Calculate the end of the previous week (Sunday)
    const endOfPreviousWeek = new Date(startOfPreviousWeek);
    endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

    // Format the dates
    const formattedStartDate = formatDate(startOfPreviousWeek);
    const formattedEndDate = formatDate(endOfPreviousWeek);

    let getTransactionDetailsUrl = `${BACKEND_URL}/v1/transaction`;

    let retrieveTransactionsObject = {
      customerID: customerId,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };

    await fetch(getTransactionDetailsUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(retrieveTransactionsObject),
    })
      .then((response) => response.json())
      .then((transactionDetails) => {
        let transactionsList = [];
        setFetchedWithNoRecords(transactionDetails.length === 0);
        for (let transactionDetail of transactionDetails) {
          transactionsList.push(createData(transactionDetail));
        }
        setRows(transactionsList);
        setTransactionsList(transactionsList);
        console.log('transactionList', transactionsList);

        if (action === 'generate') {
          handleGenerateInvoice(transactionsList);
        } else if (action === 'download') {
          handleDownloadCSV(transactionsList);
        }
      })
      .catch((error) => {
        console.error('Error fetching transactions:', error);
      });
  };

  // CSV download handler
  const handleDownloadCSV = async (transactionsList) => {
    try {
      if (!invoiceNumber) return;
      console.log(`Generating CSV with invoice number: ${invoiceNumber}`);

      const today = new Date(todayDate);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || [];
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber = customerDetails[0]?.contactNumber || 'N/A';
      const email = customerDetails[0]?.email || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      const currentDate = new Date(today);
      const startOfCurrentWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1),
      ); // Monday of current week
      const startOfPreviousWeek = new Date(startOfCurrentWeek);
      startOfPreviousWeek.setDate(startOfCurrentWeek.getDate() - 7); // Monday of the previous week
      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6); // Sunday of the previous week

      const startOfPreviousWeekFormatted = formatDate(startOfPreviousWeek);
      const endOfPreviousWeekFormatted = formatDate(endOfPreviousWeek);

      // Calculate the due date (3 days from the end of the previous week)
      const dueDate = new Date(endOfPreviousWeek);
      dueDate.setDate(endOfPreviousWeek.getDate() + 3);
      const dueDateFormatted = formatDate(dueDate);

      // Prepare CSV content
      const csvRows = [
        ['Invoice Number', 'Invoice Date', 'Start Date', 'End Date', 'Due Date'],
        [
          invoiceNumber,
          formatDate(today),
          startOfPreviousWeekFormatted,
          endOfPreviousWeekFormatted,
          dueDateFormatted,
        ],
        [], // Empty row for separation
        ['Customer Name', 'Address', 'Phone', 'Email'],
        [customerName, `"${customerAddress}"`, contactNumber, email],
      ];

      // Group transactions by card number
      const groupedByCard = transactionsList.reduce((acc, transaction) => {
        const cardNumber = transaction.cardNumber; // Change this if the attribute name is different
        if (!acc[cardNumber]) {
          acc[cardNumber] = { cardNumber, transactions: [] };
        }
        acc[cardNumber].transactions.push(transaction);
        return acc;
      }, {});

      // Handle empty transactions list
      if (transactionsList.length === 0) {
        const headersNoRecords = [
          'Driver Name',
          'Unit Number',
          'Date',
          'Site #',
          'Site Name',
          'Site City',
          'Prov/State',
          'Prod',
          'QTY',
          'Retail',
          'Billed',
          'Pre Tax Amt',
          'HST',
          'GST',
          'PST',
          'QST',
          'Trans Fee',
          'Disc Rate',
          'Disc Amt',
          'Final Amt',
          'CUR',
        ];
        // Add table header to csvRows
        csvRows.push(headersNoRecords);

        const noRecordsData = [
          [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'No Transaction Results Found',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
        ];

        csvRows.push(...noRecordsData);
      } else {
        // Iterate over each group
        for (const group of Object.values(groupedByCard)) {
          // Add card number
          csvRows.push([`Transaction for Card: ${group.cardNumber}`]);
          csvRows.push([]); // Empty row for separation

          // Define headers for the transactions table
          const transactionsTableHeader = [
            'Driver Name',
            'Unit Number',
            'Date',
            'Site #',
            'Site Name',
            'Site City',
            'Prov/State',
            'Prod',
            'QTY',
            'Retail',
            'Billed',
            'Pre Tax Amt',
            'HST',
            'GST',
            'PST',
            'QST',
            'Trans Fee',
            'Disc Rate',
            'Disc Amt',
            'Final Amt',
            'CUR',
          ];

          // Add table header to csvRows
          csvRows.push(transactionsTableHeader);

          // Prepare the table data with subtotals
          const transactionsTableData = [];
          let totalPretaxamt = 0;
          let totalFinalamt = 0;

          // Iterate over each transaction
          group.transactions.forEach((tr) => {
            let subtotalGallons = 0;
            let subtotalPretaxamt = 0;
            let subtotalFinalamt = 0;

            // Ensure the `others` property exists and is an array
            if (tr.others && Array.isArray(tr.others)) {
              tr.others.forEach((other) => {
                const invoiceAmount = parseFloat(other.invoiceAmount);
                const transactionFee = parseFloat(other.transactionFee);
                const final = parseFloat(other.invoiceAmount).toFixed(2);
                const finalCash = (invoiceAmount + transactionFee).toFixed(2);
                const finalOther = final - other.discountAmount;
                const finalamt = other.type === 'Cash' ? finalCash : finalOther;

                subtotalGallons += parseFloat(other.gallons) || 0;
                subtotalPretaxamt += parseFloat(finalamt) || 0;
                subtotalFinalamt += parseFloat(finalamt) || 0;

                // Push each transaction row
                transactionsTableData.push([
                  tr.driverName || 'N/A',
                  tr.unitNumber || 'N/A',
                  tr.transactionDateTime || 'N/A',
                  tr.site || 'N/A',
                  tr.siteName || 'N/A',
                  tr.truckStopCity || 'N/A',
                  tr.truckStopState || 'N/A',
                  other.type || '',
                  other.gallons || '0',
                  other.unitPrice || '0',
                  other.unitPrice || '0',
                  other.invoiceAmount || '0',
                  '0', // Placeholder for 'HST'
                  '0', // Placeholder for 'GST'
                  '0', // Placeholder for 'PST'
                  '0', // Placeholder for 'QST'
                  other.transactionFee || 0,
                  other.discountPerGallon || '0', // Placeholder for 'Disc Rate'
                  other.discountAmount || '0', // Placeholder for 'Disc Amt'
                  finalamt || '0',
                  'US', // Currency
                ]);
              });

              // Add subtotal row after processing others data
              transactionsTableData.push([
                '',
                '',
                '',
                '',
                'Subtotal',
                '',
                '',
                '',
                subtotalGallons.toFixed(2),
                '',
                '',
                subtotalPretaxamt.toFixed(2),
                '0', // Placeholder for 'HST'
                '0', // Placeholder for 'GST'
                '0', // Placeholder for 'PST'
                '0', // Placeholder for 'QST'
                '',
                '0', // Placeholder for 'Disc Rate'
                '0', // Placeholder for 'Disc Amt'
                subtotalFinalamt.toFixed(2),
                'US', // Currency
              ]);

              // Update total accumulators
              totalPretaxamt += subtotalPretaxamt;
              totalFinalamt += subtotalFinalamt;
            }
          });

          // Add total row after processing all transactions
          transactionsTableData.push([
            '',
            '',
            '',
            '',
            'Total',
            '',
            '',
            '',
            '',
            '',
            '',
            totalPretaxamt.toFixed(2),
            '0', // Placeholder for 'HST'
            '0', // Placeholder for 'GST'
            '0', // Placeholder for 'PST'
            '0', // Placeholder for 'QST'
            '',
            '0', // Placeholder for 'Disc Rate'
            '0', // Placeholder for 'Disc Amt'
            totalFinalamt.toFixed(2),
            'US', // Currency
          ]);

          csvRows.push(...transactionsTableData);
          csvRows.push([]); // Empty row for separation
        }
      }

      // Calculate grand totals
      const grandTotals = {
        Diesel: {
          quantity: 0,
          cost: 0,
          unitPrice: 0,
          discountPerGallon: 0,
          discountAmount: 0,
          transactionFee: 0,
        },
        Def: {
          quantity: 0,
          cost: 0,
          unitPrice: 0,
          discountPerGallon: 0,
          discountAmount: 0,
          transactionFee: 0,
        },
        Reefer: {
          quantity: 0,
          cost: 0,
          unitPrice: 0,
          discountPerGallon: 0,
          discountAmount: 0,
          transactionFee: 0,
        },
        Cash: {
          quantity: 0,
          cost: 0,
          unitPrice: 0,
          discountPerGallon: 0,
          discountAmount: 0,
          transactionFee: 0,
        },
      };

      transactionsList.forEach((item) => {
        item.others.forEach((other) => {
          const type = other.type;
          if (grandTotals[type]) {
            grandTotals[type].quantity += parseFloat(other.gallons) || 0;
            grandTotals[type].cost += parseFloat(other.invoiceAmount) || 0;
            grandTotals[type].unitPrice += parseFloat(other.unitPrice) || 0;
            grandTotals[type].discountPerGallon += parseFloat(other.discountPerGallon) || 0;
            grandTotals[type].discountAmount += parseFloat(other.discountAmount) || 0;
            grandTotals[type].transactionFee += parseFloat(other.transactionFee) || 0;
          }
        });
      });

      // Define headers for the grand total table
      const grandTotalHeaders = [
        'Prod',
        'QTY',
        'Pre Tax Amt',
        'HST',
        'GST',
        'PST',
        'QST',
        'Trans Fee',
        'Disc Rate',
        'Disc Amt',
        'Final Amt',
        'CUR',
      ];

      // Define data for the grand total table
      const grandTotalData = Object.keys(grandTotals).map((type) => {
        const total = grandTotals[type];
        const grandPretaxamt = total.cost.toFixed(2);
        const grandFinalamt = total.discountAmount
          ? (grandPretaxamt - total.discountAmount).toFixed(2)
          : grandPretaxamt;

        return [
          type, // Product type
          total.quantity.toFixed(2), // Quantity
          grandPretaxamt, // Pre Tax Amount
          '0', // HST
          '0', // GST
          '0', // PST
          '0', // QST
          total.transactionFee.toFixed(2) || 0, // Transaction Fee
          total.discountPerGallon.toFixed(2) || 0, // Disc Rate
          total.discountAmount.toFixed(2) || 0, // Disc Amount
          grandFinalamt, // Final Amount
          'US', // Currency
        ];
      });

      // Add grand total data to CSV content
      csvRows.push([]);
      csvRows.push(['Grand Total']);

      // Add table header to csvRows
      csvRows.push(grandTotalHeaders);
      csvRows.push(...grandTotalData);

      // Convert CSV array to string
      const csvContent = csvRows.map((row) => row.join(',')).join('\n');

      // Create a Blob and URL for the CSV file
      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'invoice.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };

  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);

    // Re-fetch clients based on selected currency
    // getClients();

    // if (currency === 'CAD') {
    //   setDisableCAD(true);
    //   setDisableUSD(false); // Enable USD button
    //   // Disable the USD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableCAD(false);
    //   }, 10000); // 10 seconds
    // } else if (currency === 'USD') {
    //   setDisableUSD(true);
    //   setDisableCAD(false); // Enable CAD button
    //   // Disable the CAD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableUSD(false);
    //   }, 10000); // 10 seconds
    // }
  };

  const handleViewReportCAD = async (event, action) => {
    event.preventDefault();

    setUploading(true);

    // Validate dates
    if (!todayDate) {
      setDateErrorText('Date is required');
      return;
    }

    let companyXRef = ''; // or handle conditionally
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      // Handle the case where there is only one client
      const client = clients.find((client) => client.customerID === customerId);
      if (!client) {
        console.error(`Client not found for customerId: ${customerId}`);
        return; // Exit early or handle the error as needed
      }
      console.log('Client/CompanyXRef -', client.clientID);
      companyXRef = client.clientID;
    } else {
      // Ensure selectedClient is an object, and extract the customerID if necessary
      if (selectedClient === 'all') {
        console.log('All clients selected, no specific companyXRef required.');
        companyXRef = ''; // You can set it to 'all' or leave it undefined depending on how you want to handle this
      } else {
        // If selectedClient is an array, access the first element
        const selectedClientID =
          Array.isArray(selectedClient) && selectedClient.length > 0
            ? selectedClient[0]?.customerID
            : selectedClient?.customerID; // Extract the customerID from the first element of array or the selectedClient object

        console.log('Client/CompanyXRef selectedClientID -', selectedClientID);

        if (!selectedClientID) {
          console.error(`Selected client does not have a valid customerID: ${selectedClient}`);
          return;
        }

        const client = clients.find((client) => client.customerID === selectedClientID);
        if (!client) {
          console.error(`Client not found for selectedClient: ${selectedClientID}`);
          return; // Exit early or handle the error as needed
        }
        console.log('Client/CompanyXRef -', client.clientID);
        companyXRef = client.clientID;
      }
    }

    // Generate a random 9-digit invoice number
    const generatedInvoiceNumber = `#${Math.floor(100000000 + Math.random() * 900000000)}`;
    setInvoiceNumber(generatedInvoiceNumber);

    // Function to format date to 'YYYY-MM-DD'
    function formatDate(date) {
      return date.toISOString().split('T')[0];
    }

    // Assuming todayDate is defined, create the current date object
    const today = new Date(todayDate);
    let getTransactionDetailsUrl = `https://wex-backend.hub-ksgfuel.com/v1/transaction`;
    let startOfPreviousWeek, endOfPreviousWeek, startOfCurrentWeek, endOfCurrentWeek;

    // If today is Monday, calculate Thursday of the previous week and Sunday of the previous week
    if (today.getDay() === 1) {
      startOfPreviousWeek = new Date(today);
      startOfPreviousWeek.setDate(today.getDate() - today.getDay() - 2); // Thursday of previous week
      endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 3); // Sunday of previous week

      // Format the dates
      const formattedStartDate = formatDate(startOfPreviousWeek);
      const formattedEndDate = formatDate(endOfPreviousWeek);

      // Set the dates to be used for the transaction retrieval
      let retrieveTransactionsObject = {
        companyXRef: companyXRef,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };
      console.log('Request Object:', retrieveTransactionsObject);

      // await fetch(getTransactionDetailsUrl, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(retrieveTransactionsObject)
      // })

      // Prepare the URL with query parameters
      let urlWithParams = new URL(getTransactionDetailsUrl);
      const params = new URLSearchParams();

      // Append query parameters
      if (companyXRef !== undefined) {
        params.append('companyXRef', companyXRef);
      }
      params.append('startDate', formattedStartDate);
      params.append('endDate', formattedEndDate);

      // Attach the query parameters to the URL
      urlWithParams.search = params.toString();

      console.log('Request URL with Query Params:', urlWithParams.toString()); // Debugging line

      await fetch(urlWithParams.toString(), {
        method: 'GET', // Use GET to send query params
        headers: {
          // No 'Content-Type' header required for query params
        },
      })
        .then((response) => response.json())
        .then((transactionDetails) => {
          // Filter transactions based on date range
          let filteredTransactions = transactionDetails.filter((transaction) => {
            // Convert transactionDate to just the date (YYYY-MM-DD)
            const transactionDate = new Date(transaction.transactionDate);
            const transactionDateString = transactionDate.toLocaleDateString('en-CA', {
              timeZone: 'America/Chicago',
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }); // This returns the date as 'YYYY-MM-DD' format.

            // Filter by date (ignoring time)
            return (
              (!formattedStartDate || transactionDateString >= formattedStartDate) &&
              (!formattedEndDate || transactionDateString <= formattedEndDate)
            );
          });

          console.log('Filtered Transactions:', filteredTransactions); // Debugging line

          // Map filtered transactions to your desired format
          let transactionsList = [];
          setFetchedWithNoRecords(filteredTransactions.length === 0);
          for (let transactionDetail of filteredTransactions) {
            transactionsList.push(createDataCAD(transactionDetail));
          }
          setRows(transactionsList);
          setTransactionsList(transactionsList);
          console.log('transactionList', transactionsList);

          setUploading(true);

          if (action === 'generate') {
            handleGenerateInvoiceCAD(transactionsList, formattedStartDate, formattedEndDate);
          } else if (action === 'download') {
            handleDownloadCSVCAD(transactionsList, formattedStartDate, formattedEndDate);
          }
        })
        .catch((error) => {
          console.error('Error fetching transactions:', error);
        })
        .finally(() => {
          setUploading(false); // End loading, whether success or failure
        });
      // If today is Thursday, calculate Monday of the current week and Wednesday of the current week
    } else if (today.getDay() === 4) {
      setUploading(true);
      startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setDate(today.getDate() - today.getDay() + 2); // Monday of current week
      endOfCurrentWeek = new Date(startOfCurrentWeek);
      endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 2); // Wednesday of current week

      // Format the dates
      const formattedStartDate = formatDate(startOfCurrentWeek);
      const formattedEndDate = formatDate(endOfCurrentWeek);

      // Set the dates to be used for the transaction retrieval
      let retrieveTransactionsObject = {
        companyXRef: companyXRef,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      };

      console.log('Request Object:', retrieveTransactionsObject);

      // await fetch(getTransactionDetailsUrl, {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(retrieveTransactionsObject)
      // })
      // Prepare the URL with query parameters
      let urlWithParams = new URL(getTransactionDetailsUrl);
      const params = new URLSearchParams();

      // Append query parameters
      if (companyXRef !== undefined) {
        params.append('companyXRef', companyXRef);
      }
      params.append('startDate', formattedStartDate);
      params.append('endDate', formattedEndDate);

      // Attach the query parameters to the URL
      urlWithParams.search = params.toString();

      console.log('Request URL with Query Params:', urlWithParams.toString()); // Debugging line

      await fetch(urlWithParams.toString(), {
        method: 'GET', // Use GET to send query params
        headers: {
          // No 'Content-Type' header required for query params
        },
      })
        .then((response) => response.json())
        .then((transactionDetails) => {
          // Filter transactions based on date range
          let filteredTransactions = transactionDetails.filter((transaction) => {
            // Convert transactionDate to just the date (YYYY-MM-DD)
            const transactionDate = new Date(transaction.transactionDate);
            const transactionDateString = transactionDate.toLocaleDateString('en-CA', {
              timeZone: 'America/Chicago',
              hour12: false,
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }); // This returns the date as 'YYYY-MM-DD' format.

            // Filter by date (ignoring time)
            return (
              (!formattedStartDate || transactionDateString >= formattedStartDate) &&
              (!formattedEndDate || transactionDateString <= formattedEndDate)
            );
          });

          console.log('Filtered Transactions:', filteredTransactions); // Debugging line

          // Map filtered transactions to your desired format
          let transactionsList = [];
          setFetchedWithNoRecords(filteredTransactions.length === 0);
          for (let transactionDetail of filteredTransactions) {
            transactionsList.push(createDataCAD(transactionDetail));
          }

          setRows(transactionsList);
          setTransactionsList(transactionsList);
          console.log('transactionList', transactionsList);

          setUploading(true);
          if (action === 'generate') {
            handleGenerateInvoiceCAD(transactionsList, formattedStartDate, formattedEndDate);
          } else if (action === 'download') {
            handleDownloadCSVCAD(transactionsList, formattedStartDate, formattedEndDate);
          }
        })
        .catch((error) => {
          console.error('Error fetching transactions:', error);
        })
        .finally(() => {
          setUploading(false); // End loading, whether success or failure
        });
    }
  };

  const createDataCAD = (transactionDetail) => {
    let others = [];
    // let totalCost = 0.0;
    // console.log(transactionDetail + 'create data');

    if (transactionDetail && transactionDetail.lineItems) {
      // Checking if the line item with category "ULSD" exists
      const ulsdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'ULSD',
      );
      // console.log("ulsdLineItem", ulsdLineItem);

      const defdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'DEFD',
      );
      // console.log("defdLineItem", defdLineItem);

      const rfrLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'RFR',
      );
      // console.log("rfrLineItem", rfrLineItem);

      if (ulsdLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? ulsdLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        // Populate the data for "ULSD" line item
        others.push({
          product: 'DIESEL', // Product name for ULSD category
          qty: (ulsdLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (ulsdLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (ulsdLineItem.retailAmount / ulsdLineItem.quantity || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: doNotPerformCustomerFiltration
            ? (ulsdLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: doNotPerformCustomerFiltration
            ? (
                ulsdLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          pst: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ), // PST amount
          fet: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ), // FET amount
          pft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ), // PFT amount
          mft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ), // MFT amount
          ct: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: doNotPerformCustomerFiltration
            ? (ulsdLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3), // Savings from discount
          amount: doNotPerformCustomerFiltration
            ? (ulsdLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          customerPst: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3), // Savings
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
        // others.push(ulsdLineItem);
      } else if (defdLineItem) {
        const amount = defdLineItem.amount.toFixed(3) || 0;
        const customerAmount = defdLineItem.amount.toFixed(3) || 0;
        // Populate the data for "ULSD" line item
        others.push({
          product: 'DEF', // Product name for ULSD category
          qty: (defdLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (defdLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (defdLineItem.retailPPU || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: (defdLineItem.ppu || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3), // GST/HST amount
          pst: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ), // PST amount
          fet: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ), // FET amount
          pft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ), // PFT amount
          mft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ), // MFT amount
          ct: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: (defdLineItem.discAmount || 0).toFixed(3), // Savings from discount
          amount: (defdLineItem.amount || 0).toFixed(3), // Retail amount
          customerPpu: (defdLineItem.ppu || 0).toFixed(3),
          customerGstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3), // GST/HST amount
          customerPst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (defdLineItem.discAmount || 0).toFixed(3), // Savings
          customerAmount: (defdLineItem.amount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
      } else if (rfrLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? rfrLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        // Populate the data for "RFR" line item
        others.push({
          product: 'RFR', // Product name for RFR category
          qty: (rfrLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (rfrLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (rfrLineItem.retailAmount / rfrLineItem.quantity || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: doNotPerformCustomerFiltration
            ? (rfrLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: doNotPerformCustomerFiltration
            ? (
                rfrLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          pst: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(3), // PST amount
          fet: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(3), // FET amount
          pft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(3), // PFT amount
          mft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(3), // MFT amount
          ct: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: doNotPerformCustomerFiltration
            ? (rfrLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3), // Savings from discount
          amount: doNotPerformCustomerFiltration
            ? (rfrLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          customerPst: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3), // Savings
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
        // others.push(rfrLineItem);
      }
    }
    return {
      companyXRef: doNotPerformCustomerFiltration ? transactionDetail.companyXRef : '',
      transactionDate: `${transactionDetail?.transactionDate?.slice(0, 10)}`,
      cardNumber: transactionDetail?.cardNumber,
      driverName: transactionDetail.infos.find((info) => info.type === 'NAME')?.value || '',
      locationName: transactionDetail?.locationName,
      locationCity: transactionDetail?.locationCity,
      locationState: transactionDetail?.locationState,
      unitNumber: transactionDetail.infos.find((info) => info.type === 'UNIT')?.value || '',
      others: others,
    };
    // console.log(transactionDetail);
  };

  const handleGenerateInvoiceCAD = async (transactionsList, startDate, endDate) => {
    try {
      setUploading(true);
      console.log(`Generating PDF with invoice number: ${invoiceNumber}`);
      const doc = new jsPDF();
      const today = new Date(todayDate); // Use current date

      // Function to format date as yyyy-mm-dd
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || {};
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber =
        customerDetails[0]?.primaryContactNumber ||
        customerDetails[0]?.secondaryContactNumber ||
        'N/A';
      const email = customerDetails[0]?.primaryEmail || customerDetails[0]?.secondaryEmail || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      // const currentDate = new Date(today);
      // const startDate = startDate;	   // const endDate = endDate;

      const startDateFormatted = startDate;
      const endDateFormatted = endDate;

      // Convert endDate to a Date object if it isn't already one
      const endDateObject = new Date(endDateFormatted);
      let dueDateFormatted = new Date();
      // Check if the conversion was successful
      if (isNaN(endDateObject.getTime())) {
        console.error('Invalid date format:', endDateFormatted);
      } else {
        // Calculate the due date (3 days from the end of the previous week)
        const dueDate = new Date(endDateObject); // Create a new Date object from the end date
        dueDate.setDate(dueDate.getDate() + 3); // Add 3 days to the end date

        // Format the due date into yyyy-mm-dd format
        dueDateFormatted = formatDate(dueDate); // Format the due date

        console.log('Due Date:', dueDateFormatted);
      }

      // Define data for the invoice info table
      const invoiceInfoData = [
        [invoiceNumber, formatDate(today), startDateFormatted, endDateFormatted, dueDateFormatted],
      ];

      // Define column headers for the invoice info table
      const invoiceInfoHeaders = [
        'INVOICE NUMBER',
        'INVOICE DATE',
        'START DATE',
        'END DATE',
        'DUE DATE',
      ];

      // Draw the black rectangle behind the logo
      doc.setFillColor(0, 0, 0); // RGB for black
      doc.rect(10, 10, 35, 25, 'F'); // Rectangle position and size

      const img = new Image();
      img.src = logo; // Ensure this path is correct

      img.onload = () => {
        doc.addImage(img, 'PNG', 10, 10, 35, 25);

        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('KSG FUEL', 50, 15);
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('ADDRESS: 2575 Steeles Ave E, Unit 1,', 50, 18);
        doc.text('Brampton, Ontario, Canada, L6T 5T1', 50, 21);
        doc.text('OFFICE: +1-(905) 965-0308', 50, 24);
        doc.text('TOLL FREE: ', 50, 27);
        doc.text('WEBSITE: www.ksgfuel.com', 50, 30);
        doc.text('EMAIL: support@ksgfuel.com', 50, 33);

        // Add client information in the top right corner
        const pageWidth = doc.internal.pageSize.getWidth();
        const rightMargin = 100; // Distance from the right edge of the page
        const startX = pageWidth - rightMargin;

        // Client Info title
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text('Client Info', startX, 15, { align: 'left' });

        // Client Details
        doc.setFontSize(9);
        doc.setFont('times', 'bold');
        doc.text(customerName, startX, 20, { align: 'left' });
        doc.setFontSize(8);
        doc.setFont('times', 'normal');
        doc.text('ADDRESS: ' + customerAddress, startX, 23, { align: 'left' });
        doc.text('PHONE: ' + contactNumber, startX, 26, { align: 'left' });
        doc.text('EMAIL: ' + email, startX, 29, { align: 'left' });

        // Add invoice info table
        doc.autoTable({
          head: [invoiceInfoHeaders],
          body: invoiceInfoData,
          startY: 40,
          margin: { left: 10, right: 90 },
          theme: 'striped',
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 6,
            cellPadding: 2,
          },
          styles: {
            fontSize: 6, // Set font size for body cells
            cellPadding: 2,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto', // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
          },
        });

        let yOffset = doc.lastAutoTable.finalY + 5;

        // Add a new page for the grand total table
        // doc.addPage();
        // Add card number text
        doc.setFontSize(10);
        doc.setFont('times', 'bold');
        doc.text(`GRAND TOTAL: `, 10, yOffset);

        yOffset += 5;

        // Initialize grand total accumulators
        const grandTotals = {
          DIESEL: {
            qty: 0,
            retailAmount: 0,
            customerGstHst: 0,
            customerMft: 0,
            customerPst: 0,
            customerPft: 0,
            customerSavings: 0,
            customerAmount: 0,
          },
          DEF: {
            qty: 0,
            retailAmount: 0,
            customerGstHst: 0,
            customerMft: 0,
            customerPst: 0,
            customerPft: 0,
            customerSavings: 0,
            customerAmount: 0,
          },
          RFR: {
            qty: 0,
            retailAmount: 0,
            customerGstHst: 0,
            customerMft: 0,
            customerPst: 0,
            customerPft: 0,
            customerSavings: 0,
            customerAmount: 0,
          },
        };

        // Iterate over each transaction and accumulate totals
        transactionsList.forEach((item) => {
          item.others.forEach((other) => {
            const product = other.product;
            if (grandTotals[product]) {
              grandTotals[product].qty += parseFloat(other.qty) || 0;
              grandTotals[product].retailAmount += parseFloat(other.retailAmount) || 0;
              grandTotals[product].customerGstHst += parseFloat(other.customerGstHst) || 0;
              grandTotals[product].customerMft += parseFloat(other.customerMft) || 0;
              grandTotals[product].customerPst += parseFloat(other.customerPst) || 0;
              grandTotals[product].customerPft += parseFloat(other.customerPft) || 0;
              grandTotals[product].customerSavings += parseFloat(other.customerSavings) || 0;
              grandTotals[product].customerAmount += parseFloat(other.customerAmount) || 0;
            }
          });
        });

        // Define headers for the grand total table
        const grandTotalHeaders = [
          'PRODUCT',
          'QTY',
          'RETAIL AMT',
          'HST',
          'MFT',
          'PST',
          'PFT',
          // 'Trans Fee',
          // 'Disc Rate',
          'CUST SAVINGS',
          'CUST AMT',
          'CUR',
        ];

        // Define data for the grand total table
        const grandTotalData = Object.keys(grandTotals).map((product) => {
          const total = grandTotals[product];
          const grandRetailAmt = total.retailAmount.toFixed(3);
          const grandCustomerAmt = total.customerAmount.toFixed(3);

          return [
            product, // Product type
            total.qty.toFixed(3), // Quantity
            grandRetailAmt, // Pre Tax Amount
            total.customerGstHst.toFixed(3), // HST
            total.customerMft.toFixed(3), // MFT
            total.customerPst.toFixed(3), // PST
            total.customerPft.toFixed(3), // PFT
            // total.transactionFee.toFixed(2) || 0, //Transaction Fee
            // total.discountPerGallon.toFixed(2) || 0, // Disc Rate
            total.customerSavings.toFixed(3) || 0, // Disc Amount
            grandCustomerAmt, // Final Amount
            'CAD', // Currency
          ];
        });

        // Calculate grand total row
        const grandTotalRow = Object.values(grandTotals).reduce(
          (acc, typeTotals) => {
            acc.qty += typeTotals.qty;
            acc.retailAmount += typeTotals.retailAmount;
            acc.customerGstHst += typeTotals.customerGstHst;
            acc.customerMft += typeTotals.customerMft;
            acc.customerPst += typeTotals.customerPst;
            acc.customerPft += typeTotals.customerPft;
            acc.customerSavings += typeTotals.customerSavings;
            acc.customerAmount += typeTotals.customerAmount;
            return acc;
          },
          {
            qty: 0,
            retailAmount: 0,
            customerGstHst: 0,
            customerMft: 0,
            customerPst: 0,
            customerPft: 0,
            customerSavings: 0,
            customerAmount: 0,
          },
        );

        const grandTotalRetailAmt = grandTotalRow.retailAmount.toFixed(3);
        const grandTotalCustAmt = grandTotalRow.customerAmount.toFixed(3);

        // Define total row data
        const totalRow = [
          'Total',
          grandTotalRow.qty.toFixed(3), // Total Quantity
          grandTotalRetailAmt, // Total Pre Tax Amount
          grandTotalRow.customerGstHst.toFixed(3), // Total HST
          grandTotalRow.customerMft.toFixed(3), // Total MFT
          grandTotalRow.customerPst.toFixed(3), // Total PST
          grandTotalRow.customerPft.toFixed(3), // Total PFT
          // grandTotalRow.transactionFee.toFixed(2) || 0,
          // grandTotalRow.discountPerGallon.toFixed(2) || 0, // Disc Rate (Left blank if not applicable)
          grandTotalRow.customerSavings.toFixed(3), // Total Disc Amount
          grandTotalCustAmt || 0, // Total Final Amount
          'CAD', // Currency
        ];

        // Add grand total table
        doc.autoTable({
          head: [grandTotalHeaders],
          body: [...grandTotalData, totalRow],
          startY: yOffset,
          theme: 'striped',
          margin: { left: 10, right: 10 },
          headStyles: {
            fillColor: [1, 31, 75], // Hex color #8e918f in RGB
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            fontSize: 5,
            cellPadding: 1,
          },
          styles: {
            fontSize: 5, // Set font size for body cells
            cellPadding: 1,
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
            overflow: 'linebreak',
            cellWidth: 'auto', // Adjust column widths as needed
          },
          columnStyles: {
            0: { cellWidth: 'auto' }, // Adjust column widths as needed
            1: { cellWidth: 'auto' },
            2: { cellWidth: 'auto' },
            3: { cellWidth: 'auto' },
            4: { cellWidth: 'auto' },
            5: { cellWidth: 'auto' },
            6: { cellWidth: 'auto' },
            7: { cellWidth: 'auto' },
            8: { cellWidth: 'auto' },
            9: { cellWidth: 'auto' },
            10: { cellWidth: 'auto' },
            11: { cellWidth: 'auto' },
            12: { cellWidth: 'auto' },
            13: { cellWidth: 'auto' },
          },
          didDrawCell: (data) => {
            // Check if it's the last row (total row)
            if (data.row.index === grandTotalData.length) {
              // Apply dark blue background color
              doc.setFillColor(1, 31, 75);
              doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

              // Apply white text color
              doc.setTextColor(255, 255, 255);

              // Redraw the text with adjusted x and y for alignment
              // Adjust the x and y offsets to properly align the text within the cell
              const text = String(data.cell.text);

              // Calculate horizontal alignment
              const textWidth = doc.getTextWidth(text);
              const x = data.cell.x + (data.cell.width - textWidth) / 2;

              // Calculate vertical alignment
              const fontSize = doc.internal.getFontSize();
              const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

              // Draw the text centered in the cell
              doc.text(text, x, y);
            }
          },
        });

        // Group transactions by card number and calculate totals
        const groupedByCard = transactionsList.reduce((acc, item) => {
          if (!acc[item.cardNumber]) {
            acc[item.cardNumber] = {
              cardNumber: item.cardNumber,
              diesel: { qty: 0, customerAmount: 0, customerPpu: 0 },
              def: { qty: 0, customerAmount: 0, customerPpu: 0 },
              reefer: { qty: 0, customerAmount: 0, customerPpu: 0 },
              cash: { qty: 0, customerAmount: 0, customerPpu: 0 },
              transactions: [],
            };
          }

          // Iterate through each product in the others array
          item.others.forEach((other) => {
            const product = other.product;
            const transaction = acc[item.cardNumber];

            if (product === 'DIESEL') {
              transaction.diesel.qty += parseFloat(other.qty) || 0;
              transaction.diesel.customerAmount += parseFloat(other.customerAmount) || 0;
              transaction.diesel.customerPpu += parseFloat(other.customerPpu) || 0;
            } else if (product === 'DEF') {
              transaction.def.qty += parseFloat(item.qty) || 0;
              transaction.def.customerAmount += parseFloat(item.amount) || 0;
              transaction.def.customerPpu += parseFloat(item.ppu) || 0;
            } else if (product === 'RFR') {
              transaction.reefer.qty += parseFloat(other.qty) || 0;
              transaction.reefer.customerAmount += parseFloat(other.customerAmount) || 0;
              transaction.reefer.customerPpu += parseFloat(other.customerPpu) || 0;
            }
          });

          acc[item.cardNumber].transactions.push(item);
          return acc;
        }, {});

        yOffset = doc.lastAutoTable.finalY + 5;

        // Handle empty transactions list
        if (transactionsList.length === 0) {
          // Define column headers for the transaction table
          const headersNoRecords = [
            'DRIVER NAME',
            'UNIT NUMBER',
            'DATE',
            // 'Site #',
            // 'Site Name',
            'CITY',
            'PROV/STATE',
            'PRODUCT',
            'QTY',
            'RETAIL PPU',
            'CUST PPU',
            'RETAIL AMT',
            'HST',
            'MFT',
            'PST',
            'PFT',
            // 'Trans Fee',
            // 'Disc Rate',
            'CUST SAVINGS',
            'CUST AMT',
            'CUR',
          ];

          const noRecordsData = [
            [
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              'NO TRANSACTION RESULTS FOUND',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
              '',
            ],
          ];

          // Add table for this card number
          doc.autoTable({
            head: [headersNoRecords],
            body: noRecordsData,
            startY: yOffset,
            theme: 'striped',
            margin: { left: 10, right: 10 },
            headStyles: {
              fillColor: [1, 31, 75], // Hex color #8e918f in RGB
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              fontSize: 3,
              cellPadding: 1,
            },
            styles: {
              fontSize: 3, // Set font size for body cells
              cellPadding: 1,
              halign: 'center', // Horizontal alignment
              valign: 'middle', // Vertical alignment
              overflow: 'linebreak',
              cellWidth: 'auto', // Adjust column widths as needed
            },
            columnStyles: {
              0: { cellWidth: 'auto' }, // Adjust column widths as needed
              1: { cellWidth: 'auto' },
              2: { cellWidth: 'auto' },
              3: { cellWidth: 'auto' },
            },
          });
          // Update yOffset for the next table
          yOffset = doc.lastAutoTable.finalY + 5;
        } else {
          Object.values(groupedByCard).forEach((group) => {
            // Add card number text
            doc.setFontSize(8);
            doc.setFont('times', 'bold');
            doc.text(`TRANSACTION FOR CARD: ${group.cardNumber}`, 10, yOffset);
            yOffset += 5; // Add some space between the card number and the table

            const transactions = group.transactions;

            console.log('Transaction for card', transactions);

            // Add card number and transactions table
            const transactionsTableHeader = [
              'DRIVER NAME',
              'UNIT NUMBER',
              'DATE',
              // 'Site #',
              // 'Site Name',
              'CITY',
              'PROV/STATE',
              'PRODUCT',
              'QTY',
              'RETAIL PPU',
              'CUST PPU',
              'RETAIL AMT',
              'HST',
              'MFT',
              'PST',
              'PFT',
              // 'Trans Fee',
              // 'Disc Rate',
              'CUST SAVINGS',
              'CUST AMT',
              'CUR',
            ];

            // Prepare the table data with subtotals
            const transactionsTableData = [];

            // Initialize total accumulators
            let totalGallons = 0;
            let totalPretaxamt = 0;
            let totalFinalamt = 0;
            let totalHST = 0;
            let totalMFT = 0;
            let totalPST = 0;
            let totalPFT = 0;
            let totalSavings = 0;

            // Iterate over each transaction
            transactions.forEach((tr) => {
              let subtotalGallons = 0;
              let subtotalPretaxamt = 0;
              let subtotalFinalamt = 0;
              let subtotalHST = 0;
              let subtotalMFT = 0;
              let subtotalPST = 0;
              let subtotalPFT = 0;
              let subtotalSavings = 0;

              // Ensure the `others` property exists and is an array
              if (tr.others && Array.isArray(tr.others)) {
                tr.others.forEach((other) => {
                  subtotalGallons += parseFloat(other.qty) || 0;
                  subtotalPretaxamt += parseFloat(other.retailAmount) || 0;
                  subtotalFinalamt += parseFloat(other.customerAmount) || 0;
                  subtotalHST += parseFloat(other.customerGstHst) || 0;
                  subtotalMFT += parseFloat(other.customerMft) || 0;
                  subtotalPST += parseFloat(other.customerPst) || 0;
                  subtotalPFT += parseFloat(other.customerPft) || 0;
                  subtotalSavings += parseFloat(other.customerSavings) || 0;

                  // Push each transaction row
                  transactionsTableData.push([
                    tr.driverName || 'N/A',
                    tr.unitNumber || 'N/A',
                    tr.transactionDate || 'N/A',
                    // '' || 'N/A',
                    // '' || 'N/A',
                    tr.locationCity || 'N/A',
                    tr.locationState || 'N/A',
                    other.product || '',
                    other.qty || '0',
                    other.retailPPU || '0',
                    other.customerPpu || '0',
                    other.retailAmount || '0',
                    other.customerGstHst || '0', // Placeholder for 'HST/GST'
                    other.customerMft || '0', // Placeholder for 'MFT'
                    other.customerPst || '0', // Placeholder for 'PST'
                    other.customerPft || '0', // Placeholder for 'PFT',
                    // '0',
                    // '0', // Placeholder for 'Disc Rate'
                    other.customerSavings || '0', // Placeholder for 'Disc Amt'
                    other.customerAmount || '0',
                    'CAD', // Currency
                  ]);
                });

                // Add subtotal row after processing others data
                transactionsTableData.push([
                  '',
                  '',
                  '',
                  // '',
                  'SUBTOTAL',
                  // '',
                  '',
                  '',
                  subtotalGallons.toFixed(3),
                  '',
                  '',
                  subtotalPretaxamt.toFixed(3),
                  subtotalHST.toFixed(3), // Placeholder for 'HST'
                  subtotalMFT.toFixed(3), // Placeholder for 'MFT'
                  subtotalPST.toFixed(3), // Placeholder for 'PST'
                  subtotalPFT.toFixed(3), // Placeholder for 'PFT'
                  // '',
                  // '0', // Placeholder for 'Disc Rate'
                  subtotalSavings.toFixed(3), // Placeholder for 'Disc Amt'
                  subtotalFinalamt.toFixed(3),
                  'CAD', // Currency
                ]);

                // Update total accumulators
                totalGallons += subtotalGallons;
                totalPretaxamt += subtotalPretaxamt;
                totalFinalamt += subtotalFinalamt;
                totalHST += subtotalHST;
                totalMFT += subtotalMFT;
                totalPST += subtotalPST;
                totalPFT += subtotalPFT;
                totalSavings += subtotalSavings;
              }
            });

            // Add total row after processing all transactions
            transactionsTableData.push([
              '',
              '',
              '',
              // '',
              'TOTAL',
              // '',
              '',
              '',
              totalGallons.toFixed(3),
              '',
              '',
              totalPretaxamt.toFixed(3),
              totalHST.toFixed(3), // Placeholder for 'HST'
              totalMFT.toFixed(3), // Placeholder for 'MFT'
              totalPST.toFixed(3), // Placeholder for 'PST'
              totalPFT.toFixed(3), // Placeholder for 'PFT'
              // '',
              // '0', // Placeholder for 'Disc Rate'
              totalSavings.toFixed(3), // Placeholder for 'Disc Amt'
              totalFinalamt.toFixed(3),
              'CAD', // Currency
            ]);

            doc.autoTable({
              head: [transactionsTableHeader],
              body: transactionsTableData,
              startY: yOffset,
              theme: 'striped',
              margin: { left: 10, right: 10 },
              headStyles: {
                fillColor: [1, 31, 75],
                halign: 'center',
                valign: 'middle',
                fontSize: 4,
                cellPadding: 1,
              },
              styles: {
                fontSize: 4,
                cellPadding: 1,
                halign: 'center',
                valign: 'middle',
                overflow: 'linebreak',
                cellWidth: 'auto',
              },
              columnStyles: {
                0: { cellWidth: 'auto' },
                1: { cellWidth: 'auto' },
                2: { cellWidth: 'auto' },
                3: { cellWidth: 'auto' },
                4: { cellWidth: 'auto' },
                5: { cellWidth: 'auto' },
                6: { cellWidth: 'auto' },
                7: { cellWidth: 'auto' },
                8: { cellWidth: 'auto' },
                9: { cellWidth: 'auto' },
                10: { cellWidth: 'auto' },
                11: { cellWidth: 'auto' },
                12: { cellWidth: 'auto' },
                13: { cellWidth: 'auto' },
                14: { cellWidth: 'auto' },
                15: { cellWidth: 'auto' },
                16: { cellWidth: 'auto' },
                17: { cellWidth: 'auto' },
                18: { cellWidth: 'auto' },
                19: { cellWidth: 'auto' },
              },
              didDrawCell: (data) => {
                const lastRowIndex = transactionsTableData.length - 1;
                //const secondLastRowIndex = transactionsTableData.length - 2;

                // Check if it's the last or second last row
                if (data.row.index === lastRowIndex) {
                  // Apply dark blue background color
                  doc.setFillColor(1, 31, 75); // Dark blue background
                  doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F'); // Fill the cell with background color

                  // Apply white text color
                  doc.setTextColor(255, 255, 255);

                  // Ensure text is a string
                  const text = String(data.cell.text);

                  // Calculate horizontal alignment
                  const textWidth = doc.getTextWidth(text);
                  const x = data.cell.x + (data.cell.width - textWidth) / 2;

                  // Calculate vertical alignment
                  const fontSize = doc.internal.getFontSize();
                  const y = data.cell.y + data.cell.height / 2 + fontSize / 4; // Adjusting to center vertically

                  // Draw the text centered in the cell
                  doc.text(text, x, y);
                }
              },
            });

            yOffset = doc.lastAutoTable.finalY + 5;
          });
        }

        // Update yOffset for the next section
        yOffset = doc.lastAutoTable.finalY + 5;

        const startDateObj = new Date(startDate); // Ensure this is a Date object
        const endDateObj = new Date(endDate); // Ensure this is a Date object

        // Format the dates for the filename (e.g., Dec16-18)
        const startDateFormattedNew = `${startDateObj.toLocaleString('en-US', {
          month: 'short',
        })}${startDateObj.getDate()}`;
        const endDateFormattedNew = `${endDateObj.toLocaleString('en-US', {
          month: 'short',
        })}${endDateObj.getDate()}`;

        // Convert the PDF to a Blob and create a URL
        const pdfBlob = doc.output('blob');
        // const pdfUrl = URL.createObjectURL(pdfBlob);

        // // Display the PDF in an iframe
        // const iframe = document.createElement('iframe');
        // iframe.src = pdfUrl;
        // iframe.style.width = '100%';
        // iframe.style.height = '600px';
        // iframe.style.border = 'none';

        // // Append the iframe to a container element
        // const pdfContainer = document.getElementById('pdf-container');
        // pdfContainer.innerHTML = ''; // Clear previous content
        // pdfContainer.appendChild(iframe);

        // Prepare to send the PDF to the backend
        const formData = new FormData();
        formData.append('invoiceId', invoiceNumber);
        formData.append('clientId', clientId);
        formData.append('customerId', customerId);
        formData.append('customerName', customerName);
        // formData.append('startDate', startDateFormattedNew);
        // formData.append('endDate', endDateFormattedNew);

        // Append the generated PDF as a file to the FormData
        formData.append(
          'file',
          pdfBlob,
          `Invoice_${clientId}_${customerId}_${startDateFormattedNew}-${endDateFormattedNew}.pdf`,
        );

        // Show alert before sending email for PDF
        alert('Sending email with PDF...');

        // Send the POST request to the backend
        fetch('https://wex-backend.hub-ksgfuel.com/v1/send-invoice-approval-email', {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.message === 'Approval email sent successfully') {
              alert('Email sent successfully with PDF');
              console.log('Email sent successfully');
            } else {
              alert('Error sending email with PDF');
              console.error('Error sending email:', data.message);
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          })
          .finally(() => {
            setUploading(false); // End loading after the async action finishes
          });

        doc.save(
          `Invoice_${clientId}_${customerId}_${startDateFormattedNew}-${endDateFormattedNew}.pdf`,
        );
      };

      img.onerror = (error) => {
        console.error('Error loading image:', error);
        // Handle the error case where the image couldn't be loaded
      };
    } catch (error) {
      console.error('Error generating invoice:', error);
    } finally {
      setUploading(false);
    }
  };

  // CSV download handler
  const handleDownloadCSVCAD = async (transactionsList, startDate, endDate) => {
    try {
      setUploading(true);

      if (!invoiceNumber) return;
      console.log(`Generating CSV with invoice number: ${invoiceNumber}`);

      const today = new Date(todayDate);
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };

      // Use customerDetails from state
      const customerDetails = selectedClient || {};
      const customerName = customerDetails[0]?.clientName || 'N/A';
      const customerAddress = customerDetails[0]?.address || 'N/A';
      const contactNumber =
        customerDetails[0]?.primaryContactNumber ||
        customerDetails[0]?.secondaryContactNumber ||
        'N/A';
      const email = customerDetails[0]?.primaryEmail || customerDetails[0]?.secondaryEmail || 'N/A';

      // Calculate the start and end of the previous week (Monday to Sunday)
      // const currentDate = new Date(today);
      // const startDate = startDate;	   // const endDate = endDate;

      const startDateFormatted = new Date(startDate);
      const endDateFormatted = new Date(endDate);

      // Convert endDate to a Date object if it isn't already one
      const endDateObject = new Date(endDateFormatted);
      let dueDateFormatted = new Date();
      // Check if the conversion was successful
      if (isNaN(endDateObject.getTime())) {
        console.error('Invalid date format:', endDateFormatted);
      } else {
        // Calculate the due date (3 days from the end of the previous week)
        const dueDate = new Date(endDateObject); // Create a new Date object from the end date
        dueDate.setDate(dueDate.getDate() + 3); // Add 3 days to the end date

        // Format the due date into yyyy-mm-dd format
        dueDateFormatted = formatDate(dueDate); // Format the due date

        console.log('Due Date:', dueDateFormatted);
      }

      // Prepare CSV content
      const csvRows = [
        ['INVOICE NUMBER', 'INVOICE DATE', 'START DATE', 'END DATE', 'DUE DATE'],
        [invoiceNumber, formatDate(today), startDateFormatted, endDateFormatted, dueDateFormatted],
        [], // Empty row for separation
        ['CUSTOMER NAME', 'ADDRESS', 'PHONE', 'EMAIL'],
        [customerName, `"${customerAddress}"`, contactNumber, email],
      ];

      csvRows.push([]); // Empty row for separation
      csvRows.push([]); // Empty row for separation

      // Calculate grand totals
      const grandTotals = {
        DIESEL: {
          qty: 0,
          retailAmount: 0,
          customerGstHst: 0,
          customerMft: 0,
          customerPst: 0,
          customerPft: 0,
          customerSavings: 0,
          customerAmount: 0,
        },
        DEF: {
          qty: 0,
          retailAmount: 0,
          customerGstHst: 0,
          customerMft: 0,
          customerPst: 0,
          customerPft: 0,
          customerSavings: 0,
          customerAmount: 0,
        },
        RFR: {
          qty: 0,
          retailAmount: 0,
          customerGstHst: 0,
          customerMft: 0,
          customerPst: 0,
          customerPft: 0,
          customerSavings: 0,
          customerAmount: 0,
        },
      };

      transactionsList.forEach((item) => {
        item.others.forEach((other) => {
          const product = other.product;
          if (grandTotals[product]) {
            grandTotals[product].qty += parseFloat(other.qty) || 0;
            grandTotals[product].retailAmount += parseFloat(other.retailAmount) || 0;
            grandTotals[product].customerGstHst += parseFloat(other.customerGstHst) || 0;
            grandTotals[product].customerMft += parseFloat(other.customerMft) || 0;
            grandTotals[product].customerPst += parseFloat(other.customerPst) || 0;
            grandTotals[product].customerPft += parseFloat(other.customerPft) || 0;
            grandTotals[product].customerSavings += parseFloat(other.customerSavings) || 0;
            grandTotals[product].customerAmount += parseFloat(other.customerAmount) || 0;
          }
        });
      });

      // Calculate grand total row
      const totalRow = Object.values(grandTotals).reduce(
        (acc, typeTotals) => {
          acc.qty += typeTotals.qty;
          acc.retailAmount += typeTotals.retailAmount;
          acc.customerGstHst += typeTotals.customerGstHst;
          acc.customerMft += typeTotals.customerMft;
          acc.customerPst += typeTotals.customerPst;
          acc.customerPft += typeTotals.customerPft;
          acc.customerSavings += typeTotals.customerSavings;
          acc.customerAmount += typeTotals.customerAmount;
          return acc;
        },
        {
          qty: 0,
          retailAmount: 0,
          customerGstHst: 0,
          customerMft: 0,
          customerPst: 0,
          customerPft: 0,
          customerSavings: 0,
          customerAmount: 0,
        },
      );

      // Define headers for the grand total table
      const grandTotalHeaders = [
        'PRODUCT',
        'QTY',
        'RETAIL AMT',
        'HST',
        'MFT',
        'PST',
        'PFT',
        // 'Trans Fee',
        // 'Disc Rate',
        'CUST SAVINGS',
        'CUST AMT',
        'CUR',
      ];

      // Define data for the grand total table
      const grandTotalData = Object.keys(grandTotals).map((product) => {
        const total = grandTotals[product];
        const grandRetailAmt = total.retailAmount.toFixed(3);
        const grandCustomerAmt = total.customerAmount.toFixed(3);

        return [
          product, // Product type
          total.qty.toFixed(3), // Quantity
          grandRetailAmt, // Pre Tax Amount
          total.customerGstHst.toFixed(3), // HST
          total.customerMft.toFixed(3), // MFT
          total.customerPst.toFixed(3), // PST
          total.customerPft.toFixed(3), // PFT
          // total.transactionFee.toFixed(2) || 0, //Transaction Fee
          // total.discountPerGallon.toFixed(2) || 0, // Disc Rate
          total.customerSavings.toFixed(3) || 0, // Disc Amount
          grandCustomerAmt, // Final Amount
          'CAD', // Currency
        ];
      });

      // Format the total row data
      const grandTotalRetailAmt = totalRow.retailAmount.toFixed(3);
      const grandTotalCustAmt = totalRow.customerAmount.toFixed(3);

      const totalRowData = [
        'Total',
        totalRow.qty.toFixed(3), // Total Quantity
        grandTotalRetailAmt, // Total Pre Tax Amount
        totalRow.customerGstHst.toFixed(3), // Total HST
        totalRow.customerMft.toFixed(3), // Total MFT
        totalRow.customerPst.toFixed(3), // Total PST
        totalRow.customerPft.toFixed(3), // Total PFT
        // Total transaction fee or discount rate if applicable
        totalRow.customerSavings.toFixed(3), // Total Savings
        grandTotalCustAmt, // Total Customer Amount
        'CAD', // Currency
      ];

      // Add grand total data to CSV content
      csvRows.push([]);
      csvRows.push(['GRAND TOTAL']);

      // Add table header to csvRows
      csvRows.push(grandTotalHeaders);
      csvRows.push(...grandTotalData);
      csvRows.push(totalRowData); // Total row

      csvRows.push([]); // Empty row for separation
      csvRows.push([]); // Empty row for separation
      // Group transactions by card number
      const groupedByCard = transactionsList.reduce((acc, transaction) => {
        const cardNumber = transaction.cardNumber; // Change this if the attribute name is different
        if (!acc[cardNumber]) {
          acc[cardNumber] = { cardNumber, transactions: [] };
        }
        acc[cardNumber].transactions.push(transaction);
        return acc;
      }, {});

      // Handle empty transactions list
      if (transactionsList.length === 0) {
        const headersNoRecords = [
          'DRIVER NAME',
          'UNIT NUMBER',
          'DATE',
          // 'Site #',
          // 'Site Name',
          'CITY',
          'PROV/STATE',
          'PROD',
          'QTY',
          'RETAIL PPU',
          'BILLED PPU',
          'RETAIL AMT',
          'HST',
          'MFT',
          'PST',
          'PFT',
          // 'Trans Fee',
          // 'Disc Rate',
          'SAVINGS',
          'CUST AMT',
          'CUR',
        ];
        // Add table header to csvRows
        csvRows.push(headersNoRecords);

        const noRecordsData = [
          [
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            'No Transaction Results Found',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ],
        ];

        csvRows.push(...noRecordsData);
      } else {
        // Iterate over each group
        for (const group of Object.values(groupedByCard)) {
          // Add card number
          csvRows.push([`TRANSACTION FOR CARD: ${group.cardNumber}`]);
          csvRows.push([]); // Empty row for separation

          // Define headers for the transactions table
          const transactionsTableHeader = [
            'DRIVER NAME',
            'UNIT NUMBER',
            'DATE',
            // 'Site #',
            // 'Site Name',
            'CITY',
            'PROV/STATE',
            'PROD',
            'QTY',
            'RETAIL PPU',
            'BILLED PPU',
            'RETAIL AMT',
            'HST',
            'MFT',
            'PST',
            'PFT',
            // 'Trans Fee',
            // 'Disc Rate',
            'SAVINGS',
            'CUST AMT',
            'CUR',
          ];

          // Add table header to csvRows
          csvRows.push(transactionsTableHeader);

          // Prepare the table data with subtotals
          const transactionsTableData = [];
          let totalGallons = 0;
          let totalPretaxamt = 0;
          let totalFinalamt = 0;
          let totalHST = 0;
          let totalMFT = 0;
          let totalPST = 0;
          let totalPFT = 0;
          let totalSavings = 0;

          // Iterate over each transaction
          group.transactions.forEach((tr) => {
            let subtotalGallons = 0;
            let subtotalPretaxamt = 0;
            let subtotalFinalamt = 0;
            let subtotalHST = 0;
            let subtotalMFT = 0;
            let subtotalPST = 0;
            let subtotalPFT = 0;
            let subtotalSavings = 0;

            // Ensure the `others` property exists and is an array
            if (tr.others && Array.isArray(tr.others)) {
              tr.others.forEach((other) => {
                const invoiceAmount = parseFloat(other.invoiceAmount);
                const transactionFee = parseFloat(other.transactionFee);
                const final = parseFloat(other.invoiceAmount).toFixed(2);
                const finalCash = (invoiceAmount + transactionFee).toFixed(2);
                const finalOther = final - other.discountAmount;
                const finalamt = other.type === 'Cash' ? finalCash : finalOther;

                //const product = other.product;
                // const transaction = acc[tr.cardNumber];

                subtotalGallons += parseFloat(other.qty) || 0;
                subtotalPretaxamt += parseFloat(other.retailAmount) || 0;
                subtotalFinalamt += parseFloat(other.customerAmount) || 0;
                subtotalHST += parseFloat(other.customerGstHst) || 0;
                subtotalMFT += parseFloat(other.customerMft) || 0;
                subtotalPST += parseFloat(other.customerPst) || 0;
                subtotalPFT += parseFloat(other.customerPft) || 0;
                subtotalSavings += parseFloat(other.customerSavings) || 0;

                subtotalGallons += parseFloat(other.gallons) || 0;
                subtotalPretaxamt += parseFloat(finalamt) || 0;
                subtotalFinalamt += parseFloat(finalamt) || 0;

                // Push each transaction row
                transactionsTableData.push([
                  tr.driverName || 'N/A',
                  tr.unitNumber || 'N/A',
                  tr.transactionDate || 'N/A',
                  // tr.site || 'N/A',
                  // tr.siteName || 'N/A',
                  tr.locationCity || 'N/A',
                  tr.locationState || 'N/A',
                  other.product || '',
                  other.qty || '0',
                  other.retailPPU || '0',
                  other.customerPpu || '0',
                  other.retailAmount || '0',
                  other.customerGstHst || '0', // Placeholder for 'HST/GST'
                  other.customerMft || '0', // Placeholder for 'MFT'
                  other.customerPst || '0', // Placeholder for 'PST'
                  other.customerPft || '0', // Placeholder for 'PFT',
                  // other.transactionFee || 0,
                  // other.discountPerGallon || '0', // Placeholder for 'Disc Rate'
                  other.customerSavings || '0', // Placeholder for 'Disc Amt'
                  other.customerAmount || '0',
                  'CAD', // Currency
                ]);
              });

              // Add subtotal row after processing others data
              transactionsTableData.push([
                '',
                '',
                '',
                // '',
                'SUBTOTAL',
                // '',
                '',
                '',
                subtotalGallons.toFixed(3),
                '',
                '',
                subtotalPretaxamt.toFixed(3),
                subtotalHST.toFixed(3), // Placeholder for 'HST'
                subtotalMFT.toFixed(3), // Placeholder for 'MFT'
                subtotalPST.toFixed(3), // Placeholder for 'PST'
                subtotalPFT.toFixed(3), // Placeholder for 'PFT'
                // '',
                // '0', // Placeholder for 'Disc Rate'
                subtotalSavings.toFixed(3), // Placeholder for 'Disc Amt'
                subtotalFinalamt.toFixed(3),
                'CAD', // Currency
              ]);

              // Update total accumulators
              totalGallons += subtotalGallons;
              totalPretaxamt += subtotalPretaxamt;
              totalFinalamt += subtotalFinalamt;
              totalHST += subtotalHST;
              totalMFT += subtotalMFT;
              totalPST += subtotalPST;
              totalPFT += subtotalPFT;
              totalSavings += subtotalSavings;
            }
          });

          // Add total row after processing all transactions
          transactionsTableData.push([
            '',
            '',
            '',
            // '',
            'TOTAL',
            // '',
            '',
            '',
            totalGallons.toFixed(3),
            '',
            '',
            totalPretaxamt.toFixed(3),
            totalHST.toFixed(3), // Placeholder for 'HST'
            totalMFT.toFixed(3), // Placeholder for 'MFT'
            totalPST.toFixed(3), // Placeholder for 'PST'
            totalPFT.toFixed(3), // Placeholder for 'PFT'
            // '',
            // '0', // Placeholder for 'Disc Rate'
            totalSavings.toFixed(3), // Placeholder for 'Disc Amt'
            totalFinalamt.toFixed(3),
            'CAD', // Currency
          ]);

          csvRows.push(...transactionsTableData);
          csvRows.push([]); // Empty row for separation
        }
      }

      // Ensure startDate and endDate are Date objects
      const startDateObj = new Date(startDate); // Ensure this is a Date object
      const endDateObj = new Date(endDate); // Ensure this is a Date object

      // Format the dates for the filename (e.g., Dec16-18)
      const startDateFormattedNew = `${startDateObj.toLocaleString('en-US', {
        month: 'short',
      })}${startDateObj.getDate()}`;
      const endDateFormattedNew = `${endDateObj.toLocaleString('en-US', {
        month: 'short',
      })}${endDateObj.getDate()}`;

      // Convert CSV array to string
      const csvContent = csvRows.map((row) => row.join(',')).join('\n');

      // Create a Blob and URL for the CSV file
      const csvBlob = new Blob([csvContent], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvBlob);

      // Create a download link and trigger the download
      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = `Invoice_${clientId}_${customerId}_${startDateFormattedNew}-${endDateFormattedNew}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Prepare to send the CSV to the backend
      const formDataCSV = new FormData();
      formDataCSV.append('invoiceId', invoiceNumber);
      formDataCSV.append('clientId', clientId);
      formDataCSV.append('customerId', customerId);
      formDataCSV.append('customerName', customerName);

      // Append the generated CSV as a file to the FormData
      formDataCSV.append(
        'file',
        csvBlob,
        `Invoice_${clientId}_${customerId}_${startDateFormattedNew}-${endDateFormattedNew}.csv`,
      );

      // Show alert before sending email for CSV
      alert('Sending email with CSV...');

      fetch('https://wex-backend.hub-ksgfuel.com/v1/send-invoice-approval-email', {
        method: 'POST',
        body: formDataCSV,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === 'Approval email sent successfully') {
            console.log('Email sent successfully');
            alert('Email sent successfully with CSV');
          } else {
            console.error('Error sending email:', data.message);
            alert('Error sending email with CSV');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Error sending email with CSV');
        })
        .finally(() => {
          setUploading(false); // End loading after the async action finishes
        });
    } catch (error) {
      console.error('Error generating CSV:', error);
    } finally {
      setUploading(false);
    }
  };

  // Fetch invoices from the API
  // useEffect(() => {
  //   fetch('https://wex-backend.hub-ksgfuel.com/v1/get-invoice-details')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setInvoices(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching invoice details:', error);
  //       setLoading(false);
  //     });
  // }, []);

  // Fetch invoices from the API every 5 seconds
  useEffect(() => {
    const fetchInvoices = () => {
      fetch('https://wex-backend.hub-ksgfuel.com/v1/get-invoice-details')
        .then((response) => response.json())
        .then((data) => {
          setInvoices(data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching invoice details:', error);
          setLoading(false);
        });
    };

    // Initial fetch
    fetchInvoices();

    // Set interval to fetch invoices every 50 seconds
    const interval = setInterval(fetchInvoices, 50000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run once on mount

  const handleDownload = async (fileName) => {
    try {
      // Fetch the file from the S3 endpoint
      const response = await fetch(
        `https://wex-backend.hub-ksgfuel.com/v1/get-invoice-file?fileName=${fileName}`,
      );

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Get the file content as a blob (this will be the byte content of the file)
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob); // Create an object URL for the blob
      link.download = fileName; // Set the downloaded file name
      link.click(); // Trigger the download
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  // Define the columns for the DataGrid
  const columns = [
    // { field: 'id', headerName: 'INVOICE ID', width: 100 },
    { field: 'clientId', headerName: 'CLIENT ID', width: 100 },
    // { field: 'customerId', headerName: 'CUSTOMER ID', width: 100 },
    { field: 'customerName', headerName: 'CUSTOMER NAME', width: 200 },
    { field: 'originalFileName', headerName: 'FILE NAME', width: 300 },
    { field: 'status', headerName: 'STATUS', width: 100 },
    { field: 'createdAt', headerName: 'CREATED AT', width: 250 },
    { field: 'updatedAt', headerName: 'UPDATED AT', width: 250 },
    // { field: 'expirationTime', headerName: 'EXPIRATION TIME', width: 150 },
    // {
    //   field: 'invoiceHistory',
    //   headerName: 'INVOICE HISTORY',
    //   width: 100,
    //   renderCell: (params) => {
    //     const history = Object.entries(params.value || {}).map(([time, status]) => `${time}: ${status}`).join(', ');
    //     return <div>{history}</div>;
    //   },
    // },
    {
      field: 'download',
      headerName: 'DOWNLOAD',
      width: 150,
      renderCell: (params) => {
        // Check if the status is 'approved' before showing the download button
        if (params.row.status === 'Approved') {
          return (
            <IconButton
              onClick={() => handleDownload(params.row.originalFileName)}
              color="primary"
              aria-label="download"
              title="Download"
            >
              <DownloadIcon />
            </IconButton>
          );
        }
        return null; // Return null if not approved
      },
    },
  ];

  const handleVerifyInvoiceClicked = () => {
    navigate('/manage-invoice/verify'); // Navigate to /invoice page
  };

  return (
    <div>
      {/* Button Container */}
      {clientDetails && clientDetails.length > 0 && (
        <Box display="flex" mb={2} gap={2}>
          {/* Show both buttons if currency is 'CAD, USD' or empty */}
          {clientDetails[0]?.currency === 'CAD, USD' || !clientDetails[0]?.currency ? (
            <>
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'DM Sans',
                  fontSize: buttonsFontSize,
                  backgroundColor: getButtonBackground('CAD'),
                }}
                onClick={() => handleCurrencySelect('CAD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                CAD
              </Button>

              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'DM Sans',
                  fontSize: buttonsFontSize,
                  backgroundColor: getButtonBackground('USD'),
                }}
                onClick={() => handleCurrencySelect('USD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                USD
              </Button>
            </>
          ) : null}

          {/* Show only CAD button if currency is 'CAD' */}
          {clientDetails[0]?.currency === 'CAD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'DM Sans',
                fontSize: buttonsFontSize,
                backgroundColor: getButtonBackground('CAD'),
              }}
              onClick={() => handleCurrencySelect('CAD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              CAD
            </Button>
          )}

          {/* Show only USD button if currency is 'USD' */}
          {clientDetails[0]?.currency === 'USD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'DM Sans',
                fontSize: buttonsFontSize,
                backgroundColor: getButtonBackground('USD'),
              }}
              onClick={() => handleCurrencySelect('USD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              USD
            </Button>
          )}
        </Box>
      )}

      {selectedCurrency === 'USD' && (
        <>
          <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
            INVOICE GENERATION
          </Typography>
          <form>
            <div className="container" style={{ display: 'flex' }}>
              <div className="card" style={{ flex: '1' }}>
                <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
                  <CardContent>
                    {/* // <form id="transaction-report" onSubmit={handleViewReport}> */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <InputLabel id="customerID" style={{ ...inputLabelStyles }}>
                              Customer
                            </InputLabel>
                            {doNotPerformCustomerFiltration ? (
                              <Select
                                labelId="customerID"
                                id="customerID"
                                value={`${clientId}-${customerId}`}
                                label="Customer ID"
                                onChange={handleSelectChange}
                                required
                              >
                                {clientsUSD.length > 0 &&
                                  clientsUSD.map((client) => (
                                    <MenuItem
                                      key={client.customerID}
                                      value={`${client.clientID}-${client.customerID}`}
                                    >
                                      {client.clientID} - {client.customerID} - {client.clientName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            ) : clients.length === 1 ? (
                              <div style={{ marginTop: '30px', marginLeft: '16px' }}>
                                <Typography variant="body1">
                                  {clients[0].clientID} - {clients[0].customerID} -{' '}
                                  {clients[0].clientName}
                                </Typography>
                              </div>
                            ) : (
                              <Select
                                labelId="customerID"
                                id="customerID"
                                value={`${clientId}-${customerId}`}
                                label="Customer ID"
                                onChange={handleSelectChange}
                                required
                              >
                                {clientsUSD.length > 0 &&
                                  clientsUSD.map((client) => (
                                    <MenuItem
                                      key={client.customerID}
                                      value={`${client.clientID}-${client.customerID}`}
                                    >
                                      {client.clientID} - {client.customerID} - {client.clientName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small" error={dateErrorText}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date"
                                value={todayDate}
                                InputLabelProps={textFieldLabelStyles}
                                onChange={handleDate}
                                shouldDisableDate={(date) =>
                                  date.getDay() !== 1 && date.getDay() !== 4
                                } // Enable Monday (1) and Thursday (4)
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <TextField
                            label="Currency"
                            value={currency}
                            variant="outlined"
                            size="small"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            disabled
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {loading && <Loader />}
                  </CardContent>
                </Card>
              </div>
            </div>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
              <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} align="left">
                <Button
                  style={{
                    marginTop: '5px',
                    fontFamily: 'DM Sans',
                    fontSize: buttonsFontSize,
                    backgroundColor: buttonBackground,
                  }}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: 0 }}
                  onClick={(event) => handleViewReport(event, 'generate')}
                >
                  Generate Invoice
                </Button>
              </Grid>

              {/* <Grid item xs={4} sm={4} md={4} lg={4}></Grid> */}
              <Grid item xs={4} sm={4} md={4} lg={4} align="left">
                <Button
                  style={{
                    marginTop: '5px',
                    fontFamily: 'DM Sans',
                    fontSize: buttonsFontSize,
                    backgroundColor: buttonBackground,
                  }}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: 0 }}
                  onClick={(event) => handleViewReport(event, 'download')}
                >
                  Download CSV
                </Button>
              </Grid>

              <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
            </Grid>
          </form>

          <div id="pdf-container" style={{ marginTop: '20px' }}></div>
          {pdfUrl && (
            <div>
              <h2>View Invoice</h2>
              <iframe
                src={pdfUrl}
                style={{ width: '100%', height: '600px', border: 'none' }}
                // width="100%"
                // height="600px"
                title="Invoice"
              />
            </div>
          )}
        </>
      )}

      {selectedCurrency === 'CAD' && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              style={{ ...fontStylesRootTypography, textAlign: 'left' }}
              gutterBottom
            >
              INVOICE GENERATION
            </Typography>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <Button
                style={{
                  fontFamily: 'DM Sans',
                  fontSize: buttonsFontSize,
                  backgroundColor: buttonBackground,
                }}
                variant="contained"
                onClick={handleVerifyInvoiceClicked}
              >
                VERIFY INVOICE
              </Button>
            </div>
          </div>
          <div>
            <form>
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
                    <CardContent>
                      {/* // <form id="transaction-report" onSubmit={handleViewReportCAD}> */}
                      <Grid container spacing={2} style={gridRowStyles}>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <InputLabel id="customerID" style={{ ...inputLabelStyles }}>
                                Customer
                              </InputLabel>
                              {doNotPerformCustomerFiltration ? (
                                <Select
                                  labelId="customerID"
                                  id="customerID"
                                  value={`${clientId}-${customerId}`}
                                  label="Customer ID"
                                  onChange={handleSelectChange}
                                  required
                                >
                                  {clientsCAD.length > 0 &&
                                    clientsCAD.map((client) => (
                                      <MenuItem
                                        key={client.customerID}
                                        value={`${client.clientID}-${client.customerID}`}
                                      >
                                        {client.clientID} - {client.customerID} -{' '}
                                        {client.clientName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              ) : clients.length === 1 ? (
                                <div style={{ marginTop: '30px', marginLeft: '16px' }}>
                                  <Typography variant="body1">
                                    {clients[0].clientID} - {clients[0].customerID} -{' '}
                                    {clients[0].clientName}
                                  </Typography>
                                </div>
                              ) : (
                                <Select
                                  labelId="customerID"
                                  id="customerID"
                                  value={`${clientId}-${customerId}`}
                                  label="Customer ID"
                                  onChange={handleSelectChange}
                                  required
                                >
                                  {clientsCAD.length > 0 &&
                                    clientsCAD.map((client) => (
                                      <MenuItem
                                        key={client.customerID}
                                        value={`${client.clientID}-${client.customerID}`}
                                      >
                                        {client.clientID} - {client.customerID} -{' '}
                                        {client.clientName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              )}
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box>
                            <FormControl
                              sx={{ minWidth: '100%' }}
                              size="small"
                              error={dateErrorText}
                            >
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Date"
                                  value={todayDate}
                                  InputLabelProps={textFieldLabelStyles}
                                  onChange={handleDate}
                                  shouldDisableDate={(date) =>
                                    date.getDay() !== 1 && date.getDay() !== 4
                                  } // Enable Monday (1) and Thursday (4)
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box>
                            <TextField
                              label="Currency"
                              value={selectedCurrency}
                              variant="outlined"
                              size="small"
                              fullWidth
                              InputProps={{ readOnly: true }}
                              disabled
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      {loading && <Loader />}
                    </CardContent>
                  </Card>
                </div>
              </div>
              <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} align="left">
                  <Button
                    style={{
                      marginTop: '5px',
                      fontFamily: 'DM Sans',
                      fontSize: buttonsFontSize,
                      backgroundColor: '#011f4b',
                    }}
                    type="submit"
                    variant="contained"
                    disabled={uploading} // Disable when uploading
                    sx={{ mt: 2, mb: 5, borderRadius: 0 }}
                    onClick={(event) => handleViewReportCAD(event, 'generate')} // Pass 'generate' to the handler
                  >
                    {uploading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Generate Invoice'
                    )}
                  </Button>
                </Grid>

                {/* <Grid item xs={4} sm={4} md={4} lg={4}></Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4} align="left">
                  <Button
                    style={{
                      marginTop: '5px',
                      fontFamily: 'DM Sans',
                      fontSize: buttonsFontSize,
                      backgroundColor: '#011f4b',
                    }}
                    type="submit"
                    variant="contained"
                    disabled={uploading} // Disable when uploading
                    sx={{ mt: 2, mb: 5, borderRadius: 0 }}
                    onClick={(event) => handleViewReportCAD(event, 'download')} // Pass 'download' to the handler
                  >
                    {uploading ? <CircularProgress size={24} color="inherit" /> : 'Download CSV'}
                  </Button>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              </Grid>
            </form>
          </div>

          {/* <div id="pdf-container" style={{ marginTop: '20px' }}></div> */}
          {/* {pdfUrl && (
            <div>
              <h2>View Invoice</h2>
              <iframe
                src={pdfUrl}
                style={{ width: '100%', height: '600px', border: 'none' }}
                // width="100%"
                // height="600px"
                title="Invoice"
              />
            </div>
          )} */}

          <div style={{ height: 600, width: '97%' }}>
            <DataGrid
              rows={invoices}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              slots={{ toolbar: GridToolbar }}
              disableSelectionOnClick
              getRowId={(row) => row.invoiceId}
              rowHeight={30}
              sx={{
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'rgba(0, 9, 81)',
                  color: 'white',
                },
                '& .MuiDataGrid-cell': {
                  textAlign: 'center',
                },
                '& .MuiDataGrid-root': {
                  overflowX: 'auto',
                },
                '& .MuiDataGrid-row': {
                  height: 'auto',
                },
              }}
              sortModel={[
                {
                  field: 'status', // Sort by status first
                  sort: 'desc', // Pending first
                },
                {
                  field: 'clientId', // Then sort by clientId
                  sort: 'asc', // Ascending order
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Invoice;
