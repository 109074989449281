import { Box, Card, CardContent, Grid, Typography, Button, FormHelperText, Alert, AlertTitle } from '@mui/material'; // Tooltip, Paper, , Chip, Alert
import * as React from 'react';
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import { darken, lighten, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { TextField, InputAdornment } from '@mui/material';
import Loader from 'components/Loader';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ManageUsers = ({ isOnboard = false, onSuccess, client }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const buttonsFontSize = '16px';

  const loggedInUserClientId = localStorage.getItem('clientId');
  const doNotPerformUserFiltration = localStorage.getItem('role') === 'Super Admin';

  const [userName, setUserName] = React.useState('');
  const [userNameError, setUserNameError] = React.useState(false);
  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [passwordVisibility, setPasswordVisibility] = React.useState('');
  const [role, setRole] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [accountId, setAccountId] = React.useState('');
  const [userStatus, setUserStatus] = React.useState('');
  const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false);

  const toUpperCase = (text) => text.toUpperCase();

  // const [onboardAccountDetails, setOnboardAccountDetails] = React.useState('');
  // const [isOnboardAccountDetailsAvailable, setIsOnboardAccountDetailsAvailable] = React.useState(false);

  const [users, setUsers] = React.useState([]);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Alerts
  const [isUserCreated, setIsUserCreated] = React.useState(false);
  const [isUserUpdated, setIsUserUpdated] = React.useState(false);

  const [userListView, setUserListView] = React.useState([]);
  const [isAddUserSelected, setAddUserSelected] = React.useState(isOnboard);
  const [isUpdateUserSelected, setUpdateUserSelected] = React.useState(false);
  const defaultButtonBackground = '#011f4b';
  const chosenButtonBackground = '#03396c';
  const [accountDetails, setAccountDetails] = React.useState([]);
  const userStatuses = [
    {
      id: 1,
      value: 'Active'
    },
    {
      id: 2,
      value: 'Inactive'
    }
  ];

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`);
    } else {
      setLoggedIn(true);
      setLoading(true);
      if (!isOnboard) generateListViewDetails();
      if (isOnboard) {
        // getClientById(clientId)
      }
      setLoading(false);
    }
  }, []);

  // const getClientById = async (clientId) => {
  //   console.log(clientId)
  //   let getClientsByIdUrl = `${BACKEND_URL}/v1/client?clientID=${clientId}`
  //   const response = await fetch(getClientsByIdUrl)
  //   const data = await response.json()
  //   let details = `${data[0].clientID} - ${data[0].customerID} - ${data[0].clientName}`
  //   setOnboardAccountDetails(details)
  //   setTimeout(() => {
  //     setIsOnboardAccountDetailsAvailable(true)
  //   }, 2000)
  // }

  const userListViewColumns = [
    {
      field: 'id',
      headerName: 'Serial No.',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1
    },
    {
      field: 'userName',
      headerName: 'User Name',
      width: 150,
      align: 'Left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1
    },
    {
      field: 'account',
      headerName: 'Customer Account',
      width: 150,
      align: 'Left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1
    },
    {
      field: 'userStatus',
      headerName: 'Status',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1
    }
  ];

  const NoRecordsFoundRow = [
    {
      id: '',
      userName: '',
      account: 'No records found',
      userStatus: '',
      role: ''
    }
  ];

  const userRoles = [
    // {
    //   "id": 1,
    //   "value": "Super Admin"
    // },
    {
      id: 2,
      value: 'Admin'
    },
    {
      id: 3,
      value: 'Broker'
    }
  ];

  const handleAccountChange = (event) => {
    setAccountId(event.target.value);
  };

  const handleAddUserSelection = (event) => {
    event.preventDefault();
    setUserListView([]);
    setLoading(true);
    setTimeout(() => {
      setAddUserSelected(true);
      setUpdateUserSelected(false);
      if (!isOnboard) generateListViewDetails();
      resetFields();
      // if(isOnboard)
      // setAccountId(clientId)
      setLoading(false);
    }, 2000);
  };

  const generateListViewDetails = async () => {
    let users = await getUsers();
    let accounts = await getClients();
    if (users.length > 0 && accounts.length > 0) {
      let userListViewDetails = [];
      let userListViewId = 1;
      for (let u = 0; u < users.length; u++) {
        let user = users[u];
        if (user.role != 'Super Admin') {
          let clientAccount = accounts.filter((account) => account.clientID == user.clientID);
          if (clientAccount.length > 0) {
            if (doNotPerformUserFiltration) {
              let userListView = {
                id: userListViewId++,
                userName: toUpperCase(user.userName),
                account: toUpperCase(clientAccount[0].clientName),
                userStatus: user.userStatus ? 'ACTIVE' : 'BLOCKED',
                role: toUpperCase(user.role)
              };
              userListViewDetails.push(userListView);
            } else if (!doNotPerformUserFiltration && loggedInUserClientId == user.clientID) {
              let userListView = {
                id: userListViewId++,
                userName: toUpperCase(user.userName),
                account: toUpperCase(clientAccount[0].clientName),
                userStatus: user.userStatus ? 'ACTIVE' : 'BLOCKED',
                role: user.role
              };
              userListViewDetails.push(userListView);
            }
          }
        }
      }
      setUserListView(userListViewDetails);
    }
  };

  const handleUpdateUserSelection = (event) => {
    event.preventDefault();
    setUserListView([]);
    setLoading(true);
    setTimeout(() => {
      setAddUserSelected(false);
      setUpdateUserSelected(true);
      generateListViewDetails();
      resetFields();
      setLoading(false);
    }, 2000);
  };

  const resetFields = () => {
    setUserName('');
    setUserNameError('');
    setPassword('');
    setPasswordError('');
    setRole('');
    setEmail('');
    setEmailError('');
    setAccountId('');
    setUserStatus('');
  };

  const getUsers = async () => {
    let getUsersUrl = `${BACKEND_URL}/v1/user`;
    const response = await fetch(getUsersUrl);
    const data = await response.json();
    if (doNotPerformUserFiltration) {
      let filteredData = data.filter((user) => ['Admin', 'End User'].includes(user?.role));
      setUsers(filteredData);
    } else {
      let filteredData = data.filter((user) => user?.clientID == loggedInUserClientId);
      setUsers(filteredData);
    }
    return data;
  };

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    const response = await fetch(getClientsUrl);
    const data = await response.json();
    setAccountDetails(data);
    return data;
  };

  const handleUserNameChange = (event) => {
    const regex = /^[a-zA-Z0-9]+$/;
    setUserName(event.target.value);
    if (regex.test(event.target.value)) {
      setUserName(event.target.value);
      setUserNameError('');
      setUserStatus(1);
      setRole('Admin');
      // setEmail(`${event.target.value}@`)
      setEmail('');
    } else {
      setUserNameError('Username can contain only alphanumeric values (a-zA-Z0-9)');
    }
  };

  const handleEmailChange = (event) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(event.target.value);
    if (regex.test(event.target.value)) {
      setEmail(event.target.value);
      setEmailError('');
    } else {
      setEmailError('Email should end with @domain.com or @domain.in');
    }
  };

  const handlePasswordChange = (event) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    setPassword(event.target.value);
    if (regex.test(event.target.value)) {
      setPassword(event.target.value);
      setPasswordError('');
    } else {
      setPasswordError(
        'Password should contain atleast 8 characters, one uppercase letter, one lowercase letter, one digit and one special character'
      );
    }
  };

  const handlePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleCreateUser = (event) => {
    event.preventDefault();
    setLoading(true);
    if (isOnboard) {
      const createUserObject = {
        userName: userName,
        password: password,
        role: role,
        emailId: email,
        clientID: accountId,
        userStatus: userStatus === 1,
        address: null
      };
      setTimeout(() => {
        onSuccess(createUserObject);
      }, 1000);
    } else {
      setUserListView([]);
      setLoading(true);
      setTimeout(() => {
        const createUserObject = {
          userName: userName,
          password: password,
          role: role,
          emailId: email,
          clientID: accountId,
          userStatus: userStatus === 1,
          address: null
        };

        fetch(`${BACKEND_URL}/v1/user`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(createUserObject)
        })
          .then((response) => response.json())
          .then(() => {
            getUsers();
          })
          .then(() => {
            generateListViewDetails();
            setIsUserCreated(true);
            resetFields();
            setLoading(false);
          });
      }, 2000);
    }
  };

  const handleUpdateUserSelectionChange = (event) => {
    setLoading(true);
    setTimeout(() => {
      let chosenUserForUpdate = users.filter((user) => user.userName === event.target.value);
      if (chosenUserForUpdate) {
        let user = chosenUserForUpdate[0];
        setUserName(user.userName);
        let role = userRoles.filter((role) => role.value === user.role);
        if (role.length > 0) {
          setRole(role[0].value);
        }
        setEmail(user.emailId);
        setAccountId(user.clientID);
        setUserStatus(user.userStatus == false ? 'Inactive' : 'Active');
        setLoading(false);
      }
    }, 1000);
  };

  const handleUserStatusChange = (event) => {
    // let selectedStatus = userStatuses.filter(status => status.value === event.target.value);
    let userStatus = event.target.value;
    setUserStatus(userStatus);
  };

  const handleUpdateUser = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      const updateUserObject = {
        userName: userName,
        role: role,
        emailId: email,
        accountId: accountId,
        userStatus: userStatus === 'Active',
        name: userName,
        phoneNo: ''
      };

      fetch(`${BACKEND_URL}/v1/user`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updateUserObject)
      })
        .then((response) => response.json())
        .then(() => {
          getUsers();
        })
        .then(() => {
          generateListViewDetails();
          setIsUserUpdated(true);
          resetFields();
        });
      setLoading(false);
    }, 2000);
  };

  // const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6));

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer'
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode)
      }
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode)
      }
    }
  }));

  const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesChartTypography = { fontSize: '18px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const cardHeight = 650;
  const inputLabelStyles = { fontSize: '13px', color: 'rgb(26, 33, 56)', fontWeight: 600 };
  const textFieldLabelStyles = {
    style: {
      fontSize: '13px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600
    }
  };
  const gridRowStyles = { marginTop: '10px', marginBottom: '15px' };

  const handlePasswordGenerator = () => {
    const length = 12;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]|;:,.<>?';

    let newPassword = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      newPassword += charset[randomIndex];
    }

    setPassword(newPassword);
    setPasswordError('');
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(password);
    setIsCopiedToClipboard(true);
    // alert('Password copied to clipboard!');
  };

  const optionsWidth = isOnboard ? 2 : 4;

  return (
    loggedIn && (
      <div>
        {isUserCreated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsUserCreated(false)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
          >
            <AlertTitle>Success, User created successfully</AlertTitle>
          </Alert>
        )}
        {isUserUpdated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsUserUpdated(false)}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', fontSize: '16px' }}
          >
            <AlertTitle>Success, User updated successfully</AlertTitle>
          </Alert>
        )}
        <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
          Manage Users
        </Typography>
        <div className="container" style={{ display: 'flex' }}>
          <div className="card" style={{ flex: '1' }}>
            <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8} sm={8} md={8} lg={8} style={gridRowStyles}></Grid>
                  {isOnboard && <Grid item xs={2} sm={2} md={2} lg={2} style={gridRowStyles}></Grid>}
                  <Grid item xs={optionsWidth} sm={optionsWidth} md={optionsWidth} lg={optionsWidth} style={gridRowStyles}>
                    <Button
                      style={{
                        fontFamily: 'DM Sans',
                        fontSize: buttonsFontSize,
                        backgroundColor: isAddUserSelected ? chosenButtonBackground : defaultButtonBackground
                      }}
                      type="submit"
                      variant="contained"
                      sx={{ mr: 2, borderRadius: 0 }}
                      onClick={handleAddUserSelection}
                    >
                      <Typography>New User</Typography>
                      {/* <UserAddOutlined /> */}
                      <AddIcon />
                    </Button>
                    {!isOnboard && (
                      <Button
                        style={{
                          fontFamily: 'DM Sans',
                          fontSize: buttonsFontSize,
                          backgroundColor: isUpdateUserSelected ? chosenButtonBackground : defaultButtonBackground
                        }}
                        type="submit"
                        variant="contained"
                        sx={{ borderRadius: 0 }}
                        onClick={handleUpdateUserSelection}
                      >
                        <Typography>Update User</Typography>
                        <EditIcon />
                        {/* <EditOutlined /> */}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                {loading && <Loader />}
                {isAddUserSelected && (
                  <form id="add-user" onSubmit={handleCreateUser}>
                    <div>
                      {isOnboard && (
                        <Typography variant="h5" sx={{ m: 1 }}>
                          Account: {client}
                        </Typography>
                      )}
                    </div>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={userNameError}>
                              <TextField
                                style={inputLabelStyles}
                                id="userName"
                                label={userName ? 'User Name' : 'User Name'}
                                value={userName}
                                onChange={handleUserNameChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                              {userNameError && <FormHelperText id="input-error-text">{userNameError}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box sx={{ flex: 'column', position: 'relative' }}>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={passwordError}>
                              <TextField
                                style={inputLabelStyles}
                                id="password"
                                type={passwordVisibility ? 'text' : 'password'}
                                label={password ? 'Password' : 'Password'}
                                value={password}
                                onChange={handlePasswordChange}
                                InputLabelProps={textFieldLabelStyles}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {passwordVisibility ? (
                                        <VisibilityOffIcon onClick={handlePasswordVisibility} />
                                      ) : (
                                        <VisibilityIcon onClick={handlePasswordVisibility} />
                                      )}
                                    </InputAdornment>
                                  )
                                }}
                                required
                              />
                              {password?.length == 0 && (
                                <Typography variant="h6" onClick={handlePasswordGenerator} style={{ cursor: 'pointer', color: 'blue' }}>
                                  Generate Password
                                </Typography>
                              )}

                              {password?.length > 0 && (
                                <Typography variant="h6" onClick={handleCopyToClipboard} style={{ cursor: 'pointer', color: 'blue' }}>
                                  {isCopiedToClipboard ? 'Copied to clipboard' : 'Copy to clipboard'}
                                </Typography>
                              )}
                              {passwordError && <FormHelperText id="input-error-text">{passwordError}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <InputLabel id="role" style={inputLabelStyles}>
                                Role
                              </InputLabel>
                              <Select
                                labelId="role"
                                sx={{ minHeight: '20px' }}
                                id="role"
                                value={role}
                                label="Role"
                                onChange={handleRoleChange}
                              >
                                {userRoles.map((userRole) => (
                                  <MenuItem key={userRole.id} value={userRole.value}>
                                    {userRole.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={emailError}>
                              <TextField
                                style={inputLabelStyles}
                                id="email"
                                label={email ? 'Email' : 'Email'}
                                value={email}
                                onChange={handleEmailChange}
                                InputLabelProps={textFieldLabelStyles}
                                required
                              />
                              {emailError && <FormHelperText id="input-error-text">{emailError}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid>
                        {!isOnboard && (
                          <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <InputLabel id="accountId" style={inputLabelStyles}>
                                  Account
                                </InputLabel>
                                <Select
                                  labelId="accountId"
                                  id="accountId"
                                  value={accountId}
                                  label="Account ID"
                                  onChange={handleAccountChange}
                                >
                                  {doNotPerformUserFiltration &&
                                    accountDetails.map((account) => (
                                      <MenuItem key={account.clientID} value={account.clientID}>
                                        {account.clientID} - {account.customerID} - {account.clientName}
                                      </MenuItem>
                                    ))}
                                  {!doNotPerformUserFiltration &&
                                    accountDetails
                                      .filter((account) => loggedInUserClientId?.includes(account.clientID))
                                      .map((client) => (
                                        <MenuItem key={client.clientID} value={client.clientID}>
                                          {client.clientID} - {client.customerID} - {client.clientName}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <InputLabel id="userStatus" style={inputLabelStyles}>
                                User Status
                              </InputLabel>
                              <Select
                                labelId="userStatus"
                                id="userStatus"
                                value={userStatus}
                                label="User Status"
                                onChange={handleUserStatusChange}
                              >
                                {userStatuses.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container rowSpacing={1.5} columnSpacing={2.75} style={gridRowStyles}>
                        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                          <Box>
                            <Button
                              style={{ fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: defaultButtonBackground }}
                              type="submit"
                              variant="contained"
                              sx={{ borderRadius: 0 }}
                            >
                              {isOnboard ? 'Proceed' : 'Create'}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                      </Grid>
                    </div>
                  </form>
                )}
                {isUpdateUserSelected && (
                  <form id="add-user" onSubmit={handleUpdateUser}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%' }} size="small">
                              <InputLabel id="userName" style={inputLabelStyles}>
                                User Name
                              </InputLabel>
                              <Select
                                labelId="userName"
                                id="userName"
                                value={userName}
                                label="User Name"
                                onChange={handleUpdateUserSelectionChange}
                              >
                                {users.map((user) => (
                                  <MenuItem key={user.userName} value={user.userName}>
                                    {user.userName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box sx={{ flex: 'column', position: 'relative' }}>
                            <FormControl sx={{ minWidth: '100%' }} size="small" error={passwordError}>
                              <TextField
                                style={inputLabelStyles}
                                id="password"
                                type={passwordVisibility ? 'text' : 'password'}
                                label={password ? 'Password' : 'Password'}
                                value={password}
                                onChange={handlePasswordChange}
                                InputLabelProps={textFieldLabelStyles}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment>
                                      {passwordVisibility ? (
                                        <VisibilityOffIcon onClick={handlePasswordVisibility} />
                                      ) : (
                                        <VisibilityIcon onClick={handlePasswordVisibility} />
                                      )}
                                    </InputAdornment>
                                  )
                                }}
                                required
                              />
                              {password?.length == 0 && (
                                <Typography variant="h6" onClick={handlePasswordGenerator} style={{ cursor: 'pointer', color: 'blue' }}>
                                  Generate Password
                                </Typography>
                              )}

                              {password?.length > 0 && (
                                <Typography variant="h6" onClick={handleCopyToClipboard} style={{ cursor: 'pointer', color: 'blue' }}>
                                  {isCopiedToClipboard ? 'Copied to clipboard' : 'Copy to clipboard'}
                                </Typography>
                              )}
                              {passwordError && <FormHelperText id="input-error-text">{passwordError}</FormHelperText>}
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                          <Box>
                            <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                              <InputLabel id="role" style={inputLabelStyles}>
                                Role
                              </InputLabel>
                              <Select labelId="role" id="role" value={role} label="Role" onChange={handleRoleChange}>
                                {userRoles.map((userRole) => (
                                  <MenuItem key={userRole.id} value={userRole.value}>
                                    {userRole.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%' }} size="small" error={emailError}>
                                <TextField
                                  style={inputLabelStyles}
                                  id="email"
                                  label={email ? 'Email' : 'Email'}
                                  value={email}
                                  onChange={handleEmailChange}
                                  InputLabelProps={textFieldLabelStyles}
                                  required
                                />
                                {emailError && <FormHelperText id="input-error-text">{emailError}</FormHelperText>}
                              </FormControl>
                            </Box>
                          </Grid>
                          {!isOnboard && (
                            <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                              <Box>
                                <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                  <InputLabel id="accountId" style={inputLabelStyles}>
                                    Account
                                  </InputLabel>
                                  <Select
                                    labelId="accountId"
                                    id="accountId"
                                    value={accountId}
                                    label="Account ID"
                                    onChange={handleAccountChange}
                                  >
                                    {doNotPerformUserFiltration &&
                                      accountDetails.map((account) => (
                                        <MenuItem key={account.clientID} value={account.clientID}>
                                          {account.clientID} - {account.customerID} - {account.clientName}
                                        </MenuItem>
                                      ))}
                                    {!doNotPerformUserFiltration &&
                                      accountDetails
                                        .filter((account) => loggedInUserClientId?.includes(account.clientID))
                                        .map((client) => (
                                          <MenuItem key={client.clientID} value={client.clientID}>
                                            {client.clientID} - {client.customerID} - {client.clientName}
                                          </MenuItem>
                                        ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <InputLabel id="userStatus" style={inputLabelStyles}>
                                  User Status
                                </InputLabel>
                                <Select
                                  labelId="userStatus"
                                  id="userStatus"
                                  value={userStatus}
                                  label="User Status"
                                  onChange={handleUserStatusChange}
                                >
                                  {userStatuses.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                      {status.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                            <Box>
                              <Button
                                style={{ fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: defaultButtonBackground }}
                                type="submit"
                                variant="contained"
                                sx={{ borderRadius: 0 }}
                              >
                                Update
                              </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                )}

                {!isOnboard &&
                  (userListView.length > 0 ? (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      User Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <div style={{ height: 700, maxWidth: '95%', marginTop: '20px', align: 'center' }}>
                                <StyledDataGrid
                                  // onRowClick={gridRowClicked}
                                  sx={{
                                    '& .super-app-theme--header': {
                                      backgroundColor: 'rgba(0, 9, 81)',
                                      color: 'white'
                                    }
                                  }}
                                  rows={userListView}
                                  columns={userListViewColumns}
                                  rowHeight={36}
                                  slots={{ toolbar: GridToolbar }}
                                  // getRowClassName={(params) => `super-app-theme--${params.row.auditStatus}`}
                                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                                />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : loading ? (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      User Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <CircularProgress color="success" />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ) : (
                    <div className="container" style={{ display: 'flex' }}>
                      <div className="card" style={{ flex: '1' }}>
                        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                            <Card>
                              <CardContent>
                                <Grid container alignItems="center" justifyContent="center">
                                  <Grid item>
                                    <Typography variant="h5" style={fontStylesChartTypography}>
                                      User Details
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <div style={{ height: 700, maxWidth: '90%', marginTop: '20px', align: 'center' }}>
                                <StyledDataGrid
                                  sx={{
                                    '& .super-app-theme--header': {
                                      backgroundColor: 'rgba(0, 9, 81)',
                                      color: 'white'
                                    }
                                  }}
                                  rows={NoRecordsFoundRow}
                                  columns={userListViewColumns}
                                  slots={{ toolbar: GridToolbar }}
                                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                                />
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ))}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    )
  );
};

export default ManageUsers;
