import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  TextField,
  Button,
} from '@mui/material';

const BrokerCommission = () => {
  const BACKEND_URL = 'https://wex-backend.hub-ksgfuel.com';

  // States for broker data, selected broker, and form data
  const [brokers, setBrokers] = useState([]);
  const [brokerId, setBrokerId] = useState('');
  const [formData, setFormData] = useState({ brokerComission: '' });
  const [loading, setLoading] = useState(false);
  const [commissionLoading, setCommissionLoading] = useState(false); // State for commission loading
  const [error, setError] = useState(null);

  // Fetch brokers when the component mounts
  useEffect(() => {
    const fetchBrokers = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BACKEND_URL}/v1/brokers`); // Replace with your API endpoint
        const data = await response.json();
        setBrokers(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBrokers();
  }, []);

  // Fetch commission based on selected broker
  useEffect(() => {
    if (!brokerId) return; // If no broker is selected, do not fetch commission

    const fetchCommission = async () => {
      setCommissionLoading(true);
      try {
        // Fetch the broker data, including the commission
        const response = await fetch(`${BACKEND_URL}/v1/brokers/${brokerId}`); // Replace with the correct endpoint for a specific broker
        const data = await response.json();
        setFormData((prevData) => ({ ...prevData, brokerComission: data.brokerComission })); // Set the commission in form data
        setCommissionLoading(false);
      } catch (err) {
        setError(err.message);
        setCommissionLoading(false);
      }
    };

    fetchCommission();
  }, [brokerId]); // This effect runs whenever brokerId changes

  // Handle form data changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle Save button click to update the commission (PUT request)
  const handleSave = async () => {
    if (!brokerId || !formData.brokerComission) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/v1/brokers/${brokerId}`, {
        method: 'PUT', // PUT request to update the broker commission
        headers: {
          Authorization: `Bearer ${yourAuthToken}`, // Add your token here (if required)
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          brokerComission: formData.brokerComission, // Send updated commission
        }),
      });

      if (response.ok) {
        alert('Commission updated successfully!');
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to update commission');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <h1>Broker Commission</h1>
      <Grid container spacing={2}>
        {/* Broker Selection Dropdown */}
        <Grid item xs={4} mt={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="brokerDropdown">Select Broker</InputLabel>

            {/* Display loading or error state */}
            {loading ? (
              <CircularProgress
                size={24}
                sx={{ marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
              />
            ) : error ? (
              <Typography color="error" align="center">
                {`Error: ${error}`}
              </Typography>
            ) : (
              <Select
                labelId="brokerDropdown"
                id="brokerDropdown"
                value={brokerId}
                label="Select Broker"
                onChange={(e) => setBrokerId(e.target.value)} // Update brokerId when a broker is selected
                disabled={brokers.length === 0} // Disable if no brokers are available
              >
                {brokers.map((broker) => (
                  <MenuItem key={broker.id} value={broker.id}>
                    {`${broker.id} - ${broker.brokerName}`} {/* Display brokerId and brokerName */}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </Grid>

        {/* Broker Commission Input Field */}
        <Grid item xs={4} mb={3}>
          <TextField
            label="Broker Commission"
            name="brokerComission"
            style={{ marginBottom: '10px', padding: '2px' }}
            value={formData.brokerComission}
            onChange={handleInputChange}
            size="small"
            fullWidth
            margin="normal"
            type="number" // Assuming broker commission is a number
            sx={{ marginTop: '10px' }}
            disabled={commissionLoading} // Disable input while loading commission
          />
        </Grid>

        {/* Save Button */}
        <Grid item xs={12}>
          <Button
            style={{
              marginTop: '5px',
              fontFamily: 'DM Sans',
              fontSize: '16px',
              backgroundColor: '#011f4b',
              width: '100px',
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
            variant="contained"
            color="primary"
            onClick={handleSave}
            fullWidth
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default BrokerCommission;
