import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom'; // For routing
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer'; // Sidebar component
import Header from './Header'; // Header component
import navigation from 'menu-items'; // Navigation items
import Breadcrumbs from 'components/@extended/Breadcrumbs'; // Breadcrumbs component

// types
import { openDrawer } from 'store/reducers/menu'; // Action for opening/closing drawer

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg')); // Responsive design
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current path

  const { drawerOpen } = useSelector((state) => state.menu); // Get drawer state from Redux store

  // Local state for managing drawer open/closed state
  const [open, setOpen] = useState(drawerOpen);

  // Toggle drawer open/closed state
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // Adjust drawer state based on viewport size
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));
  }, [matchDownLG, dispatch]);

  // Synchronize local state with Redux state
  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
  }, [drawerOpen]);

  // Determine if the layout components should be shown based on the current path
  const excludedRoutes = ['/', '/login', '/new-login']; // Routes where layout should be disabled
  const showHeaderAndSidebar = !excludedRoutes.includes(location.pathname);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      {showHeaderAndSidebar ? (
        <>
          <Header
            open={open}
            handleDrawerToggle={handleDrawerToggle}
            showExpandToggle={true} // Pass the prop to enable expand/collapse functionality
          />
          <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
            <Toolbar />
            <Breadcrumbs navigation={navigation} title />
            <Outlet />
          </Box>
        </>
      ) : (
        <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Outlet />
        </Box>
      )}
    </Box>
  );
};

export default MainLayout;
