// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useNavigate } from 'react-router-dom';
import InactivityTimer from '../src/layout/MainLayout/Header/HeaderContent/Profile/InactivityTimer.js'; // Adjust the import path as needed

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Perform your logout logic here
    // e.g., clearing authentication tokens and redirecting to login
    navigate('/');
  };

  return (
    <>
      <InactivityTimer onLogout={handleLogout} />
      {/* The rest of your app components */}

      <ThemeCustomization>
        <ScrollTop>
          <Routes />
        </ScrollTop>
      </ThemeCustomization>
    </>
  );
};

export default App;
