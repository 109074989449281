import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Button,
  CardContent,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Chip,
  Checkbox,
  ListItemText,
  TextField,
  Popover,
  CircularProgress,
} from '@mui/material'; //TextField
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import Loader from 'components/Loader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import './transaction-report.css';

const TransactionReport = () => {
  useEffect(() => {
    getClients();
    //  handleCurrencySelect();
  }, []);

  const buttonsFontSize = '16px';
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  // const [unitNumber, setUnitNumber] = React.useState('');
  const buttonBackground = '#011f4b';
  const [clients, setClients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState('');
  const [cardsForClient, setCardsForClient] = React.useState([]);
  // const [currency, setCurrency] = React.useState('usd');
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [startDateErrorText, setStartDateErrorText] = React.useState('');
  const [endDateErrorText, setEndDateErrorText] = React.useState('');
  const [reportType, setReportType] = React.useState('normal');
  // const [exportType, setExportType] = React.useState('');
  //const  setCard = React.useState('');
  const [rows, setRows] = React.useState([]);
  const loggedInUserClientId = localStorage.getItem('clientId');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const [fetchedWithNoRecords, setFetchedWithNoRecords] = React.useState(false);
  const filePrefix = doNotPerformCustomerFiltration ? 'fms-ksg' : 'fms-client';
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  // State to track the disabled status of the buttons
  // const [disableCAD, setDisableCAD] = useState(false);
  // const [disableUSD, setDisableUSD] = useState(false);

  const [uploading, setUploading] = useState(false);

  const [customerErrorText, setCustomerErrorText] = useState('');

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const cardHeight = 100;
  const inputLabelStyles = { fontSize: '14px', color: 'rgb(26, 33, 56)' };
  //const inputLabelStylesSelect = { fontSize: '16px', color: 'rgb(26, 33, 56)', fontWeight: 600 };
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  const [selectedCards, setSelectedCards] = useState([]);

  const handleCardSelectionChange = (event) => {
    setSelectedCards(event.target.value);
    console.log('Selected Cards:', event.target.value); // Debugging line
  };
  const filteredRecords = rows.filter((row) => selectedCards.includes(row.cardNumber));
  console.log('Filtered Records:', filteredRecords);

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;

    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    console.log('data - ', data);

    // Create a new variable to store filtered data
    let filteredData = [...data]; // Create a shallow copy of the data array to avoid mutating the original

    // Assuming the currency data is available for each client, filter or modify it based on currency
    // Filter or modify based on currency selection
    if (selectedCurrency === 'CAD') {
      filteredData = filteredData.filter((client) => {
        // If the client has multiple currencies, we trim the string and check if it contains 'CAD'
        return client.currency
          ?.split(',')
          .map((c) => c.trim())
          .includes('CAD');
      });
    } else if (selectedCurrency === 'USD') {
      filteredData = filteredData.filter((client) => {
        // If the client has multiple currencies, we trim the string and check if it contains 'USD'
        return client.currency
          ?.split(',')
          .map((c) => c.trim())
          .includes('USD');
      });
    }

    console.log('filteredData', filteredData);
    // Sort the filtered data based on clientID
    filteredData.sort((a, b) => a.clientID - b.clientID);

    // Update the state with the filtered and sorted data
    setClients(filteredData);

    return filteredData;
  };

  const handleCustomerSelectionChange = async (event) => {
    setLoading(true);
    setRows([]);
    const customerId = event.target.value;
    setCustomerId(customerId);
    const getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerId}&maxRows=1000&pageNumber=1000`;

    try {
      const response = await fetch(getCardDetailsForCustomerUrl);
      const data = await response.json();

      console.log('data inside handle', data?.records?.card);
      const cards = data?.records?.card || [];

      if (cards.length > 0) {
        console.log('data inside handle', cards);
        setCardsForClient(cards); // Set all cards if there are multiple cards
      } else {
        setCardsForClient([]); // Set empty array if no cards
      }
      // setCurrency("usd");
      setReportType('normal');
    } catch (error) {
      console.error('Error fetching card details:', error);
    } finally {
      setLoading(false);
    }
  };

  // Set the customerId to the first item if only one item is present
  useEffect(() => {
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      const firstClient = clients[0];
      setCustomerId(firstClient.customerID);
      handleCustomerSelectionChange({
        target: { value: firstClient.customerID },
      });
    }
  }, [doNotPerformCustomerFiltration, loggedInUserClientId, BACKEND_URL, clients]);

  const handleSelectChange = (event) => {
    setCustomerId(event.target.value);

    handleCustomerSelectionChange(event);
  };

  // const handleCardChange = (event) => {
  //   setCard(event.target.value)
  // };

  // const handleCurrencyChange = (event) => {
  //   setCurrency(event.target.value);
  // };

  // const handleCustomerSelectionChange = async (event) => {
  //   setLoading(true);
  //   setRows([])
  //   let chosenClientForUpdate = clients.filter((client) => client.customerID === event.target.value);
  //   if (chosenClientForUpdate) {
  //     let customerId = chosenClientForUpdate[0].customerID
  //     setCustomerId(customerId);
  //     let getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerId}&maxRows=1000&pageNumber=1000`
  //     await fetch(getCardDetailsForCustomerUrl)
  //       .then(response => {
  //         return response.json();
  //       })
  //       .then(data => {
  //         setCardsForClient(data?.records?.card)
  //         setCurrency('usd')
  //         setReportType('normal')
  //       }
  //     ).then(() => setLoading(false))
  //   }
  // };

  const reportTypes = [
    { key: 'normal', value: 'Normal' },
    { key: 'groupByCard', value: 'Group By Card' },
    //{ key: "cardNewPage", value: "Card New Page" },
    //{ key: "surcharge", value: "Surcharge" },
    //{ key: "surchargeAll", value: "Surcharge All" },
    //{ key: 'all', value: 'All Transactions' },
  ];

  const handleViewReport = async (event) => {
    event.preventDefault();

    setUploading(true);

    // Validate if a client is selected
    if (!customerId) {
      setCustomerErrorText('Please select a customer');
      return;
    }

    // Validate start and end dates
    if (!startDate) {
      setStartDateErrorText('Start Date is required');
      return;
    }

    if (!endDate) {
      setEndDateErrorText('End Date is required');
      return;
    }

    // Function to add one day to a date
    const addOneDay = (date) => {
      const newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1); // Add one day
      return newDate;
    };

    // Add one day to start and end dates
    const newStartDate = startDate;
    const newEndDate = addOneDay(endDate);

    // Format dates to 'YYYY-MM-DD'
    const options = {
      timeZone: 'America/Chicago',
      hour12: false,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    let formattedStartDate = new Date(newStartDate).toLocaleDateString('en-CA', options);
    let formattedEndDate = new Date(newEndDate).toLocaleDateString('en-CA', options);

    // Append the required time and timezone info
    formattedStartDate = `${formattedStartDate}T00:00:00.000-06:00`;
    formattedEndDate = `${formattedEndDate}T23:59:59.999-06:00`;

    // Log the formatted dates
    console.log('Formatted start date: ', formattedStartDate);
    console.log('Formatted end date: ', formattedEndDate);

    if (selectedCurrency === 'USD') {
      let getTransactionDetailsUrl = `${BACKEND_URL}/v1/transaction`;

      // Prepare request object
      let retrieveTransactionsObject = {
        customerID: customerId === 'all' ? undefined : customerId,
        reportType: reportType,
        cardNumbers: selectedCards, // Ensure this reflects selected cards from the dropdown
      };

      if (formattedStartDate) {
        retrieveTransactionsObject.startDate = formattedStartDate;
      }

      if (formattedEndDate) {
        retrieveTransactionsObject.endDate = formattedEndDate;
      }

      console.log('Request Object:', retrieveTransactionsObject); // Debugging line

      try {
        const response = await fetch(getTransactionDetailsUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(retrieveTransactionsObject),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const transactionDetails = await response.json();

        console.log('Transaction Details:', transactionDetails); // Debugging line

        // Normalize card numbers for comparison
        const normalizeCardNumber = (cardNumber) => cardNumber.replace(/^0+/, ''); // Example normalization

        const selectedCardNumbers = selectedCards.map(normalizeCardNumber);
        console.log('Normalized Selected Card Numbers:', selectedCardNumbers); // Debugging line

        // Filter transactions based on the normalized card numbers and date range
        let filteredTransactions = transactionDetails.filter((transaction) => {
          const transactionDate = new Date(transaction.date); // Assuming transaction.date is ISO format
          const transactionCardNumber = normalizeCardNumber(transaction.cardNumber); // Normalize transaction card number

          // Log for debugging
          console.log(
            `Checking transaction card number: ${transactionCardNumber} against selected card numbers`,
          );

          return (
            (!startDate || transactionDate >= new Date(startDate)) &&
            (!endDate || transactionDate <= new Date(endDate)) &&
            (selectedCardNumbers.length === 0 ||
              selectedCardNumbers.includes(transactionCardNumber))
          );
        });

        console.log('Filtered Transactions:', filteredTransactions); // Debugging line

        // Sort transactions by year, month, and day in descending order
        filteredTransactions.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // Sorts by most recent date first
        });

        // Map filtered and sorted transactions to your desired format
        let transactionsList = filteredTransactions.map(createData);

        setFetchedWithNoRecords(transactionsList.length === 0);
        setRows(transactionsList);
      } catch (error) {
        console.error('Error fetching transaction details:', error);
        setFetchedWithNoRecords(true);
      } finally {
        setUploading(false);
      }
    } else if (selectedCurrency === 'CAD') {
      let companyXRef = ''; // or handle conditionally
      if (!doNotPerformCustomerFiltration && clients.length === 1) {
        // Handle the case where there is only one client
        const client = clients.find((client) => client.customerID === customerId);
        if (!client) {
          console.error(`Client not found for customerId: ${customerId}`);
          return; // Exit early or handle the error as needed
        }
        console.log('Client/CompanyXRef -', client.clientID);
        companyXRef = client.clientID;
      } else {
        // If selectedClient is 'all', do not search for clientID, set companyXRef accordingly
        if (selectedClient === 'all') {
          console.log('All clients selected, no specific companyXRef required.');
          companyXRef = ''; // You can set it to 'all' or leave it undefined depending on how you want to handle this
        } else {
          // Find the clientID based on the selectedCustomerID (selectedClient)
          const client = clients.find((client) => client.customerID === selectedClient);
          if (!client) {
            console.error(`Client not found for selectedClient: ${selectedClient}`);
            return; // Exit early or handle the error as needed
          }
          console.log('Client/CompanyXRef -', client.clientID);
          companyXRef = client.clientID;
        }
      }

      let getTransactionDetailsUrl = `${BACKEND_URL}/v1/transaction`;

      // Prepare request object
      let retrieveTransactionsObject = {
        companyXRef: companyXRef === 'all' ? undefined : companyXRef,
        // reportType: reportType,
        // cardNumbers: selectedCards // Ensure this reflects selected cards from the dropdown
      };

      if (formattedStartDate) {
        retrieveTransactionsObject.startDate = formattedStartDate;
      }

      if (formattedEndDate) {
        retrieveTransactionsObject.endDate = formattedEndDate;
      }

      console.log('Request Object:', retrieveTransactionsObject); // Debugging line

      // Prepare the URL with query parameters
      let urlWithParams = new URL(getTransactionDetailsUrl);
      const params = new URLSearchParams();

      // Append query parameters
      if (companyXRef !== undefined) {
        params.append('companyXRef', companyXRef);
      }
      params.append('startDate', formattedStartDate);
      params.append('endDate', formattedEndDate);

      // Attach the query parameters to the URL
      urlWithParams.search = params.toString();

      console.log('Request URL with Query Params:', urlWithParams.toString()); // Debugging line

      try {
        const response = await fetch(urlWithParams.toString(), {
          method: 'GET', // Use GET to send query params
          headers: {
            // No 'Content-Type' header required for query params
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const transactionDetails = await response.json();

        console.log('Transaction Details:', transactionDetails); // Debugging line

        // Normalize card numbers for comparison
        // const normalizeCardNumber = (cardNumber) => cardNumber.replace(/^0+/, ''); // Example normalization

        // const selectedCardNumbers = selectedCards.map(normalizeCardNumber);
        // console.log('Normalized Selected Card Numbers:', selectedCardNumbers); // Debugging line

        // Filter transactions based on the normalized card numbers and date range
        // let filteredTransactions = transactionDetails.filter((transaction) => {

        //   const transactionDate = new Date(transaction.transactionDate); // Convert from epoch milliseconds to Date object

        //   return (
        //     (!newStartDate || transactionDate >= new Date(newStartDate)) &&
        //     (!newEndDate || transactionDate <= new Date(newEndDate))
        //     // &&
        //     // (selectedCardNumbers.length === 0 || selectedCardNumbers.includes(transactionCardNumber))
        //   );
        // });

        let filteredTransactions = transactionDetails.filter((transaction) => {
          // Convert transactionDate to just the date (YYYY-MM-DD)
          const transactionDate = new Date(transaction.transactionDate);
          const transactionDateString = transactionDate.toLocaleDateString('en-CA', {
            timeZone: 'America/Chicago',
            hour12: false,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }); // This returns the date as 'YYYY-MM-DD' format.
          // toISOString().split('T')[0]; // Extract YYYY-MM-DD part

          // Convert newStartDate and newEndDate to the date strings in the same format
          // const startDateString = newStartDate ? new Date(newStartDate).toISOString().split('T')[0] : null;
          // const endDateString = newEndDate ? new Date(newEndDate).toISOString().split('T')[0] : null;

          return (
            // Filter by date (ignoring time)
            (!formattedStartDate || transactionDateString >= formattedStartDate) &&
            (!formattedEndDate || transactionDateString <= formattedEndDate)
          );
        });
        console.log('Filtered Transactions:', filteredTransactions); // Debugging line

        // Sort transactions by year, month, and day in descending order
        filteredTransactions.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA; // Sorts by most recent date first
        });

        // Map filtered and sorted transactions to your desired format
        let transactionsList = filteredTransactions.map(createDataCAD);

        console.log('transactionsList', transactionsList);
        setFetchedWithNoRecords(transactionsList.length === 0);
        setRows(transactionsList);
      } catch (error) {
        console.error('Error fetching transaction details:', error);
        setFetchedWithNoRecords(true);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleStartDateChange = (date) => {
    console.log('startDate', date);
    setStartDate(date);
    setStartDateErrorText('');
  };

  const handleEndDateChange = (date) => {
    console.log('endDate', date);
    setEndDate(date);
    setEndDateErrorText('');
  };

  const createData = (transactionDetail) => {
    let others = [];
    let totalCost = 0.0;
    console.log(transactionDetail + 'create data');

    if (transactionDetail) {
      //Diesel Row
      if (transactionDetail?.tractorFuelPricePerGallon > 0) {
        let rebateAmount = doNotPerformCustomerFiltration
          ? transactionDetail?.rebateAmount
          : transactionDetail?.customerRebateAmount;
        let transactionFee = doNotPerformCustomerFiltration ? 1 : 0;
        let dieselGallons = transactionDetail?.numTractorGallons;
        let unitPrice = transactionDetail?.tractorFuelPricePerGallon;
        let invoiceAmount = transactionDetail?.costOfTractorFuel;
        let totalInvoice = invoiceAmount + transactionFee;
        let discountPerGallon = doNotPerformCustomerFiltration
          ? transactionDetail?.discountPerGallon
          : transactionDetail?.customerDiscountPerGallon;

        let dieselRow = {
          type: 'Diesel',
          gallons: dieselGallons,
          unitPrice: unitPrice,
          transactionFee: transactionFee,
          invoiceAmount: invoiceAmount,
          totalInvoice: totalInvoice?.toFixed(3),
          discountPerGallon: +discountPerGallon.toFixed(3),
          rebate: rebateAmount?.toFixed(3),
          cost:
            totalInvoice > rebateAmount ? totalInvoice?.toFixed(3) - rebateAmount?.toFixed(3) : 0,
        };
        others.push(dieselRow);
        totalCost += dieselRow?.cost;
      }

      // Def Row
      if (transactionDetail?.otherFuelPPG > 0.0) {
        let defDieselGallons = transactionDetail?.otherFuelGallons;
        let defUnitPrice = transactionDetail?.otherFuelPPG;
        let defInvoiceAmount = transactionDetail?.otherFuelCost;

        let defRow = {
          type: 'Def',
          gallons: defDieselGallons,
          unitPrice: defUnitPrice,
          transactionFee: 0,
          invoiceAmount: defInvoiceAmount,
          totalInvoice: defInvoiceAmount,
          discountPerGallon: 0,
          rebate: 0,
          cost: defInvoiceAmount,
        };
        others.push(defRow);
        totalCost += defRow?.cost;
      }

      // Reefer Row
      if (transactionDetail && transactionDetail?.reeferPricePerGallon > 0.0) {
        let rebateAmount = doNotPerformCustomerFiltration
          ? transactionDetail?.rebateAmount
          : transactionDetail?.customerRebateAmount;
        let reeferDieselGallons = transactionDetail?.numReeferGallons;
        let reeferUnitPrice = transactionDetail?.reeferPricePerGallon;
        let reeferInvoiceAmount = transactionDetail?.costOfReeferFuel;
        let discountPerGallon = doNotPerformCustomerFiltration
          ? transactionDetail?.reeferDiscountPerGallon
          : transactionDetail?.reeferCustomerDiscountPerGallon;

        let reeferRow = {
          type: 'Reefer',
          gallons: reeferDieselGallons,
          unitPrice: reeferUnitPrice,
          transactionFee: 0,
          invoiceAmount: reeferInvoiceAmount,
          totalInvoice: reeferInvoiceAmount,
          discountPerGallon: +discountPerGallon,
          rebate: rebateAmount?.toFixed(3),
          cost: reeferInvoiceAmount,
        };
        others.push(reeferRow);
        totalCost += reeferRow?.cost;
      }

      // Cash Row
      if (transactionDetail && transactionDetail?.cashAdvanceAmount > 0.0) {
        let cashAdvanceAmount = transactionDetail?.cashAdvanceAmount;
        let transactionFee = doNotPerformCustomerFiltration
          ? transactionDetail?.costForCashAdvance
          : transactionDetail?.customerCostForCashAdvance;
        let invoiceAmount = cashAdvanceAmount + transactionFee;

        let cashRow = {
          type: 'Cash',
          gallons: '',
          unitPrice: '',
          transactionFee: transactionFee,
          invoiceAmount: cashAdvanceAmount,
          totalInvoice: invoiceAmount,
          discountPerGallon: 0,
          rebate: 0,
          cost: invoiceAmount,
        };
        others.push(cashRow);
        totalCost += cashRow?.cost;
      }
    }

    return {
      customerId: transactionDetail?.customerID,
      transactionNumber: transactionDetail?.transactionNumber,
      transactionDateTime: `${transactionDetail?.transactionDate} ${transactionDetail?.transactionTime}`,
      cardNumber: transactionDetail?.cardNumber,
      unitNumber: transactionDetail?.unitNumber,
      truckStopCity: transactionDetail?.stopCity,
      truckStopState: transactionDetail?.truckStopState,
      cost: totalCost?.toFixed(3),
      others: others,
    };
  };

  const createDataCAD = (transactionDetail) => {
    let others = [];
    // let totalCost = 0.0;
    // console.log(transactionDetail + 'create data');

    if (transactionDetail && transactionDetail.lineItems) {
      // Checking if the line item with category "ULSD" exists
      const ulsdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'ULSD',
      );
      // console.log("ulsdLineItem", ulsdLineItem);

      const defdLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'DEFD',
      );
      // console.log("defdLineItem", defdLineItem);

      const rfrLineItem = transactionDetail.lineItems.find(
        (lineItem) => lineItem.category === 'RFR',
      );
      // console.log("rfrLineItem", rfrLineItem);

      if (ulsdLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? ulsdLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        // Populate the data for "ULSD" line item
        others.push({
          product: 'DIESEL', // Product name for ULSD category
          qty: (ulsdLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (ulsdLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (ulsdLineItem.retailAmount / ulsdLineItem.quantity || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: doNotPerformCustomerFiltration
            ? (ulsdLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: doNotPerformCustomerFiltration
            ? (
                ulsdLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          pst: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ), // PST amount
          fet: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ), // FET amount
          pft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ), // PFT amount
          mft: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ), // MFT amount
          ct: (ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: doNotPerformCustomerFiltration
            ? (ulsdLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3), // Savings from discount
          amount: doNotPerformCustomerFiltration
            ? (ulsdLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          customerPst: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            ulsdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3), // Savings
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
        // others.push(ulsdLineItem);
      } else if (defdLineItem) {
        const amount = defdLineItem.amount.toFixed(3) || 0;
        const customerAmount = defdLineItem.amount.toFixed(3) || 0;
        // Populate the data for "ULSD" line item
        others.push({
          product: 'DEF', // Product name for ULSD category
          qty: (defdLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (defdLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (defdLineItem.retailPPU || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: (defdLineItem.ppu || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3), // GST/HST amount
          pst: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(
            3,
          ), // PST amount
          fet: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(
            3,
          ), // FET amount
          pft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(
            3,
          ), // PFT amount
          mft: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(
            3,
          ), // MFT amount
          ct: (defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: (defdLineItem.discAmount || 0).toFixed(3), // Savings from discount
          amount: (defdLineItem.amount || 0).toFixed(3), // Retail amount
          customerPpu: (defdLineItem.ppu || 0).toFixed(3),
          customerGstHst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N')
              ?.amount || 0
          ).toFixed(3), // GST/HST amount
          customerPst: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            defdLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (defdLineItem.discAmount || 0).toFixed(3), // Savings
          customerAmount: (defdLineItem.amount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
      } else if (rfrLineItem) {
        const amount = doNotPerformCustomerFiltration
          ? rfrLineItem.amount || 0
          : transactionDetail.customerAmount || 0;
        const customerAmount = transactionDetail.customerAmount || 0;
        // Populate the data for "RFR" line item
        others.push({
          product: 'RFR', // Product name for RFR category
          qty: (rfrLineItem.quantity || 0).toFixed(3), // Quantity, fallback to '0.000' if not available
          retailAmount: (rfrLineItem.retailAmount || 0).toFixed(3), // Retail Amount, fallback to '0.000'
          retailPPU: (rfrLineItem.retailAmount / rfrLineItem.quantity || 0).toFixed(3), // Retail PPU, fallback to '0.000'
          ppu: doNotPerformCustomerFiltration
            ? (rfrLineItem.ppu || 0).toFixed(3)
            : (transactionDetail.customerPPU || 0).toFixed(3), // PPU, fallback to '0.000'
          gstHst: doNotPerformCustomerFiltration
            ? (
                rfrLineItem.lineTaxes.find(
                  (tax) => tax.taxCode === 'HST' && tax.grossNetFlag === 'N',
                )?.amount || 0
              ).toFixed(3)
            : (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          pst: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0).toFixed(3), // PST amount
          fet: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0).toFixed(3), // FET amount
          pft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0).toFixed(3), // PFT amount
          mft: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0).toFixed(3), // MFT amount
          ct: (rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0).toFixed(3), // CT amount
          savings: doNotPerformCustomerFiltration
            ? (rfrLineItem.discAmount || 0).toFixed(3)
            : (transactionDetail.customerSavings || 0).toFixed(3), // Savings from discount
          amount: doNotPerformCustomerFiltration
            ? (rfrLineItem.amount || 0).toFixed(3)
            : (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          customerPpu: (transactionDetail.customerPPU || 0).toFixed(3),
          customerGstHst: (transactionDetail.customerGsthst || 0).toFixed(3), // GST/HST amount
          customerPst: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PST')?.amount || 0
          ).toFixed(3), // PST amount
          customerFet: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'FET')?.amount || 0
          ).toFixed(3), // FET amount
          customerPft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PFT')?.amount || 0
          ).toFixed(3), // PFT amount
          customerMft: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'MFT')?.amount || 0
          ).toFixed(3), // MFT amount
          customerCt: (
            rfrLineItem.lineTaxes.find((tax) => tax.taxCode === 'PCT')?.amount || 0
          ).toFixed(3), // CT amount
          customerSavings: (transactionDetail.customerSavings || 0).toFixed(3), // Savings
          customerAmount: (transactionDetail.customerAmount || 0).toFixed(3), // Retail amount
          ksgProfit: (customerAmount - amount || 0).toFixed(3),
        });
        // others.push(rfrLineItem);
      }
    }
    return {
      companyXRef: doNotPerformCustomerFiltration ? transactionDetail.companyXRef : '',
      transactionDate: `${transactionDetail?.transactionDate?.slice(0, 10)}`,
      cardNumber: transactionDetail?.cardNumber?.slice(-5),
      driverName: transactionDetail.infos.find((info) => info.type === 'NAME')?.value || '',
      locationName: transactionDetail?.locationName,
      locationCity: transactionDetail?.locationCity,
      locationState: transactionDetail?.locationState,
      unitNumber: transactionDetail.infos.find((info) => info.type === 'UNIT')?.value || '',
      others: others,
    };
    // console.log(transactionDetail);
  };

  const Row = (props) => {
    const { row } = props;
    const { index } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          className={index % 2 == 0 ? 'main-table-row-even' : 'main-table-row-odd'}
        >
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className="main-table-row-cell" component="th" scope="row">
            {row.customerId}
          </TableCell>
          <TableCell className="main-table-row-cell">{row.transactionDateTime}</TableCell>
          <TableCell className="main-table-row-cell">{row.cardNumber}</TableCell>
          <TableCell className="main-table-row-cell">{row.transactionNumber}</TableCell>
          <TableCell className="main-table-row-cell">{row.unitNumber}</TableCell>
          <TableCell className="main-table-row-cell">{row.truckStopCity}</TableCell>
          <TableCell className="main-table-row-cell">{row.truckStopState}</TableCell>
          <TableCell className="main-table-row-cell">{row.cost}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h4">Others</Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead className="sub-table-header">
                    <TableRow>
                      <TableCell className="sub-table-header-cell">Type</TableCell>
                      <TableCell className="sub-table-header-cell">Gallons</TableCell>
                      <TableCell className="sub-table-header-cell">Unit Price</TableCell>
                      <TableCell className="sub-table-header-cell">Transaction Fee ($)</TableCell>
                      <TableCell className="sub-table-header-cell">Invoice Amount ($)</TableCell>
                      <TableCell className="sub-table-header-cell">Total Invoice ($)</TableCell>
                      <TableCell className="sub-table-header-cell">Discount per Gallon</TableCell>
                      <TableCell className="sub-table-header-cell">Rebate ($)</TableCell>
                      <TableCell className="sub-table-header-cell">Cost ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.others?.map((otherRow) => (
                      <TableRow key={otherRow.type}>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          component="th"
                          scope="row"
                        >
                          {otherRow.type}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.gallons}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.unitPrice}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.transactionFee}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.invoiceAmount}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.totalInvoice}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.discountPerGallon.toFixed(3)}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.rebate}
                        </TableCell>
                        <TableCell className={`sub-table-row-${otherRow.type}`}>
                          {otherRow.cost?.toFixed(3)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const RowCAD = (props) => {
    const { row } = props;
    const { index } = props;
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow
          sx={{ '& > *': { borderBottom: 'unset' } }}
          className={index % 2 == 0 ? 'main-table-row-even' : 'main-table-row-odd'}
        >
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          {doNotPerformCustomerFiltration && (
            <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
              {row.companyXRef}
            </TableCell>
          )}
          {/* <TableCell className="main-table-row-cell">{row.companyXRef}</TableCell> */}
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.transactionDate}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.cardNumber?.slice(-5)}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.driverName}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.locationName}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.locationCity}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.locationState}
          </TableCell>
          <TableCell className="main-table-row-cell" style={{ fontSize: '9px' }}>
            {row.unitNumber}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '9px',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                  }}
                >
                  Others
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead
                    // sx={{ backgroundColor: "#31478F", height: "9px" }}
                    sx={{
                      backgroundColor: '#31478F',
                      '& .main-table-header-cell': {
                        color: 'white',
                        fontSize: '9px', // Further reduce font size
                        padding: '2px 6px', // Reduce padding
                        height: '20px', // Reduce row height
                        textAlign: 'left',
                      },
                      '& .MuiTableRow-root': {
                        height: '20px', // Reduce overall row height
                        textAlign: 'left',
                      },
                      '& .MuiTableCell-root': {
                        padding: '1px', // Reduce cell padding
                        fontSize: '9px', // Reduce font size
                        textAlign: 'left',
                      },
                    }}
                  >
                    <TableRow sx={{ height: '20px', fontSize: '8px' }}>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: 'sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        PRODUCT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: 'sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        QTY
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: 'sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        RETAIL AMOUNT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: ' sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        RETAIL PPU
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: 'sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        BILLING PPU
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: ' sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        GST/HST
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: ' sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        PST
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        FET
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        PFT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        MFT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        CT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        SAVINGS
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'white',
                          fontSize: '9px',
                          fontFamily: '  sans-serif',
                          padding: '9px',
                          fontWeight: 'bold',
                        }}
                      >
                        BILLING AMOUNT
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.others?.map((otherRow) => (
                      <TableRow key={otherRow.type}>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          component="th"
                          scope="row"
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.product}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.qty}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.retailAmount}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.retailPPU}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.ppu}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.gstHst}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.pst}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.fet}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.pft}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.mft}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.ct}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.savings}
                        </TableCell>
                        <TableCell
                          className={`sub-table-row-${otherRow.type}`}
                          style={{ fontSize: '9px' }}
                        >
                          {otherRow.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  const exportTable = (records) => {
    let exportRecords = [];
    records?.map((record) => {
      let dieselDetails = record?.others?.filter((other) => other?.type === 'Diesel');
      let defDetails = record?.others?.filter((other) => other?.type === 'Def');
      let reeferDetails = record?.others?.filter((other) => other?.type === 'Reefer');
      let cashDetails = record?.others?.filter((other) => other?.type === 'Cash');
      let dieselGallons,
        dieselUnitPrice,
        dieselTransactionFee,
        dieselInvoiceAmount,
        dieselTotalInvoice,
        dieselDiscountPerGallon,
        dieselRebate,
        dieselCost = 0;
      let defGallons,
        defUnitPrice,
        defTransactionFee,
        defInvoiceAmount,
        defTotalInvoice,
        defDiscountPerGallon,
        defRebate,
        defCost = 0;
      let reeferGallons,
        reeferUnitPrice,
        reeferTransactionFee,
        reeferInvoiceAmount,
        reeferTotalInvoice,
        reeferDiscountPerGallon,
        reeferRebate,
        reeferCost = 0;
      let cashGallons,
        cashUnitPrice,
        cashTransactionFee,
        cashInvoiceAmount,
        cashTotalInvoice,
        cashDiscountPerGallon,
        cashRebate,
        cashCost = 0;

      if (dieselDetails && dieselDetails.length > 0) {
        let dieselObject = dieselDetails[0];
        dieselGallons = dieselObject?.gallons;
        dieselUnitPrice = dieselObject?.unitPrice;
        dieselTransactionFee = dieselObject?.transactionFee;
        dieselInvoiceAmount = dieselObject?.invoiceAmount;
        dieselTotalInvoice = dieselObject?.totalInvoice;
        dieselDiscountPerGallon = dieselObject?.discountPerGallon;
        dieselRebate = dieselObject?.rebate;
        dieselCost = dieselObject?.cost;
      }

      if (defDetails && defDetails.length > 0) {
        let defObject = defDetails[0];
        defGallons = defObject?.gallons;
        defUnitPrice = defObject?.unitPrice;
        defTransactionFee = defObject?.transactionFee;
        defInvoiceAmount = defObject?.invoiceAmount;
        defTotalInvoice = defObject?.totalInvoice;
        defDiscountPerGallon = defObject?.discountPerGallon;
        defRebate = defObject?.rebate;
        defCost = defObject?.cost;
      }

      if (reeferDetails && reeferDetails.length > 0) {
        let reeferObject = reeferDetails[0];
        reeferGallons = reeferObject?.gallons;
        reeferUnitPrice = reeferObject?.unitPrice;
        reeferTransactionFee = reeferObject?.transactionFee;
        reeferInvoiceAmount = reeferObject?.invoiceAmount;
        reeferTotalInvoice = reeferObject?.totalInvoice;
        reeferDiscountPerGallon = reeferObject?.discountPerGallon;
        reeferRebate = reeferObject?.rebate;
        reeferCost = reeferObject?.cost;
      }

      if (cashDetails && cashDetails.length > 0) {
        let cashObject = cashDetails[0];
        cashGallons = cashObject?.gallons;
        cashUnitPrice = cashObject?.unitPrice;
        cashTransactionFee = cashObject?.transactionFee;
        cashInvoiceAmount = cashObject?.invoiceAmount;
        cashTotalInvoice = cashObject?.totalInvoice;
        cashDiscountPerGallon = cashObject?.discountPerGallon;
        cashRebate = cashObject?.rebate;
        cashCost = cashObject?.cost;
      }

      let recordObject = {
        'Customer ID': record?.customerId,
        'Transaction Date & Time': record?.transactionDateTime,
        'Card Number': record?.cardNumber,
        'Transaction Number': record?.transactionNumber,
        'Unit Number': record?.unitNumber,
        'Truck Stop City': record?.truckStopCity,
        'Truck Stop State': record?.truckStopState,
        'Diesel Gallons': dieselGallons,
        'Diesel Unit Price': dieselUnitPrice,
        'Diesel Transaction Fee': dieselTransactionFee,
        'Diesel Invoice Amount': dieselInvoiceAmount,
        'Diesel Total Invoice': dieselTotalInvoice,
        'Diesel Discount Per Gallon': dieselDiscountPerGallon,
        'Diesel Rebate': dieselRebate,
        'Diesel Cost': dieselCost,
        'Def Gallons': defGallons,
        'Def Unit Price': defUnitPrice,
        'Def Transaction Fee': defTransactionFee,
        'Def Invoice Amount': defInvoiceAmount,
        'Def Total Invoice': defTotalInvoice,
        'Def Discount Per Gallon': defDiscountPerGallon,
        'Def Rebate': defRebate,
        'Def Cost': defCost,
        'Reefer Gallons': reeferGallons,
        'Reefer Unit Price': reeferUnitPrice,
        'Reefer Transaction Fee': reeferTransactionFee,
        'Reefer Invoice Amount': reeferInvoiceAmount,
        'Reefer Total Invoice': reeferTotalInvoice,
        'Reefer Discount Per Gallon': reeferDiscountPerGallon,
        'Reefer Rebate': reeferRebate,
        'Reefer Cost': reeferCost,
        'Cash Gallons': cashGallons,
        'Cash Unit Price': cashUnitPrice,
        'Cash Transaction Fee': cashTransactionFee,
        'Cash Invoice Amount': cashInvoiceAmount,
        'Cash Total Invoice': cashTotalInvoice,
        'Cash Discount Per Gallon': cashDiscountPerGallon,
        'Cash Rebate': cashRebate,
        'Cash Cost': cashCost,
        'Total Cost': dieselCost + defCost + reeferCost + cashCost,
      };
      exportRecords.push(recordObject);
    });

    return exportRecords;
  };

  const exportCADReport = (records) => {
    let exportRecords = [];

    records?.map((record) => {
      let dieselDetails = record?.others?.filter((other) => other?.product === 'DIESEL');
      let defDetails = record?.others?.filter((other) => other?.product === 'DEF');
      let reeferDetails = record?.others?.filter((other) => other?.product === 'RFR');
      let product,
        qty,
        retailAmount,
        retailPPU,
        ppu,
        gstHst,
        pst,
        fet,
        pft,
        mft,
        ct,
        savings,
        amount = 0;

      // Helper function to format values to 3 decimal places
      const formatDecimal = (value) => {
        // Check if the value is a valid number (not null, undefined, or NaN)
        if (value === null || value === undefined || isNaN(value)) {
          return '0.000'; // Return 0.000 for invalid values
        }
        return parseFloat(value).toFixed(3); // Return the value as a string with 3 decimal places
      };

      if (dieselDetails && dieselDetails.length > 0) {
        let dieselObject = dieselDetails[0];
        product = dieselObject?.product;
        qty = formatDecimal(dieselObject?.qty);
        retailAmount = formatDecimal(dieselObject?.retailAmount);
        retailPPU = formatDecimal(dieselObject?.retailPPU);
        ppu = formatDecimal(dieselObject?.ppu);
        gstHst = formatDecimal(dieselObject?.gstHst);
        pst = formatDecimal(dieselObject?.pst);
        fet = formatDecimal(dieselObject?.fet);
        pft = formatDecimal(dieselObject?.pft);
        mft = formatDecimal(dieselObject?.mft);
        ct = formatDecimal(dieselObject?.ct);
        savings = formatDecimal(dieselObject?.savings);
        amount = formatDecimal(dieselObject?.amount);
      }

      if (defDetails && defDetails.length > 0) {
        let defObject = defDetails[0];
        product = defObject?.product;
        qty = formatDecimal(defObject?.qty);
        retailAmount = formatDecimal(defObject?.retailAmount);
        retailPPU = formatDecimal(defObject?.retailPPU);
        ppu = formatDecimal(defObject?.ppu);
        gstHst = formatDecimal(defObject?.gstHst);
        pst = formatDecimal(defObject?.pst);
        fet = formatDecimal(defObject?.fet);
        pft = formatDecimal(defObject?.pft);
        mft = formatDecimal(defObject?.mft);
        ct = formatDecimal(defObject?.ct);
        savings = formatDecimal(defObject?.savings);
        amount = formatDecimal(defObject?.amount);
      }

      if (reeferDetails && reeferDetails.length > 0) {
        let reeferObject = reeferDetails[0];
        product = reeferObject?.product;
        qty = formatDecimal(reeferObject?.qty);
        retailAmount = formatDecimal(reeferObject?.retailAmount);
        retailPPU = formatDecimal(reeferObject?.retailPPU);
        ppu = formatDecimal(reeferObject?.ppu);
        gstHst = formatDecimal(reeferObject?.gstHst);
        pst = formatDecimal(reeferObject?.pst);
        fet = formatDecimal(reeferObject?.fet);
        pft = formatDecimal(reeferObject?.pft);
        mft = formatDecimal(reeferObject?.mft);
        ct = formatDecimal(reeferObject?.ct);
        savings = formatDecimal(reeferObject?.savings);
        amount = formatDecimal(reeferObject?.amount);
      }

      let recordObject = {
        ...(doNotPerformCustomerFiltration ? { COMPANYXREF: record?.companyXRef } : {}),
        'TRANSACTION DATE': record?.transactionDate,
        'CARD NUMBER': record?.cardNumber?.slice(-5),
        'DRIVER NAME': record?.driverName,
        'LOCATION NAME': record?.locationName,
        'LOCATION CITY': record?.locationCity,
        'LOCATTION STATE': record?.locationState,
        'UNIT NUMBER': record?.unitNumber,
        PRODUCT: product,
        QUANTITY: qty,
        'RETAIL AMOUNT': retailAmount,
        'RETAIL PPU': retailPPU,
        'BILLING PPU': ppu,
        'GST/HST': gstHst,
        PST: pst,
        FET: fet,
        PFT: pft,
        MFT: mft,
        CT: ct,
        SAVINGS: savings,
        AMOUNT: amount,
      };

      // If Super Admin, add extra columns for each product type (DIESEL, DEF, RFR)
      if (doNotPerformCustomerFiltration) {
        // For DIESEL
        if (dieselDetails && dieselDetails.length > 0) {
          let dieselObject = dieselDetails[0];
          recordObject['CUSTOMER BILLING PPU'] = formatDecimal(dieselObject?.customerPpu);
          recordObject['CUSTOMER GST/HST'] = formatDecimal(dieselObject?.customerGstHst);
          recordObject['CUSTOMER PST'] = formatDecimal(dieselObject?.customerPst);
          recordObject['CUSTOMER FET'] = formatDecimal(dieselObject?.customerFet);
          recordObject['CUSTOMER PFT'] = formatDecimal(dieselObject?.customerPft);
          recordObject['CUSTOMER MFT'] = formatDecimal(dieselObject?.customerMft);
          recordObject['CUSTOMER CT'] = formatDecimal(dieselObject?.customerCt);
          recordObject['CUSTOMER SAVINGS'] = formatDecimal(dieselObject?.customerSavings);
          recordObject['CUSTOMER AMOUNT'] = formatDecimal(dieselObject?.customerAmount);
          recordObject['PROFIT TO KSG'] = formatDecimal(dieselObject?.ksgProfit);
        }

        // For DEF
        if (defDetails && defDetails.length > 0) {
          let defObject = defDetails[0];
          recordObject['CUSTOMER BILLING PPU'] = formatDecimal(defObject?.customerPpu);
          recordObject['CUSTOMER GST/HST'] = formatDecimal(defObject?.customerGstHst);
          recordObject['CUSTOMER PST'] = formatDecimal(defObject?.customerPst);
          recordObject['CUSTOMER FET'] = formatDecimal(defObject?.customerFet);
          recordObject['CUSTOMER PFT'] = formatDecimal(defObject?.customerPft);
          recordObject['CUSTOMER MFT'] = formatDecimal(defObject?.customerMft);
          recordObject['CUSTOMER CT'] = formatDecimal(defObject?.customerCt);
          recordObject['CUSTOMER SAVINGS'] = formatDecimal(defObject?.customerSavings);
          recordObject['CUSTOMER AMOUNT'] = formatDecimal(defObject?.customerAmount);
          recordObject['PROFIT TO KSG'] = formatDecimal(defObject?.ksgProfit);
        }

        // For RFR (Reefer)
        if (reeferDetails && reeferDetails.length > 0) {
          let reeferObject = reeferDetails[0];
          recordObject['CUSTOMER BILLING PPU'] = formatDecimal(reeferObject?.customerPpu);
          recordObject['CUSTOMER GST/HST'] = formatDecimal(reeferObject?.customerGstHst);
          recordObject['CUSTOMER PST'] = formatDecimal(reeferObject?.customerPst);
          recordObject['CUSTOMER FET'] = formatDecimal(reeferObject?.customerFet);
          recordObject['CUSTOMER PFT'] = formatDecimal(reeferObject?.customerPft);
          recordObject['CUSTOMER MFT'] = formatDecimal(reeferObject?.customerMft);
          recordObject['CUSTOMER CT'] = formatDecimal(reeferObject?.customerCt);
          recordObject['CUSTOMER SAVINGS'] = formatDecimal(reeferObject?.customerSavings);
          recordObject['CUSTOMER AMOUNT'] = formatDecimal(reeferObject?.customerAmount);
          recordObject['PROFIT TO KSG'] = formatDecimal(reeferObject?.ksgProfit);
        }
      }

      exportRecords.push(recordObject);
    });

    return exportRecords;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(customerId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (clientID) => {
    console.log('Client ID selected:', clientID);
    setSelectedClient(clientID);
    // Validate if a client is selected
    if (!clientID) {
      setCustomerErrorText('Please select a customer');
    } else {
      setCustomerErrorText('');
    }
    handleSelectChange({ target: { value: clientID } });
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'client-select-popover' : undefined;

  // Filter clients based on search term
  const filteredClients = clients.filter(
    (client) =>
      client.clientID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '' ||
      client.customerID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '' ||
      client.clientName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '',
  );

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);

    // Re-fetch clients based on selected currency
    getClients();

    // if (currency === 'CAD') {
    //   setDisableCAD(true);
    //   setDisableUSD(false); // Enable USD button
    //   // Disable the USD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableCAD(false);
    //   }, 10000); // 10 seconds
    // } else if (currency === 'USD') {
    //   setDisableUSD(true);
    //   setDisableCAD(false); // Enable CAD button
    //   // Disable the CAD button for 10 seconds
    //   setTimeout(() => {
    //     setDisableUSD(false);
    //   }, 10000); // 10 seconds
    // }
  };
  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  return (
    <div style={{ marginLeft: '10px', marginTop: '-28px' }}>
      <Box display="flex" alignItems="center" mb={2} gap={2}>
        <Typography
          variant="h1"
          sx={{
            fontSize: '14px', // Adjust the size as needed
            fontFamily: 'sans-serif',
            fontWeight: 'bold', // Optional for better visibility
            marginTop: '20px', // Keeps gutterBottom spacing
          }}
          gutterBottom
        >
          TRANSACTION REPORT
        </Typography>

        {/* Button Container */}
        <Box display="flex" mb={2} gap={2}>
          {/* Button 1 */}
          <Button
            variant="contained"
            style={{
              marginTop: '30px',

              fontFamily: 'Sans-serif',
              fontSize: '12px', // Reduce font size
              backgroundColor: getButtonBackground('CAD'),
              padding: '4px 8px', // Adjust padding for a smaller button
              minWidth: '50px', // Set a minimum width
              height: '30px', // Reduce height
            }}
            onClick={() => handleCurrencySelect('CAD')}
            // disabled={disableUSD} // Disable this button if disableCAD is true
            // onClick={handleButton1Click}  // Provide your own handler
            sx={{ fontSize: '16px', textTransform: 'none' }}
          >
            CAD
          </Button>

          {/* Button 2 */}
          {/* <Button
          variant="contained"
          style={{ marginTop: '5px', fontFamily: 'DM Sans', fontSize: buttonsFontSize, 
            backgroundColor: getButtonBackground('USD') }}
          onClick={() => handleCurrencySelect('USD')}
          disabled={disableCAD} // Disable this button if disableUSD is true
        // onClick={handleButton2Click}  // Provide your own handler
          sx={{ fontSize: '16px', textTransform: 'none' }}
        >
          USD
        </Button> */}
        </Box>
      </Box>
      <div className="container" style={{ display: 'flex' }}>
        <div className="card" style={{ flex: '1', marginTop: '-40px', marginLeft: '-14px' }}>
          {/* <Card sx={{ minWidth: '100%', minHeight: cardHeight }}> */}
          <CardContent>
            <form id="transaction-report" onSubmit={handleViewReport}>
              <Grid container spacing={2} style={gridRowStyles}>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Box>
                    <FormControl
                      sx={{
                        minWidth: '100%',
                        minHeight: '20px', // Reduce height
                        fontSize: '12px', // Reduce text size
                        lineHeight: '1',
                      }}
                      size="small"
                      error={customerErrorText}
                    >
                      {doNotPerformCustomerFiltration ? (
                        <div>
                          <IconButton
                            aria-describedby={id}
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                              textAlign: 'left',
                              width: '100%',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              height: '24.5px', // Reduced height
                              minHeight: '24.5px',
                              maxHeight: '24.5px',
                              padding: '4px', // Reduced padding
                              boxSizing: 'border-box',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              fontSize: '10px', // Smaller text
                            }}
                            style={inputLabelStyles}
                          >
                            <span style={{ fontSize: '12px' }}>
                              {selectedClient === 'all'
                                ? 'All Transactions'
                                : selectedClient
                                ? `${
                                    clients.find((client) => client.customerID === selectedClient)
                                      ?.clientID
                                  } - ${
                                    clients.find((client) => client.customerID === selectedClient)
                                      ?.customerID
                                  } - ${
                                    clients.find((client) => client.customerID === selectedClient)
                                      ?.clientName
                                  }`
                                : 'Customer'}
                            </span>
                          </IconButton>
                          <Popover
                            id={id}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 250,
                                width: 300, // Increased width (adjust as needed)
                                overflow: 'auto',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '8px', // Added padding to the Popover container
                              },
                            }}
                          >
                            <div style={{ width: '250px' }}>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="search"
                                label="Search"
                                type="search"
                                fullWidth
                                variant="outlined"
                                size="small"
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{
                                  width: '120%', // Ensure full width
                                  '& .MuiInputBase-root': {
                                    height: '28px', // Reduced height
                                    fontSize: '12px', // Adjusted font size
                                    padding: '2px 8px', // Reduced padding
                                  },
                                  '& .MuiInputLabel-root': {
                                    fontSize: '12px', // Adjusted label font size
                                    transform: 'translate(14px, 8px) scale(1)', // Adjust label position
                                    '&.Mui-focused': {
                                      transform: 'translate(14px, -6px) scale(0.75)', // Adjust label position when focused
                                    },
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '4px 8px', // Adjust input padding
                                  },
                                }}
                              />
                              <MenuItem
                                value="all"
                                onClick={() => handleSelect('all')}
                                sx={{
                                  minHeight: '30px', // Reduced item height
                                  fontSize: '12px', // Adjusted font size
                                  padding: '6px 12px', // Adjusted padding
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5', // Hover effect for menu items
                                  },
                                }}
                              >
                                All Transactions
                              </MenuItem>
                              {filteredClients.length > 0 ? (
                                filteredClients.map((client) => (
                                  <MenuItem
                                    key={client.customerID}
                                    selected={client.customerID === selectedClient}
                                    onClick={() => handleSelect(client.customerID)}
                                    sx={{
                                      minHeight: '30px', // Reduced item height
                                      fontSize: '12px', // Adjusted font size
                                      padding: '6px 12px', // Adjusted padding
                                      '&:hover': {
                                        backgroundColor: '#f5f5f5', // Hover effect for menu items
                                      },
                                    }}
                                  >
                                    {client.clientID} - {client.customerID} - {client.clientName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem disabled>No results found</MenuItem>
                              )}
                            </div>
                          </Popover>
                        </div>
                      ) : clients.length === 1 ? (
                        <div style={{ marginTop: '0px', marginLeft: '16px' }}>
                          <Typography variant="body1">
                            {clients[0].clientID} - {clients[0].customerID} -{' '}
                            {clients[0].clientName}
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          <IconButton
                            aria-describedby={id}
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            sx={{
                              textAlign: 'left',
                              width: '100%',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              height: '24px', // Reduced height
                              minHeight: '24px',
                              maxHeight: '24px',
                              padding: '4px', // Reduced padding
                              boxSizing: 'border-box',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              fontSize: '10px', // Smaller text
                              postion: 'fixed',
                            }}
                            style={inputLabelStyles}
                          >
                            {selectedClient === 'all'
                              ? 'All Transactions'
                              : selectedClient
                              ? `${
                                  clients.find((client) => client.customerID === selectedClient)
                                    ?.clientID
                                } - ${
                                  clients.find((client) => client.customerID === selectedClient)
                                    ?.customerID
                                } - ${
                                  clients.find((client) => client.customerID === selectedClient)
                                    ?.clientName
                                }`
                              : 'Customer'}
                          </IconButton>
                          <Popover
                            id={id}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: 300,
                                width: 200, // Increased width (adjust as needed)
                                overflow: 'auto',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                padding: '8px', // Added padding to the Popover container
                              },
                            }}
                          >
                            <div style={{ width: '250px' }}>
                              <TextField
                                autoFocus
                                margin="dense"
                                id="search"
                                label="Search"
                                type="search"
                                fullWidth
                                variant="outlined"
                                size="small" // Reduced text field height
                                onChange={(e) => setSearchTerm(e.target.value)}
                                sx={{
                                  width: '100%', // Ensure full width
                                  '& .MuiInputBase-root': {
                                    height: '28px', // Reduced height
                                    fontSize: '12px', // Adjusted font size
                                    padding: '2px 8px', // Reduced padding
                                  },
                                  '& .MuiInputLabel-root': {
                                    fontSize: '12px', // Adjusted label font size
                                    transform: 'translate(14px, 8px) scale(1)', // Adjust label position
                                    '&.Mui-focused': {
                                      transform: 'translate(14px, -6px) scale(0.75)', // Adjust label position when focused
                                    },
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '4px 8px', // Adjust input padding
                                  },
                                }}
                              />
                              <MenuItem
                                value="all"
                                onClick={() => handleSelect('all')}
                                sx={{
                                  minHeight: '30px', // Reduced item height
                                  fontSize: '12px', // Adjusted font size
                                  padding: '6px 12px', // Adjusted padding
                                  '&:hover': {
                                    backgroundColor: '#f5f5f5', // Hover effect for menu items
                                  },
                                }}
                              >
                                All Transactions
                              </MenuItem>
                              {filteredClients.length > 0 ? (
                                filteredClients.map((client) => (
                                  <MenuItem
                                    key={client.customerID}
                                    selected={client.customerID === selectedClient}
                                    onClick={() => handleSelect(client.customerID)}
                                    sx={{
                                      minHeight: '30px', // Reduced item height
                                      fontSize: '12px', // Adjusted font size
                                      padding: '6px 12px', // Adjusted padding
                                      '&:hover': {
                                        backgroundColor: '#f5f5f5', // Hover effect for menu items
                                      },
                                    }}
                                  >
                                    {client.clientID} - {client.customerID} - {client.clientName}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem
                                  disabled
                                  sx={{
                                    margin: '4px 8px 8px 8px',
                                    fontSize: '12px', // Adjusted font size
                                  }}
                                >
                                  No results found
                                </MenuItem>
                              )}
                            </div>
                          </Popover>
                        </div>
                      )}
                      {customerErrorText && (
                        <FormHelperText id="customer-error-text">
                          {customerErrorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Box>
                    <FormControl sx={{ minWidth: '100%' }} size="small" error={startDateErrorText}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          value={startDate}
                          InputLabelProps={textFieldLabelStyles}
                          onChange={handleStartDateChange}
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                sx: {
                                  fontSize: '11px',
                                  top: '-50%', // Initially center the label vertically
                                },
                              },
                              sx: {
                                '& .MuiInputBase-root': {
                                  height: 25,
                                  minHeight: 25,
                                }, // Adjust height
                                '& .MuiInputBase-input': {
                                  fontSize: 11,
                                  padding: '2px',
                                }, // Reduce font size & padding
                                '& .MuiIconButton-root': { padding: '4px' }, // Reduce padding around the icon
                                '& .MuiSvgIcon-root': { fontSize: '13px' }, // Reduce the icon size
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {startDateErrorText && (
                        <FormHelperText id="input-error-text">{startDateErrorText}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl sx={{ minWidth: '100%' }} size="small" error={endDateErrorText}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="End Date"
                          value={endDate}
                          InputLabelProps={textFieldLabelStyles}
                          onChange={handleEndDateChange}
                          slotProps={{
                            textField: {
                              InputLabelProps: {
                                sx: {
                                  fontSize: '11px',
                                  top: '-50%', // Initially center the label vertically
                                },
                              },
                              sx: {
                                '& .MuiInputBase-root': {
                                  height: 25,
                                  minHeight: 25,
                                }, // Adjust height
                                '& .MuiInputBase-input': {
                                  fontSize: 11,
                                  padding: '2px',
                                }, // Reduce font size & padding
                                '& .MuiIconButton-root': { padding: '4px' }, // Reduce padding around the icon
                                '& .MuiSvgIcon-root': { fontSize: '13px' }, // Reduce the icon size
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                      {endDateErrorText && (
                        <FormHelperText id="input-error-text">{endDateErrorText}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={gridRowStyles}>
                {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                    <Box>
                      <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                        <InputLabel id="currency" style={inputLabelStyles}>
                          Currency
                        </InputLabel>
                        <Select labelId="currency" id="currency" value={currency} label="Currency" onChange={handleCurrencyChange}>
                          <MenuItem key="cad" value="cad">
                            Canadian Dollar (CAD)
                          </MenuItem>
                          <MenuItem key="usd" value="usd">
                            US Dollar (USD)
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid> */}
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Box>
                    <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                      <InputLabel
                        id="reportType"
                        style={inputLabelStyles}
                        sx={{
                          fontSize: '11px',
                        }}
                      >
                        Report Type
                      </InputLabel>
                      <Select
                        labelId="reportType"
                        id="reportType"
                        value={reportType}
                        label="Report Type"
                        onChange={(event) => setReportType(event.target.value)}
                        sx={{
                          '& .MuiInputBase-root': { height: 22, minHeight: 22 }, // Adjust height
                          '& .MuiInputBase-input': {
                            fontSize: 11,
                            padding: '2px',
                          }, // Reduce font size & padding
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: '200px', // Adjust the maximum height of the dropdown
                              minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                              '& .MuiMenuItem-root': {
                                fontSize: '10px', // Reduce font size inside the dropdown
                                marginLeft: '5px', // Adjust left margin
                                padding: '5px 15px', // Adjust the padding inside the items to control height
                              },
                            },
                          },
                        }}
                      >
                        {reportTypes.map((type) => (
                          <MenuItem key={type.key} value={type.key}>
                            {type.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Box>
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel
                        id="cards"
                        sx={{
                          fontSize: '11px',
                          top: '-4px',
                        }}
                      >
                        Cards
                      </InputLabel>
                      <Select
                        labelId="cards"
                        label="cards"
                        id="cards"
                        multiple
                        value={selectedCards}
                        onChange={handleCardSelectionChange}
                        renderValue={(selected) => (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} style={{ margin: 2 }} />
                            ))}
                          </div>
                        )}
                        sx={{
                          '& .MuiInputBase-root': { height: 18, minHeight: 18 }, // Adjust height
                          '& .MuiInputBase-input': {
                            fontSize: 11,
                            padding: '2px',
                          }, // Reduce font size & padding
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: '200px', // Adjust the maximum height of the dropdown
                              minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                              '& .MuiMenuItem-root': {
                                fontSize: '8px', // Reduce font size inside the dropdown
                                marginLeft: '5px', // Adjust left margin
                                padding: '5px 15px', // Adjust the padding inside the items to control height
                              },
                            },
                          },
                        }}
                      >
                        {cardsForClient.map((card) => (
                          <MenuItem key={card.cardNumber} value={card.cardNumber}>
                            <Checkbox checked={selectedCards.indexOf(card.cardNumber) > -1} />
                            <ListItemText primary={card.cardNumber} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
              <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                  <Button
                    style={{
                      marginTop: '-3px',
                      fontFamily: '  sans-serif',
                      fontSize: '10px',
                      marginLeft: '-50px',
                      backgroundColor: '#31478F',
                      fontWeight: 'bold',
                    }}
                    type="submit"
                    variant="contained"
                    disabled={uploading}
                    sx={{ borderRadius: '5px' }}
                  >
                    {uploading ? <CircularProgress size={24} color="inherit" /> : ' View Report'}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              </Grid>
            </form>
            {/* {loading && <Loader />} */}
          </CardContent>
          {/* </Card> */}
        </div>
      </div>
      {selectedCurrency === 'USD' && rows?.length > 0 && (
        <TableContainer component={Paper}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={3} sm={3} md={3} lg={3} container justifyContent="flex-end">
              <Button
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '10px',
                  backgroundColor: '#31478F',
                }}
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 5, mr: 2, borderRadius: '5px', ml: 'auto' }}
              >
                <CSVLink
                  style={{
                    textDecoration: 'none',
                    color: 'white',
                    borderRadius: '5px',
                  }}
                  filename={`${filePrefix}-${Math.floor(Date.now() / 1000)}.csv`}
                  data={exportTable(rows)}
                >
                  <Typography>Download CSV</Typography>
                </CSVLink>
              </Button>
            </Grid>
          </Grid>
          <Table aria-label="collapsible table">
            <TableHead className="main-table-header">
              <TableRow>
                <TableCell />
                <TableCell className="main-table-header-cell">Customer ID</TableCell>
                <TableCell className="main-table-header-cell">Transaction Date & Time</TableCell>
                <TableCell className="main-table-header-cell">Card Number</TableCell>
                <TableCell className="main-table-header-cell">Transaction Number</TableCell>
                <TableCell className="main-table-header-cell">Unit Number</TableCell>
                <TableCell className="main-table-header-cell">Truck Stop City</TableCell>
                <TableCell className="main-table-header-cell">Truck Stop State</TableCell>
                <TableCell className="main-table-header-cell">Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <Row key={row.customerId} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedCurrency === 'CAD' && rows?.length > 0 && (
        <TableContainer>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid container justifyContent="flex-end">
              <Button
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '8px',
                  backgroundColor: '#31478F',
                }}
                type="submit"
                variant="contained"
                sx={{ mt: 5, mb: 5, borderRadius: '5px' }}
              >
                <CSVLink
                  style={{ textDecoration: 'none', color: 'white' }}
                  filename={`${filePrefix}-${Math.floor(Date.now() / 1000)}.csv`}
                  data={exportCADReport(rows)}
                >
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontFamily: 'sans-serif',
                      fontWeight: 'bold',
                    }}
                  >
                    Download CSV
                  </Typography>
                </CSVLink>
              </Button>
            </Grid>
          </Grid>
          <Table aria-label="collapsible table">
            <TableHead
              // sx={{
              //   backgroundColor: " #31478F",
              //   height: "10px",
              //   color: "white",
              //   fontFamily: "sans-serif",
              // }}
              sx={{
                backgroundColor: '#31478F',
                '& .main-table-header-cell': {
                  color: 'white',
                  fontSize: '9px', // Further reduce font size
                  padding: '2px 6px', // Reduce padding
                  height: '20px', // Reduce row height
                  textAlign: 'left',
                },
                '& .MuiTableRow-root': {
                  height: '20px', // Reduce overall row height
                  textAlign: 'left',
                },
                '& .MuiTableCell-root': {
                  padding: '1px', // Reduce cell padding
                  fontSize: '9px', // Reduce font size
                  textAlign: 'left',
                },
              }}
            >
              <TableRow sx={{ height: '24px', fontSize: '9px' }}>
                <TableCell />
                {doNotPerformCustomerFiltration && (
                  <TableCell
                    sx={{
                      color: 'white',
                      fontSize: '9px',
                      fontFamily: ' sans-serif',
                      padding: '6px 8px',
                      fontWeight: 'bold',
                    }}
                  >
                    COMPANYXREF
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  TRANSACTION DATE
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  CARD NUMBER
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  DRIVER
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  LOCATION
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  CITY
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  PROVINCE
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '9px',
                    fontFamily: 'sans-serif',
                    padding: '6px 8px',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                  }}
                >
                  UNIT NUMBER
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                // backgroundColor: "#31478F",
                '& .main-table-row-cell': {
                  color: 'black',
                  fontSize: '9px', // Further reduce font size
                  padding: '2px', // Reduce padding
                  // margin: "2px",
                  height: '0px', // Reduce row height
                },
                '& .MuiTableCell-root': {
                  padding: '2px', // Reduce cell padding
                  margin: '1px',
                  fontSize: '6px', // Reduce font size
                },
              }}
            >
              {rows.map((row, index) => (
                <RowCAD
                  key={row.customerId}
                  row={row}
                  index={index}
                  rowSx={{
                    height: '16px', // Reduced row height
                    fontSize: '6px', // Reduced font size for rows
                    padding: '2px',
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedCurrency === 'USD' && rows?.length == 0 && fetchedWithNoRecords && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead
              sx={{
                backgroundColor: ' #31478F',
                height: '10px',
                color: 'white',
                fontFamily: '  sans-serif',
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell className="main-table-header-cell">Customer ID</TableCell>
                <TableCell className="main-table-header-cell">Transaction Date & Time</TableCell>
                <TableCell className="main-table-header-cell">Card Number</TableCell>
                <TableCell className="main-table-header-cell">Unit Number</TableCell>
                <TableCell className="main-table-header-cell">Truck Stop City</TableCell>
                <TableCell className="main-table-header-cell">Truck Stop State</TableCell>
                <TableCell className="main-table-header-cell">Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>No Transaction records found</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedCurrency === 'CAD' && rows?.length == 0 && fetchedWithNoRecords && (
        <TableContainer>
          <Table>
            <TableHead
              // sx={{
              //   backgroundColor: " #31478F",
              //   height: "10px",
              //   color: "white",
              //   fontFamily: "  sans-serif",
              // }}
              sx={{
                backgroundColor: '#31478F',
                '& .main-table-header-cell': {
                  color: 'white',
                  fontSize: '10px', // Further reduce font size
                  padding: '2px 6px', // Reduce padding
                  height: '20px', // Reduce row height
                  textAlign: 'left',
                },
                '& .MuiTableRow-root': {
                  height: '20px', // Reduce overall row height
                  textAlign: 'left',
                },
                '& .MuiTableCell-root': {
                  padding: '1px', // Reduce cell padding
                  fontSize: '7px', // Reduce font size
                  textAlign: 'left',
                },
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  COMPANYXREF
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  TRANSACTION DATE
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  CARD NUMBER
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  DRIVER
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  LOCATION
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: '  sans-serif',
                    padding: '6px',
                  }}
                >
                  CITY
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: 'sans-serif',
                    padding: '6px',
                  }}
                >
                  PROVINCE
                </TableCell>
                <TableCell
                  sx={{
                    color: 'white',
                    fontSize: '10px',
                    fontFamily: 'sans-serif',
                    padding: '6px',
                  }}
                >
                  UNIT NUMBER
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                // backgroundColor: "#31478F",
                '& .main-table-row-cell': {
                  color: 'black',
                  fontSize: '10px', // Further reduce font size
                  padding: '2px', // Reduce padding
                  // margin: "2px",
                  height: '0px', // Reduce row height
                },
                '& .MuiTableCell-root': {
                  padding: '2px', // Reduce cell padding
                  margin: '1px',
                  fontSize: '9px', // Reduce font size
                },
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>No Transaction records found</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* {filteredRecords.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell>Transaction Date & Time</TableCell>
                <TableCell>Card Number</TableCell>
                <TableCell>Unit Number</TableCell>
                <TableCell>Truck Stop City</TableCell>
                <TableCell>Truck Stop State</TableCell>
                <TableCell>Cost</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.map((row) => (
                <TableRow key={row.transactionNumber}>
                  <TableCell>{row.customerId}</TableCell>
                  <TableCell>{row.transactionDateTime}</TableCell>
                  <TableCell>{row.cardNumber}</TableCell>
                  <TableCell>{row.unitNumber}</TableCell>
                  <TableCell>{row.truckStopCity}</TableCell>
                  <TableCell>{row.truckStopState}</TableCell>
                  <TableCell>{row.totalCost}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )} */}
    </div>
  );
};

export default TransactionReport;
