import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  FaGasPump,
  FaArrowLeft,
  FaArrowRight,
  FaFileAlt,
  FaTachometerAlt,
  FaUsers,
  FaUserTie,
  FaPercent,
  FaTruck,
  FaCogs,
  FaMapMarkedAlt,
  FaClipboardList,
  FaFileInvoice,
  FaHandshake,
  FaUserCircle,
  FaSignOutAlt,
  FaBell,
  FaListAlt,
  FaUserPlus,
  FaUserEdit,
} from 'react-icons/fa';
import Logo from '../assets/ksg-logo.png';
import Snackbar from '@mui/material/Snackbar';

import Alert from '@mui/material/Alert';
import { backgroundImages } from '../../node_modules/polished/lib/index';

const Sidebar = ({ isSidebarVisible, toggleSidebar, handleOpenProfile }) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [isHovered, setIsHovered] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isNotifyClicked, setIsNotifyClicked] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const role = localStorage.getItem('role');

  const sidebarLinks = [
    {
      to: '/manage-dashboard',
      icon: <FaTachometerAlt />,
      label: 'Dashboard',
      roles: ['Super Admin'],
    },
    {
      to: '/fuel-card',
      icon: <FaGasPump />,
      label: 'Fuel Card',
      roles: ['Super Admin', 'Admin', 'User'],
    },
    {
      to: '/transaction-report',
      icon: <FaFileAlt />,
      label: 'Transaction Report',
      roles: ['Super Admin', 'Admin', 'User'],
    },
    {
      to: '/manage-customers',
      icon: <FaUsers />,
      label: 'Customers',
      roles: ['Super Admin'],
    },
    {
      to: '/manage-users',
      icon: <FaUserTie />,
      label: 'Users',
      roles: ['Super Admin', 'Admin', 'User'],
    },
    {
      to: '/manage-discount',
      icon: <FaPercent />,
      label: 'Discount',
      roles: ['Super Admin'],
    },
    {
      to: '/manage-profit-margin',
      icon: <FaCogs />,
      label: 'Profit Margin',
      roles: ['Super Admin'],
    },
    {
      to: '/manage-driver',
      icon: <FaTruck />,
      label: 'Driver',
      roles: ['Admin', 'User'],
    },
    {
      to: '/manage-unit-number',
      icon: <FaListAlt />,
      label: 'Unit Number',
      roles: ['Admin', 'User'],
    },
    {
      to: '/manage-location-pricing',
      icon: <FaMapMarkedAlt />,
      label: 'Location Pricing',
      roles: ['Super Admin', 'Admin', 'User'],
    },
    {
      to: '/manage-invoice',
      icon: <FaFileInvoice />,
      label: 'Invoice',
      roles: ['Super Admin'],
    },
    {
      to: '/onboard-client',
      icon: <FaUserEdit />,
      label: 'Onboard Client',
      roles: ['Super Admin'],
    },
    // {
    //   to: "/manage-brokers/list",
    //   icon: <FaHandshake />,
    //   label: "Broker",
    //   roles: ["Super Admin", "Broker"],
    // },
    // {
    //   to: "/manage-brokers/BrokerCommission",
    //   icon: <FaClipboardList />,
    //   label: "Broker Commission",
    //   roles: ["Super Admin"],
    // },
    // {
    //   to: "/manage-brokers/BrokerOnboard",
    //   icon: <FaUserPlus />,
    //   label: "Onboard Broker",
    //   roles: ["Super Admin"],
    // },
    // {
    //   to: "/manage-brokers/BrokerUsers",
    //   icon: <FaUserPlus />,
    //   label: "Broker User",
    //   roles: ["Super Admin", "Broker"],
    // },
  ];

  const handleNotifyClick = async () => {
    if (isFetching || openSnackbar) return;

    setIsFetching(true);
    try {
      const [transactionResponse, invoicesResponse, discrepanciesResponse] = await Promise.all([
        fetch(`${BACKEND_URL}/v1/latest-transaction`),
        fetch(`${BACKEND_URL}/v1/get-declined-invoices-only`),
        fetch(`${BACKEND_URL}/v1/discrepancies`),
      ]);

      if (!transactionResponse.ok || !invoicesResponse.ok || !discrepanciesResponse.ok) {
        throw new Error(
          `HTTP error! Status: ${
            !transactionResponse.ok
              ? 'Transaction'
              : !invoicesResponse.ok
              ? 'Invoices'
              : 'Discrepancies'
          } fetch failed`,
        );
      }

      const transactionDate = await transactionResponse.text();
      let declinedInvoices = await invoicesResponse.json();
      let discrepancies = await discrepanciesResponse.json();

      // Sort invoices by Last Updated date (newest first)
      if (declinedInvoices && declinedInvoices.length > 0) {
        declinedInvoices = declinedInvoices.sort((a, b) => {
          const dateA = a.updatedAt ? new Date(a.updatedAt) : new Date(a.createdAt);
          const dateB = b.updatedAt ? new Date(b.updatedAt) : new Date(b.createdAt);
          return dateB - dateA;
        });
      }

      // Sort discrepancies by difference (largest first)
      if (discrepancies && discrepancies.length > 0) {
        discrepancies = discrepancies.sort((a, b) => b.difference - a.difference);
      }

      let combinedMessage;

      if (declinedInvoices?.length > 0 || discrepancies?.length > 0) {
        combinedMessage = (
          <div>
            {transactionDate && transactionDate.trim() !== '' && (
              <div style={{ marginBottom: '16px' }}>
                <strong>Last Transaction:</strong> {transactionDate}
              </div>
            )}

            {discrepancies?.length > 0 && (
              <>
                <div style={{ fontWeight: 'bold', marginBottom: '8px', color: '#87C55F' }}>
                  Invoice Amount Mismatches ({discrepancies.length}):
                </div>
                <div style={{ maxHeight: '200px', overflowY: 'auto', marginBottom: '16px' }}>
                  {discrepancies.map((item, index) => (
                    <div
                      key={`disc-${index}`}
                      style={{
                        marginBottom: '8px',
                        padding: '8px',
                        borderBottom: '1px solid #eee',
                        backgroundColor: 'rgba(255,255,255,0.05)',
                        borderRadius: '4px',
                      }}
                    >
                      <div>
                        <strong>Customer:</strong> {item.customerName || 'N/A'}
                      </div>
                      <div>
                        <strong>Invoice ID:</strong> {item.invoiceId || 'N/A'}
                      </div>
                      <div>
                        <strong>Total Customer Amount:</strong> {item.totalCustAmt || 'N/A'}
                      </div>
                      <div>
                        <strong>Total Invoice Amount:</strong> {item.invoiceKSG || 'N/A'}
                      </div>
                      <div>
                        <strong>Difference:</strong> ${item.difference?.toFixed(2) || 'N/A'}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

            {declinedInvoices?.length > 0 && (
              <>
                <div style={{ fontWeight: 'bold', marginBottom: '8px', color: '#87C55F' }}>
                  Declined Invoices ({declinedInvoices.length}):
                </div>
                <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {declinedInvoices.map((invoice, index) => {
                    const updatedAt = invoice.updatedAt ? new Date(invoice.updatedAt) : null;
                    const createdAt = invoice.createdAt ? new Date(invoice.createdAt) : null;

                    return (
                      <div
                        key={`inv-${index}`}
                        style={{
                          marginBottom: '8px',
                          padding: '8px',
                          borderBottom: '1px solid #eee',
                          backgroundColor: 'rgba(255,255,255,0.05)',
                          borderRadius: '4px',
                        }}
                      >
                        <div>
                          <strong>Client ID:</strong> {invoice.clientId || 'N/A'}
                        </div>
                        <div>
                          <strong>Customer ID:</strong> {invoice.customerId || 'N/A'}
                        </div>
                        <div>
                          <strong>Created:</strong> {createdAt ? createdAt.toLocaleString() : 'N/A'}
                        </div>
                        <div>
                          <strong>Last Updated:</strong>{' '}
                          {updatedAt ? updatedAt.toLocaleString() : 'N/A'}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      } else if (transactionDate && transactionDate.trim() !== '') {
        combinedMessage = `Last Transaction: ${transactionDate}`;
      } else {
        combinedMessage = 'No recent activity found';
      }

      setSnackbarMessage(combinedMessage);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setSnackbarMessage('Error fetching notifications');
      setOpenSnackbar(true);
    } finally {
      setIsFetching(false);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
    console.log('Snackbar closed');
  };

  const handleLogout = () => {
    const isConfirmed = window.confirm('Are you sure you want to log out?');
    if (isConfirmed) {
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('userRole');
      navigate('/login');
    }
  };

  return (
    <div
      style={{
        width: isSidebarVisible ? '200px' : '50px',
        backgroundColor: '#31478F',
        color: '#fff',
        height: '100vh',
        position: 'fixed',
        top: '0',
        left: '0',
        transition: 'width 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'hidden',
        paddingTop: '10px',
        zIndex: 1000, // Ensure sidebar is on top
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        style={{
          position: 'absolute',
          top: '10px',
          right: isSidebarVisible ? '1px' : '8px',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '50%',
          color: 'black',
          width: '28px',
          height: '28px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          zIndex: 1100,
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        }}
        onClick={toggleSidebar}
      >
        {isSidebarVisible ? <FaArrowLeft size={14} /> : <FaArrowRight size={14} />}
      </button>

      <div
        style={{
          position: 'absolute',
          top: '20px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src={Logo}
          alt="Sidebar Logo"
          style={{
            width: isSidebarVisible ? '50%' : '25px',
            height: 'auto',
            transition: 'width 0.3s ease',
          }}
        />
      </div>

      <div
        style={{
          flexGrow: 1,
          width: '100%',
          overflowY: isHovered ? 'auto' : 'hidden',
          marginTop: '100px',
          marginBottom: '3px',
          maxHeight: 'calc(100vh - 150px)',
          backgroundColor: isHovered ? 'transparent' : 'transparent',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        <ul style={{ listStyleType: 'none', padding: '0', width: '100%' }}>
          {sidebarLinks
            .filter((link) => link.roles.includes(role))
            .map((item, index) => (
              <li key={index} style={{ marginBottom: '5px' }}>
                <Link
                  to={item.to}
                  style={{
                    color: location.pathname === item.to ? 'orange' : 'white',
                    textDecoration: 'none',
                    fontSize: '11px',
                    display: 'flex',
                    textTransform: 'uppercase',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontFamily: 'sans-serif',
                    padding: '10px',
                    borderRadius: '4px',
                    width: '90%',
                    justifyContent: isSidebarVisible ? 'flex-start' : 'center',
                  }}
                >
                  <span
                    style={{
                      fontSize: '12px',
                      marginRight: isSidebarVisible ? '8px' : '14px',
                      padding: '6px',
                      borderRadius: '4px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
                      backgroundColor: location.pathname === item.to ? 'white' : 'transparent',
                      color: location.pathname === item.to ? 'orange' : 'white',
                    }}
                  >
                    {item.icon}
                  </span>
                  {isSidebarVisible && <span>{item.label}</span>}
                </Link>
              </li>
            ))}
        </ul>
      </div>

      <div
        style={{
          position: 'absolute',
          bottom: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px',
          zIndex: 1200, // Ensure buttons are clickable
        }}
      >
        <Link
          to="/profile"
          style={{
            color: '#fff',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '10px',
          }}
          onClick={handleOpenProfile}
        >
          <FaUserCircle />
          {isSidebarVisible && <span style={{ marginLeft: '4px' }}>Profile</span>}
        </Link>
        <button
          style={{
            color: '#fff',
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            cursor: isFetching ? 'not-allowed' : 'pointer', // Change cursor based on state
            marginLeft: '8px',
            padding: '5px', // Increase clickable area
            zIndex: 1300, // Ensure Notify button is on top
            opacity: isFetching ? 0.5 : 1, // Visually indicate disabled state
          }}
          onClick={handleNotifyClick}
          disabled={isFetching} // Disable the button when fetching
        >
          <FaBell />
          {isSidebarVisible && <span style={{ marginLeft: '4px' }}>Notify</span>}
        </button>

        <button
          style={{
            color: '#fff',
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginLeft: '8px',
            cursor: 'pointer',
            padding: '5px',
          }}
          onClick={handleLogout}
        >
          <FaSignOutAlt />
          {isSidebarVisible && <span style={{ marginLeft: '8px' }}>Logout</span>}
        </button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Changed to 'right'
        sx={{
          marginRight: '20px', // Added some right margin
          mt: '-10px',
          backgroundColor: '#C6DEF1',
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={
            typeof snackbarMessage === 'string' && snackbarMessage.toLowerCase().includes('error')
              ? 'error'
              : 'success'
          }
          sx={{
            width: '100%',
            maxWidth: '400px', // Optional: Set a max width
            '& .MuiAlert-message': {
              width: '100%', // Ensures message takes full width
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Sidebar;
