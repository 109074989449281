import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
// import Loader from 'components/Loader';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin
import utc from 'dayjs/plugin/utc'; // Import utc plugin to handle UTC

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};
const ManageProfit = ({ isOnboard = false, onSuccess, client }) => {
  const [loading, setLoading] = React.useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [chosenAccount, setChosenAccount] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [profitMargin, setProfitMargin] = React.useState('');
  const [profitMarginHistoryListView, setProfitMarginHistoryListView] = React.useState([]);
  const [acknowledgementMessage, setAcknowledgementMessage] = React.useState('');
  const [isProfitMarginAcknowledged, setIsProfitMarginAcknowledged] = React.useState(false);
  const [percentageError, setPercentageError] = React.useState('');
  const [accountDetails, setAccountDetails] = React.useState([]);
  const [searchTerm] = React.useState('');
  const [effectiveDate, setEffectiveDate] = React.useState(null);

  const [effectiveDateErrorText, setEffectiveDateErrorText] = React.useState('');

  const handleAccountChange = (event) => {
    const selectedAccount = accountDetails.find(
      (account) => account.clientID === event.target.value,
    );
    if (selectedAccount) {
      const { clientID, customerID } = selectedAccount;
      setChosenAccount(clientID);
      setCustomerId(customerID);
      getProfitMarginByAccountId(clientID);
    }
  };

  const handleProfitMarginChange = (event) => {
    event.preventDefault();

    // Validate start and end dates
    if (!effectiveDate) {
      setEffectiveDateErrorText('Effective Date is required');
      return;
    }

    if (isOnboard) {
      const createProfitMarginObject = {
        profitMargin: profitMargin,
      };
      onSuccess(createProfitMarginObject);
    } else {
      const updateProfitMarginUrl = `${BACKEND_URL}/v1/send-approval-email-profitMargin?clientID=${chosenAccount}&customerID=${customerId}&profitMargin=${profitMargin}&effectiveDate=${effectiveDate}`;
      setLoading(true);
      fetch(updateProfitMarginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log(data);
          alert('Profit Margin Approval Email Sent Successfully');
          setChosenAccount('');
          setProfitMargin('');
          setEffectiveDate(null);
          setAcknowledgementMessage('');
          setIsProfitMarginAcknowledged(false);
          getProfitMarginByAccountId(chosenAccount);
        })
        .catch((error) => {
          console.error('Error updating profit margin:', error);
        })
        .finally(() => setLoading(false));
    }
  };

  // const getProfitMarginByAccountId = (accountId) => {
  //   const getProfitMarginUrl = `${BACKEND_URL}/v1/profit-margin?clientID=${accountId}`;
  //   setLoading(true);
  //   fetch(getProfitMarginUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setProfitMargin(data.profitMargin);
  //       setEffectiveDate(data.effectiveDate);
  //       convertProfitMarginHistory(data.profitMarginHistory2, data.effectiveDate);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching profit margin details:', error);
  //       setProfitMargin(0);
  //       setEffectiveDate(0);
  //       convertProfitMarginHistory({}, '');
  //     })
  //     .finally(() => setLoading(false));
  // };

  const getProfitMarginByAccountId = (accountId) => {
    const getProfitMarginUrl = `${BACKEND_URL}/v1/profit-margin?clientID=${accountId}`;
    setLoading(true); // Start the loading spinner

    // Make the GET request
    fetch(getProfitMarginUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          // Check if the expected properties are present in the response
          const { profitMargin, effectiveDate, profitMarginHistory, profitMarginHistory2 } = data;

          // If the required data is not present, return early and handle accordingly
          if (profitMargin === undefined || effectiveDate === undefined) {
            console.error('Missing profitMargin or effectiveDate in the response');
            return;
          }

          // Update the state with the fetched data
          setProfitMargin(profitMargin);
          // setEffectiveDate(effectiveDate);
          // setEffectiveDate(effectiveDate ? dayjs(effectiveDate) : null);  // Ensure dayjs conversion

          // Convert profitMarginHistory2 into a usable format (list)
          if (profitMarginHistory2 == null) {
            convertProfitMarginHistory(profitMarginHistory);
          } else {
            convertProfitMarginHistory(profitMarginHistory2);
          }
        } else {
          // If the response body is empty or invalid
          console.error('Invalid response data:', data);
          setProfitMargin(0);
          setEffectiveDate(null);
          convertProfitMarginHistory({}); // Empty history
        }
      })
      .catch((error) => {
        console.error('Error fetching profit margin details:', error);
        setProfitMargin(0);
        setEffectiveDate(null);
        convertProfitMarginHistory({}, ''); // Empty history
      })
      .finally(() => setLoading(false)); // Always stop loading after the request
  };

  const profitMarginHistoryListViewColumns = [
    // {
    //   field: 'id',
    //   headerName: 'Serial No.',
    //   flex: 1,
    //   align: 'left',
    //   headerAlign: 'left',
    //   headerClassName: 'super-app-theme--header'
    // },
    {
      field: 'date',
      headerName: 'Profit Margin Date',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'profitMargin',
      headerName: 'Profit Margin Applied',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
  ];

  // const convertProfitMarginHistory = (history = {}) => {
  //   if (!history || Object.keys(history).length === 0) {
  //     console.log("Profit Margin History is empty or invalid.");
  //     return; // Early return if the history is empty or undefined
  //   }

  //   const historyList = [];
  //   let historyId = 1;

  //   for (const [historyEntry, effDate] of Object.entries(history)) {
  //     try {
  //       const parsedEntry = JSON.parse(historyEntry);
  //       for (const [timestamp, profitValue] of Object.entries(parsedEntry)) {
  //         const date = new Date(timestamp);
  //         const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
  //         const readableDate = date.toLocaleString('en-US', options);
  //         historyList.push({
  //           id: historyId++,
  //           date: readableDate,
  //           profitMargin: profitValue,
  //           effectiveDate: effDate,
  //         });
  //       }
  //     } catch (error) {
  //       console.error("Error parsing history entry:", error);
  //     }
  //   }

  //   historyList.sort((a, b) => new Date(a.date) - new Date(b.date));
  //   setProfitMarginHistoryListView(historyList);
  // };

  const convertProfitMarginHistory = (history = {}) => {
    if (!history || Object.keys(history).length === 0) {
      console.log('Profit Margin History is empty or invalid.');
      return; // Early return if the history is empty or undefined
    }

    const historyList = [];
    let historyId = 1;

    for (const [historyEntry, effDate] of Object.entries(history)) {
      try {
        let parsedEntry;

        // Check if the entry is in the {timestamp=profitMargin} format or plain timestamp format
        if (historyEntry.startsWith('{') && historyEntry.endsWith('}')) {
          // Manually parse if it's in the {timestamp=profitMargin} format (profitMarginHistory2)
          parsedEntry = parseHistoryEntry(historyEntry);
        } else {
          // Handle plain timestamp format (profitMarginHistory)
          parsedEntry = {
            timestamp: historyEntry,
            profitMargin: effDate, // Assuming the value in historyEntry maps to profitMargin
          };
        }

        if (parsedEntry) {
          const { timestamp, profitMargin } = parsedEntry;
          const date = new Date(timestamp);

          // Format the date to a readable string
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
          };
          const readableDate = date.toLocaleString('en-US', options);
          // Set effectiveDate to null for profitMarginHistory (plain timestamp format)
          const effectiveDate =
            historyEntry.startsWith('{') && historyEntry.endsWith('}') ? effDate : null;

          setEffectiveDate(effectiveDate ? dayjs(effectiveDate) : null); // Ensure dayjs conversion

          // Push the formatted data to the history list
          historyList.push({
            id: historyId++,
            date: readableDate,
            profitMargin: profitMargin,
            effectiveDate: effectiveDate,
          });
        }
      } catch (error) {
        console.error('Error parsing history entry:', error);
      }
    }

    // Sort by date
    historyList.sort((a, b) => new Date(a.date) - new Date(b.date));
    setProfitMarginHistoryListView(historyList);
  };

  // Function to manually parse the history entry format
  const parseHistoryEntry = (historyEntry) => {
    // Assuming historyEntry is like {2025-02-13T12:17:56.127110100Z=0.07}
    const regex = /{([^=]+)=([^}]+)}/;
    const match = historyEntry.match(regex);

    if (match && match.length === 3) {
      return {
        timestamp: match[1], // Extract timestamp (2025-02-13T12:17:56.127110100Z)
        profitMargin: match[2], // Extract profit margin (0.07)
      };
    } else {
      console.error('Invalid history entry format:', historyEntry);
      return null;
    }
  };

  const acknowledgeProfitMargin = () => {
    setIsProfitMarginAcknowledged(!isProfitMarginAcknowledged);
  };

  const handlePercentageChange = (event) => {
    const value = event.target.value;
    const regex = /^[0-9].*$/;
    if (regex.test(value) && value >= 0 && value <= 100) {
      setProfitMargin(value);
      setPercentageError('');
      setTimeout(() => {
        setAcknowledgementMessage(
          <div style={{ marginLeft: '-250px', marginTop: '-83px' }}>
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                marginLeft: '100px', // Move this line slightly to the left
              }}
            >
              {`Are you sure about applying a discount of ${value}% on invoices`}
            </span>
            <br />
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                marginLeft: '100px', // Keep the second line at the original position
              }}
            >
              {`generated for client ${isOnboard ? client : chosenAccount}?`}
            </span>
          </div>,
        );
      }, 500);
    } else {
      setPercentageError('Enter a valid Profit Margin');
    }
  };

  useEffect(() => {
    if (isOnboard) {
      // Implement onboard-specific logic if needed
    } else {
      const getClientsUrl = `${BACKEND_URL}/v1/client`;

      fetch(getClientsUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          data.sort((a, b) => a.clientID - b.clientID);
          setAccountDetails(data);
        })
        .catch((error) => {
          console.error('Error fetching client details:', error);
        });
    }
  }, [isOnboard]);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .even': {
      backgroundColor: 'rgb(230, 238, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(230, 238, 242)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(216, 233, 242)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(216, 233, 242)', theme.palette.mode),
      },
    },
  }));

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const cardHeight = 300;
  const inputLabelStyles = {
    fontSize: '12px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600,
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '12px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600,
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  const filteredProfitMarginHistoryListView = profitMarginHistoryListView
    .filter(
      (entry) =>
        entry.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.profitMargin.toString().includes(searchTerm),
    )
    .sort((a, b) => {
      if (a.date.toLowerCase().includes(searchTerm.toLowerCase())) return -1;
      if (b.date.toLowerCase().includes(searchTerm.toLowerCase())) return 1;
      return 0;
    });

  // const handleEffectiveDateChange = (date) => {
  //   console.log('Effective Date', date);
  //   setEffectiveDate(date);
  //   setEffectiveDateErrorText('');
  // };

  // Extend dayjs with plugins
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const handleEffectiveDateChange = (date) => {
    console.log('Original Effective Date:', date);

    // Convert the selected date to CST (Central Standard Time)
    const dateInCST = dayjs(date).tz('America/Chicago', true); // CST timezone conversion

    console.log('Effective Date in CST:', dateInCST.format()); // Log the date in CST

    // Set the state with the CST formatted date
    setEffectiveDate(dateInCST);

    // Reset error text
    setEffectiveDateErrorText('');
  };

  return (
    <div style={{ marginLeft: '10px', marginTop: '40px' }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: '14px',
          marginLeft: '20px',
          fontFamily: 'sans-serif',
          fontWeight: 'bold',
          marginTop: '-35px',
          textTransform: 'uppercase',
        }}
        gutterBottom
      >
        Manage Profit Margin
      </Typography>
      {/* {loading && <Loader />} */}
      <div className="container" style={{ display: 'flex' }}>
        <div className="card" style={{ flex: '1' }}>
          {/* <Card sx={{ minWidth: '100%', minHeight: cardHeight }}> */}
          <CardContent>
            <form id="check-available-inspectors" onSubmit={handleProfitMarginChange}>
              <div>
                {isOnboard && (
                  <Typography variant="h5" sx={{ m: 1 }}>
                    Account: {client}
                  </Typography>
                )}
              </div>
              {!isOnboard && (
                <Grid container spacing={2}>
                  <Grid item xs={5} sm={5} md={5} lg={4.5} style={gridRowStyles}>
                    <Box>
                      <FormControl
                        sx={{ minWidth: '310px', width: '310px', minHeight: 2 }}
                        size="small"
                      >
                        <InputLabel id="chosenAccount" sx={{ fontSize: '10px' }}>
                          Account
                        </InputLabel>
                        <Select
                          labelId="chosenAccount"
                          id="chosenAccount"
                          value={chosenAccount}
                          label="Account"
                          onChange={handleAccountChange}
                          required
                          InputLabelProps={{
                            sx: {
                              fontSize: '11px',
                              top: '-50%', // Initially center the label vertically
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22,
                            }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 11,
                              padding: '2px',
                            }, // Reduce font size & padding
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxHeight: '200px', // Adjust the maximum height of the dropdown
                                minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                                '& .MuiMenuItem-root': {
                                  fontSize: '10px', // Reduce font size inside the dropdown
                                  marginLeft: '5px', // Adjust left margin
                                  padding: '4px 10px', // Adjust the padding inside the items to control height
                                },
                              },
                            },
                          }}
                        >
                          {accountDetails.map((account) => (
                            <MenuItem key={account.clientID} value={account.clientID}>
                              {account.clientID} - {account.customerID} - {account.clientName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={5} style={gridRowStyles}>
                <Grid item xs={6} sm={6} md={6} lg={4}>
                  <Box
                    sx={{
                      width: '100%',
                      marginLeft: '330px',
                      marginTop: '-30px',
                    }}
                  >
                    <FormControl
                      sx={{ minWidth: '90%' }}
                      size="small"
                      error={Boolean(percentageError)}
                    >
                      <TextField
                        style={inputLabelStyles}
                        id="profitMargin"
                        label={profitMargin ? 'Profit Margin ($)' : 'Profit margin'}
                        value={profitMargin}
                        onChange={handlePercentageChange}
                        // InputLabelProps={textFieldLabelStyles}
                        required
                        size="small"
                        InputLabelProps={{
                          sx: { fontSize: '11px', top: '-10%' }, // Reduce placeholder size
                        }}
                        sx={{
                          '& .MuiInputBase-root': { height: 24 }, // Adjust height
                          '& .MuiInputBase-input': {
                            fontSize: 11,
                            padding: '4px',
                          }, // Reduce font size & padding
                        }}
                      />
                      {percentageError && (
                        <FormHelperText id="input-error-text">{percentageError}</FormHelperText>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={5}
                    sm={5}
                    md={4}
                    mt={-4}
                    ml={6}
                    lg={6}
                    sx={{ display: 'flex', marginLeft: '39px' }}
                  >
                    <Box>
                      <FormControl
                        sx={{ minWidth: '100%' }}
                        size="small"
                        error={effectiveDateErrorText}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            placeholder="Effective Date" // Use placeholder instead of label
                            label="Effective Date"
                            value={effectiveDate}
                            size="small"
                            slotProps={{
                              textField: {
                                InputLabelProps: {
                                  sx: {
                                    fontSize: '11px',
                                    top: '-9px',
                                  },
                                },
                                sx: {
                                  '& .MuiInputBase-root': {
                                    height: 25, // Increased height for better centering
                                    minHeight: 25,
                                    width: '310px',
                                    paddingRight: '28px', // Space for the icon
                                    display: 'flex',
                                    alignItems: 'center', // Ensures vertical centering
                                  },
                                  '& .MuiInputBase-input': {
                                    fontSize: 11,
                                    textAlign: 'left', // Centers text horizontally
                                    padding: '8px 0px', // Centers text vertically
                                  },
                                  '& .MuiSvgIcon-root': {
                                    fontSize: 12, // Reduce icon size
                                    right: '8px', // Position the icon
                                  },
                                },
                              },
                            }}
                            onChange={handleEffectiveDateChange}
                            shouldDisableDate={(date) => {
                              const day = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                              const today = dayjs().startOf('day'); // Get today without time
                              return !(day === 1 || day === 4) || date.isBefore(today); // Allow only Monday (1) & Thursday (4) and disable past dates
                            }}
                          />
                        </LocalizationProvider>

                        {effectiveDateErrorText && (
                          <FormHelperText id="input-error-text">
                            {effectiveDateErrorText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                <Grid item xs={5} sm={5} md={4} lg={4.5} mt={2} sx={{ display: 'flex' }}>
                  {acknowledgementMessage && (
                    <Box sx={{ width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={acknowledgeProfitMargin}
                            sx={{
                              position: 'relative',
                              left: '-150px', // Adjust this value to move left/right
                              top: '-67px', // Adjust this value to move up/down
                            }}
                          />
                        }
                        label={acknowledgementMessage}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontFamily: 'DM Sans, sans-serif',
                          },
                        }}
                      />
                    </Box>
                  )}
                  <Button
                    style={{
                      fontFamily: 'sans-serif',
                      fontSize: '9px',
                      backgroundColor: '#31478F',
                      color: 'white',
                      position: 'absolute',
                      padding: '0px 6px', // Slightly increase padding for better text display
                      height: '30px', // Slightly increase height for better visibility
                      whiteSpace: 'nowrap', // Prevents text wrapping
                      marginTop: '-15px',
                      marginLeft: '-60px',
                      minWidth: 'unset',
                    }}
                    type="submit"
                    variant="contained"
                    disabled={!isProfitMarginAcknowledged}
                    sx={{ mt: 0, mb: 0, borderRadius: '5px' }}
                  >
                    {isOnboard ? 'Set Profit Margin' : 'Apply Profit Margin'}
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
              </Grid>
            </form>

            {filteredProfitMarginHistoryListView.length > 0 && (
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                    <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                      {/* <Card> */}
                      <CardContent>
                        <Grid container alignItems="center" justifyContent="space-between">
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: '14px',
                              fontFamily: 'sans-serif',
                              marginTop: '-110px',
                              marginLeft: '-10px',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                            }}
                          >
                            Profit Margin History
                          </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={4} lg={4.5} sx={{ marginBottom: '5px' }}>
                          <Box>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              placeholder="Search Profit Margin History..."
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon sx={{ fontSize: 14 }} />
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                marginTop: '-70px',
                                marginLeft: '585px',
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '12px',
                                  '& fieldset': {
                                    borderColor: '#31478F',
                                    borderWidth: '2px',
                                    borderStyle: 'solid',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#31478F',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#31478F',
                                  },
                                },
                                '& .MuiInputBase-root': {
                                  height: 24,
                                  minHeight: 24,
                                },
                                '& .MuiInputBase-input': {
                                  fontSize: 12,
                                  padding: '8px',
                                },
                              }}
                            />
                          </Box>
                        </Grid>
                        <StyledDataGrid
                          rows={filteredProfitMarginHistoryListView}
                          columns={profitMarginHistoryListViewColumns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          rowHeight={25}
                          columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                          sx={{
                            marginTop: '-65px',
                            marginLeft: '-15px',
                            '& .MuiDataGrid-columnHeaders': {
                              fontSize: '10px', // Reduce font size of header text
                              backgroundColor: '#31478F', // Set custom header background color
                              color: 'white', // Set font color to white for better contrast
                            },
                            '& .MuiDataGrid-cell': {
                              fontSize: '10px', // Reduced font size for table cells
                            },

                            '& .even': {
                              backgroundColor: '#F8F8F8', // Light blue color for even rows
                            },
                            '& .odd': {
                              backgroundColor: '#E6F0FF', // Darker blue for odd rows
                            },
                          }}
                          slots={{ toolbar: CustomToolbar }}
                          getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                          }
                        />
                      </CardContent>
                      {/* </Card> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </CardContent>
          {/* </Card> */}
        </div>
      </div>
    </div>
  );
};

export default ManageProfit;
