import React from 'react';
import { lazy } from 'react';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import Login from 'pages/login/Login';
import OnboardClient from 'pages/onboard-client/index';
import ClientDetailedView from 'pages/manage-clients/detailed-view';
import NewLogin from 'pages/login/index';
import Invoice from 'pages/manage-invoice/invoice';
import CreateBroker from 'pages/manage-brokers/CreateBroker';
import BrokerList from 'pages/manage-brokers/BrokerList';
import LocationPricing from 'pages/manage-location-pricing/location-pricing';
import Dashboard from 'pages/manage-dashboard/index';

const FuelCardListView = Loadable(lazy(() => import('pages/fuel-card/list-view')));
const ManageDiscount = Loadable(lazy(() => import('pages/manage-discount')));
const ManageUsers = Loadable(lazy(() => import('pages/manage-users')));
const ManageClients = Loadable(lazy(() => import('pages/manage-clients')));
const ManageTransaction = Loadable(lazy(() => import('pages/transaction-report/manage-transaction')));
const ErrorPage = Loadable(lazy(() => import('pages/extra-pages/Error')));
const TransactionReport = Loadable(lazy(() => import('pages/transaction-report')));
const ManageCards = Loadable(lazy(() => import('pages/manage-cards')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Login />
    },
    {
      path: '/new-login',
      element: <NewLogin />
    },
    {
      path: 'fuel-card',
      element: <FuelCardListView />
    },
    {
      path: 'transaction-report',
      element: <TransactionReport />
    },
    {
      path: 'manage-discount',
      element: <ManageDiscount />
    },
    {
      path: 'manage-users',
      element: <ManageUsers />
    },
    {
      path: 'manage-cards',
      element: <ManageCards />
    },
    {
      path: 'manage-customers',
      element: <ManageClients />
    },
    {
      path: 'manage-transaction',
      element: <ManageTransaction />
    },
    {
      path: 'onboard-client',
      element: <OnboardClient />
    },
    {
      path: 'manage-invoice',
      element: <Invoice />
    },
    {
      path: 'customers/:clientId',
      element: <ClientDetailedView />
    },
    // Use a nested route or different paths for CreateBroker and BrokerList
    {
      path: 'manage-brokers',
      element: <CreateBroker />
    },
    {
      path: 'manage-brokers/list',
      element: <BrokerList />
    },
    {
      path: 'manage-location-pricing',
      element: <LocationPricing />
    },
    {
      path: 'manage-dashboard',
      element: <Dashboard />
    },
    {
      path: '*',
      element: <ErrorPage />
    }
  ]
};

export default MainRoutes;
