import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Alert,
  AlertTitle,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download'; // Import DownloadIcon
import { darken, lighten, styled } from '@mui/material/styles';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const DriverForm = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // State management
  const [driversList, setDriversList] = useState([]);
  const [unitNumbers, setUnitNumbers] = useState([]);
  const [cardsForClient, setCardsForClient] = useState([]);
  const [clients, setClients] = useState([]);
  const [licenseFiles, setLicenseFiles] = useState([]);
  const [isAddDriverSelected, setIsAddDriverSelected] = useState(false);
  const [isUpdateDriverSelected, setIsUpdateDriverSelected] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [licenseState, setLicenseState] = useState(''); // Default to empty string

  // Form states
  const [driverId, setDriverId] = useState('');
  const [driverName, setDriverName] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [licenseNumberError, setLicenseNumberError] = useState('');
  const [primaryPhone, setPrimaryPhone] = useState('+1');
  const [secondaryPhone, setSecondaryPhone] = useState('+1');
  const [unitNumber, setUnitNumber] = useState('');
  const [driverStatus, setDriverStatus] = useState('Inactive');
  const [assignedCard, setAssignedCard] = useState('');
  const [licenseFile, setLicenseFile] = useState(null);

  // Role-based filtration
  const loggedInUserClientId = localStorage.getItem('clientId');
  const loggedInUserName = localStorage.getItem('userName');
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';

  const driverStatuses = [
    { id: 'Active', value: 'Active' },
    { id: 'Inactive', value: 'Inactive' },
  ];

  const licenseStates = [
    { id: 'ON', value: 'ON' },
    { id: 'QC', value: 'QC' },
    { id: 'NB', value: 'NB' },
    { id: 'NS', value: 'NS' },
    { id: 'NFL', value: 'NFL' },
    { id: 'MB', value: 'MB' },
    { id: 'SK', value: 'SK' },
    { id: 'AB', value: 'AB' },
    { id: 'BC', value: 'BC' },
    { id: 'AK', value: 'AK' },
    { id: 'YK', value: 'YK' },
    { id: 'NT', value: 'NT' },
    { id: 'NU', value: 'NU' },
  ];

  useEffect(() => {
    console.log('Starting initial data fetch...');
    Promise.all([getClients(), fetchDrivers(), fetchUnits(), fetchCards(), fetchLicenseFiles()])
      .then(([clientsData, driversData, unitsData, cardsData, licenseFilesData]) => {
        console.log('All data fetched successfully');
        generateListViewDetails(driversData, licenseFilesData);
        setUnitNumbers(unitsData.map((unit) => unit.unitNumber));
      })
      .catch((error) => {
        console.error('Error during initial fetch:', error);
        setErrorMessage('Failed to load data: ' + error.message);
        setIsError(true);
      });
  }, []);

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }
    const response = await axios.get(getClientsUrl);
    const data = response.data.sort((a, b) => a.clientID - b.clientID);
    console.log('Clients fetched:', data);
    setClients(data);
    return data;
  };

  const fetchCards = async () => {
    const response = await axios.get(`${BACKEND_URL}/v1/card/all`);
    const inactiveCards = response.data.filter((card) => card.status === 'INACTIVE');
    console.log('Cards fetched:', inactiveCards);
    setCardsForClient(inactiveCards);
    return inactiveCards;
  };

  const fetchUnits = async () => {
    const response = await axios.get(`${BACKEND_URL}/v1/unit`);
    console.log('Units fetched:', response.data);
    return response.data;
  };

  const fetchDrivers = async () => {
    const response = await axios.get(`${BACKEND_URL}/v1/drivers`);
    console.log('Raw drivers fetched:', response.data);
    return response.data; // Return data directly, set in generateListViewDetails
  };

  const fetchLicenseFiles = async () => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/get-all-driver-license-files`);
      console.log('License files fetched:', response.data);
      setLicenseFiles(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching license files:', error);
      return [];
    }
  };

  const generateListViewDetails = (drivers, licenseFilesData = []) => {
    console.log(
      'Generating list view with drivers:',
      drivers,
      'and license files:',
      licenseFilesData,
    );
    if (!drivers || drivers.length === 0) {
      console.log('No drivers to display');
      setDriversList([]);
      return;
    }

    const driverListViewDetails = drivers.map((driver) => {
      const licenseFileMatch =
        licenseFilesData.find((file) => file.fileName === driver.licenseFileName) || {};
      return {
        id: driver.driverId,
        driverId: driver.driverId || 'N/A',
        driverName: driver.driverName || '',
        licenseState: driver.licenseState || '',
        licenseNumber: driver.licenseNumber || '',
        primaryPhone: driver.primaryPhone || '',
        secondaryPhone: driver.secondaryPhone || '',
        unitNumber: driver.unitNumber || '',
        driverStatus: driver.driverStatus ? 'ACTIVE' : 'INACTIVE',
        assignedCard: driver.assignedCard || '',
        licenseFileName: licenseFileMatch.fileName || driver.licenseFileName || '', // For download
        licenseFileUrl: licenseFileMatch.fileUrl || driver.licenseDocumentUrl || '',
      };
    });
    console.log('Final drivers list for table:', driverListViewDetails);
    setDriversList(driverListViewDetails);
  };

  const checkLicenseNumberExistence = async (licenseNum) => {
    try {
      const response = await axios.get(`${BACKEND_URL}/v1/driver/license/${licenseNum}`);
      if (response.status === 200 && response.data) {
        setLicenseNumberError('License Number already exists');
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setLicenseNumberError('');
      } else {
        // setLicenseNumberError("Error checking license number");
      }
    }
  };

  const handleLicenseNumberChange = (event) => {
    const value = event.target.value;
    setLicenseNumber(value);
    setLicenseNumberError('');
    checkLicenseNumberExistence(value);
  };

  const resetFields = () => {
    setDriverId('');
    setDriverName('');
    setLicenseNumber('');
    setLicenseNumberError('');
    setPrimaryPhone('+1');
    setSecondaryPhone('+1');
    setUnitNumber('');
    setDriverStatus('Inactive');
    setAssignedCard('');
    setLicenseFile(null);
    setLicenseState('');
  };

  const handleCreateDriver = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessage(Object.values(errors).join(', '));
      setIsError(true);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('driverId', driverId);
      formData.append('driverName', driverName);
      formData.append('licenseNumber', licenseNumber);
      formData.append('primaryPhone', primaryPhone);
      formData.append('secondaryPhone', secondaryPhone);
      formData.append('unitNumber', unitNumber);
      formData.append('driverStatus', driverStatus === 'Active');
      formData.append('assignedCard', assignedCard);
      formData.append('customerId', loggedInUserClientId);
      formData.append('licenseState', licenseState);

      if (licenseFile) {
        console.log('Sending licenseFileName:', licenseFile.name);
        formData.append('licenseDocument', licenseFile);
        formData.append('licenseFileName', licenseFile.name);
      } else {
        formData.append('licenseFileName', '');
      }

      await axios.post(`${BACKEND_URL}/v1/driver`, formData);
      const [driversData, licenseFilesData] = await Promise.all([
        fetchDrivers(),
        fetchLicenseFiles(),
      ]);
      generateListViewDetails(driversData, licenseFilesData);
      setSuccessMessage('Driver created successfully!');
      setIsSuccess(true);
      setIsError(false);
      resetFields();
      // Reload the page after successful creation
      window.location.reload();
    } catch (error) {
      setErrorMessage(
        'Failed to create driver: ' + (error.response?.data?.message || error.message),
      );
      setIsError(true);
      setIsSuccess(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 100 * 1024 * 1024) {
      // 100 MB in bytes
      setLicenseFile(file);
    } else {
      setErrorMessage('File size exceeds 100 MB limit');
      setIsError(true);
    }
  };

  const handleUpdateDriver = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrorMessage(Object.values(errors).join(', '));
      setIsError(true);
      return;
    }

    try {
      const formData = new FormData();

      // Create a Driver object and send it as a JSON string in the "driver" part
      const driverData = {
        driverId: driverId,
        driverName: driverName,
        licenseNumber: licenseNumber,
        primaryPhone: primaryPhone,
        secondaryPhone: secondaryPhone,
        unitNumber: unitNumber,
        driverStatus: driverStatus === 'Active', // Boolean true/false
        assignedCard: assignedCard,
        customerId: loggedInUserClientId,
        licenseState: licenseState,
      };
      formData.append(
        'driver',
        new Blob([JSON.stringify(driverData)], { type: 'application/json' }),
      );

      // Add license file if present
      if (licenseFile) {
        console.log('Sending licenseFileName:', licenseFile.name);
        formData.append('licenseFile', licenseFile); // Matches @RequestPart("licenseFile")
      }

      const response = await axios.put(`${BACKEND_URL}/v1/driver/${driverId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      console.log('Update driver response:', response.data);

      const [driversData, licenseFilesData] = await Promise.all([
        fetchDrivers(),
        fetchLicenseFiles(),
      ]);
      generateListViewDetails(driversData, licenseFilesData);
      setSuccessMessage('Driver updated successfully!');
      setIsSuccess(true);
      setIsError(false);
      resetFields();
      // Reload the page after successful creation
      window.location.reload();
    } catch (error) {
      console.error('Error updating driver:', error.response?.data || error.message);
      setErrorMessage(
        'Failed to update driver: ' + (error.response?.data?.message || error.message),
      );
      setIsError(true);
      setIsSuccess(false);
    }
  };

  const handleUpdateDriverSelectionChange = (event) => {
    const selectedDriverId = event.target.value;
    setDriverId(selectedDriverId);
    const driver = driversList.find((d) => d.driverId === selectedDriverId);
    if (driver) {
      setDriverName(driver.driverName);
      setLicenseNumber(driver.licenseNumber);
      setPrimaryPhone(driver.primaryPhone || '+1');
      setSecondaryPhone(driver.secondaryPhone || '+1');
      setUnitNumber(driver.unitNumber);
      setDriverStatus(driver.driverStatus === 'ACTIVE' ? 'Active' : 'Inactive');
      setAssignedCard(driver.assignedCard);
      setLicenseState(driver.licenseState || '');
    }
  };

  const handleDelete = async (driverId) => {
    const confirmation = window.confirm(`Are you sure you want to delete driver: ${driverId}?`);
    if (confirmation) {
      try {
        const response = await axios.post(
          `${BACKEND_URL}/v1/send-driver-deletion-approval-email?driverId=${driverId}`,
        );
        setSuccessMessage(response.data.message || 'Approval email sent successfully!');
        setIsSuccess(true);
        setIsError(false);
        // Reload the page after successful creation
        window.location.reload();
      } catch (error) {
        setErrorMessage('Failed to send approval email: ' + error.message);
        setIsError(true);
        setIsSuccess(false);
      }
    }
  };

  const handleDownload = async (fileName) => {
    if (!fileName) {
      console.error('No file name provided for download');
      setErrorMessage('No license file available for download');
      setIsError(true);
      return;
    }

    try {
      const response = await axios.get(
        `${BACKEND_URL}/v1/get-driver-license-file?fileName=${fileName}`,
        {
          responseType: 'blob', // Important: Expect a binary response
        },
      );

      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Use the original file name
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log(`Successfully triggered download for: ${fileName}`);
    } catch (error) {
      console.error('Error downloading file:', error);
      setErrorMessage(
        'Failed to download license file: ' + (error.response?.data?.message || error.message),
      );
      setIsError(true);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!driverId) errors.driverId = 'Driver ID is required';
    if (!driverName) errors.driverName = 'Driver Name is required';
    if (!licenseNumber) errors.licenseNumber = 'License Number is required';
    if (!licenseState) errors.licenseState = 'License State is required';
    if (!primaryPhone) errors.primaryPhone = 'Primary Phone is required';
    return errors;
  };

  // const getBackgroundColor = (color, mode) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode),
      },
    },
  }));

  // const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '10px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };

  const columns = [
    {
      field: 'driverId',
      headerName: 'DRIVER ID',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'driverName',
      headerName: 'DRIVER NAME',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'licenseNumber',
      headerName: 'LICENSE #',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'licenseState',
      headerName: 'LICENSE STATE',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'primaryPhone',
      headerName: 'PRIMARY PH #',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'secondaryPhone',
      headerName: 'SECONDARY PH #',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'unitNumber',
      headerName: 'UNIT #',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'driverStatus',
      headerName: 'STATUS',
      width: 100,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'assignedCard',
      headerName: 'ASSIGNED CARD #',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      flex: 1,
    },
    {
      field: 'download',
      headerName: 'LICENSE DOC',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params) =>
        params.row.licenseFileName ? (
          <DownloadIcon
            style={{ cursor: 'pointer', fontSize: '14px' }}
            onClick={() => handleDownload(params.row.licenseFileName)}
            color="primary"
          />
        ) : (
          'N/A'
        ),
    },
    // {
    //   field: "actions",
    //   headerName: "ACTIONS",
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   headerClassName: "super-app-theme--header",
    //   flex: 1,
    //   renderCell: (params) => (
    //     <DeleteIcon
    //       style={{ cursor: "pointer" }}
    //       onClick={() => handleDelete(params.row.driverId)}
    //       color="error"
    //     />
    //   ),
    // },
  ];

  const buttonsFontSize = '16px';
  const defaultButtonBackground = '#011f4b';
  const chosenButtonBackground = '#03396c';
  const inputLabelStyles = {
    fontSize: '10px',
    color: 'rgb(26, 33, 56)',
  };

  return (
    <Container maxWidth="lg" style={{ paddingTop: '20px' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          fontFamily: 'sans-serif',
          fontSize: '14px',
          textTransform: 'uppercase',
          marginTop: '-8px',
        }}
      >
        Manage Drivers
      </Typography>

      {isSuccess && (
        <Alert
          severity="success"
          variant="filled"
          onClose={() => setIsSuccess(false)}
          sx={{ mb: 2, fontSize: '16px', textAlign: 'center' }}
        >
          <AlertTitle>Success</AlertTitle>
          {successMessage}
        </Alert>
      )}
      {isError && (
        <Alert
          severity="error"
          variant="filled"
          onClose={() => setIsError(false)}
          sx={{ mb: 2, fontSize: '16px', textAlign: 'center' }}
        >
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={6}>
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Button
            style={{
              backgroundColor: '#31478F',
            }}
            variant="contained"
            sx={{
              mr: 2,
              mt: -5,
              ml: 26,
              borderRadius: '5px',
              padding: '6px 10px', // Adjust padding to make the button smaller
              whiteSpace: 'nowrap',
            }}
            onClick={async () => {
              setIsAddDriverSelected(true);
              setIsUpdateDriverSelected(false);
              resetFields();
              const response = await axios.get(`${BACKEND_URL}/v1/next/driver-id`);
              setDriverId(response.data);
            }}
          >
            <Typography sx={{ fontSize: '10px' }}>New Driver</Typography>
          </Button>
          <Button
            style={{
              backgroundColor: '#31478F',
            }}
            variant="contained"
            sx={{
              mr: 2,
              ml: -28,
              mt: -5,
              borderRadius: '5px',
              padding: '5px 10px', // Adjust padding to make the button smaller
            }}
            onClick={() => {
              setIsAddDriverSelected(false);
              setIsUpdateDriverSelected(true);
              resetFields();
            }}
          >
            <Typography sx={{ fontSize: '10px' }}>Update Driver</Typography>
          </Button>
        </Grid>
      </Grid>

      {isAddDriverSelected && (
        <form onSubmit={handleCreateDriver}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Customer Account"
                fullWidth
                value={`${loggedInUserClientId}-${loggedInUserName}`}
                InputProps={{ readOnly: true }}
                // InputLabelProps={{ style: inputLabelStyles }}
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Driver ID"
                fullWidth
                value={driverId}
                onChange={(e) => setDriverId(e.target.value)}
                InputProps={{ readOnly: true }}
                required
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Driver Name"
                fullWidth
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" error={!!licenseNumberError}>
                <TextField
                  label="License #"
                  value={licenseNumber}
                  onChange={handleLicenseNumberChange}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 25 }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '4px',
                    }, // Reduce font size & padding
                  }}
                  required
                  error={!!licenseNumberError}
                />
                {licenseNumberError && <FormHelperText>{licenseNumberError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>License State</InputLabel>
                <Select
                  label="License State"
                  value={licenseState}
                  onChange={(e) => setLicenseState(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a state</em>
                  </MenuItem>
                  {licenseStates.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Primary Phone"
                fullWidth
                value={primaryPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure it starts with '+1' and only allows digits after that
                  if (value.startsWith('+1')) {
                    const digits = value.slice(2).replace(/\D/g, ''); // Remove non-digits after '+1'
                    if (digits.length <= 10) {
                      setPrimaryPhone('+1' + digits);
                    }
                  } else {
                    setPrimaryPhone('+1'); // Reset to '+1' if invalid
                  }
                }}
                // InputLabelProps={{ style: inputLabelStyles }}
                inputProps={{
                  maxLength: 12, // '+1' (2 chars) + 10 digits = 12 chars total
                  pattern: '[+][1][0-9]{0,10}', // Optional: HTML5 pattern for validation
                }}
                required
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Secondary Phone"
                fullWidth
                value={secondaryPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure it starts with '+1' and only allows digits after that
                  if (value.startsWith('+1')) {
                    const digits = value.slice(2).replace(/\D/g, ''); // Remove non-digits after '+1'
                    if (digits.length <= 10) {
                      setSecondaryPhone('+1' + digits);
                    }
                  } else {
                    setSecondaryPhone('+1'); // Reset to '+1' if invalid
                  }
                }}
                // InputLabelProps={{ style: inputLabelStyles }}
                inputProps={{
                  maxLength: 12, // '+1' (2 chars) + 10 digits = 12 chars total
                  pattern: '[+][1][0-9]{0,10}', // Optional: HTML5 pattern for validation
                }}
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Unit #</InputLabel>
                <Select
                  label="Unit #"
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a unit</em>
                  </MenuItem>
                  {unitNumbers.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Status</InputLabel>
                <Select
                  label="Status"
                  value={driverStatus}
                  onChange={(e) => setDriverStatus(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      // top: "-50%", // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  {driverStatuses.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Assigned Card #</InputLabel>
                <Select
                  label="Assigned Card #"
                  value={assignedCard}
                  onChange={(e) => setAssignedCard(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a card</em>
                  </MenuItem>
                  {cardsForClient.map((card) => (
                    <MenuItem key={card.cardNumber} value={card.cardNumber}>
                      {card.cardNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  onChange={handleFileChange}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                />
                {licenseFile && <Typography variant="body2">{licenseFile.name}</Typography>}
              </Box>
            </Grid>
          </Grid>
          <Box mt={2} textAlign="center">
            <Button
              style={{
                backgroundColor: '#31478F',
                color: 'white',
                height: '30px',
                fontSize: '10px',
              }}
              variant="contained"
              sx={{
                mr: 2,
                borderRadius: '5px',
                padding: '5px 10px', // Adjust padding to make the button smaller
                color: 'white',
              }}
              // variant="contained"
              type="submit"
            >
              Create
            </Button>
          </Box>
        </form>
      )}

      {isUpdateDriverSelected && (
        <form onSubmit={handleUpdateDriver}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Customer Account"
                fullWidth
                value={`${loggedInUserClientId}-${loggedInUserName}`}
                InputProps={{ readOnly: true }}
                InputLabelProps={{
                  sx: {
                    fontSize: '11px',
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 25,
                    minHeight: 25,
                  }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '2px',
                  }, // Reduce font size & padding
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: '200px', // Adjust the maximum height of the dropdown
                      minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                      '& .MuiMenuItem-root': {
                        fontSize: '10px', // Reduce font size inside the dropdown
                        marginLeft: '5px', // Adjust left margin
                        padding: '5px 15px', // Adjust the padding inside the items to control height
                      },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Driver ID</InputLabel>
                <Select
                  label="Driver ID"
                  value={driverId}
                  onChange={handleUpdateDriverSelectionChange}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 20,
                      minHeight: 20,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a driver</em>
                  </MenuItem>
                  {driversList.map((driver) => (
                    <MenuItem key={driver.driverId} value={driver.driverId}>
                      {driver.driverId}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Driver Name"
                fullWidth
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
                // InputLabelProps={{ style: inputLabelStyles }}
                required
                InputLabelProps={{
                  sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" error={!!licenseNumberError}>
                <TextField
                  label="License #"
                  value={licenseNumber}
                  onChange={handleLicenseNumberChange}
                  // InputLabelProps={{ style: inputLabelStyles }}
                  required
                  error={!!licenseNumberError}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-10px' }, // Reduce placeholder size
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 25 }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '4px',
                    }, // Reduce font size & padding
                  }}
                />
                {licenseNumberError && <FormHelperText>{licenseNumberError}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>License State</InputLabel>
                <Select
                  label="License State"
                  value={licenseState}
                  onChange={(e) => setLicenseState(e.target.value)}
                  InputLabelProps={{
                    sx: { fontSize: '11px', top: '-50%' },
                  }}
                  sx={{
                    '& .MuiInputBase-root': { height: 22, minHeight: 22 },
                    '& .MuiInputBase-input': { fontSize: 11, padding: '2px' },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px',
                        minHeight: '100px',
                        '& .MuiMenuItem-root': {
                          fontSize: '10px',
                          marginLeft: '5px',
                          padding: '5px 15px',
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a state</em>
                  </MenuItem>
                  {licenseStates.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Primary Phone"
                fullWidth
                value={primaryPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure it starts with '+1' and only allows digits after that
                  if (value.startsWith('+1')) {
                    const digits = value.slice(2).replace(/\D/g, ''); // Remove non-digits after '+1'
                    if (digits.length <= 10) {
                      setPrimaryPhone('+1' + digits);
                    }
                  } else {
                    setPrimaryPhone('+1'); // Reset to '+1' if invalid
                  }
                }}
                // InputLabelProps={{ style: inputLabelStyles }}
                inputProps={{
                  maxLength: 12, // '+1' (2 chars) + 10 digits = 12 chars total
                  pattern: '[+][1][0-9]{0,10}', // Optional: HTML5 pattern for validation
                }}
                required
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Secondary Phone"
                fullWidth
                value={secondaryPhone}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure it starts with '+1' and only allows digits after that
                  if (value.startsWith('+1')) {
                    const digits = value.slice(2).replace(/\D/g, ''); // Remove non-digits after '+1'
                    if (digits.length <= 10) {
                      setSecondaryPhone('+1' + digits);
                    }
                  } else {
                    setSecondaryPhone('+1'); // Reset to '+1' if invalid
                  }
                }}
                // InputLabelProps={{ style: inputLabelStyles }}
                inputProps={{
                  maxLength: 12, // '+1' (2 chars) + 10 digits = 12 chars total
                  pattern: '[+][1][0-9]{0,10}', // Optional: HTML5 pattern for validation
                }}
                InputLabelProps={{
                  sx: { fontSize: '11px' }, // Reduce placeholder size
                }}
                sx={{
                  '& .MuiInputBase-root': { height: 25 }, // Adjust height
                  '& .MuiInputBase-input': {
                    fontSize: 11,
                    padding: '4px',
                  }, // Reduce font size & padding
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Unit #</InputLabel>
                <Select
                  label="Unit #"
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a unit</em>
                  </MenuItem>
                  {unitNumbers.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Status</InputLabel>
                <Select
                  label="Status"
                  value={driverStatus}
                  onChange={(e) => setDriverStatus(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  {driverStatuses.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small">
                <InputLabel style={inputLabelStyles}>Assigned Card #</InputLabel>
                <Select
                  label="Assigned Card #"
                  value={assignedCard}
                  onChange={(e) => setAssignedCard(e.target.value)}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>Select a card</em>
                  </MenuItem>
                  {cardsForClient.map((card) => (
                    <MenuItem key={card.cardNumber} value={card.cardNumber}>
                      {card.cardNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                  onChange={handleFileChange}
                  InputLabelProps={{
                    sx: {
                      fontSize: '11px',
                      top: '-50%', // Initially center the label vertically
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 22,
                      minHeight: 22,
                    }, // Adjust height
                    '& .MuiInputBase-input': {
                      fontSize: 11,
                      padding: '2px',
                    }, // Reduce font size & padding
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // Adjust the maximum height of the dropdown
                        minHeight: '100px', // Adjust the minimum height of the dropdown (optional)
                        '& .MuiMenuItem-root': {
                          fontSize: '10px', // Reduce font size inside the dropdown
                          marginLeft: '5px', // Adjust left margin
                          padding: '5px 15px', // Adjust the padding inside the items to control height
                        },
                      },
                    },
                  }}
                />
                {licenseFile && <Typography variant="body2">{licenseFile.name}</Typography>}
              </Box>
            </Grid>
          </Grid>
          <Box mt={2} textAlign="center">
            <Button
              style={{
                backgroundColor: '#31478F',
                color: 'white',
                height: '30px',
                fontSize: '10px',
              }}
              variant="contained"
              sx={{
                mr: 2,
                borderRadius: '5px',
                padding: '5px 10px', // Adjust padding to make the button smaller
                color: 'white',
              }}
              type="submit"
              disabled={!driverId}
            >
              Update
            </Button>
          </Box>
        </form>
      )}

      <Box sx={{ marginTop: '30px' }}>
        {/* <Typography variant="h6" gutterBottom>
          Drivers List
        </Typography> */}
        {driversList.length > 0 ? (
          <StyledDataGrid
            rows={driversList}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            slots={{ toolbar: CustomToolbar }}
            getRowId={(row) => row.driverId}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            columnHeaderHeight={31} // Match ManageUsers header height
            rowHeight={22}
            sx={{
              '& .super-app-theme--header': {
                backgroundColor: '#31478F !important', // Header background color
                color: 'white', // Header text color
                fontSize: '12px', // Reduce font size in the header
              },
              '& .MuiDataGrid-columnHeaders': {
                height: 25, // Adjust header height (reduce the height to your desired size)
                fontSize: '12px', // Optional: Ensure the font size for header is correct
              },
              '& .MuiDataGrid-cell': {
                padding: '2px 8px', // Reduce row padding (adjust as needed)
                fontSize: '10px', // Reduce font size for rows
              },
              '& .MuiDataGrid-iconButton': {
                fontSize: '12px', // Reduce icon size in the toolbar
                color: 'grey', // Set the icon color to grey
              },
              '& .MuiDataGrid-row': {
                minHeight: '28px', // Reduce the height of rows
              },
              '& .MuiDataGrid-row.even': {
                backgroundColor: '#F8F8F8', // Even row background color
              },
              '& .MuiDataGrid-row.odd': {
                backgroundColor: '#E6F0FF', // Odd row background color
              },
              // Add media queries for responsiveness
              '@media (max-width: 768px)': {
                '& .super-app-theme--header': {
                  fontSize: '10px', // Smaller font size on mobile
                },
                '& .MuiDataGrid-cell': {
                  padding: '2px 4px', // Smaller padding for mobile
                  fontSize: '10px', // Smaller font size for rows
                },
                '& .MuiDataGrid-iconButton': {
                  fontSize: '10px', // Smaller icon size on mobile
                },
                '& .MuiDataGrid-row': {
                  minHeight: '26px', // Adjust row height for mobile
                },
              },
            }}
          />
        ) : (
          <Typography></Typography>
        )}
      </Box>
    </Container>
  );
};

export default DriverForm;
