import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText
} from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';

const FuelCard = ({ inputCardNumber, inputClientId, inputCustomerId, inputCustomerName }) => {
  // inputClientId
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  // const formRef = useRef(null);

  const [loading, setLoading] = React.useState(true);
  const [cardInquiryDetails, setCardInquiryDetails] = React.useState('');

  // CUSTOMER
  // const [customerId, setCustomerId] = React.useState('');
  const [driverId, setDriverId] = React.useState('');
  const [driverIdError, setDriverIdError] = React.useState(false);
  const [driverIdErrorMessage, setDriverIdErrorMessage] = React.useState(false);
  const [driverType, setDriverType] = React.useState('');
  const [cashLimit, setCashLimit] = React.useState('');
  const [cashLimitError, setCashLimitError] = React.useState(false);
  const [currency, setCurrency] = React.useState('');
  const [cashRefreshDays, setCashRefreshDays] = React.useState([]);
  const [selectedDay, setSelectedDay] = React.useState('');
  const [cashRefreshDaysString, setCashRefreshDaysString] = React.useState('');

  // const loggedInUserClientId = localStorage.getItem('clientId')
  // const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin'

  // CARD
  const [cardsForClient, setCardsForClient] = React.useState([]);
  const [cardNumber, setCardNumber] = React.useState('');
  const [cardStatus, setCardStatus] = React.useState('');
  // const [cardExpiryDate, setCardExpiryDate] = React.useState('');
  const [usFuelingAllowed, setUsFuelingAllowed] = React.useState('');

  // TRUCK
  const [truckNumber, setTruckNumber] = React.useState('');
  const [driverName, setDriverName] = React.useState('');
  const [dailyVolumeLimit, setDailyVolumeLimit] = React.useState('');
  const [weeklyVolumeLimit, setWeeklyVolumeLimit] = React.useState('');

  // FUEL
  const [dieselCad, setDieselCad] = React.useState('');
  const [dyedDieselCad, setDyedDieselCad] = React.useState('');
  const [dieselUsa, setDieselUsa] = React.useState('');
  const [dyedDieselUsa, setDyedDieselUsa] = React.useState('');
  const [reeferCad, setReeferCad] = React.useState('');
  const [reeferUsa, setReeferUsa] = React.useState('');
  const [updateFuelCardEnabled, setUpdateFuelCardEnabled] = React.useState(false);

  // MODAL
  // const [isAvailableCardsModalOpen, setIsAvailableCardsModalOpen] = React.useState(false)
  // const [availableCards, setAvailableCards] = React.useState([]);

  const driverTypes = [
    {
      id: 1,
      value: 'Single'
    },
    {
      id: 2,
      value: 'Team'
    }
  ];

  const currencyTypes = [
    // {
    //   id: 1,
    //   value: "US Dollars (USD)"
    // },
    {
      id: 2,
      value: 'Canadian Dollars (CAD)'
    }
  ];

  const cardStatuses = [
    {
      id: 1,
      value: 'Active',
      shortForm: 'A'
    },
    {
      id: 2,
      value: 'Inactive',
      shortForm: 'B'
    }
  ];

  const fuelSelections = [
    {
      id: 1,
      value: 'Authorised'
    },
    {
      id: 2,
      value: 'Un-Authorised'
    }
  ];

  const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesSectionTypography = { fontSize: '18px', textAlign: 'left', fontWeight: '600', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesSubTypography = { fontSize: '14px', textAlign: 'left', fontWeight: '550', fontFamily: 'DM Sans, sans-serif' };
  const leftCardsHeight = 308;
  const rightCardsHeight = 620;
  const buttonBackground = '#bb7bf4';
  const inputLabelStyles = { fontSize: '16px', color: 'rgb(26, 33, 56)', fontWeight: 600 };
  const textFieldLabelStyles = {
    style: {
      fontSize: '16px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 600
    }
  };
  const gridRowInternalStyles = { marginTop: 15 };
  const gridRowStyles = { marginTop: '15px', marginBottom: '15px' };
  const buttonsFontSize = '16px';

  const handleCardChange = (cardNumber, customerId) => {
    let getCardIdDetailsUrl = `${BACKEND_URL}/v1/cardId?cardNumber=${cardNumber}&customerId=${customerId}`;

    setTimeout(async () => {
      await fetch(getCardIdDetailsUrl)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          let cardInquiryReturn = data.cardInquiryReturn;
          setCardInquiryDetails(cardInquiryReturn);
          setCardNumber(cardNumber);
          populateCustomerDetailsPostInquiry(cardInquiryReturn);
          populateCardDetailsPostInquiry(cardInquiryReturn);
          populateTractorDetailsPostInquiry(cardInquiryReturn, cardNumber);
        })
        .then(setLoading(false));
    }, 1000);
  };

  const populateCustomerDetailsPostInquiry = (cardInquiryReturn) => {
    setDriverId(cardInquiryReturn.employeeNumber || 'N/A');
    setDriverType('Single');
    let inputCashRefreshDays = cardInquiryReturn.cashRenewDayOfWeek;
    let initialValues = inputCashRefreshDays
      ? inputCashRefreshDays.split('').map((char) => parseInt(char, 10))
      : '0000000'.split('').map((char) => parseInt(char, 10));
    let daysOfWeek = [
      { index: 0, value: 'sunday', label: 'Sunday', refreshValue: initialValues[0] },
      { index: 1, value: 'monday', label: 'Monday', refreshValue: initialValues[1] },
      { index: 2, value: 'tuesday', label: 'Tuesday', refreshValue: initialValues[2] },
      { index: 3, value: 'wednesday', label: 'Wednesday', refreshValue: initialValues[3] },
      { index: 4, value: 'thursday', label: 'Thursday', refreshValue: initialValues[4] },
      { index: 5, value: 'friday', label: 'Friday', refreshValue: initialValues[5] },
      { index: 6, value: 'saturday', label: 'Saturday', refreshValue: initialValues[6] }
    ];
    daysOfWeek.filter((day) => {
      if (day.refreshValue === 1) {
        setSelectedDay(day.value);
      }
    });

    let newCashRefreshDaysString = daysOfWeek.map((day) => day.refreshValue);
    setCashRefreshDaysString(newCashRefreshDaysString.join(''));
    setCashRefreshDays(daysOfWeek);
    setCashLimit(cardInquiryReturn.cashLimit);
    setCurrency('Canadian Dollars (CAD)');
  };

  const handleCashRefreshDaysChange = (event, chosenValue) => {
    console.log(event);
    setSelectedDay(chosenValue);
    cashRefreshDays.map((day) => {
      day.value === chosenValue ? (day.refreshValue = 1) : (day.refreshValue = 0);
    });
    let newCashRefreshDaysString = cashRefreshDays.map((day) => day.refreshValue);
    setCashRefreshDaysString(newCashRefreshDaysString.join(''));
  };

  const populateCardDetailsPostInquiry = (cardInquiryReturn) => {
    let cardStatus = cardStatuses.filter((status) => status.shortForm === cardInquiryReturn.cardStatus);
    if (cardStatus) {
      let status = cardStatus[0];
      setCardStatus(status.value);
    }
    setUsFuelingAllowed(cardInquiryReturn.fuelPurchase ? 'Authorised' : 'Un-Authorised'); // TODO: Recheck
  };
  const populateTractorDetailsPostInquiry = (cardInquiryReturn, cardNumber) => {
    let filterCardForClient = cardsForClient.filter((card) => card.cardNumber === cardNumber);
    if (filterCardForClient) {
      setTruckNumber(cardInquiryReturn.unitNumber || 'N/A');
      setDriverName(cardInquiryReturn.driverName);
      setDailyVolumeLimit(300);
      setWeeklyVolumeLimit(cardInquiryReturn.dieselGallonLimit);

      // Fuel Products
      setDieselCad(cardInquiryReturn.fuelPurchase ? 'Authorised' : 'Un-Authorised');
      setDieselUsa(cardInquiryReturn.fuelPurchase ? 'Authorised' : 'Un-Authorised');
      setDyedDieselCad(cardInquiryReturn.fuelPurchase ? 'Authorised' : 'Un-Authorised');
      setDyedDieselUsa(cardInquiryReturn.fuelPurchase ? 'Authorised' : 'Un-Authorised');
      setReeferCad(cardInquiryReturn.reeferRenewDailyReset ? 'Authorised' : 'Un-Authorised');
      setReeferUsa(cardInquiryReturn.reeferRenewDailyReset ? 'Authorised' : 'Un-Authorised');
    }
  };

  // const handleCustomerChange = (event) => {
  //   console.log(event.target.value)
  //   let chosenClient = clients.filter((client) => client.clientID === event.target.value)
  //   if (chosenClient) {
  //     let client = chosenClient[0];
  //     let customerId = client.customerID
  //     setClientId(client.clientID);
  //     setCustomerId(customerId)
  //     setCardNumber('');
  //     console.log(cardNumber)
  //     let getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerId}`

  //     fetch(getCardDetailsForCustomerUrl)
  //       .then(response => {
  //         return response.json();
  //       })
  //       .then(data => {
  //         setCardsForClient(data.records.card);
  //       }
  //     )
  //   }
  // };

  useEffect(() => {
    handleCardChange(inputCardNumber, inputCustomerId);
    setCardNumber(inputCardNumber);
  }, []);

  // const getClients = async () => {
  //   let getClientsUrl = `${BACKEND_URL}/v1/client`

  //   await fetch(getClientsUrl)
  //     .then(response => {
  //       return response.json();
  //     })
  //     .then(data => {
  //       data.sort((a,b) => a.clientID - b.clientID)
  //       setClients(data);
  //       return data;
  //     }
  //   )
  // }

  const handleDriverTypeChange = (event) => {
    let driverType = event.target.value;
    if (driverType === 'Single') {
      setWeeklyVolumeLimit(cardInquiryDetails.dieselGallonLimit);
    } else if (driverType === 'Team') {
      setWeeklyVolumeLimit(cardInquiryDetails.dieselGallonLimit * 2);
    }
    setDriverType(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  const handleCardStatusChange = (event) => {
    let chosenStatus = cardStatuses.filter((status) => status.value === event.target.value);
    if (chosenStatus) {
      let status = chosenStatus[0];
      setCardStatus(status.value);
    }
  };

  const handleFuelCardUpdate = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(() => {
      let fuelCardUpdateUrl = `${BACKEND_URL}/v1/cards`;
      const fuelCardUpdateObject = {
        cardNumber: inputCardNumber,
        unitNumber: truckNumber,
        employeeNumber: driverId,
        cashLimit: +cashLimit,
        cashRenewDayOfWeek: cashRefreshDaysString,
        oneTwoQuantityLimit: +weeklyVolumeLimit

        // cardStatus: cardStatus === 'Active' ? 'A' : 'B',
      };

      console.log(fuelCardUpdateObject);
      fetch(fuelCardUpdateUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fuelCardUpdateObject)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          resetFields();
        });
      setLoading(false);
    }, 2000);
  };

  const resetFields = () => {
    setDriverId('');
    setDriverType('');
    setCashLimit(0);
    setCashLimitError('');
    setCurrency('');

    // CARD
    setCardsForClient([]);
    setCardNumber('');
    setCardStatus('');
    setUsFuelingAllowed('');

    // TRUCK
    setTruckNumber('');
    setDriverName('');
    setDailyVolumeLimit('');
    setWeeklyVolumeLimit('');

    // FUEL
    setDieselCad('');
    setDyedDieselCad('');
    setDieselUsa('');
    setDyedDieselUsa('');
    setReeferCad('');
    setReeferUsa('');
  };

  const handleUnitNumberChange = (event) => {
    let newUnitNumber = event.target.value;
    setTruckNumber(newUnitNumber);
    generateEmployeeNumber(inputClientId, newUnitNumber);
  };

  const generateEmployeeNumber = (clientId, unitNumber) => {
    let isError = true;
    if (clientId && clientId.length == 3) {
      if (unitNumber) {
        isError = false;
        const regex = /[a-zA-Z]/g;
        unitNumber = unitNumber.replace(regex, '');
        if (!(unitNumber.length == 3 || unitNumber.length == 4)) {
          isError = true;
        }
      }
    }

    if (isError) {
      setDriverId('');
      setDriverIdError(true);
      setDriverIdErrorMessage(`The requested employee number ${clientId}${unitNumber} is invalid, Please contact admin helpdesk`);
      setUpdateFuelCardEnabled(true);
    } else {
      setDriverId(`${clientId}${unitNumber}`);
      setDriverIdError(false);
      setDriverIdErrorMessage('');
      setUpdateFuelCardEnabled(false);
    }
  };

  const handleCashLimitChange = (event) => {
    const regex = /^[0-9]*$/;
    let inputCashLimit = event.target.value;
    if (regex.test(inputCashLimit)) {
      if (+inputCashLimit > cardInquiryDetails.cashLimit) {
        setCashLimitError(`Cash Limit cannot be more than the assigned limit of $${cashLimit}`);
        setCashLimit(cardInquiryDetails.cashLimit);
      } else {
        setCashLimit(+inputCashLimit);
        setCashLimitError('');
      }
    } else {
      setCashLimitError(`Cash Limit can be only between 0 and ${cashLimit}`);
    }
  };

  return (
    <div>
      <Typography variant="h1" sx={fontStylesRootTypography} gutterBottom>
        CARD MANAGEMENT
      </Typography>
      {loading ? (
        <Grid container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
            <Card>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center"></Grid>
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <CircularProgress color="success" align="center" />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <form className="container" onSubmit={handleFuelCardUpdate}>
          <div style={{ display: 'flex' }}>
            <div className="card" style={{ flex: '1', margin: '5px' }}>
              <Card sx={{ minWidth: '100%', minHeight: leftCardsHeight }}>
                <CardContent>
                  <Typography variant="h4" sx={fontStylesSectionTypography} gutterBottom>
                    CUSTOMER {`${inputClientId} - ${inputCustomerId} - ${inputCustomerName}`}
                  </Typography>
                  <form id="fuel-card-customer">
                    {/* ROW 1 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Card Number</Typography>
                          </FormControl>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <Typography style={{ fontSize: '12px' }}>{cardNumber}</Typography>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small" error={driverIdError}>
                            <TextField
                              sx={{ minHeight: 10 }}
                              id="driverId"
                              label="Driver ID"
                              value={driverId}
                              disabled
                              InputLabelProps={textFieldLabelStyles}
                            />
                            {driverIdError && <FormHelperText id="input-error-text">{driverIdErrorMessage}</FormHelperText>}
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* ROW 2 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <InputLabel id="driverType" style={inputLabelStyles}>
                              Driver Type
                            </InputLabel>
                            <Select labelId="driverType" id="driverType" value={driverType} label="Card" onChange={handleDriverTypeChange}>
                              {driverTypes.map((driverType) => (
                                <MenuItem key={driverType.id} value={driverType.value}>
                                  {driverType.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small" error={cashLimitError}>
                            <TextField
                              required
                              sx={{ minHeight: 10 }}
                              id="cashLimit"
                              label="Cash Limit"
                              value={cashLimit}
                              onChange={handleCashLimitChange}
                              InputLabelProps={textFieldLabelStyles}
                            />
                            {cashLimitError && <FormHelperText id="input-error-text">{cashLimitError}</FormHelperText>}
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <InputLabel id="currency" style={inputLabelStyles}>
                              Currency
                            </InputLabel>
                            <Select labelId="currency" id="currency" value={currency} label="Currency" onChange={handleCurrencyChange}>
                              {currencyTypes.map((currency) => (
                                <MenuItem key={currency.id} value={currency.value}>
                                  {currency.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* ROW 3 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px', marginTop: '30px' }}>
                        <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                          Cash Refresh Days
                        </Typography>
                      </Grid>
                      <Grid item xs={9} sm={9} md={9} lg={9}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="cashRefreshDays"
                            name="cashRefreshDays"
                            value={cashRefreshDays}
                            onChange={handleCashRefreshDaysChange}
                          >
                            {cashRefreshDays &&
                              cashRefreshDays.map((day) => (
                                <FormControlLabel
                                  value={day.value}
                                  key={day.index}
                                  control={<Radio checked={selectedDay === day.value} />}
                                  label={day.label}
                                />
                              ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <br />
              <Card sx={{ minWidth: '100%', minHeight: leftCardsHeight }}>
                <CardContent>
                  <Typography variant="h4" sx={fontStylesSectionTypography} gutterBottom>
                    CARD
                  </Typography>
                  <form id="fuel-card-card">
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <InputLabel id="cardStatus" style={inputLabelStyles}>
                              Card Status
                            </InputLabel>
                            <Select labelId="cardStatus" id="cardStatus" value={cardStatus} label="Card" onChange={handleCardStatusChange}>
                              {cardStatuses.map((status) => (
                                <MenuItem key={status.id} value={status.value}>
                                  {status.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Box sx={{ minWidth: 120 }}>
                          <Typography style={{ fontSize: '12px', fontWeight: '600' }}>Card Balance ($)</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} lg={2}>
                        <Box sx={{ minWidth: 120 }}>
                          <Typography style={{ fontSize: '12px', fontWeight: '600' }}>
                            {cardInquiryDetails && Math.round(cardInquiryDetails.cardBalance * 100) / 100}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: 120 }} size="small">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker label="Expire Date" InputLabelProps={textFieldLabelStyles} disabled />
                            </LocalizationProvider>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <InputLabel id="usFuelingAllowed" style={inputLabelStyles}>
                              US Fueling allowed
                            </InputLabel>
                            <Select
                              labelId="usFuelingAllowed"
                              id="usFuelingAllowed"
                              value={usFuelingAllowed}
                              label="US Fueling allowed"
                              onChange={(event) => {
                                setUsFuelingAllowed(event.target.value);
                              }}
                              disabled
                            >
                              {fuelSelections.map((selection) => (
                                <MenuItem key={selection.id} value={selection.value}>
                                  {selection.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </div>
            <div className="card" style={{ flex: '1', margin: '5px' }}>
              <Card sx={{ minWidth: 275, minHeight: rightCardsHeight }}>
                <CardContent>
                  <Typography variant="h4" sx={fontStylesSectionTypography} gutterBottom>
                    TRUCK
                  </Typography>
                  <form id="fuel-card-truck">
                    {/* ROW 1 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                            <TextField
                              required
                              sx={{ minHeight: 10 }}
                              id="truckNumber"
                              label="Unit Number"
                              value={truckNumber}
                              onChange={handleUnitNumberChange}
                              InputLabelProps={textFieldLabelStyles}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <TextField
                              required
                              name="password"
                              sx={{ minHeight: 10 }}
                              id="driverName"
                              label="Driver Name"
                              value={driverName}
                              onChange={(event) => setDriverName(event.target.value)}
                              InputLabelProps={textFieldLabelStyles}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* ROW 2 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box sx={{ minWidth: 120 }}>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <TextField
                              sx={{ minHeight: 10 }}
                              id="dailyVolumeLimit"
                              label="Daily Volume Limit"
                              value={dailyVolumeLimit}
                              onChange={(event) => setDailyVolumeLimit(event.target.value)}
                              InputLabelProps={textFieldLabelStyles}
                              disabled
                            />
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Box>
                          <FormControl sx={{ minWidth: '100%' }} size="small">
                            <TextField
                              sx={{ minHeight: 10 }}
                              id="weeklyVolumeLimit"
                              label="Weekly Volume Limit"
                              value={weeklyVolumeLimit}
                              onChange={(event) => setWeeklyVolumeLimit(event.target.value)}
                              InputLabelProps={textFieldLabelStyles}
                            />
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* ROW 3 */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                          Fuel Products Allowed
                        </Typography>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Diesel (CAD)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="dieselCad"
                                  id="dieselCad"
                                  value={dieselCad}
                                  label="Diesel (CAD)"
                                  onChange={(event) => setDieselCad(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Dyed Diesel (CAD)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="dyedDieselCad"
                                  id="dyedDieselCad"
                                  value={dyedDieselCad}
                                  label="Dyed Diesel (CAD)"
                                  onChange={(event) => setDyedDieselCad(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Reefer (CAD)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="reeferCad"
                                  id="reeferCad"
                                  value={reeferCad}
                                  label="Reefer (CAD)"
                                  onChange={(event) => setReeferCad(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Diesel (USA)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="dieselUsa"
                                  id="dieselUsa"
                                  value={dieselUsa}
                                  label="Diesel (USA)"
                                  onChange={(event) => setDieselUsa(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Dyed Diesel (USA)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="dyedDieselUsa"
                                  id="dyedDieselUsa"
                                  value={dyedDieselUsa}
                                  label="Dyed Diesel (USA)"
                                  onChange={(event) => setDyedDieselUsa(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} style={gridRowInternalStyles}>
                          <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs={3} sm={3} md={3} lg={3}>
                            <Typography variant="h5" sx={fontStylesSubTypography} gutterBottom>
                              Reefer (USA)
                            </Typography>
                          </Grid>
                          <Grid item xs={5} sm={5} md={5} lg={5}>
                            <Box>
                              <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                                <Select
                                  labelId="reeferUsa"
                                  id="reeferUsa"
                                  value={reeferUsa}
                                  label="Reefer (USA)"
                                  onChange={(event) => setReeferUsa(event.target.value)}
                                  disabled
                                >
                                  {fuelSelections.map((selection) => (
                                    <MenuItem key={selection.id} value={selection.value}>
                                      {selection.value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </div>
          </div>
          <div className="card" style={{ flex: '1', margin: '5px' }}>
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
              <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} align="center">
                <Button
                  style={{ fontFamily: 'DM Sans', fontSize: buttonsFontSize, backgroundColor: buttonBackground }}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 5, borderRadius: 1 }}
                  onClick={handleFuelCardUpdate}
                  disabled={updateFuelCardEnabled}
                >
                  Update Details
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </div>
  );
};

export default FuelCard;
