import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormControl,
  MenuItem,
  CircularProgress,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  TextField,
  IconButton,
} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import Loader from "../../components/Loader";
import 'jspdf-autotable';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // Import timezone plugin
import utc from 'dayjs/plugin/utc'; // Import utc plugin to handle UTC
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

function SalesReport() {
  const buttonsFontSize = '16px';
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const buttonBackground = '#011f4b';
  const [clients, setClients] = React.useState([]);
  const [, setClientsCAD] = React.useState([]);
  const [, setClientsUSD] = React.useState([]);
  const [, setCustomerId] = React.useState('');

  const [clientId, setClientId] = React.useState('');
  // const [, setSelectedClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [customerErrorText, setCustomerErrorText] = useState('');
  const [fetchedWithNoRecords, setFetchedWithNoRecords] = React.useState(false);
  const [, setCardsForClient] = React.useState([]);
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const [clientDetails, setClientDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  const [, setRows] = React.useState([]);
  const [uploading, setUploading] = useState(false);

  const [invoices, setInvoices] = useState([]);

  const [startDate, setStartDate] = React.useState(null);

  const [startDateErrorText, setStartDateErrorText] = React.useState('');

  const [endDate, setEndDate] = React.useState(null);
  const [error, setError] = React.useState('');
  const [endDateErrorText, setEndDateErrorText] = React.useState('');

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  const cardHeight = 100;
  const inputLabelStyles = { fontSize: '14px', color: 'rgb(26, 33, 56)' };
  const textFieldLabelStyles = {
    style: {
      fontSize: '14px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
    },
  };
  const gridRowStyles = { marginTop: '5px', marginBottom: '5px' };

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (!loggedInUserClientId) {
        setError('Client ID not found in localStorage.');
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/v1/client?clientID=${loggedInUserClientId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientDetails(data);
        console.log('clientDetails', data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClientDetails();
  }, [loggedInUserClientId]);

  useEffect(() => {
    getClients();
  }, []);

  // Use Effect to handle single client case
  useEffect(() => {
    if (clients.length === 1) {
      const singleClient = clients[0];
      const singleClientValue = `${singleClient.clientID}-${singleClient.customerID}`;
      setClientId(singleClient.clientID);
      setCustomerId(singleClient.customerID);

      // Trigger handleSelectChange programmatically
      handleSelectChange({ target: { value: singleClientValue } });
    }
  }, [clients]); // Only depends on clients array

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);

    // Create two arrays to hold the clients by currency
    const clientsCADData = [];
    const clientsUSDData = [];

    // Iterate over the client data and segregate into CAD or USD
    data.forEach((client) => {
      const currency = client.currency;

      // Ensure currency is not null or undefined before checking for CAD or USD
      if (currency && typeof currency === 'string') {
        // Check if currency contains "CAD"
        if (currency.includes('CAD')) {
          clientsCADData.push(client);
        }

        // Check if currency contains "USD"
        if (currency.includes('USD')) {
          clientsUSDData.push(client);
        }
      }
    });

    // Set the state variables for CAD and USD clients
    setClientsCAD(clientsCADData); // Set clients for CAD
    setClientsUSD(clientsUSDData); // Set clients for USD
    return data;
  };

  // const handleSelectChange = (event) => {
  //   const selectedValue = event.target.value;

  //   // Check if selectedValue is not undefined and contains '-'
  //   if (selectedValue && selectedValue.includes('-')) {
  //     // Extract clientID and customerID from the selected value
  //     const [clientID, customerID] = selectedValue.split('-').map((value) => value.trim());

  //     if (clientID && customerID) {
  //       setClientId(clientID); // Update clientId state
  //       setCustomerId(customerID); // Update customerId state

  //       // Call the function to handle customer selection change with clientId and customerId
  //       handleCustomerSelectionChange(clientID, customerID);
  //     } else {
  //       console.error(
  //         'Failed to extract clientID and customerID from the selected value:',
  //         selectedValue,
  //       );
  //     }
  //   } else {
  //     console.error('Selected value is not in the expected format:', selectedValue);
  //   }
  // };

  const handleSelectChange = (event) => {
    setCustomerId(event.target.value);

    handleCustomerSelectionChange(event);
  };

  const handleCustomerSelectionChange = async (clientID, customerID) => {
    setLoading(true);
    setRows([]);
    console.log('Client inside handlecustomer ', clientID);
    try {
      // Use clientID in the getClientsUrl
      const getClientsUrl = `${BACKEND_URL}/v1/client?clientID=${clientID}`;
      const getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerID}&maxRows=1000&pageNumber=1000`;

      const [clientResponse, cardResponse] = await Promise.all([
        fetch(getClientsUrl),
        fetch(getCardDetailsForCustomerUrl),
      ]);

      if (!clientResponse.ok) {
        throw new Error('Failed to fetch client data');
      }

      if (!cardResponse.ok) {
        throw new Error('Failed to fetch card details');
      }

      const clientData = await clientResponse.json();
      const cardData = await cardResponse.json();

      setSelectedClient(clientData);
      console.log('Data client', cardData?.records);
      setCardsForClient(cardData?.records?.card);
      console.log('Customer Details new', clientData);
    } catch (error) {
      console.error('Error fetching client or card details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  // Set the customerId to the first item if only one item is present
  useEffect(() => {
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      const firstClient = clients[0];
      setCustomerId(firstClient.customerID);
      handleCustomerSelectionChange({
        target: { value: firstClient.customerID },
      });
    }
  }, [doNotPerformCustomerFiltration, loggedInUserClientId, BACKEND_URL]);

  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };

  const fetchInvoices = async (event) => {
    setUploading(true);
    event.preventDefault();

    // if (!clientId) {
    //   console.error('Client ID is required');
    //   setUploading(false);
    //   return;
    // }

    try {
      // const response = await fetch(
      //   `${BACKEND_URL}/v1/get-sales-report-by-client?clientId=${clientId}`
      // );

      const apiUrl =
        selectedClient === 'all'
          ? `${BACKEND_URL}/v1/get-sales-report`
          : `${BACKEND_URL}/v1/get-sales-report-by-client?clientId=${selectedClient}`;

      const response = await fetch(apiUrl);
      const data = await response.json();

      // Transform invoiceId to "Feb27-Mar02" format and extract date portions
      let updatedData = data.map((invoice) => {
        const parts = invoice.invoiceId.split('_'); // Example: "110_Feb272025_Mar022025"
        if (parts.length === 3) {
          const startDateStr = parts[1]; // "Feb272025"
          const endDateStr = parts[2]; // "Mar022025"

          // Convert invoice period to comparable date format (YYYY-MM-DD)
          const invoiceStartDate = dayjs(startDateStr, 'MMMDDYYYY').format('YYYY-MM-DD');
          const invoiceEndDate = dayjs(endDateStr, 'MMMDDYYYY').format('YYYY-MM-DD');
          const invoiceId = invoice.invoiceId;

          return { ...invoice, invoiceId, invoiceStartDate, invoiceEndDate };
        }
        return invoice;
      });

      // Apply date filtering if startDate or endDate is selected
      if (startDate || endDate) {
        const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
        const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;

        updatedData = updatedData.filter((invoice) => {
          const { invoiceStartDate, invoiceEndDate } = invoice;

          if (formattedStartDate && formattedEndDate) {
            return invoiceEndDate >= formattedStartDate && invoiceStartDate <= formattedEndDate;
          } else if (formattedStartDate) {
            return invoiceEndDate >= formattedStartDate;
          } else if (formattedEndDate) {
            return invoiceStartDate <= formattedEndDate;
          }
          return true;
        });
      }

      setFetchedWithNoRecords(updatedData.length === 0);
      setInvoices(updatedData);
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    } finally {
      setUploading(false);
    }
  };

  const columns = [
    // { field: 'id', headerName: 'INVOICE ID', width: 100 },
    { field: 'clientId', headerName: 'CLIENT ID', width: 80 },
    // { field: 'customerId', headerName: 'CUSTOMER ID', width: 100 },
    { field: 'customerName', headerName: 'CUSTOMER NAME', width: 150 },
    { field: 'invoiceId', headerName: 'INV PERIOD', width: 200 },
    { field: 'totalDiesel', headerName: 'DSL QTY', width: 100 },
    { field: 'totalDef', headerName: 'DEF QTY', width: 100 },
    { field: 'totalRfr', headerName: 'RFR QTY', width: 100 },
    { field: 'discQty', headerName: 'DISC QTY', width: 100 },
    { field: 'ksgDisc', headerName: 'KSG DISC', width: 100 },
    { field: 'disc2Client', headerName: 'DISC2CLIENT', width: 100 },
    { field: 'ksgProfit', headerName: 'KSG PROFIT', width: 100 },
    { field: 'inv2Ksg', headerName: 'INV2KSG', width: 100 },
    { field: 'inv2Client', headerName: 'INV2CLIENT', width: 100 },
  ];

  const handleVerifyInvoiceClicked = () => {
    navigate('/manage-invoice/verify'); // Navigate to /invoice page
  };

  const handleBackClicked = () => {
    navigate('/manage-invoice'); // Navigate to /invoice page
  };

  // Extend dayjs with plugins
  dayjs.extend(timezone);
  dayjs.extend(utc);

  const handleStartDateChange = (date) => {
    console.log('Original Start Date:', date);

    // Convert the selected date to CST (Central Standard Time)
    const dateInCST = dayjs(date).tz('America/Chicago', true); // CST timezone conversion

    console.log('Start Date in CST:', dateInCST.format()); // Log the date in CST

    // Set the state with the CST formatted date
    setStartDate(dateInCST);

    // Reset error text
    setStartDateErrorText('');
  };

  const handleEndDateChange = (date) => {
    console.log('Original End Date:', date);

    // Convert the selected date to CST (Central Standard Time)
    const dateInCST = dayjs(date).tz('America/Chicago', true); // CST timezone conversion

    console.log('End Date in CST:', dateInCST.format()); // Log the date in CST

    // Set the state with the CST formatted date
    setEndDate(dateInCST);

    // Reset error text
    setEndDateErrorText('');
  };

  const handleSelect = (clientID) => {
    console.log('Client ID selected:', clientID);
    setSelectedClient(clientID);
    // Validate if a client is selected
    if (!clientID) {
      setCustomerErrorText('Please select a customer');
    } else {
      setCustomerErrorText('');
    }
    handleSelectChange({ target: { value: clientID } });
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedClient, setSelectedClient] = useState(clientId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'client-select-popover' : undefined;

  // Filter clients based on search term
  const filteredClients = clients.filter(
    (client) =>
      client.clientID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '' ||
      client.customerID?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '' ||
      client.clientName?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
      '',
  );

  return (
    <div>
      {/* Button Container */}
      {clientDetails && clientDetails.length > 0 && (
        <Box display="flex" mb={2} gap={2}>
          {/* Show both buttons if currency is 'CAD, USD' or empty */}
          {clientDetails[0]?.currency === 'CAD, USD' || !clientDetails[0]?.currency ? (
            <>
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'Sans-serif',
                  fontSize: '12px', // Reduce font size
                  backgroundColor: getButtonBackground('CAD'),
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                  marginLeft: '19px',
                }}
                onClick={() => handleCurrencySelect('CAD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                CAD
              </Button>

              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'sans-serif',
                  fontSize: '12px', // Reduce font size
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                  backgroundColor: getButtonBackground('USD'),
                }}
                onClick={() => handleCurrencySelect('USD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                USD
              </Button>
            </>
          ) : null}

          {/* Show only CAD button if currency is 'CAD' */}
          {clientDetails[0]?.currency === 'CAD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'Sans-serif',
                fontSize: '12px', // Reduce font size
                backgroundColor: getButtonBackground('CAD'),
                padding: '4px 8px', // Adjust padding for a smaller button
                minWidth: '50px', // Set a minimum width
                height: '30px', // Reduce height
                marginLeft: '17px',
              }}
              onClick={() => handleCurrencySelect('CAD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              CAD
            </Button>
          )}

          {/* Show only USD button if currency is 'USD' */}
          {clientDetails[0]?.currency === 'USD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'DM Sans',
                fontSize: buttonsFontSize,
                backgroundColor: getButtonBackground('USD'),
              }}
              onClick={() => handleCurrencySelect('USD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              USD
            </Button>
          )}
        </Box>
      )}

      {selectedCurrency === 'CAD' && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              style={{
                textAlign: 'left',
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                fontSize: '14px',
                marginLeft: '17px',
              }}
              gutterBottom
            >
              SALES REPORT
            </Typography>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <Button
                variant="contained"
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '10px',
                  backgroundColor: '#31478F',
                  padding: '4px 9px', // Reduce padding
                  minWidth: '70px', // Reduce width
                  height: '24px', // Reduce height
                  marginRight: '20px',
                }}
                onClick={handleBackClicked}
              >
                BACK
              </Button>
              <Button
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '10px',
                  backgroundColor: '#31478F',
                  padding: '4px 9px', // Reduce padding
                  minWidth: '70px', // Reduce width
                  height: '24px', // Reduce height
                  marginRight: '20px',
                }}
                variant="contained"
                onClick={handleVerifyInvoiceClicked}
              >
                VERIFY INVOICE
              </Button>
            </div>
          </div>
          <div>
            <form>
              <div className="container" style={{ display: 'flex' }}>
                <div className="card" style={{ flex: '1' }}>
                  {/* <Card sx={{ minWidth: "100%", minHeight: cardHeight }}> */}
                  <CardContent>
                    {/* // <form id="transaction-report" onSubmit={handleViewReportCAD}> */}
                    <Grid container spacing={2} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl
                            sx={{ minWidth: '100%', minHeight: 2 }}
                            size="small"
                            error={customerErrorText}
                          >
                            {doNotPerformCustomerFiltration ? (
                              <div>
                                <IconButton
                                  aria-describedby={id}
                                  onClick={handleClick}
                                  endIcon={<ArrowDropDownIcon />}
                                  sx={{
                                    textAlign: 'left',
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '4.5px',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    minHeight: '10px',
                                  }}
                                  style={inputLabelStyles}
                                >
                                  {selectedClient === 'all'
                                    ? 'All Customers'
                                    : selectedClient
                                    ? `${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.clientID
                                      } - ${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.customerID
                                      } - ${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.clientName
                                      }`
                                    : 'Customer'}
                                </IconButton>
                                <Popover
                                  id={id}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 250,
                                      width: 300,
                                    },
                                  }}
                                >
                                  <div style={{ width: '95%' }}>
                                    <TextField
                                      autoFocus
                                      margin="dense"
                                      id="search"
                                      label="Search"
                                      type="search"
                                      fullWidth
                                      variant="outlined"
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                      sx={{
                                        margin: 1,
                                        '& .MuiInputBase-root': {
                                          height: '25px', // Reduce height
                                          fontSize: '10px', // Reduce font size
                                          padding: '2px 6px', // Adjust padding
                                        },
                                        '& .MuiInputLabel-root': {
                                          fontSize: '10px', // Reduce label font size
                                        },
                                      }}
                                    />
                                    <MenuItem
                                      value="all"
                                      onClick={() => handleSelect('all')}
                                      sx={{
                                        fontSize: '12px',
                                        padding: '4px 8px',
                                      }}
                                    >
                                      All Customers
                                    </MenuItem>
                                    {filteredClients.length > 0 ? (
                                      filteredClients.map((client) => (
                                        <MenuItem
                                          key={client.clientID}
                                          selected={client.clientID === selectedClient}
                                          onClick={() => handleSelect(client.clientID)}
                                          sx={{
                                            fontSize: '12px',
                                            padding: '4px 8px',
                                          }}
                                        >
                                          {client.clientID} - {client.customerID} -{' '}
                                          {client.clientName}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled>No results found</MenuItem>
                                    )}
                                  </div>
                                </Popover>
                              </div>
                            ) : clients.length === 1 ? (
                              <div
                                style={{
                                  marginTop: '30px',
                                  marginLeft: '16px',
                                }}
                              >
                                <Typography variant="body1">
                                  {clients[0].clientID} - {clients[0].customerID} -{' '}
                                  {clients[0].clientName}
                                </Typography>
                              </div>
                            ) : (
                              <div>
                                <IconButton
                                  aria-describedby={id}
                                  onClick={handleClick}
                                  endIcon={<ArrowDropDownIcon />}
                                  sx={{
                                    textAlign: 'left',
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                  style={inputLabelStyles}
                                >
                                  {selectedClient === 'all'
                                    ? 'All Customers'
                                    : selectedClient
                                    ? `${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.clientID
                                      } - ${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.customerID
                                      } - ${
                                        clients.find((client) => client.clientID === selectedClient)
                                          ?.clientName
                                      }`
                                    : 'Customer'}
                                </IconButton>
                                <Popover
                                  id={id}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      maxHeight: 400,
                                      width: 300,
                                    },
                                  }}
                                >
                                  <div style={{ width: '300px' }}>
                                    <TextField
                                      autoFocus
                                      margin="dense"
                                      id="search"
                                      label="Search"
                                      type="search"
                                      fullWidth
                                      variant="outlined"
                                      onChange={(e) => setSearchTerm(e.target.value)}
                                      sx={{ margin: 1 }}
                                    />
                                    <MenuItem value="all" onClick={() => handleSelect('all')}>
                                      All Customers
                                    </MenuItem>
                                    {filteredClients.length > 0 ? (
                                      filteredClients.map((client) => (
                                        <MenuItem
                                          key={client.clientID}
                                          selected={client.clientID === selectedClient}
                                          onClick={() => handleSelect(client.clientID)}
                                        >
                                          {client.clientID} - {client.customerID} -{' '}
                                          {client.clientName}
                                        </MenuItem>
                                      ))
                                    ) : (
                                      <MenuItem disabled>No results found</MenuItem>
                                    )}
                                  </div>
                                </Popover>
                              </div>
                            )}
                            {customerErrorText && (
                              <FormHelperText id="customer-error-text">
                                {customerErrorText}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>

                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl
                            sx={{ minWidth: '100%' }}
                            size="small"
                            error={startDateErrorText}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="Start Date"
                                value={startDate}
                                InputLabelProps={textFieldLabelStyles}
                                onChange={handleStartDateChange}
                                shouldDisableDate={(date) => {
                                  const day = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                                  const today = dayjs().startOf('day'); // Get today's date (without time)
                                  return (day !== 1 && day !== 4) || date.isAfter(today);
                                }}
                                slotProps={{
                                  textField: {
                                    sx: {
                                      '& .MuiInputLabel-root': {
                                        fontSize: '12px', // Adjust font size

                                        top: '-10px',
                                      },
                                      '& .MuiInputBase-root': {
                                        height: 25,
                                        minHeight: 25,
                                      },
                                      '& .MuiInputBase-input': {
                                        fontSize: 11,
                                        padding: '2px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        fontSize: '15px', // Reduce icon size
                                      },
                                    },
                                  },
                                  popper: {
                                    modifiers: [
                                      {
                                        name: 'preventOverflow',
                                        options: {
                                          boundary: 'window',
                                        },
                                      },
                                    ],
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      '& .MuiPaper-root': {
                                        maxHeight: '200px',
                                        minHeight: '100px',
                                        minWidth: '120px', // Reduce dropdown width
                                        maxWidth: '150px',
                                      },
                                      '& .MuiMenuItem-root': {
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                        padding: '3px 12px',
                                      },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            {startDateErrorText && (
                              <FormHelperText id="input-error-text">
                                {startDateErrorText}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Box>
                          <FormControl
                            sx={{ minWidth: '100%' }}
                            size="small"
                            error={endDateErrorText}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                label="End Date"
                                value={endDate}
                                InputLabelProps={textFieldLabelStyles}
                                onChange={handleEndDateChange}
                                shouldDisableDate={(date) => {
                                  const day = date.day(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                                  const today = dayjs().startOf('day'); // Get today's date (without time)
                                  return (day !== 0 && day !== 3) || date.isAfter(today);
                                }}
                                slotProps={{
                                  textField: {
                                    sx: {
                                      '& .MuiInputLabel-root': {
                                        fontSize: '12px', // Adjust font size

                                        top: '-10px',
                                      },
                                      '& .MuiInputBase-root': {
                                        height: 25,
                                        minHeight: 25,
                                      },
                                      '& .MuiInputBase-input': {
                                        fontSize: 11,
                                        padding: '2px',
                                      },
                                      '& .MuiSvgIcon-root': {
                                        fontSize: '15px', // Reduce icon size
                                      },
                                    },
                                  },
                                }}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      '& .MuiPaper-root': {
                                        maxHeight: '200px',
                                        minHeight: '100px',
                                        minWidth: '120px', // Reduce dropdown width
                                        maxWidth: '150px',
                                      },
                                      '& .MuiMenuItem-root': {
                                        fontSize: '10px',
                                        marginLeft: '5px',
                                        padding: '3px 12px',
                                      },
                                    },
                                  },
                                }}
                              />
                            </LocalizationProvider>
                            {endDateErrorText && (
                              <FormHelperText id="input-error-text">
                                {endDateErrorText}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} align="left">
                        <Button
                          style={{
                            fontFamily: 'sans-serif',
                            fontSize: '10px',
                            backgroundColor: '#31478F',
                            padding: '4px 9px', // Reduce padding
                            minWidth: '70px', // Reduce width
                            height: '24px', // Reduce height
                            marginLeft: '400px',
                            marginTop: '-4px',
                            whiteSpace: 'nowrap',
                          }}
                          type="submit"
                          variant="contained"
                          disabled={uploading} // Disable when uploading
                          sx={{ mt: 2, mb: 5, borderRadius: '5px' }}
                          onClick={fetchInvoices}
                        >
                          {uploading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            'Fetch Sales Report'
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                    {/* {loading && <Loader />} */}
                  </CardContent>
                  {/* </Card> */}
                </div>
              </div>
            </form>
          </div>

          {invoices.length > 0 ? (
            <div style={{ height: 600, width: '97%' }}>
              <DataGrid
                rows={invoices}
                columns={columns}
                slots={{ toolbar: CustomToolbar }}
                disableSelectionOnClick
                getRowId={(row) => row.invoiceId}
                pageSize={5}
                rowsPerPageOptions={[5]}
                rowHeight={25}
                columnHeaderHeight={31} // Adjust header height (default is usually around 56px)
                autoHeight
                sx={{
                  marginTop: '-45px',
                  marginLeft: '20px',
                  '& .MuiDataGrid-columnHeaders': {
                    fontSize: '10px', // Reduce font size of header text
                    backgroundColor: '#31478F', // Set custom header background color
                    color: 'white', // Set font color to white for better contrast
                    textAlign: 'center',
                  },
                  '& .MuiDataGrid-cell': {
                    fontSize: '10px', // Reduced font size for table cells
                    textAlign: 'center',
                  },

                  '& .even': {
                    backgroundColor: '#F8F8F8', // Light blue color for even rows
                  },
                  '& .odd': {
                    backgroundColor: '#E6F0FF', // Darker blue for odd rows
                  },
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                sortModel={[
                  // { field: "status", sort: "desc" }, // Sort by status first
                  { field: 'invoiceId', sort: 'asc' }, // Then sort by clientId
                ]}
              />
            </div>
          ) : (
            fetchedWithNoRecords && (
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead className="main-table-header">
                    <TableRow>
                      <TableCell />
                      <TableCell className="main-table-header-cell">CLIENT ID</TableCell>
                      <TableCell className="main-table-header-cell">CUSTOMER NAME</TableCell>
                      <TableCell className="main-table-header-cell">FILE NAME</TableCell>
                      <TableCell className="main-table-header-cell">STATUS</TableCell>
                      <TableCell className="main-table-header-cell">CREATED AT</TableCell>
                      <TableCell className="main-table-header-cell">UPDATED AT</TableCell>
                      <TableCell className="main-table-header-cell">DOWNLOAD</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>No Invoice records found</TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )
          )}
        </>
      )}
    </div>
  );
}

export default SalesReport;
