import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Alert,
  AlertTitle,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material'; // Tooltip, Paper, , Chip,
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
} from '@mui/x-data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import { darken, lighten, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
// import Loader from 'components/Loader';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

// import EditIcon from '@mui/icons-material/Edit';
// import DoneIcon from '@mui/icons-material/Done';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useLocation } from 'react-router-dom';

const CustomToolbar = () => {
  const apiRef = useGridApiContext();

  const handleReset = () => {
    apiRef.current.setFilterModel({ items: [] });
    apiRef.current.setSortModel([]);
    apiRef.current.setColumnVisibilityModel({});
    apiRef.current.setDensity('standard');
    // Add any other state resets you need
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Button
        size="small"
        onClick={handleReset}
        startIcon={<RefreshIcon fontSize="medium" />}
        sx={{
          fontSize: '14px',
          textTransform: 'uppercase',
          padding: '4px 8px',
          minWidth: 'auto',
          '& .MuiButton-startIcon': {
            marginRight: '4px',
            '& > *:nth-of-type(1)': {
              fontSize: '20px',
            },
          },
        }}
      >
        Reset
      </Button>
    </GridToolbarContainer>
  );
};

const ManageClients = ({ isOnboard = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statusFilter = queryParams.get('status'); // "active" or "inactive"
  const [loggedIn, setLoggedIn] = React.useState(false);

  // Alerts
  const [isClientCreated, setIsClientCreated] = React.useState(false);
  const [users, setUsers] = useState([]);
  const [admins, setAdmins] = useState([]);

  const [isClientUpdated, setIsClientUpdated] = React.useState(false);

  //const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin'
  //const [weeklyVolume, setWeeklyVolume] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const buttonsFontSize = '16px';

  const toUpperCase = (text) => text.toUpperCase();
  const customerDetails = [
    {
      clientId: 101,
      customerId: 'DGCQ4',
      clientName: 'Royal Truck',
    },
    {
      clientId: 102,
      customerId: 'DGCQ5',
      clientName: 'Triple Gold',
    },
    {
      clientId: 103,
      customerId: 'DGCQ6',
      clientName: 'Ramos',
    },
    {
      clientId: 104,
      customerId: 'DGCQ7',
      clientName: 'Can-EX',
    },
    {
      clientId: 105,
      customerId: 'DGCQ8',
      clientName: 'GT',
    },
    {
      clientId: 106,
      customerId: 'DGCQ9',
      clientName: 'Nildiya',
    },
    {
      clientId: 107,
      customerId: 'DGCRA',
      clientName: '',
    },
    {
      clientId: 108,
      customerId: 'DGCRB',
      clientName: '',
    },
    {
      clientId: 109,
      customerId: 'DGCRC',
      clientName: '',
    },
    {
      clientId: 110,
      customerId: 'DGCRD',
      clientName: '',
    },
    {
      clientId: 111,
      customerId: 'DGCRE',
      clientName: '',
    },
    {
      clientId: 112,
      customerId: 'DGCRF',
      clientName: '',
    },
    {
      clientId: 113,
      customerId: 'DGCRG',
      clientName: '',
    },
    {
      clientId: 114,
      customerId: 'DGCRH',
      clientName: '',
    },
    {
      clientId: 115,
      customerId: 'DGCRI',
      clientName: '',
    },
    {
      clientId: 116,
      customerId: 'DGCRJ',
      clientName: '',
    },
    {
      clientId: 117,
      customerId: 'DGCRK',
      clientName: '',
    },
    {
      clientId: 118,
      customerId: 'DGCRL',
      clientName: '',
    },
    {
      clientId: 119,
      customerId: 'DGCRM',
      clientName: '',
    },
    {
      clientId: 120,
      customerId: 'DGCRN',
      clientName: '',
    },
    {
      clientId: 121,
      customerId: 'DGCRO',
      clientName: '',
    },
    {
      clientId: 122,
      customerId: 'DGCRP',
      clientName: '',
    },
    {
      clientId: 123,
      customerId: 'DGCRQ',
      clientName: '',
    },
    {
      clientId: 124,
      customerId: 'DGCRR',
      clientName: '',
    },
    {
      clientId: 125,
      customerId: 'DGCRS',
      clientName: '',
    },
  ];

  useEffect(() => {
    // Check if the user is logged in
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`); // Redirect to home page
    } else {
      setLoggedIn(true);
      generateListViewDetails(); // Assuming this is your custom function
    }

    // Simulate fetching the country value from DB, API, or other sources
    const fetchedCountry = 'CA'; // Example: Retrieved 'CA' for Canada from DB or props
    setCountry(fetchedCountry); // Set the country state

    console.log('Country fetched and set:', fetchedCountry); // Debug log
  }, [navigate]); // Empty dependency array ensures this runs once when the component mounts
  // Create
  const [clientID, setClientID] = React.useState('');
  // const [clientIdError, setClientIdError] = React.useState(false);
  // const [clientIdErrorMessage, setClientIdErrorMessage] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');
  const [clientName, setClientName] = React.useState('');

  const [pointOfContact, setPointOfContact] = React.useState('');
  const [secondaryPointOfContact, setSecondaryPointOfContact] = React.useState('');
  const [primaryEmail, setPrimaryEmail] = React.useState('');
  const [primaryContactNumber, setPrimaryContactNumber] = React.useState('');
  const [secondaryEmail, setSecondaryEmail] = React.useState('');
  const [secondaryContactNumber, setSecondaryContactNumber] = React.useState('');
  const [unitNumber, setUnitNumber] = React.useState('');
  const [streetNumber, setStreetNumber] = React.useState('');
  const [streetName, setStreetName] = React.useState('');
  const [city, setCity] = React.useState('');
  const [province, setProvince] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [xreferenceID, setxreferenceID] = React.useState('');
  const [subfleetID, setSubfleetID] = React.useState('');
  const [profitMargin, setProfitMargin] = React.useState('');
  const [clientReference, setClientReference] = React.useState('');
  const [discount, setDiscount] = React.useState('');
  const [invoiceCycle, setInvoiceCycle] = React.useState('');
  const [formData, setFormData] = useState({
    clientID: '',
    clientName: '',
    clientStatus: 'Inactive',
    pointOfContact: '',
    secondaryPointOfContact: '',
    primaryEmail: '',
    primaryContactNumber: '',
    secondaryEmail: '',
    secondaryContactNumber: '',
    unitNumber: '',
    streetNumber: '',
    streetName: '',
    city: '',
    province: '',
    postalCode: '',
    country: '',
    currency: 'Both',
    xreferenceID: '',
    subfleetID: '',
    profitMargin: '',
    clientReference: '',
    discount: '',
    username: '',
    password: '',
    role: 'Admin',
    userEmail: '',
    userStatus: 'Inactive',
    invoiceCycle: '', // Added for invoice cycle
  });

  const [primaryContactError, setPrimaryContactError] = useState('');
  const [secondaryContactError, setSecondaryContactError] = useState('');

  const [setEmailError] = useState('');

  const [errors, setErrors] = useState({
    invoiceCycle: '', // To track errors for the invoice cycle field
  });

  // const [addressSuggestions, setAddressSuggestions] = useState([]);

  const [primaryEmailError, setPrimaryEmailError] = useState('');
  const [secondaryEmailError, setSecondaryEmailError] = useState('');

  const [primaryContactNumberError, setPrimaryContactNumberError] = useState('');
  const [secondaryContactNumberError, setSecondaryContactNumberError] = useState('');

  // Client List View
  const [clientListView, setClientListView] = React.useState([]);

  const [clients, setClients] = React.useState([]);

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [isAddCardSelected, setAddCardSelected] = React.useState(isOnboard);
  const [isUpdateCardSelected, setUpdateCardSelected] = React.useState(false);
  const defaultButtonBackground = '#011f4b';
  const chosenButtonBackground = '#03396c';
  const [clientStatus, setClientStatus] = useState('Active'); // Default to 'Active' or another appropriate value

  const clientStatuses = [
    { id: 'Active', value: 'Active' },
    { id: 'Inactive', value: 'Inactive' },
  ];

  const clientListViewColumns = [
    {
      field: 'customerName',
      headerName: 'CUST NAME',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'accountNumber',
      headerName: 'ACCT NUMBER',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'customerId',
      headerName: 'CUST ID',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'customerStatus',
      headerName: 'CUST STATUS',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === 'ACTIVE' ? 'green' : 'red',
            fontWeight: 'bold',
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'activeDiscount',
      headerName: 'USD DISCOUNT',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'activeProfitMargin',
      headerName: 'CAD MARGIN',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'volumesPerWeek',
      headerName: 'VOLUMES/WEEK',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'volumesPerMonth',
      headerName: 'VOLUMES/MONTH',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'yearToDateVolume',
      headerName: 'VOLUME/YEAR',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'profitWeekly',
      headerName: 'WEEKLY PROFIT',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'profitMonthly',
      headerName: 'MONTHLY PROFIT',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
      width: 150,
    },
    {
      field: 'profitYear',
      headerName: 'YEARLY PROFIT',
      flex: 0.8,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'super-app-theme--header',
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {/* Delete Icon */}
            <DeleteIcon
              style={{ cursor: 'pointer', fontSize: '16px' }}
              onClick={() => handleDeleteClient(params.row.accountNumber)} // Call the delete function with the client ID
              color=""
              grey
            />
          </div>
        );
      },
    },
  ];

  const NoRecordsFoundRow = [
    {
      id: '',
      customerName: '',
      accountNumber: '',
      customerId: '',
      customerStatus: '',
      activeDiscount: 'No records found',
      activeProfitMargin: '',
      volumesPerWeek: '',
      volumesPerMonth: '',
      yearToDateVolume: '',
      profitWeekly: '',
      profitMonthly: '',
      profitYear: '',
    },
  ];

  // const addNewClientDiscount = () => {
  //   let updateDiscountUrl = `${BACKEND_URL}/v1/update-discount?clientID=${clientID}&customerID=${customerId}&discount=0`;
  //   fetch(updateDiscountUrl, {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }).then((response) => response.json());
  //   // .then(data => {
  //   //     //console.log(data);
  //   // });
  // };

  const [currency, setCurrency] = useState(''); // To track selected currency

  const handleAddCustomerSelection = (event) => {
    event.preventDefault();
    setLoading(true);
    generateListViewDetails();
    let newClientId = 100;
    setTimeout(() => {
      setAddCardSelected(true);
      setUpdateCardSelected(false);
      if (clients.length > 0) {
        clients.sort((client1, client2) => +client1.clientID - +client2.clientID);
        newClientId = +clients[clients.length - 1].clientID;
      }
      newClientId = typeof newClientId === 'number' ? newClientId + 1 : '';
      resetForm(newClientId);
      let customerInfoDetails = customerDetails.filter((detail) => detail.clientId == newClientId);
      if (customerInfoDetails && customerInfoDetails[0]) {
        setCustomerId(customerInfoDetails[0].customerId);
        setClientName(customerInfoDetails[0].clientName);
      }
      setLoading(false);
    }, 2000);
  };

  // Handle client deletion request
  const handleDeleteClient = (clientID) => {
    // Log the action of initiating client deletion
    console.log(`Attempting to delete client: ${clientID}`);

    // Show confirmation dialog before proceeding with the delete
    const confirmation = window.confirm(
      `Are you sure you want to delete the client with ID: ${clientID}?`,
    );

    if (confirmation) {
      // Log that the deletion was confirmed by the user
      console.log(`Client ${clientID} confirmed for deletion.`);

      // Proceed with sending the approval email for client deletion
      sendApprovalEmailForClient(clientID);
    } else {
      // Log that the deletion was canceled by the user
      console.log(`Client ${clientID} deletion canceled.`);
    }
  };

  // Function to send approval email for client deletion
  const sendApprovalEmailForClient = (clientID) => {
    // Show loading or message indicating the request is being sent
    console.log('Sending approval email for client deletion...');

    // Make the POST request to send the approval email
    fetch(`${BACKEND_URL}/v1/send-client-deletion-approval-email?clientId=${clientID}`, {
      method: 'POST',
    })
      .then((response) => {
        if (response.ok) {
          console.log(`Approval email sent for client: ${clientID}`);
          // Optionally show confirmation to the user
          alert('Approval email sent. Please check your inbox for further instructions.');
        } else {
          throw new Error('Failed to send approval email');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an issue sending the approval email.');
      });
  };

  // // Function to delete client after approval
  // const deleteClient = (clientID) => {
  //     // Log the attempt to send the DELETE request for client deletion
  //     console.log(`Sending DELETE request for client: ${clientID}`);

  //     // Call the delete API with the provided clientId
  //     fetch(`${BACKEND_URL}/v1/client?clientId=${clientID}`, {
  //         method: 'DELETE',
  //     })
  //     .then((response) => {
  //         if (response.ok) {
  //             // Log successful client deletion
  //             console.log(`Client ${clientID} deleted successfully.`);
  //             alert(`Client ${clientID} deleted successfully.`);
  //             // Optionally, update the UI, like removing the client from a list
  //         } else {
  //             // Log failure to delete
  //             console.error(`Failed to delete client ${clientID}. Response: ${response.status}`);
  //             alert('Failed to delete the client.');
  //         }
  //     })
  //     .catch((error) => {
  //         // Log error in case of network failure or any other issue
  //         console.error('Error deleting client:', error);
  //         alert('An error occurred while deleting the client.');
  //     });
  // };

  const resetForm = (newClientId) => {
    setClientID(newClientId);
    setCustomerId('');
    setClientName('');
    setClientStatus('Inactive');
    setPointOfContact('');
    setSecondaryPointOfContact('');
    setPrimaryEmail('');
    setPrimaryContactNumber('');
    setSecondaryEmail('');
    setSecondaryContactNumber('');
    setxreferenceID('');
    setUnitNumber('');
    setStreetNumber('');
    setStreetName('');
    setCity('');
    setProvince('');
    setCountry('');
    setPostalCode('');
    setCurrency('');
    setSubfleetID('');
    setProfitMargin('');
    setDiscount('');
    setInvoiceCycle('');
  };

  const handleUpdateCustomerSelection = (event) => {
    event.preventDefault();
    setLoading(true);
    // generateListViewDetails();
    setTimeout(() => {
      setAddCardSelected(false);
      setUpdateCardSelected(true);
      resetForm('');
      setLoading(false);
    }, 2000);
  };

  const generateListViewDetails = async () => {
    setLoading(true);
    let clients = await getClients();

    // Apply status filter if present
    if (statusFilter) {
      clients = clients.filter((client) =>
        statusFilter === 'active' ? client.clientStatus : !client.clientStatus,
      );
    }
    // Exclude client with clientId 100
    clients = clients.filter((client) => client.clientID !== '100');

    let customerListViewDetails = await getDiscountDetailsForClients(clients);

    setClientListView(customerListViewDetails);
    setLoading(false);
  };

  useEffect(() => {
    if (!localStorage.getItem('isLoggedIn')) {
      setLoggedIn(false);
      navigate(`/`);
    } else {
      setLoggedIn(true);
      generateListViewDetails();
    }
  }, [navigate, statusFilter]); // Add statusFilter to dependencies

  const getDiscountDetailsForClients = async (inputClients) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // 1-12
    const currentYear = currentDate.getFullYear();
    const currentWeek = Math.ceil(currentDate.getDate() / 7); // Rough week number (1-5)

    return Promise.all(
      inputClients.map(async (client, index) => {
        try {
          const [weeklyResp, monthlyResp, yearlyResp] = await Promise.all([
            fetch(
              `${BACKEND_URL}/v1/${client.clientID}/quantity?type=weekly&week=${currentWeek}&year=${currentYear}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  // Add Authorization header if required, e.g., "Bearer " + token
                },
              },
            ),
            fetch(
              `${BACKEND_URL}/v1/${client.clientID}/quantity?type=monthly&month=${currentMonth}&year=${currentYear}`,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            ),
            fetch(`${BACKEND_URL}/v1/${client.clientID}/quantity?type=total`, {
              headers: {
                'Content-Type': 'application/json',
              },
            }),
          ]);

          if (!weeklyResp.ok || !monthlyResp.ok || !yearlyResp.ok) {
            throw new Error(`Failed to fetch volumes for client ${client.clientID}`);
          }

          const weeklyVolume = await weeklyResp.json();
          const monthlyVolume = await monthlyResp.json();
          const yearlyVolume = await yearlyResp.json();

          // console.log(`Client ${clientID} - Raw API Responses:`, {
          //   weekly: weeklyVolume,
          //   monthly: monthlyVolume,
          //   yearly: yearlyVolume,
          // });

          return {
            id: index + 1,
            customerName: toUpperCase(client.clientName),
            accountNumber: client.clientID,
            customerId: client.customerID,
            customerStatus: client.clientStatus ? 'ACTIVE' : 'INACTIVE',
            activeDiscount: client.discount != null ? `${client.discount}%` : 'N/A',
            activeProfitMargin: client.profitMargin != null ? `${client.profitMargin}` : 'N/A',
            volumesPerWeek: Number(weeklyVolume || 0).toFixed(3), // Weekly volume, 3 decimal places
            volumesPerMonth: Number(weeklyVolume || 0 * 4).toFixed(3), // Monthly = Weekly * 4 weeks (example)
            yearToDateVolume: Number(weeklyVolume || 0 * 52).toFixed(3), // Yearly = Weekly * 52 weeks (example)
            profitWeekly: 0,
            profitMonthly: 0,
            profitYear: 0,
          };
        } catch (error) {
          console.error(`Error processing client ${client.clientID}:`, error);
          return {
            id: index + 1,
            customerName: toUpperCase(client.clientName),
            accountNumber: client.clientID,
            customerId: client.customerID,
            customerStatus: client.clientStatus ? 'ACTIVE' : 'INACTIVE',
            activeDiscount: client.discount != null ? `${client.discount}%` : 'N/A',
            activeProfitMargin: client.profitMargin != null ? `${client.profitMargin}` : 'N/A',
            volumesPerWeek: 0,
            volumesPerMonth: 0,
            yearToDateVolume: 0,
            profitWeekly: 0,
            profitMonthly: 0,
            profitYear: 0,
          };
        }
      }),
    );
  };
  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);
    return data;
  };

  const handleClientNameChange = (event) => {
    setClientName(event.target.value);
  };

  // const handlePointOfContactChange = (e) => {
  //   const newValue = e.target.value;

  //   // Check if the value contains numbers
  //   if (/\d/.test(newValue)) {
  //     setPrimaryContactError('Point of Contact cannot contain numbers.');
  //   }
  //   // Check if the new value is different from the secondary contact
  //   else if (newValue !== formData.secondaryPointOfContact) {
  //     setPrimaryContactError(''); // Clear error if valid
  //     handleInputChange(e); // Update the formData if valid
  //   }
  //   // If primary contact is the same as secondary contact, set an error
  //   else {
  //     setPrimaryContactError(
  //       'Point of Contact and Secondary Point of Contact should be different.',
  //     );
  //   }
  // };

  // const handleSecondaryPointOfContactChange = (e) => {
  //   const newValue = e.target.value;

  //   // Check if the value contains numbers
  //   if (/\d/.test(newValue)) {
  //     setSecondaryContactError('Secondary Point of Contact cannot contain numbers.');
  //   }
  //   // Check if the new value is different from the primary contact
  //   else if (newValue !== formData.pointOfContact) {
  //     setSecondaryContactError(''); // Clear error if valid
  //     handleInputChange(e); // Update the formData if valid
  //   }
  //   // If secondary contact is the same as primary contact, set an error
  //   else {
  //     setSecondaryContactError(
  //       'Point of Contact and Secondary Point of Contact should be different.',
  //     );
  //   }
  // };

  // Function to handle generic input change

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update state for postal code if the field is changed
    if (name === 'postalCode') {
      setPostalCode(value);
    }

    if (name === 'invoiceCycle') {
      setInvoiceCycle(value);
    }

    // Handle Email validation (if applicable)
    if (name === 'email') {
      if (validateEmail(value)) {
        setEmailError(''); // Clear error if valid
      } else {
        setEmailError('Please enter a valid email address with a proper domain.');
      }
    }

    switch (name) {
      case 'pointOfContact':
        setPointOfContact(value);
        break;
      case 'secondaryPointOfContact':
        setSecondaryPointOfContact(value);
        break;
      case 'primaryEmail':
        setPrimaryEmail(value);
        break;
      case 'secondaryEmail':
        setSecondaryEmail(value);
        break;
      case 'primaryContactNumber':
        setPrimaryContactNumber(value);
        break;
      case 'secondaryContactNumber':
        setSecondaryContactNumber(value);
        break;
      default:
        break;
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Email validation function
  const validateEmail = (email) => {
    // Regular expression to check for a valid email format with domain (must have '@' and a valid domain)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  };

  // const validateContactNumber = (number) => {
  //   // Regular expression to validate a number starting with +1 followed by exactly 10 digits
  //   const phoneRegex = /^\+1\d{10}$/;
  //   return phoneRegex.test(number);
  // };

  const handlePrimaryContactChange = (e) => {
    const newValue = e.target.value;
    handleInputChange(e); // Update form data

    // Validate primary contact number (should start with +1 and be followed by 10 digits)
    if (newValue !== formData.secondaryContactNumber) {
      if (validateContactNumber(newValue)) {
        setPrimaryContactNumberError(''); // Clear error if valid
      } else {
        setPrimaryContactNumberError(
          'Primary Contact Number must be a valid 10-digit number with country code (+1).',
        );
      }
    } else {
      setPrimaryContactNumberError('Primary and Secondary Contact Numbers must be different.');
    }
  };

  const handleSecondaryContactChange = (e) => {
    const newValue = e.target.value;

    // Update form data via generic input change handler
    handleInputChange(e);

    // Validate secondary contact number (should start with +1 and be followed by 10 digits)
    if (newValue !== formData.primaryContactNumber) {
      if (validateContactNumber(newValue)) {
        setSecondaryContactNumberError(''); // Clear error if valid
      } else {
        setSecondaryContactNumberError(
          'Secondary Contact Number must be a valid 10-digit number with country code (+1).',
        );
      }
    } else {
      setSecondaryContactNumberError('Primary and Secondary Contact Numbers must be different.');
    }
  };

  // Function to validate if the contact number is a valid 10-digit number with country code +1
  const validateContactNumber = (number) => {
    const regex = /^\+1\d{10}$/; // Regular expression for +1 followed by 10 digits
    return regex.test(number);
  };

  // const handleCustomerIdChange = (event) => {
  //   setCustomerId(event.target.value);
  // };

  // const handleCreateClient = (event) => {
  //   event.preventDefault();
  //   setLoading(true);
  //   if (isOnboard) {
  //     let selectedStatus = clientStatuses.filter((status) => status.value === clientStatus);
  //     const createClientObject = {
  //       clientID: clientID,
  //       customerID: customerId,
  //       clientStatus: selectedStatus[0].value === 'Inactive',
  //       clientName: clientName,
  //       pointOfContact: pointOfContact,
  //       secondaryPointOfContact: secondaryPointOfContact,
  //       primaryEmail: primaryEmail,
  //       primaryContactNumber: primaryContactNumber,
  //       secondaryEmail: secondaryEmail,
  //       secondaryContactNumber: secondaryContactNumber,
  //       address: `${setUnitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
  //       postalCode: postalCode,
  //     };

  //     setTimeout(() => {
  //       onSuccess(createClientObject);
  //     }, 1000);
  //   } else {
  //     setTimeout(async () => {
  //       let selectedStatus = clientStatuses.filter((status) => status.value === clientStatus);
  //       const createClientObject = {
  //         clientID: clientID,
  //         customerID: customerId,
  //         clientStatus: selectedStatus[0].value === 'Inactive',
  //         clientName: clientName,
  //         pointOfContact: pointOfContact,
  //         secondaryPointOfContact: secondaryPointOfContact,
  //         primaryEmail: primaryEmail,
  //         primaryContactNumber: primaryContactNumber,
  //         secondaryEmail: secondaryEmail,
  //         secondaryContactNumber: secondaryContactNumber,
  //         address: `${unitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
  //         postalCode: postalCode,
  //       };

  //       await fetch(`${BACKEND_URL}/v1/client`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(createClientObject),
  //       })
  //         .then((response) => response.json())
  //         .then(() => {
  //           generateListViewDetails();
  //           setIsClientCreated(true);
  //           addNewClientDiscount();
  //         });
  //       resetForm('');
  //       setLoading(false);
  //     }, 2000);
  //   }
  // };

  const handleUpdateClientSelectionChange = (event) => {
    setLoading(true);

    setTimeout(() => {
      // Reset form data before loading new client details

      // Find the client by matching the clientID
      let chosenClientForUpdate = clients.filter(
        (client) => client.clientID === event.target.value,
      );

      if (chosenClientForUpdate && chosenClientForUpdate.length > 0) {
        const selectedCustomer = chosenClientForUpdate[0]; // Extract the first matched client
        console.log('selectedCustomer', selectedCustomer);

        // Set basic customer details
        setClientID(selectedCustomer.clientID);
        setCustomerId(selectedCustomer.customerID);
        setClientName(selectedCustomer.clientName);
        setClientStatus(selectedCustomer.clientStatus === true ? 'Active' : 'Inactive');
        setPointOfContact(selectedCustomer.pointOfContact);
        setSecondaryPointOfContact(selectedCustomer.secondaryPointOfContact);
        setPrimaryEmail(selectedCustomer.primaryEmail);
        setSecondaryEmail(selectedCustomer.secondaryEmail);
        setPrimaryContactNumber(selectedCustomer.primaryContactNumber);
        setSecondaryContactNumber(selectedCustomer.secondaryContactNumber);
        setInvoiceCycle(selectedCustomer.invoiceCycle);
        // Set the currency (parse correctly)
        const currency = selectedCustomer.currency || '';
        if (currency.includes('CAD') && currency.includes('USD')) {
          setCurrency('Both');
        } else if (currency.includes('CAD')) {
          setCurrency('Canada');
        } else if (currency.includes('USD')) {
          setCurrency('United States');
        } else {
          setCurrency(''); // Handle case when no valid currency is found
        }

        // Set additional customer details (e.g., subfleet, profit margin, etc.)
        setxreferenceID(selectedCustomer.xreferenceID || ''); // Handle null values
        setSubfleetID(selectedCustomer.subfleetID);
        setProfitMargin(selectedCustomer.profitMargin);
        setClientReference(selectedCustomer.clientReference || ''); // Handle null values
        setDiscount(selectedCustomer.discount);

        // Parse address if it's available and has space-separated parts
        let incomingAddress = selectedCustomer.address;
        if (incomingAddress) {
          let splitAddress = incomingAddress.split(','); // Split by commas

          // Assuming fixed order: [unit number, street number, street name, city, province, country, postal code]
          setUnitNumber(splitAddress[0]?.trim() || ''); // Unit number
          setStreetNumber(splitAddress[1]?.trim() || ''); // Street number
          setStreetName(splitAddress[2]?.trim() || ''); // Street name
          setCity(splitAddress[3]?.trim() || ''); // City
          setProvince(splitAddress[4]?.trim() || ''); // Province
          setCountry(splitAddress[5]?.trim() || ''); // Country
          setPostalCode(splitAddress[6]?.trim() || ''); // Postal code
        }

        setLoading(false);
      } else {
        // Handle case when no client is found
        console.error('Client not found for the given ID');
        setLoading(false);
      }
    }, 1000); // Timeout for async simulation
  };

  // Handle country change
  // Handle country change
  //  const handleCountryChange = (event) => {
  //   setCountry(event.target.value);  // Update country state when the user selects a value
  // };

  // Handle Currency Change
  const handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    setCurrency(selectedCurrency);

    // Set values to 0 based on the selected currency
    if (selectedCurrency === 'Canada') {
      setProfitMargin('0');
      setxreferenceID('');
      setSubfleetID('');
    } else if (selectedCurrency === 'United States') {
      setProfitMargin('');
      setxreferenceID('0');
      setSubfleetID('0');
    } else {
      setProfitMargin('');
      setxreferenceID('');
      setSubfleetID('');
    }
  };

  // Handle postal code change
  // Handling postal code change
  const handlePostalCodeChange = (event) => {
    const value = event.target.value;

    // Check the selected country
    if (country === 'Canada') {
      // Allow alphanumeric characters for Canada
      if (/^[a-zA-Z0-9\s]*$/.test(value)) {
        setPostalCode(value);
      }
    } else if (country === 'United States') {
      // Only allow numeric characters for US
      if (/^\d*$/.test(value)) {
        setPostalCode(value);
      }
    } else {
      // Allow general case (adjust as needed)
      setPostalCode(value);
    }
  };

  const handleClientStatusChange = (event) => {
    // let selectedStatus = clientStatuses.filter((status) => status.value === event.target.value);
    // setClientStatus(selectedStatus[0].value);
    let clientStatus = event.target.value;
    setClientStatus(clientStatus);
  };

  const setErrorMessages = (messages) => {
    setErrors(messages); // Use setErrors to update the error state

    // Display an alert for each error
    Object.keys(messages).forEach((field) => {
      alert(`Need to fix the error message for ${field}: ${messages[field]}`);
    });
  };

  const handleUpdateClient = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMessages({}); // Clear previous errors

    // Validate form inputs
    const errors = validateClientForm();

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      setLoading(false);
      return;
    }

    console.log('Updating client status to:', clientStatus);

    // Prepare the updated client object
    const updateClientObject = {
      clientID: clientID,
      customerID: customerId,
      clientStatus: clientStatus === 'Active', // true for "Active", false for "Inactive"
      clientName: clientName,
      pointOfContact: pointOfContact,
      secondaryPointOfContact: secondaryPointOfContact,
      primaryEmail: primaryEmail,
      primaryContactNumber: primaryContactNumber,
      secondaryEmail: secondaryEmail,
      secondaryContactNumber: secondaryContactNumber,
      address: `${unitNumber},${streetNumber},${streetName},${city},${province},${country},${postalCode}`,
      currency:
        formData.currency === 'Both' ? 'CAD, USD' : formData.currency === 'Canada' ? 'CAD' : 'USD',
      postalCode: postalCode,
      xreferenceID: xreferenceID,
      subfleetID: subfleetID,
      profitMargin: profitMargin,
      clientReference: clientReference,
      discount: discount,
      invoiceCycle: invoiceCycle,
    };

    try {
      // Update client data via PUT request
      const response = await fetch(`${BACKEND_URL}/v1/client`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateClientObject),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update client: ${errorText}`);
      }

      console.log('Client successfully updated for clientID:', clientID);

      // Fetch the updated client status
      const getStatusResponse = await fetch(
        `${BACKEND_URL}/v1/client/status?clientID=${clientID}`,
        {
          method: 'GET',
        },
      );

      if (!getStatusResponse.ok) {
        const errorText = await getStatusResponse.text();
        throw new Error(`Failed to fetch client status: ${errorText}`);
      }

      const statusMessage = await getStatusResponse.text();
      console.log('Client status response:', statusMessage);

      // Update local state with the new client status
      setClientStatus(statusMessage.includes('is Active') ? 'Active' : 'Inactive');

      // Fetch updated users and admins to reflect their statuses in the UI
      const [usersResponse, adminsResponse] = await Promise.all([
        fetch(`${BACKEND_URL}/v1/usersbyid?clientID=${clientID}`, { method: 'GET' }),
        fetch(`${BACKEND_URL}/v1/adminusersbyid?clientID=${clientID}`, { method: 'GET' }),
      ]);

      if (!usersResponse.ok || !adminsResponse.ok) {
        throw new Error('Failed to fetch users or admins');
      }

      const users = await usersResponse.json();
      const admins = await adminsResponse.json();

      console.log('Updated users:', users);
      console.log('Updated admins:', admins);

      // Optionally update local state with users and admins (if your UI displays them)
      setUsers(users); // Assuming you have a setUsers state function
      setAdmins(admins); // Assuming you have a setAdmins state function

      // Fetch updated client data
      await getClients(); // Ensure this updates the UI with the latest data

      // Reset form and update UI
      resetForm('');
      setLoading(false);
      setIsClientUpdated(true);

      // Provide user feedback
      alert(`${statusMessage}\nUsers affected: ${users.length}, Admins affected: ${admins.length}`);

      // Reload the page after 5 seconds
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } catch (error) {
      console.error('Error updating client:', error);
      setErrorMessages({ general: error.message });
      setLoading(false);
    }
  };

  // Client form validation function
  const validateClientForm = () => {
    const errors = {};
    if (!clientName) {
      errors.clientName = 'Client name is required';
    }
    if (!pointOfContact) {
      errors.pointOfContact = 'Point of contact is required';
    }
    if (!primaryEmail || !/\S+@\S+\.\S+/.test(primaryEmail)) {
      errors.primaryEmail = 'Valid primary email is required';
    }
    if (!primaryContactNumber) {
      errors.primaryContactNumber = 'Primary contact number is required';
    }
    if (!secondaryEmail || !/\S+@\S+\.\S+/.test(secondaryEmail)) {
      errors.secondaryEmail = 'Valid secondary email is required';
    }
    if (!secondaryContactNumber) {
      errors.secondaryContactNumber = 'Secondary contact number is required';
    }
    if (!streetNumber) {
      errors.streetNumber = 'Street number is required';
    }
    if (!streetName) {
      errors.streetName = 'Street name is required';
    }
    if (!city) {
      errors.city = 'City is required';
    }
    if (!province) {
      errors.province = 'Province is required';
    }
    if (!country) {
      errors.country = 'Country is required';
    }
    if (!postalCode) {
      errors.postalCode = 'Postal code is required';
    }

    if (!xreferenceID) {
      errors.xreferenceID = 'XReference ID is required';
    }
    if (!subfleetID) {
      errors.subfleetID = 'Subfleet ID is required';
    }

    if (!invoiceCycle) {
      errors.invoiceCycle = 'Invoice Cycle is required';
    }
    // Add any other validation checks as needed

    return errors;
  };

  // // Helper function to format field names for better display
  // const formatFieldName = (field) => {
  //   return field.replace(/^ +| +$/g, '')  // Remove extra spaces
  //     .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
  //     .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  // };

  // const handleClientIDChange = (event) => {
  //   let clientId = event.target.value;
  //   setClientID(clientId);
  //   let existingClient = clients.filter((client) => client.clientID == clientId);
  //   if (existingClient && existingClient.length > 0) {
  //     setClientIdError(true);
  //     setClientIdErrorMessage(`Client ID ${clientId} already exists!`);
  //     return;
  //   } else {
  //     setClientIdError(false);
  //     setClientIdErrorMessage('');
  //   }
  //   let customerInfoDetails = customerDetails.filter((detail) => detail.clientId == clientId);
  //   if (customerInfoDetails && customerInfoDetails[0]) {
  //     setCustomerId(customerInfoDetails[0].customerId);
  //     setClientName(customerInfoDetails[0].clientName);
  //   } else {
  //     setCustomerId('');
  //     setClientName('');
  //   }

  //   // Reset the form fields (optional)
  //   resetForm('');
  // };

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-cell:hover': {
      cursor: 'pointer',
    },
    '& .even': {
      backgroundColor: 'rgb(248, 248, 248)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(248, 248, 248)', theme.palette.mode),
      },
    },
    '& .odd': {
      backgroundColor: 'rgb(180, 200, 220)',
      '&:hover': {
        backgroundColor: getHoverBackgroundColor('rgb(180, 200, 220)', theme.palette.mode),
      },
    },
    '& .MuiDataGrid-row': {
      height: '16px', // Set row height to 16px (1rem)
    },
    '& .MuiDataGrid-cell': {
      lineHeight: '16px', // Ensure text aligns properly within the row
    },
  }));

  // const fontStylesRootTypography = { fontSize: '22px', textAlign: 'left', fontWeight: '800', fontFamily: 'DM Sans, sans-serif' };
  const fontStylesChartTypography = {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };
  // const cardHeight = 650;
  const inputLabelStyles = {
    fontSize: '12px',
    color: 'rgb(26, 33, 56)',
    fontWeight: 600,
  };
  const textFieldLabelStyles = {
    style: {
      fontSize: '12px',
      color: 'rgb(26, 33, 56)',
      fontFamily: 'DM Sans, sans-serif',
      // width: 300,
      fontWeight: 600,
    },
  };
  const gridRowStyles = { marginTop: '0', marginBottom: '15px' };

  const gridRowClicked = (event) => {
    let rowDetails = event?.row;
    navigate(`/customers/${rowDetails.accountNumber}`, {
      state: {
        chosenClient: rowDetails,
      },
    });
    // setCurrentRowDetails(rowDetails)
    // setIsDetailedViewOpen(!isDetailedViewOpen)
  };

  // Handle select change for Invoice Cycle
  const handleInvoiceCycleChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      invoiceCycle: value,
    });

    setInvoiceCycle(value);

    // Validate the invoice cycle (optional: add other custom validations if needed)
    if (value === '') {
      setErrors({
        ...errors,
        invoiceCycle: 'Invoice Cycle is required',
      });
    } else {
      setErrors({
        ...errors,
        invoiceCycle: '', // Clear error if valid selection
      });
    }
  };

  return (
    loggedIn && (
      <div>
        {isClientCreated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsClientCreated(false)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '16px',
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '16px',
            }}
          >
            <AlertTitle>Success, Customer created successfully</AlertTitle>
          </Alert>
        )}
        {isClientUpdated && (
          <Alert
            severity="success"
            variant="filled"
            size="large"
            onClose={() => setIsClientUpdated(false)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '16px',
            }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '16px',
            }}
          >
            <AlertTitle>Success, Customer updated successfully.</AlertTitle>
          </Alert>
        )}

        <div
          className="container"
          style={{ display: 'flex', marginLeft: '10px', marginTop: '20px' }}
        >
          <div className="card" style={{ flex: '1' }}>
            {/* <Card sx={{ minWidth: '100%', minHeight: cardHeight }}>
              <CardContent> */}
            <Grid container spacing={2}>
              <Grid item xs={9} sm={9} md={9} lg={9} style={gridRowStyles} />
              <Grid item xs={3} sm={3} md={3} lg={3} style={gridRowStyles}>
                {isOnboard && (
                  <Button
                    style={{
                      marginTop: '0',
                      fontFamily: 'DM Sans',
                      fontSize: buttonsFontSize,
                      backgroundColor: isAddCardSelected
                        ? chosenButtonBackground
                        : defaultButtonBackground,
                    }}
                    type="submit"
                    variant="contained"
                    sx={{ borderRadius: 2 }}
                    onClick={handleAddCustomerSelection}
                  >
                    <Typography>New Customer</Typography>
                    <AddIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
            {/* {loading && <Loader />} */}

            {isUpdateCardSelected && (
              <form id="get-cards" onSubmit={handleUpdateClient}>
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <InputLabel id="clientID" sx={{ fontSize: '11px' }}>
                            Client ID
                          </InputLabel>
                          <Select
                            labelId="clientID"
                            id="clientID"
                            value={clientID}
                            label="Client ID"
                            onChange={handleUpdateClientSelectionChange}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 22,
                                minHeight: 22,
                              }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '2px',
                              }, // Reduce font size & padding
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  fontSize: '10px', // Reduce font size of dropdown items
                                  maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                                },
                              },
                            }}
                          >
                            {clients.length > 0 &&
                              clients.map((client) => (
                                <MenuItem
                                  key={client.clientID}
                                  value={client.clientID}
                                  sx={{ fontSize: '10px' }}
                                >
                                  {client.clientID} - {client.customerID} - {client.clientName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="customerId"
                            label="Customer ID"
                            value={customerId}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-5px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                            // disabled
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="clientName"
                            label="Client Name"
                            value={clientName}
                            onChange={handleClientNameChange}
                            // InputLabelProps={textFieldLabelStyles}
                            required
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%', minHeight: 2 }} size="small">
                          <InputLabel
                            id="clientStatus"
                            style={inputLabelStyles}
                            sx={{ fontSize: '11px', top: '-3px' }}
                          >
                            Client Status
                          </InputLabel>
                          <Select
                            labelId="clientStatus"
                            id="clientStatus"
                            value={clientStatus}
                            label="Client Status"
                            onChange={handleClientStatusChange}
                            required
                            size="small"
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 22,
                                minHeight: 22,
                              }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '2px',
                              }, // Reduce font size & padding
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  fontSize: '10px', // Reduce font size of dropdown items
                                  maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                                },
                              },
                            }}
                          >
                            {clientStatuses.map((status) => (
                              <MenuItem
                                key={status.value}
                                value={status.value}
                                sx={{ fontSize: '10px' }}
                              >
                                {status.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Primary Contact"
                            name="pointOfContact"
                            value={pointOfContact}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setPrimaryContactError(
                                newValue === secondaryPointOfContact
                                  ? 'Primary and Secondary Contacts should be different.'
                                  : '',
                              );
                              handleInputChange(e);
                            }}
                            error={!!primaryContactError}
                            helperText={primaryContactError}
                            required
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Secondary Contact"
                            name="secondaryPointOfContact"
                            value={secondaryPointOfContact}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              setSecondaryContactError(
                                newValue === pointOfContact
                                  ? 'Primary and Secondary Contacts should be different.'
                                  : '',
                              );
                              handleInputChange(e);
                            }}
                            error={!!secondaryContactError}
                            helperText={secondaryContactError}
                            required
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Primary Email"
                            name="primaryEmail"
                            value={primaryEmail}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Update the form data first
                              handleInputChange(e);

                              // Check if primary email is different from secondary email
                              if (newValue !== formData.secondaryEmail) {
                                if (validateEmail(newValue)) {
                                  setPrimaryEmailError(''); // Clear error if valid
                                } else {
                                  setPrimaryEmailError(
                                    'Primary Email must be a valid email address.',
                                  );
                                }
                              } else {
                                setPrimaryEmailError(
                                  'Primary Email and Secondary Email should be different.',
                                );
                              }
                            }}
                            error={!!primaryEmailError} // Show error if there's an error message
                            helperText={primaryEmailError} // Display the error message
                            required
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Secondary Email"
                            name="secondaryEmail"
                            value={secondaryEmail}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Update the form data first
                              handleInputChange(e);

                              // Check if secondary email is different from primary email
                              if (newValue !== formData.primaryEmail) {
                                if (validateEmail(newValue)) {
                                  setSecondaryEmailError(''); // Clear error if valid
                                } else {
                                  setSecondaryEmailError(
                                    'Secondary Email must be a valid email address.',
                                  );
                                }
                              } else {
                                setSecondaryEmailError(
                                  'Primary Email and Secondary Email should be different.',
                                );
                              }
                            }}
                            error={!!secondaryEmailError} // Show error if there's an error message
                            helperText={secondaryEmailError} // Display the error message
                            required
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Primary Contact Number"
                            name="primaryContactNumber"
                            value={primaryContactNumber}
                            onChange={handlePrimaryContactChange}
                            error={!!primaryContactNumberError} // Show error if there's an error message
                            helperText={primaryContactNumberError} // Display the error message
                            required
                            // Mask the input to display +1 and only allow 10 digits
                            inputProps={{ maxLength: 12 }} // Total length = 13, i.e., "+1" + 10 digits
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            fullWidth
                            label="Secondary Contact Number"
                            name="secondaryContactNumber"
                            value={secondaryContactNumber}
                            onChange={handleSecondaryContactChange}
                            error={!!secondaryContactNumberError} // Show error if there's an error message
                            helperText={secondaryContactNumberError} // Display the error message
                            required
                            // Mask the input to display +1 and only allow 10 digits
                            inputProps={{ maxLength: 12 }} // Total length = 13, i.e., "+1" + 10 digits
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="unitNumber"
                            label={unitNumber ? 'Unit Number' : 'Unit Number'}
                            value={unitNumber}
                            onChange={(event) => {
                              // Only allow numeric values for unit number
                              const value = event.target.value;
                              if (/^\d*$/.test(value)) {
                                // Allow only digits
                                setUnitNumber(value);
                              }
                            }}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="streetNumber"
                            label={streetNumber ? 'Street Number' : 'Street Number'}
                            value={streetNumber}
                            required
                            onChange={(event) => {
                              // Only allow numeric values for unit number
                              const value = event.target.value;
                              if (/^\d*$/.test(value)) {
                                // Allow only digits
                                setStreetNumber(value);
                              }
                            }}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="streetName"
                            label={streetName ? 'Street Name' : 'Street Name'}
                            value={streetName}
                            required
                            onChange={(event) => {
                              setStreetName(event.target.value);
                            }}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="city"
                            label={city ? 'City' : 'City'}
                            value={city}
                            required
                            onChange={(event) => {
                              setCity(event.target.value);
                            }}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    {/* Country Field */}
                    <Grid item xs={12} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small" required>
                          <InputLabel
                            id="country"
                            sx={{ fontSize: '11px' }} // Adjust InputLabel font size
                          >
                            Country
                          </InputLabel>
                          <Select
                            id="country"
                            value={country}
                            onChange={(event) => setCountry(event.target.value)}
                            label="Country"
                            // InputLabelProps={{ style: textFieldLabelStyles }}
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-5px' }, // Reduce placeholder size
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  fontSize: '10px', // Reduce font size of dropdown items
                                  maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                                },
                              },
                            }}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 22,
                                minHeight: 22,
                              }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '2px',
                              }, // Reduce font size & padding
                            }}
                          >
                            <MenuItem value="Canada" sx={{ fontSize: '10px' }}>
                              Canada
                            </MenuItem>
                            <MenuItem value="United States" sx={{ fontSize: '10px' }}>
                              United States
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <Box>
                        <FormControl sx={{ minWidth: '100%' }} size="small">
                          <TextField
                            style={inputLabelStyles}
                            id="province"
                            label={country === 'Canada' ? 'Province' : 'State'}
                            value={province}
                            required
                            onChange={(event) => setProvince(event.target.value)}
                            // InputLabelProps={textFieldLabelStyles}
                            size="small"
                            InputLabelProps={{
                              sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                            }}
                            sx={{
                              '& .MuiInputBase-root': { height: 25 }, // Adjust height
                              '& .MuiInputBase-input': {
                                fontSize: 12,
                                padding: '4px',
                              }, // Reduce font size & padding
                            }}
                          />
                        </FormControl>
                      </Box>
                    </Grid>

                    {/* Postal Code Field */}
                    <Grid item xs={4} sm={4} md={4} lg={4} style={gridRowStyles}>
                      <FormControl sx={{ minWidth: '100%' }} size="small">
                        <TextField
                          fullWidth
                          label={country === 'Canada' ? 'Postal Code' : 'Zip Code'}
                          name="postalCode"
                          value={postalCode}
                          onChange={handlePostalCodeChange}
                          inputProps={{ maxLength: 6 }} // Max length of 6 characters for both countries
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '4px',
                            }, // Reduce font size & padding
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {/* Invoice Cycle Select */}
                    <Grid item xs={4}>
                      <FormControl
                        fullWidth
                        required
                        error={Boolean(errors.invoiceCycle)}
                        size="small"
                      >
                        <InputLabel sx={{ fontSize: '11px' }}>Invoice Cycle</InputLabel>
                        <Select
                          name="invoiceCycle"
                          label="invoiceCycle"
                          value={invoiceCycle}
                          onChange={handleInvoiceCycleChange}
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              height: 22,
                              minHeight: 22,
                            }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '2px',
                            }, // Reduce font size & padding
                          }}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                fontSize: '10px', // Reduce font size of dropdown items
                                maxHeight: 200, // Limit the height of the dropdown to avoid stretching
                              },
                            },
                          }}
                        >
                          <MenuItem value="Cycle 1" sx={{ fontSize: '10px' }}>
                            Cycle 1 (Mon-Sun)
                          </MenuItem>
                          <MenuItem value="Cycle 2" sx={{ fontSize: '10px' }}>
                            Cycle 2 (Mon-Wed, Thur-Sun)
                          </MenuItem>
                        </Select>
                        {errors.invoiceCycle && (
                          <FormHelperText>{errors.invoiceCycle}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Currency Selection */}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginBottom: '8px',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }}
                  >
                    Currency Selection
                  </Typography>
                  <FormControl component="fieldset" margin="normal" fullWidth>
                    <RadioGroup row value={currency} onChange={handleCurrencyChange}>
                      <FormControlLabel
                        value="Canada"
                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
                        label="Canada (CAD)"
                        componentsProps={{ typography: { fontSize: 12 } }}
                      />
                      <FormControlLabel
                        value="United States"
                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
                        label="United States (USD)"
                        componentsProps={{ typography: { fontSize: 12 } }}
                      />
                      <FormControlLabel
                        value="Both"
                        control={<Radio sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }} />}
                        label="Both"
                        componentsProps={{ typography: { fontSize: 12 } }}
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* For Canada */}
                  {currency === 'Canada' || currency === 'Both' ? (
                    <Grid container spacing={2} alignItems="center">
                      {/* Typography for Canada */}
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{
                            marginRight: '16px',
                            ml: 0.3,
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                        >
                          For Canada
                        </Typography>
                      </Grid>

                      {/* xReference ID */}
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="xReference ID"
                          name="xreferenceID"
                          value={xreferenceID}
                          onChange={(event) => setxreferenceID(event.target.value)}
                          margin="dense"
                          required
                          disabled
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '4px',
                            }, // Reduce font size & padding
                            marginTop: '0px',
                          }}
                        />
                      </Grid>

                      {/* Subfleet ID */}
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Subfleet ID"
                          name="subfleetID"
                          value={subfleetID}
                          onChange={(event) => setSubfleetID(event.target.value)}
                          margin="dense"
                          disabled
                          required
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '4px',
                            }, // Reduce font size & padding
                            marginTop: '0px',
                          }}
                        />
                      </Grid>

                      {/* Profit Margin */}
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Profit Margin ($)"
                          name="profitMargin"
                          value={profitMargin}
                          disabled
                          onChange={(event) => {
                            const value = event.target.value;
                            // Allow only numeric input with decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              setProfitMargin(value);
                            }
                          }}
                          margin="dense"
                          size="small" // Set to small size
                          InputLabelProps={{
                            sx: {
                              fontSize: '11px', // Reduce font size
                              top: '-3px',
                            },
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '4px',
                            }, // Reduce font size & padding
                            marginTop: '0px',
                          }}
                          inputProps={{
                            type: 'number',
                            step: '0.01',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  {/* For United States */}
                  {currency === 'United States' || currency === 'Both' ? (
                    <Grid container spacing={2} alignItems="center">
                      {/* Typography for United States */}
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          component="span"
                          sx={{
                            marginRight: '16px',
                            ml: 0.3,
                            fontSize: '11px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                          }}
                        >
                          For United States
                        </Typography>
                      </Grid>

                      {/* Comdata Reference */}
                      {/* <Grid item xs={12} sm={4} md={3}>
                            <TextField
                              fullWidth
                              label="Comdata Reference"
                              name="clientReference"
                              value={clientReference}
                              onChange={(event) => setClientReference(event.target.value)}
                              margin="normal"
                            />
                          </Grid> */}

                      {/* Discount */}
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          label="Discount (%)"
                          name="discount"
                          value={discount}
                          disabled
                          onChange={(event) => {
                            const value = event.target.value;
                            // Allow only numeric input with decimals
                            if (/^\d*\.?\d*$/.test(value)) {
                              setDiscount(value);
                            }
                          }}
                          margin="dense"
                          size="small"
                          InputLabelProps={{
                            sx: { fontSize: '11px', top: '-3px' }, // Reduce placeholder size
                          }}
                          sx={{
                            '& .MuiInputBase-root': { height: 25 }, // Adjust height
                            '& .MuiInputBase-input': {
                              fontSize: 12,
                              padding: '4px',
                            }, // Reduce font size & padding
                            marginTop: '0px',
                          }}
                          inputProps={{
                            type: 'number',
                            step: '0.01',
                            min: '0',
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}

                  <Grid container spacing={2}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75} style={gridRowStyles}>
                      <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4} align="center">
                        <Box>
                          <Button
                            style={{
                              fontFamily: 'Roboto',
                              fontSize: '11px',
                              fontWeight: 'bold',
                              backgroundColor: ' #31478F',
                            }}
                            type="submit"
                            variant="contained"
                            sx={{ borderRadius: '5px' }}
                          >
                            Save
                            <SaveIcon sx={{ fontSize: '16px' }} />
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
                    </Grid>
                  </Grid>
                </div>
              </form>
            )}

            {!isUpdateCardSelected &&
              (!loading && clientListView.length > 0 ? (
                <div className="container" style={{ display: 'flex' }}>
                  <div className="card" style={{ flex: '1', margin: '2px' }}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                        {/* <Card> */}
                        <CardContent>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Typography
                                variant="h5"
                                style={{
                                  ...fontStylesChartTypography,
                                  fontSize: '14px',
                                  textTransform: 'uppercase',
                                  marginTop: '-60px',
                                }}
                              >
                                Customer Details
                                {statusFilter && (
                                  <span
                                    style={{
                                      marginLeft: '10px',
                                      fontSize: '12px',
                                    }}
                                  >
                                    (Filtered: {statusFilter.toUpperCase()})
                                  </span>
                                )}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                style={{
                                  marginTop: '0',
                                  fontFamily: 'Roboto',
                                  fontSize: '2px',
                                  fontSize: buttonsFontSize,
                                  backgroundColor: ' #31478F',
                                  borderRadius: '5px',
                                  padding: '6px 12px',
                                  marginRight: '50px',
                                }}
                                type="submit"
                                variant="contained"
                                sx={{ borderRadius: 0 }}
                                onClick={handleUpdateCustomerSelection}
                              >
                                <Typography style={{ fontSize: '11px' }}>
                                  Update Customer
                                </Typography>

                                {/* <EditIcon /> */}
                              </Button>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              height: 700,
                              maxWidth: '95%',
                              marginTop: '20px',
                              align: 'center',
                            }}
                          >
                            <StyledDataGrid
                              onRowClick={gridRowClicked}
                              rows={clientListView}
                              columns={clientListViewColumns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              rowHeight={25}
                              columnHeaderHeight={32} // Adjust header height (default is usually around 56px)
                              sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                  fontSize: '10px', // Reduce font size of header text
                                  backgroundColor: '#31478F', // Set custom header background color
                                  color: 'white', // Set font color to white for better contrast
                                },
                                '& .MuiDataGrid-cell': {
                                  fontSize: '10px', // Reduced font size for table cells
                                },
                                '& .even': {
                                  backgroundColor: '#F8F8F8', // Light blue color for even rows
                                },
                                '& .odd': {
                                  backgroundColor: '#E6F0FF', // Darker blue for odd rows
                                },
                              }}
                              slots={{ toolbar: CustomToolbar }}
                              getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                              }
                            />
                          </div>
                        </CardContent>
                        {/* </Card> */}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : loading ? (
                <div className="container" style={{ display: 'flex' }}>
                  <div className="card" style={{ flex: '1', margin: '2px' }}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                        {/* <Card> */}
                        <CardContent>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              {/* <Typography variant="h5" style={{ ...fontStylesChartTypography, fontSize: '12px', textTransform: 'capitalize' }}>
                              Customer Details
                            </Typography> */}
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                              <CircularProgress color="success" />
                            </Grid>
                          </Grid>
                        </CardContent>
                        {/* </Card> */}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div className="container" style={{ display: 'flex' }}>
                  <div style={{ flex: '1', margin: '2px' }}>
                    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={gridRowStyles}>
                        {/* <Card>
                              <CardContent> */}
                        <Grid container alignItems="center" justifyContent="center">
                          <Grid item>
                            {/* <Typography variant="h5" style={{ ...fontStylesChartTypography, fontSize: '12px', textTransform: 'capitalize' }}>
                              Customer Details
                            </Typography> */}
                          </Grid>
                        </Grid>

                        <div
                          style={{
                            height: 700,
                            maxWidth: '95%',
                            marginTop: '20px',
                            align: 'center',
                            paddingTop: '400px',
                          }}
                        >
                          <StyledDataGrid
                            // onRowClick={gridRowClicked}
                            sx={{
                              '& .super-app-theme--header': {
                                backgroundColor: 'rgba(0, 9, 81)',
                                color: 'white',
                              },
                            }}
                            rows={NoRecordsFoundRow}
                            columns={clientListViewColumns}
                            slots={{ toolbar: CustomToolbar }}
                            getRowClassName={(params) =>
                              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                          />
                        </div>
                        {/* </CardContent>
                            </Card> */}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ))}
            {/* </CardContent>
            </Card> */}
          </div>
        </div>
      </div>
    )
  );
};

export default ManageClients;
