import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
} from '@mui/material';
import { Lock } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

const FuelCard = ({ inputCardNumber, inputClientId, inputCustomerId, inputCustomerName }) => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [loading, setLoading] = useState(true);
  const [fuelCardData, setFuelCardData] = useState({});
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const [formData, setFormData] = useState({
    policyNumber: '',
    driverName: '',
    personalId: '',
    subFleet: '',
    unitNumber: '',
    xref: '',
    driverId: '',
    status: '',
    limit: '',
  });

  // Passcode protection state
  const [showPersonalId, setShowPersonalId] = useState(false);
  const [isPasscodeDialogOpen, setIsPasscodeDialogOpen] = useState(false);
  const [enteredPasscode, setEnteredPasscode] = useState('');

  useEffect(() => {
    const fetchCardDetails = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/v1/getCardInfo?cardNumber=${inputCardNumber}`);
        const data = await response.json();

        if (data) {
          const nameInfo = data.infos.find((info) => info.infoId === 'NAME')?.reportValue || '';
          const ppinInfo = data.infos.find((info) => info.infoId === 'PPIN')?.matchValue || '';
          const ssubInfo = data.infos.find((info) => info.infoId === 'SSUB')?.reportValue || '';
          const unitInfo = data.infos.find((info) => info.infoId === 'UNIT')?.matchValue || '';
          console.log("Personal ID: ", ppinInfo)

          setFuelCardData(data);
          setFormData({
            policyNumber: data.header.policyNumber,
            driverName: nameInfo,
            personalId: ppinInfo,
            subFleet: ssubInfo,
            unitNumber: unitInfo,
            xref: data.header.companyXRef || '',
            driverId: '',
            status: data.header.status || '',
            limit: '',
          });
        }
      } catch (error) {
        console.error('Error fetching fuel card details:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchCardDetails();
  }, [inputCardNumber, BACKEND_URL]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const queryParams = new URLSearchParams({
        cardNumber: inputCardNumber,
        status: formData.status,
        driverName: formData.driverName,
        unitNumber: formData.unitNumber,
      }).toString();

      const response = await fetch(`${BACKEND_URL}/v1/setCardInfo?${queryParams}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        alert('Fuel card details updated successfully!');
      } else {
        const errorMessage = await response.text();
        alert(`Error updating fuel card details: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error submitting fuel card details:', error);
      alert('An error occurred while updating fuel card details.');
    }
  };

  // Passcode functions
  const handleUnlockClick = () => setIsPasscodeDialogOpen(true);
  const handlePasscodeSubmit = () => {
    if (enteredPasscode === formData.personalId) {
      setShowPersonalId(true);
    } else {
      alert('Incorrect passcode. Try again!');
    }
    setIsPasscodeDialogOpen(false);
    setEnteredPasscode('');
  };

  return (
    <div>
      <Typography variant="h1" sx={{ fontSize: '22px', fontWeight: '800' }} gutterBottom>
        CARD MANAGEMENT
      </Typography>
      {loading ? (
        <Grid container justifyContent="center">
          <CircularProgress color="success" />
        </Grid>
      ) : (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField label="Card Number" value={inputCardNumber} disabled fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Policy Number"
                    name="policyNumber"
                    value={formData.policyNumber}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Driver Name"
                    name="driverName"
                    value={formData.driverName}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Personal ID Number"
                    name="personalId"
                    value={showPersonalId ? formData.personalId : '******'}
                    disabled
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleUnlockClick} edge="end">
                            <Lock />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {doNotPerformCustomerFiltration && (
                  <Grid item xs={6}>
                    <TextField
                      label="Sub Fleet Identifier"
                      name="subFleet"
                      value={formData.subFleet}
                      disabled
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    label="Unit #"
                    name="unitNumber"
                    value={formData.unitNumber}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                {doNotPerformCustomerFiltration && (
                  <Grid item xs={6}>
                    <TextField label="XREF" name="xref" value={formData.xref} disabled fullWidth />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    label="Driver ID"
                    name="driverId"
                    value={formData.driverId}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select name="status" value={formData.status} onChange={handleChange}>
                      <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                      <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Limit" name="limit" value={formData.limit} disabled fullWidth />
                </Grid>
              </Grid>
              <Button type="submit" variant="contained" sx={{ mt: 3 }}>
                Update Details
              </Button>
            </CardContent>
          </Card>
        </form>
      )}

      {/* Passcode Dialog */}
      <Dialog open={isPasscodeDialogOpen} onClose={() => setIsPasscodeDialogOpen(false)}>
        <DialogTitle>Enter Passcode</DialogTitle>
        <DialogContent>
          <Input type="password" value={enteredPasscode} onChange={(e) => setEnteredPasscode(e.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsPasscodeDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePasscodeSubmit} color="primary">
            Unlock
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FuelCard;
