import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  TableCell,
  Collapse,
  TableRow,
  Table,
  TableHead,
  TableBody,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import 'jspdf-autotable';
import './verify-invoice.css';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';

function Invoice() {
  const buttonsFontSize = '16px';
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const buttonBackground = '#011f4b';
  const [clients, setClients] = React.useState([]);
  const [, setClientsCAD] = React.useState([]);
  const [, setClientsUSD] = React.useState([]);
  const [, setSelectedClient] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [, setCustomerId] = React.useState('');
  const [, setClientId] = React.useState('');
  const [, setCurrency] = React.useState('USD'); // Set default currency to USD
  const [, setCardsForClient] = React.useState([]);
  const doNotPerformCustomerFiltration = localStorage.getItem('role') === 'Super Admin';
  const loggedInUserClientId = localStorage.getItem('clientId');
  const loggedInUserName = localStorage.getItem('userName');
  const [clientDetails, setClientDetails] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState('CAD'); // New state to store the selected currency
  const [, setRows] = React.useState([]);
  const [, setError] = React.useState('');

  const [invoices, setInvoices] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null); // Track expanded row

  const fontStylesRootTypography = {
    fontSize: '22px',
    textAlign: 'left',
    fontWeight: '800',
    fontFamily: 'DM Sans, sans-serif',
  };

  useEffect(() => {
    const fetchClientDetails = async () => {
      if (!loggedInUserClientId) {
        setError('Client ID not found in localStorage.');
        return;
      }

      try {
        const response = await fetch(`${BACKEND_URL}/v1/client?clientID=${loggedInUserClientId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setClientDetails(data);
        console.log('clientDetails currency', data[0]?.currency);
        console.log('clientDetails', data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchClientDetails();
  }, [loggedInUserClientId]);

  useEffect(() => {
    getClients();
  }, []);

  // Use Effect to handle single client case
  useEffect(() => {
    if (clients.length === 1) {
      const singleClient = clients[0];
      const singleClientValue = `${singleClient.clientID}-${singleClient.customerID}`;
      setClientId(singleClient.clientID);
      setCustomerId(singleClient.customerID);

      // Trigger handleSelectChange programmatically
      handleSelectChange({ target: { value: singleClientValue } });
    }
  }, [clients]); // Only depends on clients array

  const getClients = async () => {
    let getClientsUrl = `${BACKEND_URL}/v1/client`;
    if (!doNotPerformCustomerFiltration) {
      getClientsUrl += `?clientID=${loggedInUserClientId}`;
    }

    const response = await fetch(getClientsUrl);
    const data = await response.json();
    data.sort((a, b) => a.clientID - b.clientID);
    setClients(data);

    // Create two arrays to hold the clients by currency
    const clientsCADData = [];
    const clientsUSDData = [];

    // Iterate over the client data and segregate into CAD or USD
    data.forEach((client) => {
      const currency = client.currency;

      // Ensure currency is not null or undefined before checking for CAD or USD
      if (currency && typeof currency === 'string') {
        // Check if currency contains "CAD"
        if (currency.includes('CAD')) {
          clientsCADData.push(client);
        }

        // Check if currency contains "USD"
        if (currency.includes('USD')) {
          clientsUSDData.push(client);
        }
      }
    });

    // Set the state variables for CAD and USD clients
    setClientsCAD(clientsCADData); // Set clients for CAD
    setClientsUSD(clientsUSDData); // Set clients for USD
    return data;
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    // Check if selectedValue is not undefined and contains '-'
    if (selectedValue && selectedValue.includes('-')) {
      // Extract clientID and customerID from the selected value
      const [clientID, customerID] = selectedValue.split('-').map((value) => value.trim());

      if (clientID && customerID) {
        setClientId(clientID); // Update clientId state
        setCustomerId(customerID); // Update customerId state

        // Call the function to handle customer selection change with clientId and customerId
        handleCustomerSelectionChange(clientID, customerID);
      } else {
        console.error(
          'Failed to extract clientID and customerID from the selected value:',
          selectedValue,
        );
      }
    } else {
      console.error('Selected value is not in the expected format:', selectedValue);
    }
  };

  const handleCustomerSelectionChange = async (clientID, customerID) => {
    setLoading(true);
    setRows([]);
    console.log('Client inside handlecustomer ', clientID);
    try {
      // Use clientID in the getClientsUrl
      const getClientsUrl = `${BACKEND_URL}/v1/client?clientID=${clientID}`;
      const getCardDetailsForCustomerUrl = `${BACKEND_URL}/v1/cards?customerId=${customerID}&maxRows=1000&pageNumber=1000`;

      const [clientResponse, cardResponse] = await Promise.all([
        fetch(getClientsUrl),
        fetch(getCardDetailsForCustomerUrl),
      ]);

      if (!clientResponse.ok) {
        throw new Error('Failed to fetch client data');
      }

      if (!cardResponse.ok) {
        throw new Error('Failed to fetch card details');
      }

      const clientData = await clientResponse.json();
      const cardData = await cardResponse.json();

      setSelectedClient(clientData);
      console.log('Data client', cardData?.records);
      setCardsForClient(cardData?.records?.card);
      setCurrency('USD');
      console.log('Customer Details new', clientData);
    } catch (error) {
      console.error('Error fetching client or card details:', error.message);
    } finally {
      setLoading(false);
    }
  };

  // Set the customerId to the first item if only one item is present
  useEffect(() => {
    if (!doNotPerformCustomerFiltration && clients.length === 1) {
      const firstClient = clients[0];
      setCustomerId(firstClient.customerID);
      handleCustomerSelectionChange({
        target: { value: firstClient.customerID },
      });
    }
  }, [doNotPerformCustomerFiltration, loggedInUserClientId, BACKEND_URL]);

  // Define a function to determine the button background color based on selected currency
  const getButtonBackground = (currency) => {
    return selectedCurrency === currency ? '#FF8C00' : buttonBackground; // Highlight color for selected currency
  };

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };

  const fetchInvoices = () => {
    fetch(`${BACKEND_URL}/v1/get-invoice-details`)
      .then((response) => response.json())
      .then((data) => {
        setInvoices(data);
        console.log('invoice details:', data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching invoice details:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // Initial fetch
    fetchInvoices();

    // Set interval to fetch invoices every 50 seconds
    const interval = setInterval(fetchInvoices, 50000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to run once on mount

  // Function to toggle row expansion
  const handleExpandClick = (invoiceId) => {
    setExpandedRow(expandedRow === invoiceId ? null : invoiceId);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const handleInvoiceAction = async (invoiceId, action, loggedInUserName) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/v1/approve-invoice-generation?invoiceId=${invoiceId}&action=${action}&approvedBy=${loggedInUserName}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Set content type as json (no body)
          },
        },
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // If the response is not JSON, handle it as text
      const textData = await response.text(); // Use .text() to handle plain text response
      console.log(textData); // You can show this response or handle it as needed

      alert(textData);
      fetchInvoices();
      // Optionally, you can show a success message or update the table state here
    } catch (error) {
      // Handle error (show an error message or update state)
      console.error('Error processing the action:', error);
    }
  };

  const handleBackClicked = () => {
    navigate('/manage-invoice'); // Navigate to /invoice page
  };

  const handleDownload = async (fileName) => {
    try {
      // Fetch the file from the S3 endpoint
      const response = await fetch(`${BACKEND_URL}/v1/get-invoice-file?fileName=${fileName}`);

      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Get the file content as a blob (this will be the byte content of the file)
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob); // Create an object URL for the blob
      link.download = fileName; // Set the downloaded file name
      link.click(); // Trigger the download
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  return (
    <div>
      {/* Button Container */}
      {clientDetails && clientDetails.length > 0 && (
        <Box display="flex" mb={2} gap={2}>
          {/* Show both buttons if currency is 'CAD, USD' or empty */}
          {clientDetails[0]?.currency === 'CAD, USD' || !clientDetails[0]?.currency ? (
            <>
              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'Sans-serif',
                  fontSize: '12px', // Reduce font size
                  backgroundColor: getButtonBackground('CAD'),
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                }}
                onClick={() => handleCurrencySelect('CAD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                CAD
              </Button>

              <Button
                variant="contained"
                style={{
                  marginTop: '5px',
                  fontFamily: 'sans-serif',
                  fontSize: '12px', // Reduce font size
                  padding: '4px 8px', // Adjust padding for a smaller button
                  minWidth: '50px', // Set a minimum width
                  height: '30px', // Reduce height
                  backgroundColor: getButtonBackground('USD'),
                }}
                onClick={() => handleCurrencySelect('USD')}
                sx={{ fontSize: '16px', textTransform: 'none' }}
              >
                USD
              </Button>
            </>
          ) : null}

          {/* Show only CAD button if currency is 'CAD' */}
          {clientDetails[0]?.currency === 'CAD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'Sans-serif',
                fontSize: '12px', // Reduce font size
                backgroundColor: getButtonBackground('CAD'),
                padding: '4px 8px', // Adjust padding for a smaller button
                minWidth: '50px', // Set a minimum width
                height: '30px', // Reduce height
              }}
              onClick={() => handleCurrencySelect('CAD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              CAD
            </Button>
          )}

          {/* Show only USD button if currency is 'USD' */}
          {clientDetails[0]?.currency === 'USD' && (
            <Button
              variant="contained"
              style={{
                marginTop: '5px',
                fontFamily: 'sans-serif',
                fontSize: '12px', // Reduce font size
                padding: '4px 8px', // Adjust padding for a smaller button
                minWidth: '50px', // Set a minimum width
                height: '30px', // Reduce height
                backgroundColor: getButtonBackground('USD'),
              }}
              onClick={() => handleCurrencySelect('USD')}
              sx={{ fontSize: '16px', textTransform: 'none' }}
            >
              USD
            </Button>
          )}
        </Box>
      )}

      {selectedCurrency === 'CAD' && (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="h1"
              style={{
                textAlign: 'left',
                fontSize: '14px',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
              }}
              gutterBottom
            >
              VERIFY INVOICE
            </Typography>
            <div>
              <Button
                variant="contained"
                style={{
                  fontFamily: 'sans-serif',
                  fontSize: '10px',
                  backgroundColor: '#31478F',
                  padding: '4px 9px', // Reduce padding
                  minWidth: '70px', // Reduce width
                  height: '24px', // Reduce height
                  marginRight: '20px',
                }}
                onClick={handleBackClicked}
              >
                BACK
              </Button>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <Table style={{ maxWidth: '98%' }} aria-label="collapsible table">
              <TableHead
                sx={{
                  backgroundColor: '#31478F',
                  '& .main-table-header-cell': {
                    color: 'white',
                    fontSize: '10px', // Further reduce font size
                    padding: '2px 6px', // Reduce padding
                    height: '20px', // Reduce row height
                    textAlign: 'left',
                  },
                  '& .MuiTableRow-root': {
                    height: '20px', // Reduce overall row height
                    textAlign: 'left',
                  },
                  '& .MuiTableCell-root': {
                    padding: '1px', // Reduce cell padding
                    fontSize: '9px', // Reduce font size
                    textAlign: 'left',
                  },
                }}
              >
                <TableRow>
                  <TableCell className="main-table-header-cell"></TableCell>
                  <TableCell className="main-table-header-cell">BILLING PERIOD</TableCell>
                  <TableCell className="main-table-header-cell">ACC#</TableCell>
                  <TableCell className="main-table-header-cell">INVOICE#</TableCell>
                  <TableCell className="main-table-header-cell">DIESEL QTY</TableCell>
                  <TableCell className="main-table-header-cell">RFR QTY</TableCell>
                  <TableCell className="main-table-header-cell">DEF QTY</TableCell>
                  <TableCell className="main-table-header-cell">MARGIN</TableCell>
                  <TableCell className="main-table-header-cell">C2PROFIT</TableCell>
                  <TableCell className="main-table-header-cell">INV2KSG</TableCell>
                  <TableCell className="main-table-header-cell">C2INVAMT</TableCell>
                  <TableCell className="main-table-header-cell">CUST AMT</TableCell>
                  <TableCell className="main-table-header-cell">STATUS</TableCell>
                  <TableCell className="main-table-header-cell">APPROVE/DECLINE</TableCell>
                  <TableCell className="main-table-header-cell">APPROVED BY</TableCell>
                  <TableCell className="main-table-header-cell">DOWNLOAD</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  // backgroundColor: "#31478F",
                  '& .main-table-row-cell': {
                    color: 'black',
                    fontSize: '10px', // Further reduce font size
                    padding: '2px', // Reduce padding
                    // margin: "2px",
                    height: '0px', // Reduce row height
                  },
                  '& .MuiTableCell-root': {
                    padding: '2px', // Reduce cell padding
                    margin: '1px',
                    fontSize: '9px', // Reduce font size
                  },
                }}
              >
                {invoices
                  .filter((invoice) => invoice.invoiceId.endsWith('.csv'))
                  .sort((a, b) => {
                    // Sort by 'status' first (Pending on top), then by 'clientId' ascending
                    if (a.status === 'Pending' && b.status !== 'Pending') return -1;
                    if (a.status !== 'Pending' && b.status === 'Pending') return 1;
                    if (a.invoiceId < b.invoiceId) return -1;
                    if (a.invoiceId > b.invoiceId) return 1;
                    return 0;
                  })
                  .map((invoice, index) => (
                    <React.Fragment key={invoice.invoiceId}>
                      <TableRow
                        sx={{ '& > *': { borderBottom: 'unset' } }}
                        className={index % 2 == 0 ? 'main-table-row-even' : 'main-table-row-odd'}
                      >
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleExpandClick(invoice.invoiceId)}
                            className="main-table-row-cell"
                          >
                            {expandedRow === invoice.invoiceId ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {invoice.originalFileName}
                        </TableCell>
                        <TableCell className="main-table-row-cell">{invoice.clientId}</TableCell>
                        <TableCell className="main-table-row-cell">{invoice.invoiceId}</TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalDiesel).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalRfr).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalDef).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.profitMargin).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalProfitMargin).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalAmt).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.invoice2KSG).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">
                          {parseFloat(invoice.totalCustAmt).toFixed(3)}
                        </TableCell>
                        <TableCell className="main-table-row-cell">{invoice.status}</TableCell>
                        <TableCell className="main-table-row-cell">
                          {invoice.status === 'Pending' && (
                            <div className="icon-container">
                              <IconButton
                                onClick={() =>
                                  handleInvoiceAction(
                                    invoice.invoiceId,
                                    'Approved',
                                    loggedInUserName,
                                  )
                                }
                                // color="primary"
                                aria-label="approve"
                                title="Approve"
                                sx={{ padding: '2px', color: 'green' }}
                              >
                                <CheckIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleInvoiceAction(
                                    invoice.invoiceId,
                                    'Declined',
                                    loggedInUserName,
                                  )
                                }
                                // color="secondary"
                                aria-label="decline"
                                title="Decline"
                                sx={{ padding: '2px', color: 'red' }}
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            </div>
                          )}
                        </TableCell>
                        <TableCell className="main-table-row-cell">{invoice.approvedBy}</TableCell>
                        <TableCell className="main-table-row-cell">
                          {/* Render the download button if the status is 'Approved' */}
                          {
                            <IconButton
                              onClick={() => handleDownload(invoice.originalFileName)}
                              color="primary"
                              aria-label="download"
                              title="Download CSV"
                              sx={{ padding: '2px' }} // Reduce padding
                            >
                              <DownloadIcon fontSize="small" />
                            </IconButton>
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={19}>
                          <Collapse
                            in={expandedRow === invoice.invoiceId}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ margin: 1 }}>
                              <Table sx={{ backgroundColor: '#31478F' }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      STATE
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      DIESEL QTY
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      RFR QTY
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      TOTAL QTY
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      PROFIT CALC
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        // padding: "2px 4px",
                                        fontSize: '12px',
                                        color: 'white',
                                      }}
                                    >
                                      TOTAL PROFIT CALC
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {[
                                    'ON',
                                    'QC',
                                    'NS',
                                    'NB',
                                    'NFL',
                                    'MB',
                                    'SK',
                                    'AB',
                                    'BC',
                                    'YT',
                                  ].map((state, rowIndex) => (
                                    <TableRow
                                      key={state}
                                      sx={{ height: '-5px' }}
                                      className={
                                        rowIndex % 2 === 0
                                          ? 'sub-table-row-even'
                                          : 'sub-table-row-odd'
                                      }
                                    >
                                      <TableCell
                                        className={`sub-table-row-${state}`}
                                        component="th"
                                        scope="row"
                                        sx={{
                                          // padding: "2px 4px",
                                          fontSize: '11px',
                                        }}
                                      >
                                        {state}
                                      </TableCell>
                                      <TableCell className={`sub-table-row-${state}`}>
                                        {parseFloat(invoice[`totalDiesel${state}`]).toFixed(3)}
                                      </TableCell>
                                      <TableCell className={`sub-table-row-${state}`}>
                                        {parseFloat(invoice[`totalRfr${state}`]).toFixed(3)}
                                      </TableCell>
                                      <TableCell className={`sub-table-row-${state}`}>
                                        {parseFloat(invoice[`total${state}Qty`]).toFixed(3)}
                                      </TableCell>
                                      <TableCell className={`sub-table-row-${state}`}>
                                        {(() => {
                                          // Tax rates map from your static block
                                          const provinceTaxRates = {
                                            ns: [0.15, 0.0], // 15% GST, 0% PST
                                            bc: [0.05, 0.0], // 5% GST, 0% PST
                                            ab: [0.05, 0.0], // 5% GST, 0% PST
                                            sk: [0.05, 0.0], // 5% GST, 0% PST
                                            mb: [0.05, 0.0], // 5% GST, 0% PST
                                            on: [0.13, 0.0], // 13% GST, 0% PST
                                            qc: [0.05, 0.0998], // 5% GST, 9.98% QST
                                          };

                                          // Get tax rates for the current state
                                          const taxRates = provinceTaxRates[
                                            state.toLowerCase()
                                          ] || [0, 0]; // Default to no tax if state not found
                                          const gstRate = taxRates[0];
                                          const pstRate = taxRates[1];

                                          // Calculate the profit margin with both GST and PST applied
                                          const profitMargin = parseFloat(invoice.profitMargin);
                                          const profitMarginWithTax =
                                            profitMargin * (1 + gstRate + pstRate);

                                          return profitMarginWithTax.toFixed(3); // Display the calculated value
                                        })()}
                                      </TableCell>

                                      <TableCell className={`sub-table-row-${state}`}>
                                        {/* Add total profit calculation logic here */}
                                        {/* Assuming it's the sum of all state profits */}
                                        {parseFloat(invoice[`total${state}ProfitMargin`]).toFixed(
                                          3,
                                        )}{' '}
                                        {/* Example of total profit */}
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                  {/* Total Row */}
                                  <TableRow key="TOTAL" sx={{ color: 'white' }}>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      component="th"
                                      scope="row"
                                      sx={{ color: 'white' }}
                                    >
                                      TOTAL
                                    </TableCell>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      sx={{ color: 'white' }}
                                    >
                                      {['ON', 'QC', 'NS', 'NB', 'NFL', 'MB', 'SK', 'AB', 'BC', 'YT']
                                        .reduce(
                                          (sum, state) =>
                                            sum + parseFloat(invoice[`totalDiesel${state}`]),
                                          0,
                                        )
                                        .toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      sx={{ color: 'white' }}
                                    >
                                      {['ON', 'QC', 'NS', 'NB', 'NFL', 'MB', 'SK', 'AB', 'BC', 'YT']
                                        .reduce(
                                          (sum, state) =>
                                            sum + parseFloat(invoice[`totalRfr${state}`]),
                                          0,
                                        )
                                        .toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      sx={{ color: 'white' }}
                                    >
                                      {['ON', 'QC', 'NS', 'NB', 'NFL', 'MB', 'SK', 'AB', 'BC', 'YT']
                                        .reduce(
                                          (sum, state) =>
                                            sum + parseFloat(invoice[`total${state}Qty`]),
                                          0,
                                        )
                                        .toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      sx={{ color: 'white' }}
                                    >
                                      {['ON', 'QC', 'NS', 'NB', 'NFL', 'MB', 'SK', 'AB', 'BC', 'YT']
                                        .reduce(
                                          (sum) => sum + parseFloat(invoice[`profitMarginWithTax`]),
                                          0,
                                        )
                                        .toFixed(3)}
                                    </TableCell>
                                    <TableCell
                                      className="sub-table-body-cell"
                                      sx={{ color: 'white' }}
                                    >
                                      {['ON', 'QC', 'NS', 'NB', 'NFL', 'MB', 'SK', 'AB', 'BC', 'YT']
                                        .reduce(
                                          (sum, state) =>
                                            sum + parseFloat(invoice[`total${state}ProfitMargin`]),
                                          0,
                                        )
                                        .toFixed(3)}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
}

export default Invoice;
